/* search result scss */

.otis-search{
    $_searchBtnWidth : 70px;
    $_inputHeight: 35px;
    $_closeIconWidth: 20px;
    @include mainTextSize();
    @media #{$phone}{
	  @include height-vh-patch("100vh");
      overflow:hidden;
	}
	
	.search-result-info {
		text-align: center;
		margin-bottom: 10px;
		font-style: italic;
		
		.currentPageInfo {
			font-weight: bold;
		}
	}
	
	.search-result-pagination {
		text-align: center;
		margin-top: 20px;
	}
	
    .search-text-input{
        width:calc(100% - #{$_searchBtnWidth}  );
        vertical-align:middle;
        height: $_inputHeight;
        line-height: $_inputHeight;
        font-size: inherit;

    }
    .search-btn{
        width: $_searchBtnWidth - 5px;
        height: $_inputHeight;
        line-height: $_inputHeight;
        padding:0;
        margin:0;
    }
	.icon-spinner{
		width: 1.5rem;
		height: 1.5rem;
		vertical-align: middle;
	}
	.pagination{
		margin: 10px 0 !important;
	}
	.pagination-prev, .pagination-first, .pagination-next, .pagination-last {
			a{padding:6px !important;}
			span{padding:6px !important;}
	}


}

.icon-spinner{
  display: inline-block;
  animation: spinnerRotation 2s infinite linear;
  -webkit-animation: spinnerRotation 2s infinite linear;
  -moz-animation: spinnerRotation 2s infinite linear;
}

@keyframes spinnerRotation{
  from{ transform: rotate(0deg)}

  to{ transform:rotate(360deg)}
}

@-webkit-keyframes spinnerRotation{
  from{ transform: rotate(0deg)}

  to{ transform:rotate(360deg)}
}

@-moz-keyframes spinnerRotation{
  from{ transform: rotate(0deg)}

  to{ transform:rotate(360deg)}
}

.search-result-body{
	$_searchHeader: 50px;
	$_searchInput: 100px;
	$_searchFooter: 55px;
	padding: 0px 15px 15px 15px;
	@include max-height-vh-patch("95vh  - #{$_searchHeader}  - #{$_searchInput}  - #{$_searchFooter} ");
	$_mainColor: $main-text;
	@include scrollTinyBar($_mainColor,$barSize);
	@media #{$phone}{
		padding: 0px 15px 30px 15px !important;
		@include height-vh-patch("100vh  - #{$_searchHeader}  - #{$_searchInput}  - #{$_searchFooter} ");
	}

}

.search-results{
	@include smallTextSize();
	line-height:$h4 * 1.5;
	margin-bottom:1em;
	.label{
		padding:0 !important;
		color: purple;
	}

	.result-title{
		font-size:inherit;
		.result-type{
			font-size:inherit;
		}
		a{
			font-size:inherit;
		}
	}

	pop-search-highlightings{
		font-style: italic;
		em{
			font-weight:bold;
			color: $warm;
		}
	}

	.result-origin{
		font-size:1em;
		white-space:normal;
		.origin-tag{
			font-size:inherit;
			white-space:normal;
		}
		.origin-section{
			font-size:inherit;
			white-space:normal;
		}
		.label{
			font-size:inherit;
			white-space:normal;
		}
	}
}
