

.mobile-view{
	&.otis-content{
		@media #{$phone}{
			background: white;
		}
	}
	#project-list-box{
		.box-header{
			color: black !important;
			input{
				border-color: lighten(gray, 20%);
				width: 100%;
				@media #{$miniPhone}{
					max-width:8em;
				}

			}
			$_placeHolderColor: lightgray;
			@include otisPlaceholderColor($_placeHolderColor); 
		}
		.add-project-card{
			box-shadow:0px 1px 3px rgba(0,0,0,0.6) !important;
			.add-icon{
				font-size: 2em !important;
				line-height:initial !important;
			}
		}
	}
	
	.myTodo-list-div{
		color:$main-text;
		.tab-content{
			background:inherit;
		}
	}
	.myNotice-list-div{
	

		.myNotice-zone{
			background: $notice-mobile-background;

		}
		.otis-modal-header{
			// color:black;
			//background:whitesmoke; todo#40942:使用Mobile時，MyTodo 的Title設計應與 MyNotice 統一。[Jane 2018-10-05 14:58:02]
			.header-text-h2{
				@include headerText();
			}
		} 
	} 
	.notice-card.unread{
		//background: rgba(20,20,20,0.6);
	}
	.view-title{
		//background:whitesmoke;
		color:black;
	}
	.view-page-header, .backlog-page-title{
		font-weight:600;
	}
	.selected-phase-title{
		.circle-icon{
			background:gray;
		}
	}
	.discussion-sel-topic-title{
		background:whitesmoke;
		border-top: 1px solid lightgray;
		border-bottom: 1px solid lightgray;
		.sel-topic{ 
			color:$main-text !important; 
			font-weight: 600;
		}
	}
	.discussion-topic-add{
		.form-contorl{
			font-size:1.2em !important;
		}
	}
	.discussion-detail{
		background: white;
	}
	.panel{
		background:white;
		border-radius:0;
	}
	.file-view-body{
		background:white;
	}
	.project-info-div{
		select{
			width:100%;
			max-width:100%;
		}
		.tabs,select{
			color:$main-text;
		}
		.effort-number{
			border:1px solid lightgray;
		}
		.tab-pane{
			overflow-y:auto;
			overflow-x:hidden;
			padding-bottom: 100px;
		}
		latest-delete-info{
			background: white;
			color:$main-text;
		}
		.sub-title{
			color: $main-text !important;
		}
		.del-itemName-div{
			background:initial !important;
			color: $main-text  !important;
		}
		.recent-deletedInfo-card{
			background:whitesmoke;
		}
		.info-row{
			color: $main-text  !important;
		} 
		latest-activity-modal{
			background:initial !important;
			color:initial !important;
		}
		.pop-goto-item{
			color: initial !important;
		}
	}

}
.mobileMenu-main {
	background-color: $mobileMenu-main; 
	overflow-y:auto;
	height: calc(100vh - #{$navbarHeight});
	position:fixed;
	left:0;
	width:100vw;
	padding-bottom: 80px;
	$_fontSize: 13px;
	//@include h2sTextSize();
	//todo#48316:請問是否有調整字型大小？在iPhone retina 上，字型顯得太小了。 [Jane 2019-01-21 09:26:57]
	@include retinaFontSize($_fontSize);
	@include unSelectable();
	
	@media #{$phone}{ 
		@include height-vh-patch("100vh - #{$sidebarIconHeight} - #{$navbarHeight}");
	}
	// $_bottomHeight: 150px;
	// @include overflowBottomSpace($_bottomHeight);
	// @media #{$tablet}{ // temp remove until isMobile can set support tablet size
	// 		padding-bottom:100px;
	// 		@include height-vh-patch("100vh - #{$sidebarIconHeight} - #{$navbarHeight} - 5px");
	// }
	.current-user{
		padding: 10px;
		position:absolute;
		right:0;
		.user-name{
			max-width: 8em;
			$_lineHeight:1.3em;
			$_lines:3;
			@include multiline-ellipsis($_lineHeight, $_lines);
		}
	}
	
}

.my-dashboard{
	padding: 0px 10px;
    display: flex;
    flex-wrap: wrap;
	justify-content: space-between;
}
.otis-card{
	box-shadow: 0px 1px 3px rgba(0,0,0,0.4);
	flex-grow:1;
	flex-basis:0;
	margin-bottom:15px;
	background: $otis-card-background; 
	font-size: 16px;
	
	// &.left {
	// 	border-radius: 5px 0px 0px 5px;
	// }

	// &.right {
	// 	border-radius: 0px 5px 5px 0px;
	// }
	
}
.card-header{ 
    color: #333; 
    height: 2.2em;
    line-height: 1em;
    display: table-cell; 
    width: 100%; 
    text-align: center;
    vertical-align: middle;
}
.card-body{
	background: $otis-card-body-background;
	font-size:1.3em; 
	padding:6px 0px;
	color:gray;
	padding: 6px 0px;
    min-height: 2.5em; 
    display: table; 
	width: 100%;
	border-top:1px solid rgba(lightgray, 0.8);
	text-align: center; // patch for firefox

	&.left {
		border-radius: 0px 0px 0px 5px;
	}

	&.right {
		border-radius: 0px 0px 5px 0px;
	}
	
}

.mobileMenu-searchBar{
	overflow: auto;
}

.mobileMenu-searchBar-icon{
	$_iconWidth:35px;
	$_mainTextColor : $header-text;
	color: $_mainTextColor;
	background-color: $navBarColor;
	height: $navbarHeight;
	line-height: $navbarHeight;
	font-size: $fontUnit * 1.5;
	text-align:center;
	width: $_iconWidth;
}

.mobileMenu-time {
	padding:10px;
	display: flex;
	align-items: center;
	flex-grow:1;
	@media #{$phone}{
		padding:0 10px;
	}
}

.mobileMenu-time-date {
	font-size: 70px;
	padding:0px 5px;
}

.mobileMenu-time-month {
	font-size: 22px;
}

.mobileMenu-time-day {
	font-size: 30px;
	line-height: 29px;
}

.mobileMenu-board {
	padding:0px 10px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

}
.mobileMenu-panel{
	margin-bottom: 15px;
    padding: 10px;
    // box-shadow: 0px 1px 3px rgba(0,0,0,0.4); [Jane 2019-03-06 14:46:57]
	width: calc( 50% - 7.5px);
	cursor:pointer;
	position:relative;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
	border-radius: 5px;
	
}

.mobileMenu-panel-description{
	 
}

.mobileMenu-panel--create {
	background-color: #fff;
}
.mobileMenu-panel--done {
	background-color: #c9f2cb;
}
.mobileMenu-panel--project {
	background-color: $mobileMenu-panel-project-background;
}
.mobileMenu-panel--todo {
	background-color: $mobileMenu-panel-todo-background;
}
.mobileMenu-panel--backlog {
	background-color:$mobileMenu-panel-backlog-background;
}
.mobileMenu-panel--review {
	background-color: $mobileMenu-panel-review-background;
}
.mobileMenu-panel--effort{
	background-color: $mobileMenu-panel-effort-background;
}
.mobileMenu-panel--managed{
	background-color: $mobileMenu-panel-managed-background;
}

.mobileMenu-panel--restore{
	background-color:lighten(lightgray, 5%);
}

.mobileMenu-panel--teamTimeSheet{ 
	background-color:floralwhite;
}

.mobileMenu-panel-title{
	display: flex;
	margin-bottom: 20px;
	@media #{$desktop}{
		// margin-bottom:65px;
        height: 70px;
	}
	
    justify-content: space-between;
    // align-items:center;
    font-size: 24px;

    & > i {
        font-size: 50px;
        opacity: 0.3;
    }

    .this-week-total-effort {
        .effort-title {
            font-size: 18px;
        }
        
    }

    .last-week-total-effort {
        font-size: 15px;

        .effort-value {
            font-size: 20px;
        }
    }
}

.mobile-add-new-todo-modal{
	.assigned-list{
		text-overflow: ellipsis;
		white-space: nowrap;
		overflow: hidden;
		border: 1px solid #b4b4b4 !important;
		padding: 15px !important;
		background: transparent;
	}

	.otis-toggle {
		@include hover-supported () {
			&.btn-transparent  {
				background: $toggle-hover;
				color: $toggle-color;
			}
		}
	}

	.otis-toggle-on {
		background: $toggle-hover;
		color: $toggle-color;
	}
}


//TODO: rename and move .myTodoList-modal [Benx 2018-09-28 14:45:48]
.myTodoList-modal{
	$_headerHeight:50px;
	.modal-header{
		height:$_headerHeight;
		line-height:$_headerHeight;
		padding:0 !important;
		border:initial;
		border-bottom: 1px solid lightgray;
		//margin: 0 5% !important; this will cause ie 11 has different behaviour
		@media #{$tablet}{
			//margin:0 3% !important;
		}
		@media #{$phone}{
			//margin:0 3% !important;
		}
	}
	
	.modal-body{ 
		
		height: calc(100% -  #{$_headerHeight}); 
		overflow:auto;
		padding: 6px 5% !important;
		background:whitesmoke;
		@media #{$tablet}{
			padding:6px  5%  important;
		}
		@media #{$phone}{
			padding:6px  3%  !important;
		}

		.otis-info-title{
			padding: 6px 12px;
			min-width: 8em;
			text-align:center;
			box-shadow: 0 5px 5px -5px #333;
			background: rgba(lighten($dynamicBackground, 50%), 0.6);
			
		}

		.todo-list{
			padding: 0px;
		}
		//background: rgba(lighten($dynamicBackground, 50%), 0.6);
	}

	.modal-footer{
		position:absolute;
		bottom:0;
		width: 100%;
	}

	.todo-list-brick{
		// padding-bottom: 100px !important;
	}
}

// NOTE: .addNewTodoModal definitions are moved to _addNewTodoModal.scss (by Benx)
