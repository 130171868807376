$_linkPreviewMainImageLogoHeight: 70px; 
$_linkPreviewMainImageLogoHeight_inCommentText: 100px; 

pop-link-preview {
	width: 90%;
    display: block;
    margin-left: auto;
	margin-right: auto;
	margin-top: 7px;
	border-left: 3px solid lightgray;
	padding: 8px;
    background: aliceblue;

	a {
// 		color: $main-text;
		color: #6e8da9;
		word-break: break-word;
	}

	.description {
		color: #585757;
	}
	
	.favIcon {
		max-width: 20px;
		max-height: 20px;
		float: left;
		margin-right: 3px;
	}
	
	img.linkPreview-mainImage {
		max-width: 100%;
	}
	
	
	img.linkPreview-mainImage-logo {
		width: $_linkPreviewMainImageLogoHeight;
		height: $_linkPreviewMainImageLogoHeight;
		margin-left: 7px;
		border-radius: 5px;
	}
	
	.isMainImageLogo .linkPreview-title {
		min-height: $_linkPreviewMainImageLogoHeight;
		max-height: 170px;
    	overflow: hidden;
	}
	
	.linkPreview-title {
		margin-bottom: 7px;
		text-align: left;

		.title-text {
			margin-bottom: 8px;
		}

	}
}

.todo-card {
	pop-link-preview {
		text-align: center;
		img.linkPreview-mainImage {
			max-width: 100%;
			max-height: 250px;
		}
	}
}

.comment-text {
	pop-link-preview {
		width: 75%;
		margin-left: 20px;
		
		img.linkPreview-mainImage {
			max-height: 330px;
		}
		
		img.linkPreview-mainImage-logo {
			width: $_linkPreviewMainImageLogoHeight_inCommentText;
			height: $_linkPreviewMainImageLogoHeight_inCommentText;
		}
		
		.isMainImageLogo .linkPreview-title {
			min-height: $_linkPreviewMainImageLogoHeight_inCommentText;
		}
		
		@media #{$phone} {
			width: 100%;
			margin-left: auto;
			margin-right: auto;
			
			img.linkPreview-mainImage {
				max-width: 100%;
			}
			
			img.linkPreview-mainImage-logo {
				width: $_linkPreviewMainImageLogoHeight;
				height: $_linkPreviewMainImageLogoHeight;
			}
			
			.isMainImageLogo .linkPreview-title {
				min-height: $_linkPreviewMainImageLogoHeight;
			}
			
		}
	}
	
}

/* https://stackoverflow.com/questions/25302836/responsive-video-iframes-keeping-aspect-ratio-with-only-css */
.aspect-ratio {	
	position: relative;
    width: 100%;
    height: 0;
	padding-bottom: 56%;
	
	iframe.video {
		position: absolute;
		width: 100%;
		height: 100%;
		left: 0;
		top: 0;
	}
}


.link-preview{
    text-align: left;
    width:600px;
    padding:10px;
    outline: black dotted 3px;
    margin : 10px;
    
}

.lp-favicon{
    float:left;
    img{
        width:20px;
    }
}

.lp-site-name{
    float:left;
    font-size: 18px
}

.lp-title{
    cursor: pointer;
    font-size: 18px;
    
}

.lp-description{
    font-size:14px;
}

.lp-thumbnail{
    img {
        border-radius: 4px;
        width:100%;
        min-height:100px;
    }
}

.lp-fail{
    text-align: left;
    width:600px;
    padding:10px;
    outline: black dotted 3px;
    margin : 10px;
}
