$ganttTaskMaxHeight : 2em;
.gantt-tree-body{
	ol{
		li{
			list-style-type: none;
		}
	}
	.btn[disabled]{
		.glyphicon-chevron-down:before{
			display:none;
		}
	}
	.angular-ui-tree-nodes{
		padding-left:6px;
		max-width: 30em; 
		.angular-ui-tree-nodes{
			padding-left:15px;
			.angular-ui-tree-node{
				padding-left:15px !important; 
			} 
		} 
	}

	.glyphicon-chevron-down:before {
		font-family: 'icomoon' !important;
		speak: never;
		font-style: normal;
		font-weight: normal;
		font-variant: normal;
		text-transform: none;
		line-height: 1;
	  
		/* Better Font Rendering =========== */
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		content: "\e9a7" !important; 
		color:slategray; 
		margin-right:3px;
		display: block;
	  }
	  
	  
	  .glyphicon-chevron-right:before {
		  font-family: 'icomoon' !important;
		  speak: never;
		  font-style: normal;
		  font-weight: normal;
		  font-variant: normal;
		  text-transform: none;
		  line-height: 1;
		
		  /* Better Font Rendering =========== */
		  -webkit-font-smoothing: antialiased;
		  -moz-osx-font-smoothing: grayscale; 
			content: "\e9a6" !important;
			color:slategray; 
			margin-right:3px;
			display: block;
	  }
	  
	  .gantt-valign-content{
		  align-items:center;
	  }
	  
}

.gantt-background{
	background:white; //@extend(244, 243, 233);
}

.gantt-side{
	min-width: 30vw;
	max-width: 70vw;
	.gantt-side-content{
		display:flex;
	}
	
}

.gantt-row{
	&:hover{
		background-color:rgba(slategray, 0.5)  !important;
	}
}

.gantt-current-date-line{
	border-color:orangered;
	border-style:dashed; 
	border-width: 1px;
}

.gantt-row-height{
	height:2.5em;	
}

.hide-outside-dom{
	position:absolute;
	left:-500px;
}

.task-done{
	.taskName-value{
		text-decoration:line-through;
	}
}

.gantt-task-group{
	top: -100%;
	opacity: 0.9;
}


.gantt-task-content{
	overflow:visible;
	>span{
	 
		margin:0; 
		color: slategray;
		left: calc(100% + 5px);
		position: relative;
		max-width: 20em;
		min-width: 20em;
		white-space: pre-wrap;
		$_lineHeight: 1.1em;
		$_lines: 2;
		@include  multiline-ellipsis($_lineHeight, $_lines);
		text-align: left;
	}	
}

.gantt-row-label {
	span.gantt-label-text {
		min-width:6em;
		padding-left:6px;
		padding-right:6px;
	}
	.taskName-value{
		$_lineHeight: 1.1em;
		$_lines: 2;
		@include  multiline-ellipsis($_lineHeight, $_lines);
	}
}

.taskName-value {
	min-width:10em; 
	cursor:pointer;
}

.task-done{
	$_taskDoneColor:rgb(160,160,160);
	color:$_taskDoneColor;
	.taskName-value{
		color:$_taskDoneColor;
	}
}

 
.gantt-scrollable{
	min-height: calc(100vh - 200px);
}


.gantt-time-label{
	font-weight: 1000;
    background: rgba(10,10,10,0.1);
    /* height: 2em; */
    /* line-height: 2em; */
    display: block;
    /* right: 0; */
    display: flex;
    height: calc(100% - 2px);
    align-items: center;
    padding: 5px;
    color: steelblue;
}

.gantt-corner-area{
	.gantt-header-row{
		.gantt-column-header{
			padding:0px;
			z-index:1;
			display: none;
		} 
	}
}

.gantt-resizer-enabled {
    cursor: w-resize;
    cursor: col-resize;
}
.gantt-valign-content{
	display:flex; // when wrap will keep text align left
}

.gantt-label-text{
	white-space:normal;
	word-break:break-word;
}
