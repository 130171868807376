.ng-gallery {
  width: 100%;
  height: 100%;
  display: inline-block;

}

img.ng-thumb {
  height: 50px;
  float: left;
  display: block;
  cursor: pointer;
  margin: 2px 2px 0 0;
}

.ng-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #000;
  opacity: 0.85;
  z-index: 9999;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-user-drag: none;
}

.imgGallery-close{
  background-color: black;
  border: 2px solid;
  border-radius: 50%;
  width: 37px;
  text-align: center;
  margin-top: 4px;
  margin-right: 4px;
}

.ng-gallery-content {
	$_marginHeight: 75px;
	$_marginWidth: 60px;
	position: fixed;
 	top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10000;
  text-align: center;
  overflow: auto;
	.close{
		color: rgb(175,175,175) !important;
		opacity: 0.9;
		border-radius: 50%;
		border: 2px solid;
		font-size: 10px !important;
		padding: 3px !important;
		right: 7px !important;
		top: 7px;
		text-shadow: 0 1px 0 #fff;

		position: fixed !important;
		right: 20px !important;
		top: 12px;
		font-size: 14px !important;

		@media #{$phone} {
			right: 10px !important;
			font-size: 12px !important;
		}
		
		&:hover, &:active, &:focus {
			color: white !important;
		}
	}


  #fileImg{
    overflow: scroll;
  }

	img {
		height: auto;
		max-height: calc(100% - 150px);
		max-width: calc(100% - 120px);
		@media #{$phone} {
			max-height: 100%;
			max-width: 100%;
		}
		position: relative;
		display: block;
		margin: 0 auto 0 auto;
		top: 50%;
		transform: translateY(-50%);
		-webkit-transform: translateY(-50%); //cursor: pointer;
		-webkit-user-select: none;
		-khtml-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
		-webkit-user-drag: none;
		&.effect {
			animation: fadeIn 0.5s;
			background-color: white !important;
		}
		&.curr-preview-image {
			box-shadow: 0px 0px 0px 6px rgba(100, 100, 100, 0.8);
			border-radius: 4px;
		}
	
	}
	
	.ng-thumbnails-wrapper {
		$_thumbnailHeight: $_marginHeight - 5px;
		width: 400px !important;
		height: $_thumbnailHeight;
		text-align: center;
		position: fixed;
		bottom: - $_thumbnailHeight * 0.5;
		left: 0;
		right: 0;
		margin-left: auto;
		margin-right: auto;
		.ng-thumbnails {
			width: 4000px !important;
			height: $_thumbnailHeight;
			img {
				width: auto;
				height: $_thumbnailHeight;
				float: left;
				margin-right: 10px;
				cursor: pointer;
				border-radius: 2px;
				-webkit-filter: brightness(50%);
				-moz-filter: brightness(50%);
				filter: brightness(50%);
				&:hover, &.active {
					-webkit-filter: brightness(100%);
					-moz-filter: brightness(100%);
					filter: brightness(100%);
				}
			}
		}
	}
	
	a {
		color: #fff;
		text-decoration: none;
		// margin: 0 30px 0 0;
		cursor: pointer;
		//position: absolute;
		//top: 20px;

		&.close-popup {
			right: 0;
		}

		&.download-image {
			float: right;
			right: 45px;
			top: 15px;
		}

		&.nav-left,
		&.nav-right {
			color: white;
			opacity: 0.5;
			text-decoration: none;
			font-size: 60px;
			cursor: pointer;
			outline: none;
			z-index: 100;
			padding: 30px 5px;
			position: fixed;
			top: 50%;
			transform: translateY(-50%);
			transition: 1.5s;

			&.ng-hide {
				transition: 0s;
			}
			
			&:active {
				opacity: 1 !important;
				transition: all 0.3s;
			}

			@include hover-supported() {
				opacity: 0.8;
			}

		}

		&.nav-left {
			left: 30px;
			
		}

		&.nav-right {
			right: 30px;
		}

		@media #{$phone}{
			&.nav-left, &.nav-right {
				font-size: 40px;
			}

			&.nav-left {
				left: 5px;
				
			}

			&.nav-right {
				right: 5px;
			}
		}
	}

  .icon-download {
    font-size: 28px;
  }



}


@keyframes fadeIn {
    from { opacity: 0.3; }
    to { opacity: 1; }
}

.ng-gallery-content > span.info-text {
	color: #fff;
	display: inline-block;
	width: 100%; //height: 20px;
	font-weight: bold;
	text-align: center;
	position: fixed;
	left: 0;
	right: 0;
	top: 20px;
	.image-info {
		border-radius: 20px;
		padding: 5px 30px;
		background: rgba(128, 128, 128, 0.4);
		opacity: 0.9;
		&:hover {
			background: rgba(128, 128, 128, 0.8);
			opacity: 1;
			
			.icon-download {
				color: yellow;
			}
		}
	}
	.icon-download {
		margin-left: 10px;
		vertical-align: middle;
		font-size: 1.5em;
	}
}





/* Loading - from http://loading.io */
/*
uiload {
  display: inline-block;
  position: relative;
  div {
    position: relative;
  }
}


@-webkit-keyframes uil-ring-anim {
  0% {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-moz-keyframes uil-ring-anim {
  0% {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
    }
}

@-ms-keyframes uil-ring-anim {
  0% {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes uil-ring-anim {
  0% {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.uil-ring-css {
  background: none;
  position: fixed;
  top: 0;

  width: 100vw;
  height: 100vh;
  div {
	    $_size: 50px ;
		$_color: rgba(darken(lightgray, 40%), 0.6) ;
	    position: fixed;
	    display: block;
	    width: $_size;
	    height: $_size;
	    top: 50%;
	    left: 50%;
	    margin-left: - $_size * 0.5;
	    margin-top: - $_size * 0.5;
	    border: solid    $_size * 0.2   transparent;
		border-top-color: $_color;
		border-left-color: $_color;
		border-bottom-color: $_color;
		border-radius: 50%;

	    -ms-animation: uil-ring-anim 1s linear infinite;
	    -moz-animation: uil-ring-anim 1s linear infinite;
	    -webkit-animation: uil-ring-anim 1s linear infinite;
	    -o-animation: uil-ring-anim 1s linear infinite;
	    animation: uil-ring-anim 1s linear infinite;
	}
}
*/