/* iconTips scss */


.iconTips-right[iconTipsTitle]{
	&:after{
		left:initial !important;
		left:auto !important; // for ie
		right:0 !important;

		}
}
.iconTips-lgWidth[iconTipsTitle]{
	&:hover{
		&:after{
			min-width:12em !important;

		}
	}
}

.iconTips-fullWidth[iconTipsTitle]{
	&:hover{
		&:after{
			width:100% !important;
			padding: 0px !important;
		}
	}
}

.iconTips-userSetting[iconTipsTitle]{
	&:hover{
		&:after{
			width:14em !important;
	    	padding:6px !important;
			margin-top:6px !important;
		}
	}
}

.iconTips-sprintDetail[iconTipsTitle]{
	&:hover{
		&:after{
			width:14em !important;
	    	padding:3px !important
		}
	}
}

.iconTips-editProjectDetails[iconTipsTitle]{
	&:hover{
		&:after{
			width:10em !important;
	    	padding:3px !important
		}
	}
}

.otisTips-notice[iconTipsTitle]{
	&:hover{
		&:after{
			width:14em !important;
	    	padding:3px !important
		}
	}
}

.iconTips-eyesWatch[iconTipsTitle]{
	&:hover{
		&:after{
			width:14em !important;
	    	padding:3px !important
		}
	}
}

.iconTips-assignee[iconTipsTitle]{
	&:hover{
		&:after{
			width:10em !important;
	    	padding:3px !important;
			bottom:130% !important;
			top :  auto !important; // for ie
	    	top: initial !important;
		}
	}
}

.iconTips-sprintEditOption[iconTipsTitle]{
	&:hover{
		&:after{
			width:12em !important;
	    	padding:6px !important;
		}
	}
}


.iconTips-showHideTodo[iconTipsTitle]{
	&:hover{
		&:after{
			width:14em !important;
	    	padding:6px !important;
		}
	}
}

.enableOtisHelpTips{

*[iconTipsTitle]{
	position:relative;
	&:after{
			padding: 8px 12px;
			@include smallTextSize();
			color: #85003a;
			position: absolute;
			left: 0;
			top: 100%;
			white-space: pre-wrap;
			width:0;
			height:0;
			line-height: 1.3em;
			text-align: left;
			z-index: -1;
			opacity: 0;
			-moz-border-radius: 3px;
			-webkit-border-radius: 3px;
			border-radius: 3px;
			-moz-box-shadow: 0px 0px 2px #c0c1c2;
			-webkit-box-shadow: 0px 0px 2px #c0c1c2;
			box-shadow: 0px 0px 2px #c0c1c2;
			background-image: -moz-linear-gradient(top, #ffffff, #eeeeee);
			background-image: -webkit-gradient(linear,left top,left bottom,color-stop(0, #ffffff),color-stop(1, #eeeeee));
			background-image: -webkit-linear-gradient(top, #ffffff, #eeeeee);
			background-image: -moz-linear-gradient(top, #ffffff, #eeeeee);
			background-image: -ms-linear-gradient(top, #ffffff, #eeeeee);
			background-image: -o-linear-gradient(top, #ffffff, #eeeeee);
			transition: 0s;
		}
	&:hover{
		&:after{
			$_delayTime: 0s;
			$_animationTime : 5s;
			content: "\e946" " " attr(iconTipsTitle);
			font-family: icomoon,$context-font;
			//color:white;
			//background:darkslategray;

			z-index:500;
			min-width: 8em;
			width: auto;
			height: auto;
			pointer-events:none;
			-moz-animation: cssAnimation $_animationTime ease-in $_delayTime forwards;
		    -moz-animation-delay: $_delayTime;
		    /* Firefox */
		    -webkit-animation: cssAnimation $_animationTime ease-in $_delayTime forwards;
		    -webkit-animation-delay: $_delayTime; /* Chrome, Safari, Opera */
		    /* Safari and Chrome */
		    -o-animation: cssAnimation $_animationTime ease-in $_delayTime forwards;
		    -o-animation-delay: $_delayTime; /* Chrome, Safari, Opera */
		    /* Opera */
		    animation: cssAnimation $_animationTime ease-in $_delayTime forwards;
		    animation-delay: $_delayTime; /* Chrome, Safari, Opera */
			
			-webkit-animation-fill-mode: forwards;
		    animation-fill-mode: forwards;



		}
	}
}
@media #{$phone}{
	*[iconTipsTitle]{
		&:after{
			display: none;
		}
	}
}

}
.iconTips-before[iconTipsTitle]{
	&:before{
			padding: 8px 12px;
			color: #85003a;
			position: absolute;
			left: 0;
			top: 100%;
			white-space: pre-wrap;
			width:0;
			height:0;
			line-height: 1.3em;
			text-align: left;
			z-index: -1;
			opacity: 0;

			-moz-border-radius: 3px;
			-webkit-border-radius: 3px;
			border-radius: 3px;
			-moz-box-shadow: 0px 0px 2px #c0c1c2;
			-webkit-box-shadow: 0px 0px 2px #c0c1c2;
			box-shadow: 0px 0px 2px #c0c1c2;
			background-image: -moz-linear-gradient(top, #ffffff, #eeeeee);
			background-image: -webkit-gradient(linear,left top,left bottom,color-stop(0, #ffffff),color-stop(1, #eeeeee));
			background-image: -webkit-linear-gradient(top, #ffffff, #eeeeee);
			background-image: -moz-linear-gradient(top, #ffffff, #eeeeee);
			background-image: -ms-linear-gradient(top, #ffffff, #eeeeee);
			background-image: -o-linear-gradient(top, #ffffff, #eeeeee);
			transition: 0s;

		}

	&:hover{
		&:before{
			$_delayTime: 0s;
			$_animationTime : 5s;
			content: attr(iconTipsTitle);

			z-index:500;
			min-width: 8em;
			width: auto;
			height: auto;

			-moz-animation: cssAnimation $_animationTime ease-in $_delayTime forwards;
		    -moz-animation-delay: $_delayTime;
		    /* Firefox */
		    -webkit-animation: cssAnimation $_animationTime ease-in $_delayTime forwards;
		    -webkit-animation-delay: $_delayTime; /* Chrome, Safari, Opera */
		    /* Safari and Chrome */
		    -o-animation: cssAnimation $_animationTime ease-in $_delayTime forwards;
		    -o-animation-delay: $_delayTime; /* Chrome, Safari, Opera */
		    /* Opera */
		    animation: cssAnimation $_animationTime ease-in $_delayTime forwards;
		    animation-delay: $_delayTime; /* Chrome, Safari, Opera */
			
			-webkit-animation-fill-mode: forwards;
		    animation-fill-mode: forwards;

		}
	}
}


@keyframes cssAnimation {
    0% { opacity:0;}
    10% {opacity:0;}
    20% {opacity:1;}
    90% {opacity:1;}
    100% {opacity:0;}
}

@-webkit-keyframes cssAnimation {
    0% { opacity:0;}
    10% {opacity:0;}
    20% {opacity:1;}
    90% {opacity:1;}
    100% {opacity:0;}
}


.otis-markdownTips{ 
    color: gray;
    text-align: right;
    font-size: 12px;
    position: absolute;
    right: 0;
    top: -22px;
    background-color: whitesmoke;
	padding: 2px 5px;
	animation: fadeIn 1s;
	border: 1px solid lightgray; 
	animation-fill-mode: forwards;
    //filter: drop-shadow(0px 0px 1px gray);
	a{
		margin-left: 5px;
	}
	&:before{
    content: '';
    border: solid 5px;
    border-color: whitesmoke transparent transparent transparent;
    position: absolute;
    bottom: -10px;
    right: 10px;
  }
  &.ng-hide-add{//this is for when markdown'show hide is 'ng-focus', to let user can click links
	  opacity: 0;
	  transition: 0.2s;
  }
  @media #{$phone}{
    display: none;
  }
}
