/* fullCallender */
.fc-unthemed th, .fc-unthemed td, .fc-unthemed thead, .fc-unthemed tbody,
.fc-unthemed .fc-divider, .fc-unthemed .fc-row, .fc-unthemed .fc-content,
.fc-unthemed .fc-popover, .fc-unthemed .fc-list-view, .fc-unthemed .fc-list-heading td {
	border-color:$viewTitleTextColor !important;
}

.fc-content{
	cursor:pointer;
}

.fc-today {
    //background: rgba(100,100,100,1) !important;
    //color: white !important;
    color: gray !important;
    background: navajowhite !important;
 }

.fc-header.fc-widget-header{
	color: darkslategray;
}

.fc-body.fc-widget-content{
	.fc-day-grid-event{
		margin:2px !important;
	}
}

.fc-more{
    color: white;
    text-shadow: 0px 0px 10px black;
}
.fc-event-container {

  .fc-event {
   // background-color: rgba(50,50,50,0.6) !important;
    background: rgba(lighten($dynamicBackground, 5%),1);
    color: white !important;
    padding: 3px;
  }

  .event-todo-done {
    background-color: darkgray !important;
    border-color: darkgray !important;
    color:gray !important;
    &.event-hovered{
    	background-color: darkgray !important;
	    border-color: darkgray !important;
	    color:gray !important;
    	//background-color: gray !important;
	    //border-color: gray !important;
	    //color:lightgray;
    }
  }

  .event-hovered{
    	background-color:rgba(lighten($dynamicBackground, 55%),1) !important;
    	color:  rgba(darken($dynamicBackground, 5%),1) !important;
   }

}
