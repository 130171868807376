/* memberView scss */
.sprint-member-view-container{
	@include OtisPagePadding();


	.member-view{
		$_headerHeight: 50px;

		.member-edit-icon{
			position:absolute;
			right:0px;
			line-height:$_headerHeight;
			height:$_headerHeight;
		}
		.one-line-header{
			width:100% !important;
		}
		.dd-list-bottom-absolute{
			position:absolute;
			bottom:0;
			background: $navBarColor;
		}
		.dd-list{

			$barColor: gray;
			@include scrollTinyBar($barColor,$barSize);
		}

	}

	#member-scroll-container{
		overflow-x:auto;
		overflow-y:hidden;

		.todo-info {
			.mySprintName {
				display: block;
			}
		}
	}
	.dd-swimlane{

		&.backlog-color{
			$_mainColor: $memberViewBacklogColor;
			$_mainColor: transparent;
			$_hoverPercent: 20%;
			$_bottomPercent: $memberViewBottomPercent;
			$_todoCardTextColor:  $memberViewTodoCardTextColor;
			$_listHeaderTextColor: white;
			@include todoCard($_mainColor,$_hoverPercent,$_bottomPercent,$_todoCardTextColor,$_listHeaderTextColor);
			background:transparent;
			.dd-list-bottom{
				background: transparent ;
				border-color:lighten($viewTitleTextColor, 40%)  !important;
				color:$viewTitleTextColor  !important;
				border-radius:0px  !important;
			}
			.dd3-content{
				&:hover{
					background:rgb(220,220,220) !important;
				}
			}
			.otis-tips{
				 background: white !important;
				 overflow-y: initial !important;
				 color: inherit !important;
				 margin-bottom: 1em;
			}
		}
		&.member-color{
			$_mainColor: $memberViewColor;
			$_hoverPercent: 60%;
			$_bottomPercent:$memberViewBottomPercent;
			$_todoCardTextColor: $viewTitleTextColor;
			$_listHeaderTextColor: $viewTitleTextColor;
			@include todoCard($_mainColor,$_hoverPercent,$_bottomPercent,$_todoCardTextColor,$_listHeaderTextColor);
			.dd-list{ 
				&.scroll-tiny-bar{ 
					@include scrollTinyBar( $memberViewColor,$barSize);
				}
			}
		}
	}


}

#unassignedSwimlane .dd-list-bottom-absolute{
	background: $dynamic-dd-list-bottom-background;
}