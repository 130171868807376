/** login scss **/


#loginForm {
	#rememberMe:focus + label {
		text-decoration: underline;
	}
	.login-area{
		position: relative;
		margin-bottom:6px;
		//height: 60px;
		 @media #{$phone}{
	    	//height: 8rem;
	    	margin-bottom:1.5em;
	    }

		.input-tips{
			//position: absolute
			//bottom: 0px;
			clear: left;
			display: block;
			font-size:1rem;
			input[type='checkbox'],label{
				height:1em;
				line-height:1em;
				margin: 0 auto;
				vertical-align:top!important;
				font-size:1rem;
			}
			&.no-block{
				display: inline-block!important;
				span{
					height:1em;
					line-height:1em;
					margin: 0 auto;
					vertical-align:top!important;
					font-size:1rem;
					color: #fff;
				}
			}
		}
		.input-tips-a {
			//position: absolute;
			//bottom: 0px;
			display: block;
			font-size:1rem;
			.strength{

			}
			.strength-meter{
				 display:none !important;
			}

		}
	}
}

.login-img{
	img{
		width:3em;

	}
}

.xs-register-div{
	$_width: 100vw;
	$_height:100vh;
	position: fixed;
	padding:0 2em;

	@media #{$phone}{
		padding: 0;
	}
	top: (100vh - $_height) * 0.5;
	left: (100vw - $_width) * 0.5;

	height: $_height;
	width: $_width;
	z-index:100 !important;
	//background: rgba(lighten($warm, 30%), 1);
	box-shadow: 0px 0px 50px black;
	background-image: url("../../assets/img/home/banner-2.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
	background-color:white;

	.title{
		margin:0;
	}
	label{
		font-weight: 400;
		//color: white;
		margin-bottom:0 !important;
	}
	.close{

		opacity:0.5;
		position:absolute;
		right:0em;
	}
	input{
		color: darken($main-text, 30%);
	}
	hr{
	    border-top: 1px solid $main-text;
	}
}




.input-lg{
	@include h2TextSize();
}

.btn-create-account{
  color:#999999;
  background: transparent;
  border: 2px solid gray;
  border-radius:5px;
  padding:6px;
  margin:0px;
  display:inline-block;
  height:40px;
  font-size:18px;
}



.login-or{
  @include h2TextSize();
  position:absolute;
  left:42%;
  margin-left:-4px;
  padding:0px;
  line-height:40px;
  bottom:0px;
}

.login-valid{
	position:absolute;
	top: 1em;
	right: 1.5em;
}

.create-account-or{
  color:#006666 !important;
  @include h2TextSize();
  position:relative;
  left:28%;
  margin-left:-20px;
  padding:0px;
  line-height:40px;
  bottom:0px;
}

.create-account-or.invitation{
  color:#006666 !important;
  @include h2TextSize();
  position:relative;
  left:initial;
  margin-left:initial;
  padding:initialx;
  line-height:initial;
  bottom:initial;
  text-align:center;
  margin-top:6px;
  margin-bottom:6px;
}
.even-background {
    /*background: linear-gradient(to bottom, #9de8ea 0%, #bfe8f9 28%, white 100%);*/
    background: lighten(lightgray, 20%);
}

.odd-background {
    /*background: linear-gradient(to bottom, #9de8ea 0%, #bfe8f9 28%, white 100%);*/
    background: lighten(lightgray, 15%);
}

#userRegisterPage{
	.control-group{
		//margin-bottom:0.5em;
		label{
			@include h2TextSize();

			text-align: left;
			font-weight:400;
			&.must-fill{
				text-indent:-0.5em;
			}
		}
		.controls{
			margin-bottom:0.5em;
			input{
				@include h2TextSize();
				width:100%;
				border-radius:0%;
				font-weight:200;
				padding:10px 6px;
				font-size:inherit;
				border:1px solid lightgray;

			}
		}
	}
	.error{
		color:red;
		background:transparent;
	}
	.regist-btn{
		button[disabled="disabled"]{
			background: lighten($main-text, 30%);
			color: lighten($main-text, 50%);
			border:none;
		}

	}

}

.resend-verification {
	color: white;
	opacity: 0.8;
	margin-top: 0.5rem;
	&:hover {
		color: white;
	}
}

.home-register-navbar{
	$_height: 35px;
	$_mainText: $white;
	$_mainBackround: $main-background-color;
	background: $homepageBackground;
	padding:0.5em 0.5em 0 0.5em;

	.login-text{
		display:inline-block;
		padding-right:1em;
		//height: $_height;
		line-height:1 ;
		color: $_mainText;
		@include h2TextSize();

	}
	.strength{
		color: $_mainText !important;
	}
	.strength-meter{
	}
	.btn-login {

		$_mainColor: $warm;
		$_mainText: white;
		background: $_mainColor;
		color: $_mainText;
		@include mainTextSize();
		float: left;
		border: 1px solid $homepageBorder;
		padding:0;
		border-radius:0;
		//margin-top:-5px;
		text-align: center;
		display:inline-block;
		height: $_height  ;
		line-height: $_height  ;
		width:calc(100%);
		margin-bottom:5px;
		@media #{$phone,$bootstrapMdSize}{
			width:100% !important;
		}
		&:hover{
			color:#ffffff !important;
			border: 1px solid darken($_mainText, 20%);
		}
	}
	.form-group{
		@include mainTextSize();
		padding-right:0.5em;
		@media #{$phone}{
			padding-right:0;
		}
		//margin-top:0.5em;
		input{
			margin-bottom:5px;
			border:0;
			font-weight:200;
			@include h2TextSize();

			&.has-success{
				//border:1px solid white;
				background:white;
				height: $_height;
				//line-height: $_height;
				width:100%;
				padding:0 0.5em;

			}
		}
		a, label{
		  	color:white;

				font-weight: 300;
		  	@include smallTextSize();
		  	//width:9em;
		  	height: 	1em;
		    line-height: 	1em;
		    margin: 0 auto;
		    vertical-align: top !important;

		}

	}
	.alert{
		padding:0.2em;
    	margin-bottom: 0px;
   		border: 0px;
    	border-radius: 0px;
    	position: absolute;
    	bottom: 0;
    	top: 0px;
    	z-index: 100;

    	&.alert-danger{
		    background: transparent;
		    border-color: #f86c6b;
		    color: red;

    	}
	}
	.login-register-btn{
		margin-top:10px;
		@media #{$phone}{
			margin-top:0;
		}
	}
	.orGoogle-login{
		padding-right :8px;
		display:inline-block;
		text-align:center;
		$btnHeight: 50px;
		@include mainTextSize();
		height:$btnHeight;
		line-height:$btnHeight;
		color: white;
		float: right;
		vertical-align:bottom;
		//margin-left:0.5em;
	}
	.otis-login-btn{
		padding-right: 0;
	}
	.google-login-button{
			height:$_height !important;
			line-height: $_height !important;
			padding: 0 12px !important;
			width: calc(100% - 30px) !important;
			 
			@media #{$phone}{
				width: 100% !important;
				height: 50px !important;
			}
		.google-img{
			height:$_height * 0.6 !important;
			//line-height: $_height * 0.8 !important;

		}
	}
	#gsignin2{
		display:inline-block;
		//float: left;
		//margin-left:5px;
		//clear: right;
		width:80%;
		vertical-align:top;

		.abcRioButton{
			height:$_height !important;
			line-height: $_height !important;
			box-shadow:none !important;
			.abcRioButtonIcon{
				padding-top: 8px;
				padding-left: 6px;
			}
			.abcRioButtonContents{
				@include mainTextSize();
				font-family: $context-font !important;
			}
		}
	}
	#gsignin3{
		display: none;
		@media #{$phone,$bootstrapMdSize}{
			display: block;
			position: relative;
			.abcRioButton{
					@inlcude sloganContextTextSize();
					padding:0.6em 0.6em;
					padding-right:0.8em;

	  		}
	  		.abcRioButtonIcon{
	  				vertical-align: middle;
	  				position: absolute;
	  				top: calc(50% - 9px);
	  		}
			.abcRioButtonContents{
				font-family:$context-font!important;
			}
		}
	}

}



.banner{
	background-image: url("../../assets/img/home/otis-banner-01.png");
   	background-size: cover;
    background-repeat: no-repeat;
    // width: 100vw;
    background-position: center;
    padding:2% 5%;
    @media #{$phone}{
    	padding: 5% 2%;
    }

    .slogan-padding{
      @media #{$phone}{
        margin-bottom:75%;
      }
    }
    .language-option{
    	position: absolute;
    	right:2em;
    	top:0;
    	cursor:pointer;
    	.btn-link {
    		margin-left: 1em;
    		&.active{
    			font-weight: 800;
    			text-decoration:underline;
    		}
    	}

    }
    .register-div{
				@media #{$phone}{
					&.first-registration{
						display:none;
					}
				}
				@include smartBreakWord();
				padding:3em 2em;
				@media #{$tablet}{
					padding:0;
				}
				@media #{$phone}{
					padding: 10px;
				}
				.register-form{
					padding:1em 0em;
				.title {
						@include h1TextSize();
						line-height: 1 !important;
				}
				.register-input{
						.strength{
							display:none !important;
							&.hide_strength{
								display:none !important;
							}
						}
						.strength-meter{
							position: absolute !important;
							bottom: 0 !important;
							top: auto !important; // for ie
							top: initial !important;
							width: 55% !important;
							.strong{
								padding:0;
								margin:0;
							}
						}
				}
		}
	}
	.sloganText{
		@include sloganSubTitleSize();
		line-height: 1 !important;
	    margin: 0 10% 0 10%;
	    font-weight: 500;


	   /* text-shadow: 1px 1px 1px white; */
	    text-align: center;

		li{
			list-style-type:none;

			margin-bottom: 10px;
			// color:lighten($warm, 10%);
      			color : white;
			font-weight: 300;
    			text-shadow: 2px 2px 2px darkslategray;
		}
	}

}



.name{
    color: white;
    font-weight: 600;
    text-shadow: 2px 2px 5px #4f4f4f;
	font-size: 7 * $fontUnit;
    text-align: center;
    @media #{$phone}{
    	font-size: 4 * $fontUnit !important;
    }
}

.app-download{
	img{
		width:100%;
	}
}

.otisSlogan{
      	color: white;
    	text-shadow: 2px 2px 5px #4f4f4f;
	@include sloganTitleSize();
	line-height: 1 !important;
	text-align: center;
	margin-bottom: 0.5em;
	@media #{$phone}{
		margin-top: 1em;
	}

}

.otisSlogan-subTitle{
	color: $warm;
	@include sloganSubTitleSize();
	line-height: 1.2 !important;
	text-align: center;
	margin-bottom: 0.5em;
}

.ios-app-padding{
	padding: 15px;
	@media #{$tablet}{
		padding: 12px;
	}
	@media #{$phone}{
		padding:10px;
	}

}


.startBtn{
	@include sloganContextTitleSize();
	height:4vw;
	line-height:4vw;
	background-color:#5180B7;
	color:#fff;
	text-align: center;
	margin: 0 35%;
}

.home-sections{
	&.sectionBk,.sectionBk{
	    padding: 8vh 20vw;
	    overflow: auto;

	    min-height: 50vh;
	    // display: inline-block;
	    .section-text{
				@include sloganContextTextSize();
				color:lighten($main-text, 10%);
				//line-height: 1.3 !important;
				margin:1vh 0;
				font-weight:300;
			}
			.textTitle{
				@include sloganContextTitleSize();
				line-height: 1.2 !important;
				font-weight: 500;
				color:lighten($warm, 10%);
				margin:2vh 0;
			}

	}

}




.sectionImg{
	width:100%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}






.sectionBk-b{
    background-color: #D9F1FF;
    padding: 3% 10%;
    .start-section{
	    margin: 0px 15%;
	}

	.otisGetstart{
		width:100%;
		margin:5vh 0;
	}


}

.footerText{
	$_mainBackround: $main-background-color;
	background: $homepageBackground;
	height:10vh;
	line-height:10vh;
	text-align: center;
	color:white;
}

.checkMailDiv{
	@include h1TextSize();
}

@media #{$desktop-xs}{
	.home-sections{
		 .sectionBk{
		 	padding:8vh 10vw;
		    .section-text{
				margin:1vh 0;
			}
		 }
	}
}

@media #{$phone}{
	.nav-pollex-icon{
		text-align: center;
		font-size:3em !important;
	}
	.login-error-message{
		display:block;
		margin-bottom:1em;

		line-height:1em;
		padding:1em 0;
	}
	.banner{
		.sloganText{
				margin:auto;
				text-shadow: 1px 1px 1px white;
		 }
	}


	.home-sections{
		 &.sectionBk,.sectionBk{
		 	padding:8vh 1em;
		 	// margin: 0 auto;
		 	// width: 100%;
		    .section-text{
				margin:1vh 0;
			}
		 }
	}

	.home-register-navbar{
		padding:1em 0em;
		input{
			&.has-success {
				padding:12px 0.5em !important;
				margin-bottom: 0.5em !important;
				height:auto !important;
				line-height:auto !important;


			}
		}
		.btn-login{
				padding:0.5em 0.5em !important;
				height:auto !important;
				line-height:auto !important;
		}
	}


}

.session-timeout{
	.input-group{
		width: 100%;
	}
}

#passwordChangeForm{
	.input-old-pasword{

	}
	.alert{
		padding:10px !important;
		margin-bottom:6px !important;
	}
}

