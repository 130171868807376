// modify pace.js styles
.pace {
	$_paceSize: 50px ;
	$_color: rgba(189, 193, 199, 0.7) ;
	$_animationTime: 1400ms;
	-webkit-pointer-events: none;
	pointer-events: none;
	-webkit-user-select: none;
	-moz-user-select: none;
    user-select: none;
   .pace-progress {
		background: #2196f3;
		position: fixed;
		z-index: 2000;
		top: 0;
		right: 100%;
		width: 100%;
		height: 2px;
		border-top-right-radius: 2px;
		border-bottom-right-radius: 2px;
	}

	.pace-activity {
	  display: block;
	  position: fixed;
	  z-index: 8000;
	  top: 50%;
	  left:50%;
	  margin-left: - $_paceSize * 0.5 ;
	  margin-top: - $_paceSize * 0.5 ;

	//   width: $_paceSize;
	//   height: $_paceSize;

	//   border: solid    $_paceSize * 0.2   transparent;
	//   border-top-color: $_color;
	//   border-left-color: $_color;
	//   border-bottom-color: $_color;
	//   border-radius: 50%;
	//   -webkit-animation: pace-spinner $_animationTime linear infinite;
	//   -moz-animation: pace-spinner $_animationTime linear infinite;
	//   -ms-animation: pace-spinner $_animationTime linear infinite;
	//   -o-animation: pace-spinner $_animationTime linear infinite;
	//   animation: pace-spinner $_animationTime linear infinite;

		// &.lds-ring {	// demo: https://jsfiddle.net/aup0wtc7/

		// 	div {
		// 		box-sizing: border-box;
		// 		display: block;
		// 		position: absolute;
		// 		width: $_paceSize;
		// 		height: $_paceSize;
		// 		margin: 6px;
		// 		border: 6px solid #fff;
		// 		border-radius: 50%;
		// 		animation: pace-spinner 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
		// 		border-color: lightgrey transparent transparent transparent;
		// 	}

		// 	div:nth-child(1) {
		// 		animation-delay: -0.45s;
		// 	}

		// 	div:nth-child(2) {
		// 		animation-delay: -0.3s;
		// 	}

		// 	div:nth-child(3) {
		// 		animation-delay: -0.15s;
		// 	}
		// }

		&.lds-ring {	// http://cfoucht.com/loadlab/
			width: $_paceSize;
			height: $_paceSize;
			background: transparent;
			border-top: 6px solid $_color;
			border-right: 6px solid transparent;
			border-radius: 50%;
			-webkit-animation: 1s pace-spinner linear infinite;
			animation: 1s pace-spinner linear infinite;
		}
    	


	}
}
.pace-inactive {
  display: none;
}

@-webkit-keyframes pace-spinner {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(180deg);
    transform: rotate(360deg);
  }
}
@-moz-keyframes pace-spinner {
  0% {
    -moz-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -moz-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-o-keyframes pace-spinner {
  0% {
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-ms-keyframes pace-spinner {
  0% {
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes pace-spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

