.addNewTodoModal-titleBar{
	background-color: #01a89e;
	padding: 15px;
	color: #fff;
}
.addNewTodoModal-content{
	background-color: #efefef;
	padding: 0px 15px 10px 15px;
}
.addNewTodoModal-panel{
	padding-top: 10px;

	.fileUpload-new-past {
		color: #333;

		@include hover-supported() {
			color: $toggle-color;
			background: $toggle-hover;
		}
	}
}

	.addNewTodoModal-textarea{
		width: 100%;
		background-color: #fff;
		resize:none;
		padding:5px;
		font-size: 14px;
	}

	.addNewTodoModal-select{
        min-height:3em; // give a min-height, when modal loading avoid screen shake
		display: flex;
		align-items:center;
		.selectpicker{
			flex:1 1 auto;
		}
		.select-project{
			.btn-default{
				display: flex;
				&:before{
					content: "\e950";
					font-family: "icomoon" !important;
					margin-right: 8px;
				}
			}

		}
		.select-sprint{
			.btn-default{
				display: flex;
				&:before{
					content: "\e960";
					font-family: "icomoon" !important;
					margin-right: 8px;
				}
			}
		}
	}

	.addNewTodoModal-assignee{
		
		width: 100%;
		.assigned-list{
			padding:15px !important;
			border: 1px solid #b4b4b4 !important;
			font-weight: initial;
			// @include otisToggle();
			&:hover{
				outline: none !important;
				.text-placeholder {
					color: gray ;
				}
			}
		}
	}

	.addNewTodoModal-upload{
		width: 100%;
		background-color: #fff;
		border: 1px solid #b4b4b4;
		color: #b4b4b4;
		padding: 0px;
		display: flex;
		input{
			display: none;
		}
		.dropdown{
			border-left: 1px solid;
		}
		.addNewTodoModal-upload-label{
			padding: 14px 15px;
			flex: 1 1;
			margin-bottom: 0px;
			line-height:1.42857143;
			font-weight: normal;
			// color: gray;
		}
		//todo#49708: 在新增工作的modal 中，新增附檔dropdown menu open 的寬度需要和dropdown-menu 等寬。 [Jane 2019-01-17]
		.fileUpload-dropdown-menu { 
			width: 283px;
			border-radius: 0px;
		}
		.bootstrap-select{
			width: 32px !important;
    		padding: 15px 8px 15px 0px;
			text-align: center;
		}
		.dropdown-menu li{
			padding: 10px;
		}
		.caret{
			width: 10px;
			height: 10px;
			border: none;
			margin-top:-10px !important;
			&:before{
			  font-family: "icomoon" !important;
				content: "\e909" !important;
			}
	  	}
	}
		.addNewTodoModal-fileList{
			display: flex;
			align-items:stretch;
			justify-content:space-between;
			border: #b4b4b4 dashed 1px;
			margin-top: 5px;
			.btn{
				margin: -1px;
			}
		}
		.addNewTodoModal-fileName {
			flex:1 1 auto;
			text-overflow: ellipsis;
			overflow: hidden;
			white-space: nowrap;
			padding: 5px;
		}

.addNewTodoModal-btnArea{
	display: flex;
	justify-content:space-between;
	.btn{
		width: calc( (100% - 12px)/3);
		background-color: #fff;
		border-color: #b4b4b4;
	}
	.btn-primary{
		background-color: #408982;
		color: #fff;
	}
	.btn-active{
		background-color: #05a69e;
		color: #fff;
	}
}
