$_color_overdue: rgb(168,0,0);
$_bg_panel: whitesmoke;

pop-my-todo-list{
	position:relative;
	width: calc(100%);
	height: calc(100%);
}

pop-my-todo-list-item {
	
	.switch {
		margin: 3px 0px 8px 10px;;	// 增加 margin 擴大點擊範圍  [Benx 2018-09-18 20:26:25]
	}
}

.right-side-panel .myTodo-list-div {
	.myTodo-filter {
		font-size: 13px;
	}
}

.myTodo-list-div{
	$_mainColor: $warm;
	$_headerHeight: $viewTitleHeight;
	
	
	//position:relative;
	margin:0;
	min-width: 100%;
	//overflow: initial;
	//background:transparent !important;
	//color: $viewTitleTextColor; // remark by smith @ 20180710 per apple's design
	
	&.calendar-expanded {
		width: calc(100vw - #{$sidebarWidth});
	}
	
	.my-todo-list-item {
		width: 100%;
	}
	
	.list-body{
		height:calc(100% - #{$_headerHeight});
	}
	
	.doing-tag, .backlog-tag{
		margin:6px 0px 12px 0;
		line-height: initial !important;
		line-height: normal !important;
		position:relative;
	}


	.myTodo-list-header-bar {
		.tab-container {
			float: right; 
			margin-right: 40px;
			
			@media #{$phone}{
				float: none;
				text-align: center;
				margin-right: 0px;
			}
		}
		
	}
	
	.todo-numbers{
		position:absolute;
		right:8px;
		bottom:0;
	}

	.todo-list-brick{
		$_mainColor:  $warm;
		.todo-list{
			padding: 0px 6px ;
			
			.dashboard-list{
				background: white;
				&:hover{
					box-shadow: 0px 1px 3px 0px #b3aeae;
    				// transform: scale(1.005);
				}
			}

			.no-date-hr-caption {
				.dashboard-list{
					border-bottom: 1px solid lightgray;
				}
			}
		}

		&#myHistory .todo-list .dashboard-list{
			&.todo-done {
				background: lightgray;
				text-decoration: line-through;
			}

		}
	}
	
	.date-time-hr-caption {
		margin: 10px 0px;
		font-size: smaller;
		
		text-align: left;
		
		.caption-text {
			color: #484747;
		}
		
		&.today .caption-text {
			font-weight: bold;
			font-size: 12.4px;
			color: black;
		}
		
		&.today-before .caption-text {
			color: $_color_overdue;
		}
		
		&.today-after.this-week .caption-text {
			color: black;
		}
		
		.empty-content-placeholder {
			background: transparent;
			border: none;
			padding: 5px;
			font-size: 13px !important;
			font-style: italic;
			display: flex;
			align-items: center;
			height: 40px;
			margin-left: 20px;
			
			
			&.today-emptyScheduled {
				//opacity: 0.3;
				color: burlywood;
			}
			
			&.today-allDone {
				color: #90b5f9;
			}
		}
		
	}
	
	
	
	.group-header {
		padding: 5px;
		font-size: 12px;

		.hr-separate {
			margin-top: -9px;
			margin-bottom: 2px;
			border-top: 1px solid lightgray;
		}

		.caption-text-group {
			padding: 1px 10px;
			background: #e3e3e3;
			border-radius: 10px;
		}
		
		&.no-scheduled {
			margin-top: 10px;
			
			color: dimgray;
			font-size: 13px;
			text-align: center;

			.caption-text-group {
				background: $_bg_panel;
				font-style: italic;
			}


			.hr-separate {
				margin-top: -10px;
				margin-bottom: 10px;
			}
		}
	}

	.action-more {
		font-size: 13px;
		padding: 2px 5px;
	}

	.action-show-overdue {
		background: $_bg_panel;
		font-weight: bold;
		
		&.pull-right {
			margin-top: 1px;
		}
	}
  
	.tab-content{
		//background: lighten($dynamicBackground, 20%); // remove by smith sun @20180208
	
		 
		//background: rgba(lighten($dynamicBackground, 20%), 1); // remark by smith sun @20180710 per apple's design
		background: $_bg_panel; 
		//@include overflowBottomSpace($myTodoBriefDashboardHeight);
		@media #{$phone}{ 
			-webkit-overflow-scrolling: touch;
		}
		&.tab-content--todoList{
			height: calc(100%  - #{$myTodoTitleFilterHeight} );
			@media #{$phone} {
				height: calc(100%  - #{$myTodoTitleFilterHeight});
			}
		}

		&.calendar-content{
			background: $calendar-backgound;
			height: 100%;
			padding:15px;
		}
		.tab-pane{
			height: 100%;
			
			.todo-list {
				//overflow-y: auto; //todo#40894:https://www.otispm.com/otis/index.html#!/pjm/p/725/backlog-list?showTodoDetail=40894 [Jane 2018-10-03 15:46:57]
				padding-bottom: 5px;
			}
			
			&#myTodo .todo-list{
				height: calc( 100% -  #{$myTodoDashboardHeight}  ); 
				// display: inline-flex; // 此處設定是為了配合動畫，讓頁簽在手機上有左右滑動的特效
				padding-right : 0px; 
				
				@media #{$phone} { 
					height: calc( 100% -  #{$myTodoBriefDashboardHeightXs}  );  
				}
				.my-todo-tab{ 
					height: 100% ;
				}

			}

		}
	}
 
	.nav-tabs>li {
		width:50%;
		@include labelSize();
		cursor:pointer;

		.myTodo-tab,.myEvent-tab{
			background: rgba($dynamicBackground,0.1);
			//color:$viewTitleTextColor;
			//color: white;
			width:100%;
			text-align:center;
			padding: 0;
			height: $viewTitleHeight;
			line-height: $viewTitleHeight;
		}

		&.active{
			.myTodo-tab,.myEvent-tab{
				background:  rgba($dynamicBackground,1);
				//color:$viewTitleTextColor;
			}
		}
	}

	.dashboard-list {
		&.overdue {
			.todo-content .myTodo-label {
				height: 1.4em;
				@media #{$phone} {
					height: 1.5em;
				}
			}
		}
	}
	.dashboard-list{
		$_margin:15px;
		$_padding: 10px; 
	    // padding: $_padding $_margin;
		// margin: 0 $_padding $_padding $_padding;
		
		padding: 5px 10px 10px 8px; //[Jane 2018-12-11 14:39:27]
		margin: 5px 5px 5px 0px;
		background: white;
		/* border: 1px solid lightgray; */
		/* border-radius: 3px; */
		/* box-shadow: 0px 15px 10px -15px #111; */
		// font-size: 12.25px !important;
		line-height: 1.3em;  
		border-left:3px solid white;
		
	    @include mainTextSize();
		line-height: 1.3em;
		//border-radius: 2px;
		.dropdown-menu{
			li{
				a{
					color: $main-text !important;
				}

				&.active{
					a{
						color:white !important;
					}
				}
				&:hover{
					background:lighten($warm, 35%) !important;
					a{
						background:lighten($warm, 35%) !important;
						color:white !important;
					}
				}
			}
		}
		
		.todo-content{
			$_lines: 8;
			$_lineHeight: 1.2em;
			margin-bottom: 8px;
			min-height: 22px;
			@include multiline-ellipsis($_lineHeight, $_lines);
				// white-space: pre-wrap;
			> .todoText-wrap {
				font-size: 1em;
				cursor:pointer;
				@include smartBreakWord();
			}

			.todoText {
				color:rgb(50,50,50);
				display: -webkit-box;
				-webkit-box-orient: vertical;
				-webkit-line-clamp: 2;
				max-height: 2.4em;
				min-height: 2em;
				overflow: hidden;
				@media #{$phone} {
					min-height: 2.4em;
				}
			}

			.todoText-principal {
				overflow: hidden;
				white-space: nowrap;
				text-overflow: ellipsis; 
				// color: #6c7d9b;
				color: rgba(84, 99, 125, 0.7);
				// font-size: 10pt;
				font-style: italic;
				font-size: 85%;
				margin-top: -2px;

			}

			.todoItem-icon {
				margin-right: 5px;
				font-size: 22px;
			}

			.myTodo-label {
				margin-top: 5px;
				display: inline-block;
			}

		}

		.myTodoList-projectItem-info {
			width:100%; 
			margin-bottom: 5px;
			font-size: 12px;
			height: 18px;
	
			.myProjectName {
				width: 100px;
				float: right; 
				text-align:right;
				color: #611561;
			}
	
			.mySprintName {
				width: 150px; 
				float: left;
				color: #b7b2b2;
				
			}

			@media #{$phone}{
				margin-bottom: 6px;
			}
		}

		.recent-bell {
		    position: absolute !important;
		    right: -0.6em;
		    margin-top: -1.2em;
		}

   		.due-status-tag{
		    margin-top: -($_padding + 1px);
		    margin-left: -($_margin + 1px);
		    margin-right:  -($_margin + 1px);
		    padding: 3px $_margin;
			margin-bottom: 6px;
			border-radius: 3px 3px 0 0 ;
		    //border-top-left-radius: 2px;
		    //border-top-right-radius: 2px;
   		}
	  //   &.todo-doing{
    // 		background: lighten($doingTodoColor, 15%) !important;
    // 		position: relative;
    // 		&:hover{
    // 			background: lighten($doingTodoColor, 20%) !important;
    // 		}
    //         .todo-doing-status{
    //             color: white;
    //         }
		// }
		// &.todo-done{
		// 	 opacity: 0.7;
		// }
    	.todo-doing-status{
			float:left;
		    color: gray;
		    display: inline-block;
		    cursor:pointer;
		}


		/*&.due-inOneWeek, */&.due-thisWeek {
			border-color:  steelblue; 

			&.due-today {
				border-color:  orange; 
			}

	 	   .dashboard-todo-date{
    			color: steelblue;
    			font-weight:400;
    		}

		}

		&.overdue{
		 // todo#48593:打開 My Todo sidebar 的 recent created tab，
		 // 逾期的 Todo的狀態border(color: red)應只出現在左側，但這裡會下方也會出現。 [Jane 2019-01-07 16:56:39]
			border-color:  $_color_overdue; 

	 	   .dashboard-todo-date{
    			color: $_color_overdue !important;
    			font-weight:400;
    		}

		}
		
		

		
	   .dashboard-todo-date{
			//color: $main-text;
 			//text-align:right;
			margin: 3px 0;
			font-size:1em;
			//font-weight:800;
		}

	    &.todo-list-item {
	    	line-height: initial;
	    	line-height:normal;

	    	&.overdue{
	    		// a{
	    		// 	color:darken(red,0%);
	    		// }
	    	}
	    	&.todo-doing{
	    		background: lighten($doingSprintColor, 15%) !important;
	    		&:hover{
	    			background: lighten($doingSprintColor, 20%) !important;
	    		}
	    	}
	    }
    	.creator{
    		 color: $main-text ;
    		 a{
    		 	color: $main-text !important;
    			text-decoration:initial;
    			display:inline-block;
    			&:first-letter{
	    		    text-transform: uppercase;
		    	}
    		}

    	}


	}

	.myTodoList-info{
		font-size:0.8em;
		margin-top:5px;
		color: lighten($main-text, 15%);
		// min-height: 22px;
		
		@include smartBreakWord();;
		.myProjectName{
			@include smartBreakWord();
			display:inline-block;
			max-width:14em;
			line-height:1.3em;
			@media #{$desktop-xs}{
				max-width: 11em;
			}

			padding-right:2px;
		}
		
		.latest-modified-by{
			color: dimgray;
			font-style: italic;
			line-height: 1em;
		}
	}


	.threeLine-ellipsis{
		$_lineHeight:1.3em;
		$_lines: 3;
		@include multiline-ellipsis($_lineHeight, $_lines)
	}



	.todo-list-brick,.event-list-brick{
	    padding: 0;
	    width:100%;
	}
	
		
	.myTodo-title-height{
		height: 	$_headerHeight;
		line-height: 	$_headerHeight;
	}


	.myTodo-filter{
		$_height: $myTodoTitleFilterHeight;
		height: 	$_height;
		line-height: 	$_height;
		border-bottom: 1px solid darken(lightgray,10%);
		padding:0 15px;

		
		.todo-type{
			cursor:pointer;
			
			.counts {
				font-size: 90%;
    			font-family: auto;
				&:before { content: "("; }
				&:after { content: ")"; }
			}

			&.active {
				padding-left: 10px;
				padding-right: 10px;
				// background: #e3e3e3;
				border-color:  $main-theme-color;
				border-bottom: 3px solid;

				// NOTE: 狀態改成 border-bottom 跟著主題色走，並拿掉background  [Jane 2019-03-22 14:12:41]

				// &.todo-type--all {
				// 	background: #dfeaf6;
				// }

				// &.todo-type--doing {
				// 	background: #effffc;
				// }

				// &.todo-type--overdue {
				// 	background: #f6eaec;
				// }

				// &.todo-type--reviewing {
				// 	color: #ff8000;
    			// 	background: #fff0e1;
				// }

			}

			&.active, &:hover {
				//color:rgb(98,182,167);
				color:  $main-theme-color;
				// &.todo-type--all {
				// 	color: #337ab7;
				// }

				// &.todo-type--doing {
				// 	color: darken(rgb(98,182,167),10%);
				// 	border-bottom-color:darken(rgb(98,182,167),0%);
				// }

				// &.todo-type--overdue {
				// 	color: rgb(195, 45, 75);
				// }

				// &.todo-type--no-due {
				// 	color: dimgray;
				// }
				
			}
		}

	}

	dailyTodo{
		border-top: 1px solid lightgray;
		padding: 15px 0;
		display: block;
		margin-top: 15px;
		position: relative;
		.the-day{
			position: absolute;
			margin-top: -25px;
			background: $_bg_panel;
			padding: 0 5px;
			color: rgb(168,0,0);
			font-size: 0.7em;
			margin-left: 15px;
		}
	}

	.mytodo-brief-dashboard{
		position: absolute;
		bottom:0px;
		display: block;
		height: $myTodoDashboardHeight;		
		width: 100%; 
		@media #{$phone}{
			height: $myTodoBriefDashboardHeightXs;
			width: 100%;
		}
		.dashboard-header{
			padding: 5px; //[Jane 2018-09-04 11:43:49]
			border-top:1px solid lightgray;
			border-bottom:1px solid lightgray;
			height: $myTodoDashboardHeight;
			background: #f9fafb;
			padding: 10px 5px 0px 5px;
			@media #{$phone} {
				height: $myTodoBriefDashboardHeightXs;
			}
		}

		.dashboard-body{
			background: lighten(lightblue, 10%); //whitesmoke;
			overflow-y:auto;
			height:100%; // [Jane 2018-09-04 16:04:03]
		}
		.brief-dashboard-card{
			display: block;
			padding: 8px 10px; //[Jane 2018-09-04 11:43:49]
			/* margin-left: 15px; */
			border-left: 7px solid darkslateblue;
			margin-bottom: 5px; //[Jane 2018-09-04 11:43:49]
			display:inline-flex;
			width:100%;
			justify-content:space-around;
			@media #{$phone} {
				padding: 3px 10px; //fine-turn [Jane 2018-09-21 14:55:17]
			}
		}

		.arrow-up{
			position:absolute;
			width:100%;
			text-align:center;
			color:rgb(200,200,200);
			font-size:1.4em;
			top:0;
		}
		.arrow-down{
			position:absolute;
			width:100%;
			text-align:center;
			color:rgb(200,200,200);
			font-size:1.4em;
			bottom:0;
		}
	}
	
	
	.my-todos--overdue, .my-todos--no-scheduled, #myReview {
		
		.dropdown-sort {
			text-align: right;
			font-size: 13px;

			&.pull-right {
				margin-right: 5px;
			}
		}
		
		.dropdown-toggle {
			font-size: 13px; 
			padding: 3px 5px;
		}
		
		.dropdown-menu {
			li[role=menuitem] > a > .icon {
				display: none;
			}
			
			li[role=menuitem].active > a > .icon {
				display: inherit;
			}
		}
	}

}

.myTodo-list-div, .myNotice-list-div {
	// position:fixed;
	// top:$navbarHeight;
	// right:0;
	$_maxWidth: calc( 100vw - #{$sidebarWidth} );
	height: -webkit-calc( 100%   ); // 2px for box-shadow y-axis 2px offset
	height:  calc( 100%    ); // 2px for box-shadow y-axis 2px offset
	// max-width: $_maxWidth;
	// min-width: calc( ( 100vw - #{$sidebarWidth} ) * 0.25 ) ;
	//width:100%;
	width: 350px; 
	position:relative;
	text-align:left;
	float:right;
	// @include mainTextSize();
	//background: rgba(lighten($dynamicBackground, 20%), 1); // remove by smith sun @ 20180710 per Apple's design
	background: $_bg_panel;
	box-shadow: -1px 3px 10px gray;
	.otis-modal-header{
	// Todo: The icon(Calendar) under the light style theme is set to dark[Jane 2018-09-04 09:55:29] per smith's desig
		color: $notice-header-color;
		background: $notice-header-background;
		@media #{$phone} {
			color: $notice-header-color;
		}
	}
	@media #{$phone}{
		width: 100vw;
		max-width: 100vw; 
		@include height-vh-patch("100vh");
	}
}




@media #{$phone}{

	.myTodo-list-div{
		#myTodoXs{
			position: absolute;
			top: 0em;
		}

		#myEventXs{
			position: absolute;
			top: 0em;
		}
		
		.tab-content--todoList {
			width: 100vw;
		}
	}

}

.otis-modal {

	.myTodo-list-div  {
		
		position: relative;
		top:0; // for ie only, even position is relative , still need set top :0;
		width: 100%;
		height: 100%;
		box-shadow: none;
		// position:initial;
		box-shadow:initial;
		color:inherit;
		
		.myTodo-list-header-bar .tab-container {
			float: none;
			margin-right: 0px;
			text-align: center;
		}
		
		@media #{$desktop-md-above} {
			$_md_headerHeight:45px;
			$_md_tabFontSize: 16px;
			
			.myTodo-title-height {
				height: $_md_headerHeight;
				line-height: $_md_headerHeight;
			}
			
			.notice-section-tab {
				font-size: $_md_tabFontSize !important;
			}
			
			.list-body {
				height: calc(100% - #{$_md_headerHeight});
			}
			
		}	// end of @media #{$desktop-md-above}
		
	}	// end of `.otis-modal .myTodo-list-div`
    
}
