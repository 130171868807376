/* pollex button scss 2016.01.30 by smith sun */

button {
  color:inherit;
  &:disabled {
    color: gray !important;
    background-color: lightgray !important;
    background-image: gray !important;
    background: lightgray !important;
    border: 0 !important;
  }
  // &[disabled]:hover {
  //   color: gray !important;
  //   background-color: lightgray !important;
  //   border: 0 !important;
  // }
}

.btn {
  border-radius: 0 !important;

}

.btn-xs{
  @include mainTextSize();
} 

.btn-project {
  color: $viewTitleTextColor !important;
  background: $main-background-color;
  border-radius:0;
  border: none;
  @include smallTextSize();
  &:hover {
    background-color: darken($main-background-color,10%)  ;

  }
  &:active{
  	 background-color: darken($main-background-color,5%)  ;
  }
}

.btn-primary {
  &:hover,
  &.disabled,
  &.btn-primary[disabled] {
    background-color: #1985ac;
    border-color: #1985ac;
    background-image: linear-gradient(to bottom, #1985ac, #1985ac);
  }
}

.btn-default {
  border: 1px solid #fff;
  color: initial;
  &:active:hover {
    border-color: transparent;
  }
}

.btn-otis-md {
  font-size: 1em;
  border: 0;
}

.btn-with-border{
	border:1px solid !important;
}

.btn-remove{
  position: relative;
  .close{
    position: relative !important;
    font-size: $h3;
  	@media #{$desktop-md-above}{
  		font-size: $h3 * 1.2 ;
  	}
    padding: 0px !important;
    &:hover{
      background:none!important;
    }
  }


}
 

 

.btn-transparent {
 	color:inheirt;
	background: transparent;
	border: none ;
	padding:0px !important;
	  // &:focus{
	  // 	outline: none !important;
	  // }
}
 

.otis-small-btn{
	button{
		padding:3px 6px !important;
		&.dropdown-toggle{
			padding-right: 25px !important;
		}
	}
}

.btn:focus {
  color: inherit;
}

.btn:active:focus, .btn:focus {
  outline: 0px;
}