/** listView scss **/
.list-view{

    .listView-page-count-info {
        float: right;
        margin-right: 50px;

        @media #{$phone}{
            float: none;
            margin-right: 10px;
        }

        > .count-info-text {
            background: rgba(0,0,0,0.1);
            border-radius: 20px;
            font-size: 13px;
            padding: 5px 15px;
        }
    }
    
    .listView-page-title{
        width: auto;
        @media #{$phone} {
            margin-bottom:initial;
        }
    }

    .listView-add-todo{
        display:inline-block;
        margin-left:10px;

        //$_width:50vw;
        //$_height:20em;
        // $_backgroundColor: $dynamicBackground;
        //@include otis-modal-content($_width, $_height, $_backgroundColor)
        .newTodoCard{
            form{
                margin-top: 10px;
                margin-left: -15em;
                padding:35px 15px 10px 15px;
                width: 30em;
                background:  darken($main-background-color,0%);
                box-shadow: 0px 0px 20px rgb(50,50,50);
                @media #{$phone}{
                    position: fixed;
                    left: 10px;
                    left: 0px;
                    margin: 0px;
                    width: 100vw;
                }
            }
            .close{
                position:fixed !important;
            }
        }
    }

    .listView-add-sprint{
        display: inline-block;
    }

    .listView-phase-plan {
        margin: 0px 5px;

        @media #{$phone} {
            background: $filter-block-background;
            color: $projectSetting-filter-color;
            border: 1px solid $projectSetting-filter-border;
            margin: 0px;
            padding-left: 5px;
        }

    }
    
    .list-zone{
        @include height-vh-patch("100vh - #{$navbarHeight} - #{$viewTitleHeight} ");
        //background: rgba(150,150,150,0.2);
        padding:15px 15px 15px 15px;
        padding-bottom: 30vh;
        overflow: auto !important;
         @media #{$desktop-md-above}{
             padding:15px 50px 15px 50px
        }
        //@include basicFontSize();
        //@include smallTextSize();
        @media #{$phone}{
            padding:0 10px;
            @include max-height-vh-patch("100vh - #{$navbarHeight} - #{$viewTitleHeight} - #{$sidebarIconHeight} ");
            width: 100vw;
        }
        //overflow-x:auto !important;

        .todo-filter-menu{
            color: $viewTitleTextColor;
            margin:0px;
            @media #{$phone}{  // move filter icon next to plan dropdown
                position: fixed;
                margin-top: -35px;
                right: 6px;
            }
        }
        .close{
            margin-top:-0.8em ;
            margin-right:-0.8em ;
            z-index: 10;
            @media #{$phone}{
               margin:0 !important;
            }
        }
        //[class^="icon-"]{
        //    font-size:1.3em;
        //}
    }

    .list-filter{
        display:inline-flex;
        padding:0;
        width: calc( 100% - 30px );
        flex-wrap:wrap;
        margin-bottom: 15px;
        $_placeholderColor: rgb(211, 211, 211);
        @include unSelectable();
        
        @media screen and (min-width: 768px){
            .close {
                margin-top: -10px;
                margin-right: -2px;
                border-radius: 50%;
                visibility: hidden;
                display: inline-flex;
                height: 18px;
                width: 18px;
                -webkit-transform : scale(0.75);  // 9px / 12px = 0.75
            }
        }

        &.advance-filter{
            width:100%;
            padding:15px 15px 7px 15px;
            border-radius:5px;
            margin-bottom:15px;
            background: $listViewFilterPanelBackground;
            @media #{$phone}{
                padding: 15px 5px 7px 15px;
            }
            .todo-content-filter{
                background: transparent !important;
                float:initial;
                width:auto;
                input{
                    padding:3px !important;
                }
                @media #{$phone}{
                    padding:0px !important;
                    input{
                        padding:3px;
                    }
                }
            }
            .due-date,.latest-update-date{
                input{
                    width: 6em;
                    min-width: 6em;
                    @media #{$phone}{
                        width:100%;
                    }
                }
            }
            
            .filter-toggle.open, .dropdown.open>.filter-toggle {
                background: $toggle-hover-opacity !important;
            }

			.otis-input input, .filter-toggle {
				&:focus {
					background: $toggle-hover-opacity !important;
				}

				@include hover-supported {
					background: $toggle-hover-opacity !important;
				}
			}
        }

        .todo-content-filter {
            width:300px;
            float:right;
            @include otisPlaceholderColor($_placeholderColor);
            input{
                padding: 6px !important;
            }
            @media #{$phone}{
                width:100%;
            }
        }

        .due-date,.latest-update-date{
            @include otisPlaceholderColor($_placeholderColor);
            input {
                font-size: x-small !important;
            }

            @media #{$phone}{
                width:100% !important;
                input{
                    width: 6em; 
                    background: $filter-block-background;
                }
            }
        }



        .filter-label, .filter-toggle,
        .filter-label-dropdown-toggle   {
            height: auto;
            line-height:auto;
            min-height:auto; 
            height: initial;
            line-height: initial;
            min-height:initial;
            padding: 3px 6px !important;
            width: 100%;
            border: 1px solid lightgray;
            min-width: 8em;
            display:inline-block;
            @media #{$phone}{
                padding:0 6px !important;
                height: 40px !important;
                line-height: 40px !important;
                background: $filter-block-background;
             }
        }


        .todo-filter-item {
            padding: 8px;
            margin-bottom: 8px;
            margin: 2px;
            border-radius: 5px;
            color: $filter-factor-color;
            position: relative;
            
            input, .filter-toggle {
                border-color:  rgba(211, 211, 211,0.3);
            }

            &:hover, &.open {
                color: rgba($filter-factor-color,0.85);
            }

            &.status-filter {
                flex-grow: 1;
                width: 110px;
                @media #{$phone} {
                    width: 100%;
                    padding: 0px;
                }
            }

            &.severity-filter {
                flex-grow: 1;
                width: 100px;  
                @media #{$phone} {
                    width: 100%;
                }
            }

            &.workstage-filter {
                flex-grow: 1;
                width: 100px;
                @media #{$phone} {
                    width: 100%;
                    padding: 0px;
                }
            }
            &.resolution-filter {
                flex-grow: 1;
                width: 100px;  
                @media #{$phone} {
                    width: 100%;
                }
            }
            &.dueDate-filter {
                width: 200px; 
                @media #{$phone} {
                    width: 100%;
                }
            }
            &.lastUpdateDate-filter {
                width: 200px; 
                @media #{$phone} {
                    width: 100%;
                }
            }
            &.content-search {
                flex-grow: 1;
                width: 220px;
                @media #{$phone} {
                    width: 100%;
                }
            }
        }

        .filter-bottom {
            &.filter-advance-view {
                display: flex ;
                width: 100% ;
                .todo-export {
                    margin-top: 30px;
                }
            }
            &.filter-beginner-view {
                display: inline-flex;
            }
            .filter-factor {
                display: flex;
                max-width: 55em;
                width: 100%;
                margin-bottom: 8px;
                color: $filter-factor-color;

                .filter-factor-item {
                    flex-grow: 1;
                    padding: 8px;
                    margin: 5px 5px 0px 0px;
                    border-radius: 5px;
                    input, .filter-toggle {
                        border-color:  rgba(211, 211, 211,0.3);
                    }
                    &:hover , &.open {
                        color: rgba($filter-factor-color,0.85);
                    }

                }
            }

            .todo-filter-member-dropdown {
                .filter-toggle  {
                    white-space: inherit;
                }
            }

            .todo-export {
                padding-right: 10px;
            }
        }

        .filter-factor-selected {
            background: $filter-factor-selected-background;
            color:  $filter-factor-selected-color;
            .close {
                visibility: visible;
                opacity: 0.8;
                align-items: center;
                justify-content: center;
            }
        }

        .listView-query-quick {
            margin: 5px 5px 0px auto;
            padding: 8px;
        }

        .show-simple-filter {
            margin: 0px;
            padding: 0px;
        }

    }

    .list-phase-plan-filter {
        border-radius: 5px;
        margin: 0px 20px 5px 0px;
        background: $filter-factor-selected-background;
        color:  $filter-factor-selected-color;

        .dropdown:hover {
            background: $toggle-hover-opacity;
        }

        .dropdown.open {
            background: $toggle-hover-opacity;
            box-shadow: none;
            color: inherit;
        }

    }

    .selected-phase-dropdown{

        &.otis-dropdown-toggle {
            @include hover-supported() {
                background: $toggle-hover-opacity;
            }

            &.open {
                background: $toggle-hover-opacity; 
                @media #{$phone} {
                    background: $filter-block-background;
                }
            }
        }
        
        @media #{$phone}{
            display:block ;
            padding: 0 6px;
            border:1px solid lightgray;
            background: darken($navSidebarColor, 15%);
            color: #fff;
        }
    }

    .phase-plan-selector{
        width:auto;
        float:left;
        display:inline-flex;

        @media #{$phone}{
            width: 100%;
            display:inline-block;
            padding:0 0 0 0;

            .dropdown-menu{
                left:auto;
                right:0px;
                width: 100% !important;
            }
        }

        .dropdown-menu{
            width: 25em;
            max-height: 45vh;
            li{
                position:relative;
                padding:6px 15px 6px 0px;
                .check-mark{
                    position:absolute;
                    right:15px;
                    top:9px;
                }
            }

            &.show-closed-plans {
                max-height: 55vh;
            }

            .dropdown-menu-header{
                padding:0px 15px ;
                text-align: center;
            }
        }
    }
    
    .assign-to,.created-by, .selected-label{
        .dropdown-toggle{
          [class^="icon-"]:before,
          [class*=" icon-"]:before{
                font-size:1.3em;
            }
        }

        @media #{$phone}{
            .dropdown-menu{
                width: 100% !important;
            }
        }
    }

    .sprint-icon{
        margin-top: 10px;
        margin-right: 3px;
    }

    .selected-phase-plan{
        $_maxWidth: 38em;
        $_dropdownArrowWidth:20px;
        $_rlPadding: 6px;
        padding: 0px $_rlPadding;
        //border: 1px solid lightgray;
        display: inline-block;
        width: 100%;
        //min-width:10em;
        max-width: $_maxWidth;
        line-height:1  ;
        @media #{$phone}{
            padding: 0px;
        }


        .selected-sprint-list{
            display:inline-block;
            min-width:5em;
            max-width: calc( #{$_maxWidth}  - #{$_dropdownArrowWidth} - #{$_rlPadding} * 2 - 10px );
            @media #{$phone}{
                min-width:initial;
                max-width: 90%;
				max-height: 40px;
    			overflow: hidden;
            }
        }

        .selected{
            float: left;
            line-height: 1.2em;
            color: $viewTitleTextColor !important;
            @media #{$phone} {
                color: $projectSetting-filter-color !important;;
            }
        }
        .dropdown-arrow{
           // clear:both;
            display:inline-block;
            width: $_dropdownArrowWidth;
            float:right;
            text-align:center;
        }

    }

    .btn-group{
        vertical-align:top;
        @media #{$phone}{
            width:100%;
        }
    }

    .quick-query-dropdown {
      padding-right: 10px;

      @media #{$phone}{
        padding: 10px 0px 0px 0px;

        .dropdown.open {
            color: $projectSetting-content-color;
        }
      }

      .quick-query-dropdown-text{
          text-align: right;
          margin-right: 6px;
          line-height: 1em;
          @media #{$phone}{
              text-align: initial;
              margin-right: initial;
              line-height: initial;
              margin-right: 0px;
          }
      }
      .dropdown-menu {
        min-width: 300px;
        .active{
            a{
                background-color: lighten($main-background-hover, 30%);
            }
        }
      }
	}
	
	.listView-table{
		//@include max-height-vh-patch("100vh - 130px");
		//width:100%;
	   // margin-top: 15px;
	
	
		//overflow-x:auto !important;
		//overflow-y:hidden;
		//width: calc( 100vw - #{$sidebarWidth} );
	
		//@include basicFontSize();
		//@include smallTextSize(); remark by smith @2018/03/06
		@media #{$phone}{
			//@include max-height-vh-patch("100vh - 160px");
			width: 100vw;
			padding-bottom: 100px;
		}
	
		th{
			padding: 8px 3px !important;
		}
		
		tbody {
			td {
                background: #fff;
			}
			// tr:nth-child(even) {
			// 	background: #f8f8f8;
			// }
		}
	
		.due-date{
			min-width: 7em;
			white-space: nowrap;
			.overdue {
				color: red;
			}
		}
		// .even-row{
		//     background: rgba(150,150,150,0.1);
		// }
	
		.assignee-list{
			font-size: 0.86em;
		}
	
		.todo-text{
			$_lines:3;
			$_lineHeight: 1.3em;
			color: #337ab7;
	
			&:hover {
			  text-decoration: underline;
			}
			@include smartBreakWord();
			@include multiline-ellipsis($_lineHeight, $_lines);
			@media #{$desktop-md-above}{
				line-height: $_lineHeight * 1;
				max-height: $_lines * $_lineHeight * 1;
			}
	
			&.todo-status-1 {
			  color: blue;
	
			  // .icon-work {
			  //   background: blue;
			  //   color: white;
			  //   padding: 3px;
			  // }
			}
	
			&.todo-status-99 {
			  color: green;
	
			  .popTodoTextRenderedOutput {
				text-decoration: line-through;
			  }
	
			  // .icon-check {
			  //   background: green;
			  //   color: white;
			  //   padding: 3px;
			  // }
			}
		}
	
		.todo-info-counts {
		  margin-top: 5px;	// HACK: 修正 list view table td 欄位會有些許不整齊的問題 [Benx 2018-10-22 10:17:58]
		}
	
		.todo-label{
			margin:0px 3px 0px 0px; // no margin-bottom, is of inherit line-height 1em;
		}
	
		.assign-to{
			button{
				&.btn{
					white-space:normal !important;
					text-align:left !important;
					min-width: 8em;
					max-width: 10em;
				}
			}
		}
	
		.ng-table-counts{
			.btn{
				margin-left: -1px !important;
				border-color: lightgray !important;
			}
		}
		// .ng-table-pagination{
		// 	 @media #{$phone}{
		// 		display:none;
		// 	}
		// }
	
		tr.created-today > td{
		  background: #d8e9f9 !important;
		  &.latest-update, &.create-date {
				font-style: italic;
			}

			&.latest-update { font-weight: normal !important;;}
			&.create-date { font-weight: bold;}
		}
	
		tr.modified-today > td{
		  background: #f9e5f9;
		  
		  &.latest-update {
			//   color: deeppink;
			  font-style: italic;
			  font-weight: bold;
		  }
		}
		
		td.latest-update, td.create-date {
			.timeAgo.time {
				font-size: 10px;
				opacity: 0.6;
			}
		}
		
		.timeAgo.date, .timeAgo.recently {
			white-space: nowrap;
		}
		
		tr.modified-today {
			td.latest-update {
				.timeAgo.time {
					opacity: 1;
				}
			}
		}
	
		tr.unplanned > td {
		  &.col-field-plan {
			color: #c0c0c0;
		  }
		}
	
	
		td.cell-todoStatus-99 {
		  color: green;
		}
	
		td.cell-todoStatus-1 {
		  color: blue;
		}
	
		th.tbl-header-todo-text {
		  min-width: 250px;
		}
	
		.tbl-col-todo-text{
			word-break:break-all;
			max-width:150px;
			@include smartBreakWord();
		}
	}

	.criteria-inputs {
		.placeholder-default-select-all, ::placeholder {
			opacity: 0.5;
		}

	}

}

.list-view .view-title {
    @media screen and (min-width: 768px) {
        padding-top: 3px;
    }
    

    .show-closed-plans {
        background: gray;
        color: rgba(255,255,255,0.5);
        &:hover, &.open {
            cursor: pointer;
            color: rgba(255,255,255,0.9);
        }
    }
}

.list-phase-plan-filter {
    li {
        @include hover-supported() {
            background:lighten($main-background-hover, 20%);
            color: $viewTitleTextColor;
            a {
                background: transparent;
                color: $viewTitleTextColor;
            }
        }
    }
}

.filter-advance-view, .todo-filter-item {
    .dropdown.open {
        background: transparent;
        box-shadow: none;
    }
}

// Note: 目前otis裡面沒有用到此class ， 考慮之後是否可以拿掉? [Jane 2019-04-17 09:44:38]
.list-view .dropdown-overlay {
    background: transparent;
    position:absolute;
    $_topOffset :$navbarHeight + $viewTitleHeight ;
    width: 100vw;
    @include height-vh-patch("100vh - #{$_topOffset} ");
}