/* phase scss */
.sprint-process-view-container{
	@include OtisPagePadding();
	.sprint-process-view-body{
		height: 100% !important;
	}


	$_headerHeight: 50px;
	#phase-scroll-container{
		overflow-x:auto;
		overflow-y:hidden;

		@include pageScrollArrow();
		.phase-add-div{
			@include swimLanWidth();
			.phase-add-brick{
				width:100%;
				line-height:$_headerHeight;
				height:$_headerHeight;
				border:0;
				overflow:hidden;
				color:white;
				cursor:pointer;
				&:hover{
					background: darken($selfDefineProcessColor, 5%) !important;
				}
			}
		}
	}

	.phase-edit-icon{
		position:absolute;
		right:3px;
		top: 5px;
		// line-height:$_headerHeight;
		// height:$_headerHeight;
		height: 24px;
		line-height: 24px;
		width: 20px;
		border-radius: 5px;
		text-align: center;

		@include hover-supported() {
			background:rgba(128, 128, 128, 0.5);
		}

		&:active {
			background:rgba(128, 128, 128, 0.7);
		}
	}

	.one-line-header{
		width:100% !important;
	}

	.dd-list{
		$barColor: gray; 
		@include scrollTinyBar($barColor,$barSize);
	}


	.dd-swimlane{

		&.backlog-color{
			$_mainColor: lighten( $selfDefineProcessColor,  15%);
			$_hoverPercent: 40%;
			$_bottomPercent: $phaseViewBottomPercent;
			$_todoCardTextColor:  $viewTitleTextColor;
			$_listHeaderTextColor : white;
			@include todoCard($_mainColor,$_hoverPercent,$_bottomPercent,$_todoCardTextColor,$_listHeaderTextColor);
			@include backlog-list-header();	
		}
		&.process-color{
			$_mainColor: lighten($selfDefineProcessColor, 5%);
			$_hoverPercent: 50%;
			$_bottomPercent: $phaseViewBottomPercent;
			$_todoCardTextColor:  $viewTitleTextColor;
			$_listHeaderTextColor : white;
			@include todoCard($_mainColor,$_hoverPercent,$_bottomPercent,$_todoCardTextColor,$_listHeaderTextColor);
			.otis-tips{
				background:white !important;
			}
		}
	}


}

.sprint-process-view-div {

	.dd-list-bottom {
		background: $main-background-color;
		border-color: lighten($viewTitleTextColor, 40%);
		color: $viewTitleTextColor;
	}

	.dd-list-bottom-absolute{
		position: absolute;
		bottom:0;
	}

	.dd-swimlane-first .dd-list-bottom {
		background: transparent;
		border-radius: 0px; 
		
		&.dd-list-bottom-absolute {
			background: $dynamic-dd-list-bottom-background;
		}
	}

}
