/* switch pure css */
.switch {
        $_height:15px;
        $_width:60px;
        $_margin:2px;
        $_circleSize: calc( #{$_height} - #{$_margin} * 2 );
        $_movingDistance: calc( #{$_width} - #{$_height}  );
        position: relative;
        display: inline-block;
        width:  $_width;
        height:  $_height;
        input {
            display:none;
        }
         
        input[checked] + .slider {
            background-color: #41a794;
        }
        
        input:focus + .slider {
            box-shadow: 0 0 1px #2196F3;
        }
        
        input[checked] + .slider:before {
            -webkit-transform: translateX( $_movingDistance);
            -ms-transform: translateX( $_movingDistance);
            transform: translateX( $_movingDistance);
        } 
        
        input[checked] + .slider:after
        {  
            content:'Doing'; 
            transform: translate(-50%,-50%);
            font-weight:200;
        }
        .slider {
            position: absolute;
            cursor: pointer;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            //background-color: #ca2222;
            background-color:lighten(gray,10%);
            -webkit-transition: .4s;
            transition: .4s;
            border-radius: $_height;
            &:before{
                position:absolute;
                content: "";
                height:  $_circleSize;
                width:  $_circleSize;
                left: $_margin;
                bottom: $_margin;
                //background-color: white; 
                background-color: rgba(255,255,255,0.8);
                -webkit-transition: .4s;
                transition: .4s;
                border-radius: 50%;
            }
            &:after{
                content:'Open';
                color: white;
                display: block;
                position: absolute;
                transform: translate(-20%,-50%);
                //margin-top:  calc(  #{$_height} * -0.5 );
                top: 50%;
                left:auto;
                right:0px;
                font-size: 10px;
                font-family: Verdana, sans-serif;
                font-weight:200;
            }
        }
  }
  
  
 
  
  /*--------- END --------*/