
pop-spinner-bounce {
	&.white-bounce{
		.spinner{
			color:white;
			>div{
				background-color:white !important;
			}
		}
		 
		.header-text{
			color:white;
		}
	}
	&.otis-spinner{
		position: absolute;
		background: rgba(20,20,20,0.1);
		margin-left: -15px;
		margin-top: -15px;
		width: 100%;
		height: 100%; 
		display: flex;
		align-items: center;
		justify-content: center;
	}
	&.otis-spinner-on-top{ 
		background: rgba(20,20,20,0.1);
		margin-left: -15px;
		margin-top: -15px;
		width: 100%; 
		display: flex; 
		justify-content: center;
	}
	&.spinner-lg{
		.spinner > div{
			width:1em;
			height:1em;
			background-color:rgb(100,100,100);
		}
	}
	.header-text{
		z-index:1000;
	}

	.spinner {
		display: inline-block;
		z-index: 1000; // for todo detail model show on top of it 
	}

	.spinner > div {
		width: 4px;
		height: 4px;
		background-color: #333;

		border-radius: 100%;
		display: inline-block;
		-webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
		animation: sk-bouncedelay 1.4s infinite ease-in-out both;
	}

	.spinner > div {
		width: 4px;
		height: 4px;
		background-color: #333;

		border-radius: 100%;
		display: inline-block;
		-webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
		animation: sk-bouncedelay 1.4s infinite ease-in-out both;
	}

	.spinner .bounce1 {
		-webkit-animation-delay: -0.32s;
		animation-delay: -0.32s;
	}

	.spinner .bounce2 {
		-webkit-animation-delay: -0.16s;
		animation-delay: -0.16s;
	}

	@-webkit-keyframes sk-bouncedelay {
		0%, 80%, 100% { -webkit-transform: scale(0) }
		40% { -webkit-transform: scale(1.0) }
	}

	@keyframes sk-bouncedelay {
		0%, 80%, 100% { 
			-webkit-transform: scale(0);
			transform: scale(0);
		} 40% { 
			-webkit-transform: scale(1.0);
			transform: scale(1.0);
		}
	}
}