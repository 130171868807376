/* pollex color scss 2016.01.30 by smith sun */
/*   [Jane 2019-03-07 09:30:37] */
@mixin colorBase(){

	// Color
	$calm: rgb(48,106,153) !global;
	$warm: rgb(37,87,109) !global;
	$cool: rgb(48,90,140) !global;
	$fresh: rgb(199, 229, 171) !global;
	$white:rgb(255,255,255) !global;
	$selected: gold !global;
	$main-text: #333 !global;
	$datepicker-color: $white !global;
	$comment-reply-background: #c7c5c52e !global;
	$color-border-lightgray: rgba(211, 211, 211, 0.6) !global;
	$color-border-lightgray-light: rgba(211, 211, 211, 0.4) !global;
	$cold-gary: #eee !global;

	// Status 
	$overdueColor: rgb(200,0,0) !global;
	$dueTodayColor: $warm !global;
	$activeColor: orange !global;
	$todoDoingBackground: lighten(yellow, 20%) !global;

	// Step 
	$reportStepColor : #5b8cc7 !global;


	// Tips 
	$warningMessageBackground: lighten(red, 10%) !global;
	$warningMessageTextColor: white !global;
	
	
	// Dropdown 
	$dropdown-hover-bg-color: rgba(172, 172, 172, 0.25) !global;
	$dropdown-item-selected-color: #337ab7 !global;

	
	// Home Page
	$homepageBackground:#619dce !global;
	$homepageBorder:#306a99 !global;
	$mobileMenu-main: #efefef global;


	// Model BackGround
	$darkenMDBackground: rgb(65,64,64) !global;
	$complement:rgb(217,241,255) !global;
	$member-background-color: darken($complement,8%) !global;
	$selfDefineProcessColor: lighten(#217c7e,5%) !global;

	// Button 
	$btn-primary: #337ab7 !global;
	$btn-primary-border: #2e6da4 !global;
	$add-comment-btn: #4283ad !global;
	$close-btn-hover: #dd4b39 !global; 
	$btn-link-color:  #337ab7 !global;
	$btn-link-color-hover:  #23527c !global;
	$btn-round-color:  lightgray !global;
	$btn-round-color-hover:  #337ab7 !global;

	// Input
	$todo-content-filter-input : lightgray !global;
	$form-input-border-color: $color-border-lightgray !global;
	$form-input-read-only-border-color: $color-border-lightgray-light !global;

	// Table
	$table-sort-indicator: #000 !global;
	$table-sort-indicator-active: #000 !global;

	// Icon 
	$file-upload-icon: lighten($warm, 30%) !global;
	$uploadLabel:#7ea3d0 !global;
	$uploadLabel-hover: linear-gradient(to bottom, #1985ac, #1985ac)  !global;
	$uploadLabel-active: linear-gradient(to bottom, #1985ac, #1985ac)  !global;	

	// Label 
	$label-on: lighten(gold, 5%) !global;
	$label-on-color: #a22323 !global;

	// Tab
	$tab-border: #fff !global; 

	// Toggle Style
	$toggle-box-shadow: inset 0 3px 5px rgba(0,0,0,.125) !global;

	// Gantt 
	$gantt-table-header: #25576d !global;
	$gantt-table-side-col: #91B4B7 !global;
	$gantt-table-row-select: #aed3e3 !global;
	$gantt-table-row-hover: #4e9fc3 !global;
	$gantt-table-row-color: $white !global;
	
	$gantt-date-header: #305a8c !global;

	$commentReplyIconColor: #25576d !global;

	// SystemSetting
	$systemSettingSidebarBkColor : #31415f !global;

}
