/* comments.scss */
.label-comment {
    color: white;
    background-color: orange;
    // padding: 0px 5px;
    // font-size: 0.8em;
	display:inline-block; 
	margin-top: 5px; 
	margin-right: 10px;
}

.label.comment-edit-icon {
    color: white;
    background-color: gray;
}

.comment-tagName{
  font-size: 12px;
  margin-right: 3px;
  color: indianred;
  font-weight: 600;
}

.loop-user-picker-prefix {
    padding-right:5px; 
    color:#337ab7;
}


.quot-reply-block {
    width: 78%;
	margin-top: 28px;
	margin-bottom: -14px;
    padding: 5px 10px;
    border-radius: 3px;
    background: rgba(187, 187, 187, 0.4);
    font-style: italic;
	color: gray;

	@media #{$phone}{
		clear: both;
	}
    
	.quot-reply-author:after {
		content: ": ";
	}
}

pop-comment-text {
	pre {
		clear: both;
	}
}

pop-comment-text {
	&.comment--collapse {
		$_lineHeight:1.2em;
		$_lines: 10;
		@include multiline-ellipsis($_lineHeight, $_lines);
	}
}

.comment-show-entirely-button {
	display: block;
	cursor: pointer;
	margin-top: 8px;
	margin-bottom: 8px;
}

.reply-show-action {
	color: #333;
	margin-top: 8px;
	margin-bottom: 8px;
	font-weight: bold;
	display: block;
}