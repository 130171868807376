.otis-toggle{
    cursor:pointer;
    display:inline-block;

    @include hover-supported () {
      color: $toggle-color;
      background: $toggle-hover;

      .text-primary ,.text-placeholder {
        color: $toggle-color !important;
      }

    }

    &:active {
      background: $toggle-hover ;
    }
    
    &:focus {
      outline: 0px;
    }

    &.open {
      box-shadow: $toggle-box-shadow;
    }

    .dropdown.open, &.open {
      color: $toggle-color;
      background: $toggle-hover;

      .text-primary ,.text-placeholder {
        color: $toggle-color !important;
      }
    }

}


.otis-toggle-on {
    box-shadow: $toggle-box-shadow;
    border-radius: 1px !important;
    background: $toggle-hover;
    color: $toggle-color;
}

