/* select scss */
select{
   background: url(#{$selectArrow}) no-repeat !important;
   background-size: 16px 12px !important;
   background-position: 98% center !important;
   border-radius: 0px !important;
   border-width:1px  !important;
   outline: none;
   display: inline-block !important;
   appearance: none;
   cursor: pointer;
   background-color:transparent;
   -webkit-appearance: none;
   -moz-appearance: none;
   text-indent: 1px;
   text-overflow: '';
 }

 select.effort-cost-code__select-option {
	height: auto; 
	width: 100%;
	border: 0px;
	padding: 0;
	font-size: 12px;
	text-align: center;
	text-align-last: center;
	display: inline;
	text-align: center;
	color: #337ab7;
	background: none !important;
	-moz-appearance: none !important;
	-webkit-appearance: none !important;
	appearance: none !important;
	option {
		text-align: left;
	}
}

 .modal-content{
 	select{
	   //background: url(../../assets/img/otis-defined/arrow-B.png) no-repeat !important;
	   //background-size: 16px 12px !important;
	  // background-position: right center !important;
   }
 }

 .bootstrap-select{
	font-size:1em !important;
	//margin-right: 6px; 
	.dropdown-toggle{
		font-size: inherit!important;
		//width:auto !important;
	}
	.dropdown-menu{
		font-size: inherit!important;	
		li{
			font-size: inherit!important;	
			 
		}
	} 
}

.selectOption-icon { 
	.icon-option{
	    display: inline-block;
	    padding: 3px 5px 0px 5px;
	    border-radius: 2px;
	}
	&:hover, &.dropdown.open {
		.icon-option{
			background-color: $darkenMDBackground;
			color: $white;
		}
		cursor: pointer;
	}
}