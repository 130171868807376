/* modal scss */

.otis-menu-backdrop {
	top:0;
	left:0;
	height:100vh;
	width:100vw;
	background: transparent;
	z-index:150;
	position:fixed;

	.dropdown & {
		display: none;
		background: transparent;
	}

	.dropdown.open & {
		display: block;
	}
}

.otis-overlay{
	//position:fixed; // in IE 11 fixed will cause issue -> background text overlayed on the modal
	top:0;
	left:0;
	height:100vh;
	width:100vw;
	background: $overlay-color;
	//background-image: url(#{$backgroundImg})  !important;
	background-size: cover !important;
	opacity: 0.95;
	margin:0;
	padding:0;
	z-index:300;
	position:fixed;
	
	&#main-content-overlay {
		z-index:101;	// sidebar z-index is 100 [Benx 2018-10-05 19:46:35]
		background: transparent;
		top: auto;
		
		@media #{$phone} {
			background: rgba(20,20,20,0.3);
		}
	}
	
}

.otis-modal-header{
	padding: 0px 15px ;//!important;
	border-bottom: 1px solid #e5e5e5;
	overflow:hidden;
}
.otis-modal-header-title{
	padding:10px;
	text-align:center;
	@include h3TextSize();
	font-weight: 800;
}
.otis-modal-target{
	background:darken(whitesmoke, 5%);
	padding:15px;
}

.otis-modal-footer{
	padding: 10px 15px 5px 15px !important;
}

.otis-modal{
	z-index:300;
	position:fixed;
	padding: 0 10px;
    top: 0px;
	left: 0px;//in firefox when the position is absolute, need set top and left
	
	.modal-header-link{
		position: absolute;
		background: #337ab7;
		color: white;
		margin-top: -1.4em;
		
		@media #{$phone}{
     		 position: relative;
		}
	}

	.close.abs-top-right {
		position: fixed !important;
	}

	&.document-viewer-modal {
		.otis-modal-content-lg, .otis-modal-content-md, .otis-modal-content-sm {
			@media #{$phone}{
				@include height-vh-patch("100vh - 35px");
				margin-top: 35px !important;
			}
		}
	}

	.otis-modal-content-lg{
		$_width: 70vw;
		$_backgroundColor: white;
		@include otis-modal-content($_width,$_backgroundColor) ;
		@include height-vh-patch("94vh");
		
		&.auto-height {
			height: auto;
			@include max-height-vh-patch("94vh");
		}
		
		@media #{$phone}{
			$_width: 100vw;
			margin:0;
			top:0;
			left:0;
			overflow:hidden;
			
			@include otis-modal-content($_width,$_backgroundColor) ;
			@include height-vh-patch("100vh");
			
			&.auto-height {
				height: auto;
				@include max-height-vh-patch("100vh");
			}

			margin-top:0;		// must put after otis-modal-content to override margin-top
			margin-bottom:0;
		}
	}



	.otis-modal-content-md{
		$_width: 50vw;
		$_backgroundColor: white;
		@include otis-modal-content($_width, $_backgroundColor) ;
		@include height-vh-patch("94vh");
		
		&.auto-height {
			height: auto;
			@include max-height-vh-patch("94vh");
		}
		
		@media #{$tablet}{
			$_width: 80vw;
			overflow:hidden;
			$_backgroundColor: white;
			@include otis-modal-content($_width, $_backgroundColor) ;
		}
		@media #{$phone}{
			$_width: 100vw;
			overflow:hidden;
			$_backgroundColor: white;
			@include otis-modal-content($_width, $_backgroundColor) ;
			@include height-vh-patch("100vh");
		
			&.auto-height {
				height: auto;
				@include max-height-vh-patch("100vh");
			}
			
			margin-top:0;
			margin-bottom:0;
			margin:0;
			top:0;
			left:0;
			overflow:hidden;
		}

		@media #{$desktop-xs-md}{
			$_width: 80vw;
			$_backgroundColor: white;
			@include otis-modal-content($_width, $_backgroundColor) ;
			overflow:hidden;
		}

	}


	.otis-modal-content-sm{
		$_width: 30vw;
		$_backgroundColor: white;
		@include otis-modal-content($_width, $_backgroundColor) ;
		@include height-vh-patch("94vh");
		@media #{$tablet}{
			$_width:60vw;
			overflow:hidden;
			$_backgroundColor: white;
			@include otis-modal-content($_width, $_backgroundColor) ;
			@include height-vh-patch("94vh");
		}
		@media #{$phone}{
			$_width: 100vw;
			overflow:hidden;
			$_backgroundColor: white;
			@include otis-modal-content($_width, $_backgroundColor) ;
			@include height-vh-patch("100vh");
			margin:0;
			top:0;
			left:0;
			overflow:hidden;
		}

		@media #{$desktop-xs-md}{
			$_width: 80vw;
			$_backgroundColor: white;
			@include otis-modal-content($_width, $_backgroundColor) ;
			@include height-vh-patch("94vh");
			overflow:hidden;
		}

	}

}


.otis-modal {
	.subtodo-modal {
		$_width: 50vw;
		$_backgroundColor: transparent !important; // transparent to make subtodo forward header seperate from modal
		@include otis-modal-content($_width, $_backgroundColor) ;
		@include height-vh-patch("94vh");
		border-radius: 0px;
		
		.tab-content{
			background: white;
		}
		@media(max-width: 1365px) and (min-width: 768px) {
			$_width: 80vw;
			@include otis-modal-content($_width, $_backgroundColor) ;
		}
		
		@media #{$phone}{
			$_width: 100vw;
			
			margin:0;
			top:0;
			left:0;
			overflow:hidden;
			
			@include otis-modal-content($_width, $_backgroundColor) ;
			@include height-vh-patch("100vh");
			
			margin-top: 0;	// NOTE: must put after by @include otis-modal-content [Benx 2018-10-05 19:28:21]
		}
	}
}


.modal-header{
	//border-bottom: 1.3px solid lighten($main-text, 40%) !important;
	border-radius: $panel-header-border-radius;
	text-align: center;
	background:whitesmoke;
	padding:10px 15px !important;
	@include unSelectable();
	&.padding-small{
		padding:10px 15px !important;
	}

	&.information-header {
		background: $modal-information-header-bg;
		color: $modal-information-header-color;
	}

	@media #{$phone} {
		h1 {
			font-size: 15px !important;
			line-height: 25px;
		}
		border-radius: 0px;
	}
}

.modal-footer{
	border-radius: $panel-footer-border-radius;
	padding:10px 15px;
	background:whitesmoke;
	//border-top: 1.3px solid lighten($main-text, 40%) !important;
	@include unSelectable();

	@media #{$phone} {
		border-radius: 0px;
	}
}

.modal-dialog{
	@media #{$phone}{
		margin:0 !important;
	}
}

.modal-open {
	.modal{
		overflow-y:hidden !important;
		overflow-x:hidden !important;
	}
}

.modal-content{
	border-radius: 5px;
	border: 0px;
	
	@media #{$phone} {
		border-radius: 0px;
	}
}

.modal-close-btn {

	&.close:hover {
		border-radius: 0px 4px 0px 0px;
	}

	@media #{$phone} {
		&.close:hover {
			border-radius: 0px;
		}
	}
}

.otis-detail-modal {
	.otis-modal-content-lg, .otis-modal-content-md, .otis-modal-content-sm {
		border-radius: 0px;
	}

	.modal-close-btn.close:hover {
		border-radius: 0px;
	}
}


pop-project-todo-summary-list{
	@media #{$phone}{
		.otis-overlay{
			height:calc(100%) !important;
		}
		.otis-modal-content-md{
			@include height-vh-patch("100vh - #{$sidebarIconHeight}");
		} 
	}
}

.modal-target-item{
	background:darken(whitesmoke, 5%);
	padding: 10px 15px;
	.target-details{
		//padding-right: 6px;
		width: calc(100% - 50px);
		$_lineHeight:1.2em;
		$_lines: 2;
		@include multiline-ellipsis($_lineHeight, $_lines);
		display: inline-block;
		@media #{$phone}{
			$_lines: 2;
			@include multiline-ellipsis($_lineHeight, $_lines);
			display: inline-block;
		}
	}
	.target-expanded{
		max-height: 40vh !important;
	}
}

// .modal-content{
// 	box-shadow:none !important;
// }

.remove-projectMemberModal{
	select{
    min-width: 100px;
		padding:3px 8px;
		border: 1px solid lightgray !important;
   		background-position: 96% !important;
	}
}


.clipImage-modal-container{
	//padding: 35px;
}

.start-sprint-modal{
	.modal-header{
		padding: 0.5em 1em;
	}
	.modal-footer{
		padding: 0.5em 1em;
	}
}

.modal .modal-header {
	.modal-title {
		font-size: 1.2em;
	}
	
	.modal-title .icon-placeholder {
		font-size: 1.5em;
	}
}

.confirmModal {
	.modal-header {
		text-align: left;
		
		.modal-title .icon-placeholder {
			color: brown;
		}
	}
}