/* file view */

.project-func-view {
	.file-view-panel {
		@include height-vh-patch("100vh - #{$navbarHeight}");
	}
}

.fileUpload {
  position: relative;
  overflow: hidden;
  &.btn {
    font-size: 0.88em;
    margin-right: 10px;
    &.btn-primary {
      font-size: inherit !important;
      width: 40px;
      padding: 0;
      margin: 0;
      vertical-align:middle;
      height: calc(100%);
      .fileupload-icon {
          @include bigIconSize();
          color: white;
      }
      &:hover {
         font-size: inherit !important;
      }
    }
  }
  input {
    &.upload {
      position: absolute;
      top: 0;
      right: 0;
      margin: 0;
      padding: 0;
      cursor: pointer;
      opacity: 0;
      filter: alpha(opacity=0);
    }
  }
}

.fileUpload-li {
  position: relative;
  overflow: hidden;
  input {
    .upload {
      position: absolute;
      top: 0;
      right: 0;
      margin: 0;
      padding: 0;
      @include mainIconSize();
      cursor: pointer;
      opacity: 0;
      filter: alpha(opacity=0);
    }
  }
}

.file-folder-title{
    border-bottom: 1px solid gray;
    padding-bottom: 7px;
}

.file-filter-div{
	display:inline-flex;
	align-items:center;
	.file-filter-icon{
		vertical-align:middle !important;
		display:inline-block;
		margin-left:15px;
		color: inherit;
		box-shadow: none;
  }
  .otis-dropdown-toggle {
    &:hover, &.open {
      background: transparent;
    }
    .dropdown-menu li>a:hover {
      background: $fileView-filter-hover;
      color: $fileView-filter-color;
    }
  }
	.dropdown-menu{
		margin-top:-8px !important;
	}
   .file-upload-icon{
	  	$_size: 25px;
	    cursor:pointer;
	    position: relative;
	    display:inline-block;
	    margin-left:15px;
	    font-size:1.2em;
	    width:	$_size;
	    height:	$_size;
	    line-height: $_size;
	    background: $file-upload-icon;
	    text-align: center;
	    border-radius: 3px;
	    padding-left: 2px;
	    color:white;
	    &:hover{
	      background:lighten($warm, 25%);
	    }
	    &:active{
	      background:lighten($warm, 30%);
	    }
	    input{
	      display: inline-block;
	      visibility: hidden;
	    }
    }
	.customFilterOn, .customFilterOn > i,
	.customFilterOn > a > i,
	 {
		color: gold !important;
	}

	li{
		&.customFilterOn{
			background: lighten($warm, 30%) !important;
			a{
				color:gold !important;
				position: relative;
				&:hover{
					background: lighten($warm, 35%) !important;
					color: gold !important;
				}
				i{
					 position:absolute;
					 left:3px;
				}
			}
		}
		a{

			position: relative;
			i{
				 position:absolute;
				 left:3px;
			}
		}

	}
}  // end of .file-filter-div

.file-search {
  width: auto;
  margin-top: 4px;
  input {
    border: 1px solid rgba(211,211,211,0.5);
  }
}

.file-detail-modal {
  border-radius: 0px !important;
}

.file-detail-panel {
  box-shadow:none;
  $_mainColor: $cool;
  padding: 0em 0.5em;

  .owner-detail{
    $_lineHeight:30px;
    $_lines:1;
    @include  multiline-ellipsis($_lineHeight, $_lines);
    width: calc(100% - 20px);
  }
  .panel-heading {
    background: $information-header-bg;
    color: white;
    height: 40px !important;
    line-height: 40px !important;
    padding-right: 30px;
    margin:0;
    img {
      height: $swimLanHeaderHeight !important;
      width: $swimLanHeaderHeight !important;
      padding: $swimLanHeaderHeight * 0.15;
    }
    a {
      color: white;
      display: inline-flex;
      // width: 80%;
      @media #{$phone} {
        //width: 95% !important;
      }
      i {
        color: inherit;
      }
      .file-name {
        width: 21em;
        max-width: 23vw;
      }
      .file-size {
        font-size: 0.8em;
        margin-left: 1em;
        margin-right: 0.8em;
      }
      .download-icon{
      	font-size: 1.5em;
      }
    }
    .delete-it {
      position: absolute;
      right: 5px;
      height: $swimLanHeaderHeight !important;
      line-height: $swimLanHeaderHeight !important;
    }
  }
  img {
    &.file-img {
      max-width: 90%;
      max-height: 40vh;
      margin:auto;
    }
  }
  #comments {
    .panel-heading {
      background: lighten($_mainColor, 40%);
      color: white;
      height: $swimLanHeaderHeight !important;
      line-height: $swimLanHeaderHeight !important;
      padding: 0;
      img {
        height: $swimLanHeaderHeight !important;
        padding: $swimLanHeaderHeight * 0.15;
      }
    }
    .file-uploadedTime {
      color: white;
      margin-right: 5px;
      @include tinyTextSize();
    }
    .tag-area {
      @include mainTextSize();
    }
    .file-detail-panel-scroll {
      height: $todoPanelHeight;
    }
  }
}
// todo#58700: File Information modal - 添加標籤時，文字顏色白色導致無法看見 [Jane 2019-04-24 13:55:28]
.file-detail-panel .tag-area {
  .dropdown.open {
    background: transparent;
    box-shadow: none;
    color: #3b85c0;
  }

  .tag-dropdown-menu input {
    color: #333;
  }

  .btn:active {
    box-shadow: none;
  }
}

.fileUpload-container {
  $_mainColor: $cool;
  .upload-cancel-btn {
    color: $_mainColor;
    font-size: 0.88em;
    padding:0;
    border: 1px solid $_mainColor;
    border-radius: 0;
    width:100%;
    background: white;
    &:hover {
      background: lighten($_mainColor, 20%);
      color: white;
    }
    &:active {
      background: $_mainColor;
    }
  }
}

.file-selector-modal{
  $_modalHeight:75vh;
  $_modalHeader:54px;
  $_modalFooter:51px;
  @media #{$phone}{
    .file-view-panel{
      .file-detail-view-card-div{
          height: calc(100%) !important;
          padding-bottom:300px !important; // to offset ios viewport height difference issue
      }
      .folder-detail-view-div{
          height: 100% !important;
          padding-bottom:300px !important; // to offset ios viewport height difference issue
      }
    }
    // todo#48504:theme style: dark，Attach from uploaded files，右上方filter的placeholder font color顏色不明顯。[Jane 2019-01-07]
    .file-browser-header { 
      .otis-input.filter-icon:after {
        color: lightgray;
      }
    } 
  } 

  .modal-body{
    height:calc( #{$_modalHeight} );
    overflow:hidden;
    padding: 0 !important;
  }
  .file-view-panel{
    .file-view-body{
      height:calc( #{$_modalHeight} - #{$viewTitleHeight} ); 
    }
  }

  .folder-display-zone {
    height: auto !important; 
  }
  .folder-display-zone-div {
    padding: 0  !important;  
  }
  #file-detail-view{
    .file-view-panel{
      .folder-detail-view-div  {
          height: calc( #{$_modalHeight} -  #{$viewTitleHeight}  )   ;
          padding-bottom:150px !important;
           
      } 
      .file-detail-view-card-div{
          height: calc( #{$_modalHeight} -  #{$viewTitleHeight}  )   ;
          padding-bottom:150px !important;
        
      }
    }
  }
 
  .file-view-body{
      padding: 10px 15px !important;
  }
  .file-view-panel-title{
       background:$projectBodyBackground;
      
  }

  @media #{$phone} {
   
   
    .folder-display-zone-div{
      border:none !important;
    }

    .modal-footer{
        position:fixed;
        bottom:0;
        width:100%;
    }
    .margin-top-small{
        margin:0 !important;
    }
  }
}

.file-selector-modal {
  .file-panel-right {
    .file-list-header {
      display: none;
    }

    .file-list-view {
      height: calc( 100% - 28px );
      @media #{$phone} {
        height: calc( 100% - 70px);
      }
    }
    .file-grid-view {
      @media screen and (min-width: 1600px) {
        margin: 15px;
      }
    }
    
    .gridView-icon-edit {
      display: none;
    }
  }
  .file-view-panel {
    .folder-display-zone .folder-name {
      //todo#47980:用 Attach From Uploaded Files 開啟附加面板，左側分類選單寬度不正確。[Jane 2019-01-03 09:40:55]
      max-width: calc(100% - 30px - 18px);
    }
  }
}


.file-view-panel {
  $_mainColor: $calm;
  $_customizedFolderColor: $warm;
  height: 100%;

   &.nv-file-over {
      .otis-overlay{
          display: block;
      }
   }

  .otis-overlay {
	position: absolute;;
    display: none;
    height: 100%;
    width: 100%;

    .fileUploader  {
		color: darkgray;
		font-size: 5em;
		text-align: center;
		height: 85%;
		width: 85%;
		margin: auto;
		margin-top: 3%;

		&.warning {
			color: red;
		}

		.fileUpload-area {
			border: 8px dashed rgba(211,211,211,0.5);
			height:100%;
			padding-top: 20px;
		}
    }

    
  }

  .file-panel-right {
    height: 100%;
    padding: 0px 6px 6px 6px;

    .file-list-title {
      @include unSelectable();
      padding: 0px 0px 5px 0px;
      .file-name{ 
        padding: 0px;
      }
      .file-date{
        padding: 0px;
      }
      .file-size{
        padding: 0px;
      }
      .file-uploadBy{
        padding: 0px;
      }
    }

    .file-list-title-pan {
      border-bottom:1px solid gray;
      padding-bottom: 5px;
      @media #{$phone} {
        border-bottom:0px;
      }
    }

    .edit-file {
      border-radius: 5px;
      padding: 2px;
    }

    .edit-file.otis-toggle {
      &:hover {
        background: $edit-icon-hover;
      }
      &:active {
        background: $edit-icon-active;
      }
    }
  }
  .file-toggle-icon {
    float: right;
  }

  .file-toggle-view-tab {
    cursor: pointer;
    display: inline-block;
    height: 21px;
    width: 21px;
    line-height: 18px;
    text-align: center;
    background:lightgray;
    border-radius: 4px; 
    border: 0;
    box-shadow: -1px 1px 1px 0px hsla(0, 0%, 0%, 0.5);
    @media #{$phone} {
      line-height: 18px;
    }
    // &.left{
    //   border-left:none;
    //   border-top-left-radius:6px;
    //   border-bottom-left-radius:6px;
    // }
    // &.right{
    //   border-left:none;
    //   border-top-right-radius:6px;
    //   border-bottom-right-radius:6px;
    // }
    &:hover{
      background: darken(lightgray, 3%);
      box-shadow: -1px 1px 5px 1px hsla(0, 0%, 0%, 0.5);
    }
    &:active{
      background: darken(lightgray, 5%);
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.5) inset;
      position: relative;
      top: 1px
    }

    .file-toggle-view-icon {
      height: 15px;
      width: 15px;
      display: none;
      &.LIST {
        display: unset;
      }
      &.THUMBNAIL {
        display: unset;
      }
    }
  }
  
  .file-list-view {
    $_fileListTitle: 28px;
    height: calc( 100% - #{$_fileListTitle});
    padding: 0px;

    .file-list-item {
      margin-top: 6px;
      padding: 0px;
      &:hover {
        background-color: $cold-gary;
        cursor: pointer;
      }    
    }

    .listView-fileIcon {
      height: 40px;
      width: 40px;
      background-size: 40px;
      float: left;
      margin-right: 5px;
	    padding: 2px;
    }

    .listView-file-name {
      padding: 0px;
      .file-name {
        $_lineHeight: 1.2em;
        $_line: 2;
        @include multiline-ellipsis($_lineHeight,  $_line);
        word-break: break-word;
        width: calc(100% - 75px);
      }
      
      .file-label-row { 
        display: block;
        overflow: hidden;
        white-space: nowrap;
      }
    }

    .listView-file-info {
      padding: 0px;

      .file-uploadedTime {
        padding: 0px;
      }
  
      .file-fileSize {
        padding: 0px 0px 0px 2px;
      }
  
      .file-uploadedUser {
        padding: 0px;
      }
    }

    .file-row-selected {
      background-color: $fileView-selected-background; 
      border: 1px solid $fileViewHover-border-color;
      &:hover {
        background-color: $cold-gary;
        cursor: pointer;
      }
    }

  } // end of .file-list-view

  .file-grid-view {
    display: inline-block;
    text-align: center; 
    vertical-align: middle;
    overflow: hidden;
    width: 160px;
    height:160px;
    margin: 5px;
    border: 1px solid transparent;
    border-radius: 2px;
    
	[ng-click] {
		cursor: pointer;
  }

  @media #{$desktop-lg-above} {
    height: 180px;
    $fileMargin : 20px;
    $fileThumbnailNum : 5;
    @include fileThumbnail($fileMargin, $fileThumbnailNum)
  }

  @media #{$desktop-md} {
    height: 160px;
    $fileMargin : 20px;
    $fileThumbnailNum : 4;
    @include fileThumbnail($fileMargin, $fileThumbnailNum)
  }

  @media #{$desktop-sm} {
    height: 160px;
    $fileMargin : 15px;
    $fileThumbnailNum : 4;
    @include fileThumbnail($fileMargin, $fileThumbnailNum)
  }

  @media #{$desktop-xs} {
    height: 160px;
    $fileMargin : 15px;
    $fileThumbnailNum : 3;
    @include fileThumbnail($fileMargin, $fileThumbnailNum)
  }

  @media #{$tablet} {
    height: 130px;
    $fileMargin : 10px;
    $fileThumbnailNum : 3;
    @include fileThumbnail($fileMargin, $fileThumbnailNum)
  }

  @media #{$phone} {
    height: 130px;
    $fileMargin : 10px;
    $fileThumbnailNum : 2;
    @include fileThumbnail($fileMargin, $fileThumbnailNum)
  }

  // @media #{$miniPhone} {
  //   height: 130px;
  //   $fileMargin : 10px;
  //   $fileThumbnailNum : 1;
  //   @include fileThumbnail($fileMargin, $fileThumbnailNum)
  // }

    // @media screen and (max-width: 320px) {
    //   width: 130px;
    //   height:130px;
    //   margin: 5px;
    // }
    // @media #{$tablet} { // min-width: 768px and max-width: 991px
    //   width: 140px;
    //   height:140px;
    //   margin: 5px;
    // }
    // @media #{$desktop-xl} {// min-width: 920px
    //   width: 180px;
    //   height:180px;
    //   margin: 10px;
    // }
    // @media #{$desktop-lg-above} { // min-width: 1600px";
    //   width: 240px;
    //   height:240px;
    //   margin: 25px;
    // }

    .gridView-display-fileView {
      width: 80%;
      height: 55%;
      display: flex; 
      align-items: center; 
      justify-content: center;
      margin: auto;
      border-radius: 5px; 
      border: 1px solid transparent;

      .gridView-img{
        max-width: 100%;
        max-height: 100%;
        padding: 8px;
      }

      .gridView-fileIcon {
        height: 72px;
        width: 72px;
        background-size: 72px;
        @media #{$phone} {
          height: 64px;
          width: 64px;
          background-size: 64px;
        }
      }

    }

    &:hover {
      background-color: $cold-gary;
    }

    .gridView-display-fileName {
      width: 100%; 
      height: 40%;
      margin-top: 1px; 
      
      .gridView-label {
        height: 35%;
        white-space:nowrap; 
        @media #{$phone} {
          height: 40%;
        }
        .label {
          white-space: nowrap;
        }
      }
      
      .gridView-name {
        display: inline-block;
        padding: 0px;
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .gridView-icon-edit {
        align-items: center;
        padding-top: 2px;
      }
    }

    &.file-gridView-selected{
      // .gridView-display-fileView {
        border-color: $fileViewHover-border-color;
        background-color: $fileView-selected-background;
      // } 
      // .gridView-name {
      //   background-color: lighten($_mainColor, 20%);
      //   border-radius: 5px;
      //   color: #fff;
      // }
      &:hover {
        background-color: $cold-gary;
      }
    }
  } // end of .file-grid-view

  pop-file-owner-info{
    @include unSelectable();
  }

  .file-display-zone {
    height: calc(100%);
    @include mainTextSize();
    overflow: hidden;
    @media #{$phone}{
      // 不知道unquote是要做什麼的，而且看起來沒work，暫時先註解起來測試 [Jane 2018-11-05 12:25:46]
      // height: calc (unquote("100% - #{$sidebarIconHeight}")); 
    }
  }

  .file-view-panel-title {
    margin: 0;
    .file-search-box {
      margin-left: 10px;
    }

    @media #{$phone} {
      .text-ellipsis {
        width: 150px;
      }
    }

  }
  .file-view-body {
    padding: 0px $panel-side-margin;
    height: calc( 100%  - #{$viewTitleHeight} - 5px );// 5px is offset-bottom space @ 2018/09/28 by smith 
    @media #{$phone} {
      padding: 0 !important;
      height: calc( 100%   - #{$viewTitleHeight} - #{$sidebarIconHeight} );
    }
    .file-upload-icon{
    	font-size: 1.3em;
    }
  }

  .folder-display-zone-div {
    border: 1px solid lightgray;
    background: rgba(255, 255, 255, 0.9);
    border-radius: 8px;
    padding: 5px 0;
    height: calc( 100%);
    @media #{$phone} {
      width: 100% !important;
      padding: 0 !important;
      border-radius:0px !important;
    }
  }
  .folder-detail-view-div{
      height: calc( 100% - 21px )  ; // 21px is th height , hidden in phone 
      @media #{$phone}{
        height: calc(100%);
      }
  }
  .folder-display-zone {

	    border-right: 1px solid #d0d0d0;
      height: calc( 100% ) !important;
	    overflow: hidden;
	    padding-right: 10px;
	    padding-left: 10px;
	    @media #{$phone} {
	      padding-right: 0px;
	      padding-left: 0px;
       // padding-bottom: 100px; //give space for moible bottom nav height , by smith @20170921
      }
      
      .file-folder-menu {
        cursor: pointer;

        @include hover-supported() {
          background: $folder-row-hover;
	        color: white;
        }
        
        &:active {
          background: $folder-row-active;
          color: white;
        }
        
	      &.folder-row-selected {
	        	background-color: $folder-row-selected;
            color: white;
		        .folder-name {
		          color: white;
		        }
            .delete-folder-btn{
              color:white;
            }
        }
      }

      .show-hide-sprint-folder{
          font-weight: 400;
          padding-right: 0.5em;
          cursor:pointer;
      }

	    //.add-folder-btn,
	    .delete-folder-btn {
	      background-color: transparent;
	      border: 0;
	      font-style: normal;
	      line-height: inherit;
              height: inherit;
              padding:3px 5px;  // [Jane 2018-12-21 12:55:52]
	      &:hover {
	        color: white;
	      }
	    }
      .folder-editName-icon{
        background-color: transparent;
        border: 0;
        font-style: normal;
        line-height: inherit;
        height: inherit;
        cursor: pointer;
        padding:0px;
      }
	    .add-folder-btn {
	      //color: white !important;
	      //position: absolute;
	      //top: 4px;
	      //right: 12px;
	      //height: 12px;
	    }
      .folder-sub-title{
        margin: 0.5em 1em;
      }
      .folder-title{
        margin: 0.5em 1em;
      }

	    .layer-two {
		      position: relative;
		      display: inherit;
		      margin: 0.5em 1em;
          border: 1px solid lightgray;

          border-radius: 15px 0 0 15px;
		      &.create-folder{
		      		 background: lighten($_mainColor, 45%);
		  			.pjc-folder-icon{
				      	&:hover{
				      		background:transparent;
				      	}
				        &:active{
				        	background:transparent;
				        }
				    }
          }
          &.folder-row-selected {
            background-color: $folder-row-selected;
            color: $folder-row-active-color;
            border-radius: 15px 0 0 15px;

            .folder-name, .add-folder-btn {
		          color: white;
		        }
            .delete-folder-btn{
              color:white;
            }
	      }
	    }
	    .layer-one {
		      position: relative;
		      background: lighten($_customizedFolderColor, 20%);
		      .btn-link {
		        color: white;
		        background: lighten($_customizedFolderColor, 20%);
		      }
		      .customized-folder-icon {
		        .folder-name {
		          color: white;
		        }
		      }
	    }

      .layer-root{
        display:block;
        font-weight: 800;
        padding: 3px 10px;
        margin: 0px -10px;
        @media #{$phone}{
          margin:initial !important;
        }
        &:hover {
	        background: lighten($_mainColor, 30%);
	        color: white;

          .add-folder-btn {
            color: white;
          }
	      }
	      &:active {
	        background: darken($_mainColor, 5%);
	      }

      }

	    .folder-name {
		      font-weight: initial;
		      $_uploadIconWidth: 50px;
		      $_badgeWidth: 30px;
		      @include mainTextSize();
		      margin:0px;
		      margin-left: 4px;
          	      font-family: $context-font;
		      max-width: calc(100% - #{$_uploadIconWidth} - #{$_badgeWidth} - 20px);
		      display: inline-block;
		      line-height: initial;
		      line-height:normal;
		      vertical-align: middle;
		      padding:6px;

		      &:hover,
		      &:active {
		      	i{
		        	color: white;
		        }
		      }
	    }
	    .default-folder-text {
	      font-weight: 500;
	      color: darken($_mainColor, 20%);
        padding: 10px 1em 0px 1em;
        @include unSelectable();
	    }
	    .customized-folder-icon {
	      $_mainColor: $_customizedFolderColor;
	      padding: 0 0.5em;
	      color: $_mainColor;
        display: block;
        border-radius:15px 0 0 15px;
	      @include mainIconSize();

        &.folder-edit {
          background-color: burlywood;
        }
	    }
	    .tag-folder-icon {
		      $_mainColor: $calm;
		      color: $_mainColor;
		      display: block;
		      @include mainIconSize();
		      padding: 0 0.5em;
	    }
	    .pjt-folder-icon {
		      $_mainColor: $calm;
		      color: $_mainColor;
		      display: block;
		      @include mainIconSize();
		      cursor: pointer;
		      padding: 0 0.5em;
	    }
	    .user-folder-icon {
		      $_mainColor: $calm;
		      color: $_mainColor;
		      display: block;
		      @include mainIconSize();
		      cursor: pointer;
		      padding: 0 0.5em;
	    }
  }
  
  .customFilter-label {
    color: gold !important;
    cursor: pointer;
    font-size: 0.8em;
      display: inline-flex;
      align-items: center;
      height: $viewTitleHeight;
      margin-right: 10px;
      .filter-item{
          margin-left:6px;
      }

  }
}

.file-detail-comment-editor {
  width: 100%;
  padding: 0 15px;

  $_chatBackgroundColor: #f0f8ff;
  @include chatLeft($_chatBackgroundColor);
  .panel-body {
    padding: 0 !important;
  }
  .reply-area {
    position: relative;
    @include height-vh-patch("100vh - #{$navbarHeight} - #{$viewTitleHeight} ");
    padding-bottom: 100px !important;
    .media-body{
      //width: calc( 100% - 50px);
      display:inline-block;
    }
  }
  .editing-comments{
    width:calc(100% - #{$generalMemberHeight} - 13px) !important;
    display:inline-block;
    .info-assignment{
      color:$main-text;
    }
   // .info-assignment-select{
   //   button{
   //     border-color:$main-text !important;
   //   }
   // }
  }



}

.tag-area {
  .tag-dropdown-menu {}
  .tag-input {}
}

.fileviewDetailAbsolute {
  margin-top: $swimLanHeaderHeight;
  .tag-area{
  	margin-bottom: 10px;
  }
  .modal-header-link {
    position: relative;
    float: left;
    @media #{$phone} {
      margin-top: 0; 
      left: 0;
    }
  }
}

.file-icon-display {
  position: relative;
}

.lg-icon {
  margin: 10px 0px 0px 0px;
  height: 80px ! important;
}

.md-icon {
  margin: 10px 0px 0px 0px;
  height: 60px ! important;
}

.sm-icon {
  margin: 0px 0px 0px 0px;
  height: 20px ! important;
  vertical-align: middel;
}

.icon-name-lg {
  width: 120px;
  position: relative;
  @include mainTextSize();
  margin-right: 10px;
}

.icon-date-lg {
  width: 120px;
  position: relative;
  @include smallTextSize();
}

.icon-name-md {
  width: 80px;
  position: relative;
  @include tinyTextSize();
  margin-right: 10px;
}

.icon-date-md {
  width: 80px;
  position: relative;
  @include tinyTextSize();
}

.file-icon {
  display: inline-block;
}

.file-backgroundimage {
  background-image: url("../../assets/img/sprint-test.jpg") ! important;
  background-size: contain ! important;
  ;
  background-origin: content-box ! important;
  background-position: top center ! important;
  height: calc(100%) !important;
  @include height-vh-patch("100vh - 90px");
  background-repeat: no-repeat ! important;
}

.file-detail-panel-scroll {
  $barColor: $mainColor;
  @include scrollTinyBar($barColor,$barSize);
  padding-bottom:100px;
  pop-comment-editor {

  }
  &.cannot-discuss-scroll-height{
	//   TODO: 待查為何 panel-body 會被加上 inline-style(code 上沒有)，在此先用 !important 覆寫高度[tomas, 2021/5/19]
  	//  @include height-vh-patch("94vh - 150px"); // 94 vh is bootstrap modal-lg height in normal size
		height: calc(94vh - 150px) !important;
  	 @media #{$phone}{
 	  	//  @include height-vh-patch("100vh - 150px"); // 84vh is bootstrap modal-lg height in phone size
		height: calc(100vh - 150px) !important;
  	 }
  }

}

.sprint-todolist-zone {
  display: block;
}


.on-delete-it,
.on-delete-it>a {
  color: red;
}

#file-detail-view td {
  padding-top: 3px;
  padding-bottom: 3px;
}

.fileview-todo-section-header,
.fileview-comment-section-header {
  margin: 6px 0px;
  padding: 0px;
  @include unSelectable();
  
  @media #{$phone} {
	  font-weight: bold;
	  background-color: transparent;
	  margin-left: 6px;
  }
}

.fileUpload-container {
  //border: 1px dashed lightgray;  remove to make nean also void the error reactive in some scenario by smith sun @ 20180215
  margin-top: 0px;
}

.fileUploadModal-body {
  overflow-y: scroll;
  max-height: 68vh;
  .dropdown-menu {
    max-height: 50vh;
  }

  .dropdown .file-saveTo {
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.filelist-table {
  $_mainColor: $cool;
  background-color: white;
  margin-bottom: 0;
  table-layout: fixed;
  tr {
    margin: 3px 0px;
    &.readyUploadFile {
      background-color: lighten($_mainColor, 55%);
      border: 2px solid white;
      overflow: hidden;
      td {
        padding: 2px 0px 2px 2px !important;
        //overflow: hidden;   // bug: use hidden will hide the dropdown menu [Benx 2017-06-23 04:26:19]

        &.col-file-name {
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }
    }
  }
  td {
    padding: 5px;
    line-height: normal;
    vertical-align: middle;
    .col-file-name {
      word-break: break-word;
    }
  }
}
/* todo#39051:從「檔案管理」上傳檔案時，選擇想要上傳的階段計畫時，因下拉選單在 Modal 內，當階段計畫數量多時會造成選取不便。
 [jane 2018-09-10] */
.fileUploadDropdown{
  max-width: 180px;
    &.open{ 
      z-index: 1;
      position: fixed;
      width: 100%;
  }
}

.clipboard-img-table {
  $_mainColor: $cool;
  background-color: white;
  margin-bottom: 0;
  table-layout: fixed;
  tr {
    margin: 3px 0px;
    &.readyUploadImage {
      background-color: lighten($_mainColor, 55%);
      border: 2px solid white;
      overflow: hidden;
      td {
        padding: 2px 0px 2px 2px !important;
        //overflow: hidden;   // bug: use hidden will hide the dropdown menu [Benx 2017-06-23 04:26:19]

        &.col-file-name {
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }
    }
  }
  td {
    padding: 5px;
    line-height: normal;
    vertical-align: middle !important;
    text-align: center;
    .col-file-name {
      word-break: break-word;
    }


  }

  img {
    max-width:100%;
    max-height: 100px;
  }
  .upload-cancel-btn{
    vertical-align: middle;
  }
}

.fileIcon {
  $_size: $swimLanHeaderHeight * 0.8;
  float: left;
  height: $_size;
  width: $_size;
  background-image: url("../../assets/img/file-type/default-file-icon.png");
  background-size: $_size $_size;
  cursor: pointer;
  display: inline-block;

  &.fileIcon-jpg,
  &.fileIcon-jpeg,
  &.fileIcon-gif,
  &.fileIcon-png {
    background-image: url("../../assets/img/file-type/img.png");
    background-size: cover;
  }
  &.fileIcon-xls,
  &.fileIcon-xlsx {
    background-image: url("../../assets/img/file-type/xls.png");
    background-size: cover;
  }
  &.fileIcon-ppt,
  &.fileIcon-pptx {
    background-image: url("../../assets/img/file-type/ppt.png");
    background-size: cover;
  }
  &.fileIcon-pdf {
    background-image: url("../../assets/img/file-type/pdf.png");
    background-size: cover;
  }
  &.fileIcon-doc,
  &.fileIcon-docx,
  .fileIcon-rtf {
    background-image: url("../../assets/img/file-type/doc.png");
    background-size: cover;
  }
  &.fileIcon-txt {
    background-image: url("../../assets/img/file-type/txt.png");
    background-size: cover;
  }
}
.attachment-file-list{
  .attachment{
    display:inline-block;
    //margin-right: 10px;
    vertical-align: top;
    padding:3px;
    max-width: 100%;
  }
}
.attachment {
  .attachment-entry {
    display: inline-block;
    width: 100%;

  //  &.highlight>a {
  //    background-color: yellow;
  //  }
  //  &:before {
  //    content: '';
  //    display: inline-block;
  //    vertical-align: middle;
  //    height: 100%;
  //  }
  //  &>a {
  //    display: inline-block;
  //    vertical-align: middle;
  //  }

  }
  .thumbnail {
    max-width: 90%;
    max-height: 40vh;
  }
}

.file-description{
  overflow:hidden;
  text-overflow:ellipsis;
  white-space: nowrap;
  color: lightslategrey;
}

.file-label {
  $_mainColor: $calm;
  $_subColor: $warm;
  background-color: lighten($_mainColor, 10%);
  color: white;
  margin: 3px;
  border-radius: 0;
  cursor: pointer;
  display: inline-block;
  &:hover {
    background-color: lighten($_mainColor, 15%);
  }
  &.file-label-on {
    background-color: lighten($_subColor, 10%);
    border-radius: 3px;
  }
  &>.btn-remove {
    &:hover {
      color: red;
      background-color: lighten($_mainColor, 20%);
    }
  }
}

.tag-dropdown-menu {
  margin-left: 1em;
  padding: 0.5em;
  background: rgb(169,190,199);
  input {
    padding: 0.2em 0.5em;
    margin-bottom: 0.5em;
  }
  @media #{$phone}{
    width: calc( 100vw - 30px );
    width: -webkit-calc( 100vw - 30px );
  }
}

.file-comments-editor {
	  position: absolute; //fixed will make 100% be 100vw;
    clear:both;
	  width: 100%;
	  bottom: 0;
	  left: 0;
	  background: $comment-editor-background;
	  border-radius:0x;
	  padding: 10px;
	  @media #{$phone}{
	  	padding: 0px;
      min-height:50px;
      position:fixed;
      z-index:200;
      border-radius: 0px;
	  }
	  .cannot-discuss {
		    font-size: 1.1em;
		    color: white !important;

		    em{
		    	text-decoration:none !important;
		    }
	  }


}

@media #{$phone} {

  .fileviewDetailAbsolute {
    margin-top: initial;

  }
  .file-detail-panel .panel-heading a .file-name {
    width: 7em;
    max-width: initial;
  }
  .file-view-panel {
    padding-right: initial !important;
    padding-left: initial !important;
    .view-page-header {
      padding-left: 6px !important;
      @media #{$phone}{
        padding-left:0px !important;
      }
    }
  }
  .file-detailview-header{
  	background: lighten($warm, 30%);
    width: 100%;
    display: inline-block;
    color:white;
    .pjt-folder-icon {
	    $_mainColor: $calm;
	    color: $_mainColor;
	    @include mainIconSize();
	    cursor: pointer;
	    padding: 0 0.5em;
	  }
	  .selFolder-xs {
	      @include mainTextSize();
	      max-width: calc( 100vw - 100px );
	      display:inline-block;
	  }
	  .back-to-folder{
		  	background: lighten( $warm, 15%);
		  	color : white;
		  	padding: 0 6px;
	  }
  }

}
.uploadLabel{
  position: absolute!important;
  right: 0px;
  top: 0px;
  border:0px;
  background-color: $uploadLabel;
  &:hover {
    background-image: $uploadLabel-hover;
  }
  &:active {
    background-image: $uploadLabel-active;
  }
}
