/* latestInfo scss */
.label {
  white-space: initial;
}


.label-editor-div{
    background:lighten(lightgray, 10%);
    @include todoLabelMaxWidth();
    padding:20px 10px 10px 10px;
    //margin-top:-3px;
    //margin-left:-3px;
    box-shadow: 0px 0px 3px gray;
    input{
        width: 100%;
        color:$main-text !important;
    }
    .input-group {
      margin-bottom: 5px;
    }
}
.label-editor-addBtn:not([disabled]){
  background-color: $reportStepColor;
  color: $white;
}
.label-editor-icon {
  position: relative;
  top: 1.6px;
  left: 3px;
  display: inline-block;
  transform: scale(0.6,0.6);
  margin-right: -2px;
}
.label-picker-container{
   background:lighten(lightgray, 10%);
   padding:6px;
   box-shadow: 0px 0px 3px gray;
}
.filter-label-list{

    padding: 10px 5px 5px 10px ;
    min-width: calc( #{$todoFilterMinWidth} - 30px );
	display: inline-block;
    @media #{$phone}{
      min-width: calc( 100% );
      width: calc( 100% );
    }
    .label.filter-item {
        $_margin: 5px;
        min-width: calc( (100% - 24px) * 0.25 ); //20px = $_margin * 4
        margin: 0 $_margin  $_margin  0;
        padding: 5px;
        border-radius: 2px;
    }

}

.todo-label {
  @include otisLabel();
  > .icon-close {
    cursor: pointer;
  }
}

.label-editor-div {
  .todo-label.label-off {
    cursor: pointer;
  }
}

.label.label-off {
  background: gray !important;
}
.label.bg-primary {
  background-color: #337ab7 !important;
}
.label.bg-success {
  background-color: darken(#dff0d8, 30%) !important;
}
.label.bg-info {
  background-color: darken(#d9edf7, 30%) !important;
}
.label.bg-warning {
  background-color: darken(#fcf8e3, 30%) !important;
}
.label.bg-danger {
  background-color: darken(#f2dede, 30%) !important;
}


.label.filter-item {

  background: gray;
  margin-right: 5px;
  display:inline-block;
  cursor:pointer;

}

.label {
	font-size: 0.9em;
	&.filter-item.label-on, &.is-filter-label {
		font-size: 150%;
	}

	&.is-filter-label {
		font-style: italic;
	}
}

.label-filter {
	.label {
		&.filter-item.label-on, &.is-filter-label {
			font-size: .9em;
		}

		&.is-filter-label {
			font-size: .9em;
		}
	}
}

.filter-label-list {
	.label {
		&.filter-item.label-on, &.is-filter-label {
			font-size: 150%;
		}
	}
}

.list-view__label-filter {
	.label {
		&.filter-item.label-on, &.is-filter-label {
			font-size: 150%;
		}
	}
}

.list-vew__label-input--selected {
	.label {
		&.filter-item.label-on, &.is-filter-label {
			font-size: 0.9em;
			margin-top: 4px;
		}
	}
}

.dropdown-toggle.filter-toggle.label-filter {
	display: flex !important;
	flex-wrap: wrap;
	.field {
		display: flex;
		align-items: center;
	}
	.filter-item {
		margin-top: 8px;
		margin-bottom: 8px;
		&:first-child {
			margin-left: 8px;
		}
	}
}

.labelSelect {
    input{
    	padding:6px;
    	color:#555;
    	width:100%;
    }
}