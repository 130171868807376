/* pollex dropdown */
.dropdown-menu{
	width: inherit;
	padding:0;
	overflow-x:hidden;
	li{
		cursor: default;
		a{
			white-space:normal !important;
			cursor: pointer;
		}
	}
}

.dropdown.open {
	background: $toggle-hover;
	color: $toggle-color;
	box-shadow: $toggle-box-shadow;

	@media #{$phone} {
		background: transparent;
		box-shadow: none;
	}

	.otis-toggle:hover {
		box-shadow: $toggle-box-shadow;
	}
	
}

.dropdown:focus {
	outline: 0px;
}

.dropdown .dropdown-menu >li >a {
	border-bottom:0;
	
}

.dropdown-menu>li>a, 
.member-assign-dropdown .member-group {
	&:hover {
		background: $dropdown-hover-bg-color;
		// color: #fff;
	}
}

.dropdown-menu>.disabled>a, .dropdown-menu>.disabled>a:focus, .dropdown-menu>.disabled>a:hover {
    color: #777 !important;
}

.glyphicon-ok:before{
	font-family: "icomoon"!important;
	content: "\e914"!important;

}

.glyphicon-ok.check-mark:before{
	font-family: "icomoon"!important;
	content: "\e914"!important;

}

.dropdown-menu-xs{
	max-width: 6em;
	min-width: 6em;
}

.member-assign-dropdown {
	// .member-group:hover {
	// 	// background: lighten(lightgray, 10%);
	// 	cursor:pointer;
	// 	background: #acacac;
	// 	color: #fff;
	// }
	a.member-group {
		color: #333;
	}
}

// Datepicker Dropdown

.datepicker-dropdown {
	//z-index: 1200 !important; 拿掉測試會不會有副作用 [Jane 2019-04-11 15:46:41]
	// Do we need this??? [Benx 2018-09-21 13:01:56]
	//overflow-x: visible !important; // overwrite dropdown-menu setting @ 2010904 by smith sun
}

.datepicker {
	.year.active, .day.active{
		background: $datepicker-active !important;
		color: $datepicker-color  !important;

		&:hover {
			background: $datepicker-hover  !important;
		}
	}
	.day.today {
		background: rgb(211,211,211) !important;
	}
}

.bootstrap-select {
	width: 100%;
	.dropdown-menu li a{

		// @include hover-supported() {
		// 	background: #acacac;
		// 	color: #fff;
		// }
				
		&.short-divider {
			@include hover-supported()  {
				background: transparent !important;
			}
		}
	}
	.dropdown-toggle{
		background: transparent ;
		color: inherit;
		border-color:lightgray;

		.caret{
			  width: 10px;
			  height: 10px;
			  border: none;
			  margin-top:-10px !important;
			  &:before{
				font-family: "icomoon" !important;
			  	content: "\e909" !important;
			  }
		}
		&:focus {
			outline: 0px;
		}

		@include hover-supported() {
			background: $toggle-hover;
			color: $toggle-color;

		} 
	}

	&.open .dropdown-toggle{
		@include hover-supported () {
			border-color: lightgray;
			background: $toggle-hover;
		}
	}
}

.open>.dropdown-toggle.btn-default {
	border-color: transparent;
	background: $toggle-hover;
	color: $toggle-color;

	&:focus {
		background: $toggle-hover;
		border-color: lightgrey;
		color: $toggle-color;
	}

	@include hover-supported() {
		color: $toggle-color;
	}
}