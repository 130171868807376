/* layout scss */
.project-body{
	@include height-vh-patch("100vh");
    @include projectBodyWidth();
}

body{
	@include mainTextSize();
	@include height-vh-patch("100vh");
	overflow:hidden;

}

.top-navbar-height{
	top: $navbarHeight;
}

.sidebar-width-lg{
	width: $systemSettingSidebarWidth;
}

.no-js {
	height: 100vh;
	width: 100vw;
	overflow: hidden;
	position:fixed;
}


.otis-content{
    position:fixed;
    width: 100vw;
	@include height-vh-patch("100vh");
    display:block;

	overflow:hidden;
	background: $main-background-color-new;
    // background-color: $main-background-color; [Jane 2019-03-06 14:34:09]s
    //background-size: cover !important;
    //background-image: url(#{$backgroundImg})  !important;
    .sprint-view{ 
	   	background-size: cover !important; 
		-webkit-animation-name: fadeInTransitions; // issue in IE - cause to hide side bar and nav bar
		-webkit-animation-duration:2s;
		-webkit-animation-timing-function: ease-out;
		-webkit-animation-iteration-count: 1;
		animation-name: fadeInTransitions;
		animation-duration:2s;
		animation-timing-function: ease-out;
		animation-iteration-count: 1;
		@include height-vh-patch("100vh - #{$navbarHeight}");
		// patch by Benx [Benx 2018-08-27 15:40:23]
		// https://www.otispm.com/otis/index.html#!/pjm/p/725/backlog-list?showTodoDetail=33668
		// width: calc(100vw - #{$sidebarWidth});  
		@media #{$phone}{
			width: calc(100vw);
		}
	}
	
	.main-content-container {
		position: absolute;
		top: $navbarHeight;
		width: calc(100vw - #{$sidebarWidth});
		@include height-vh-patch("100vh - #{$navbarHeight}");
		margin: 0;
		padding:0;
		left:  $sidebarWidth;
		overflow:hidden;
		
		& > [ui-view="main"] {
			// NOTE: [HACK] 使用 right-sidebar-container 之 side-effect，必須指定該值，才能夠所有裝置相容
			// 若使用 overflow hidden/auto: 桌機 main 都正常，但 ios 瀏覽器首頁則空白
			// 若不設定 overflow: 桌機 sprint todo view, file view, backlog list view... 在開啟 right sidebar 後，都會空白; 但 ios 瀏覽器首頁則正常
			// （初步看來，是 .mobileMenu-main 設定 position 為 fixed 時，ios 碰到 .right-sidebar-container 時，會有問題。
			// 這個 workaround 先解，之後再看看從結構面上完整解決，否則可能很容易因為 css 的支援不同，而變得脆弱 [Benx 2018-08-30 11:25:39]）
			overflow: inherit !important;  
		}

		& > .right-sidebar-container {
			width: 0px;
		}
		
		@media (min-width: 1220px) {	// 1220px 保留 3 個看板水道的寬度 [Benx 2018-08-28 10:24:43]
			& > .right-sidebar-container.active {
				width: 350px;
				float: right;
				height: 100%;
				margin-right: -2px;
			}
		}
		
		@media (max-width: 1220px) {
			& > .right-sidebar-container.active {
				width: 320px;
			}
			
		}
		
	}
	
	@media #{$phone}{
		.main-content-container{
			position: absolute !important;
			//top: calc(#{$sidebarIconHeight} + #{$navbarHeight} + 5px)  !important;
			margin: 0 !important;
			padding:0 !important;
			left:0 !important;
			width:100vw !important;
			@include height-vh-patch("100vh - #{$navbarHeight} - #{$sidebarIconHeight}");
		}
	}
	
}


.right-side-panel { // give withd height and correct postion, enable IE correct display.
	position:fixed;
	z-index:90; // sidbar is 100, project dashboard bell is 50
	right:0;
	top:$navbarHeight;
	width: 350px;
	@include height-vh-patch("100vh - #{$navbarHeight}");
	display: none;
	
	&.active {
		display: block;
		z-index: 210;
	}
	
	//right:0;
	//width:100vw;
	@media #{$phone}{
		position:fixed;
		z-index:300; 
		right:0;
		top:0;
		width: 100vw;
	}
}

.view-title {
	height: $viewTitleHeight;
	line-height: $viewTitleHeight;
	//margin-top: 1vh;
	//background-color: lighten($titleBarColor,
	color: $viewTitleTextColor;
	//background:$viewTitleBackground;
	padding:0 10px;
	@include unSelectable();

	.view-page-header{
		margin-right: 1em;
	}
	@include menuDropdownSize();
}

.view-content-body {
	@include height-vh-patch("100vh - #{$navbarHeight} - #{$viewTitleHeight}");
}

.kanban-scroll-container {
	@include height-vh-patch("100vh - #{$navbarHeight} - #{$viewTitleHeight} - #{$panel-side-margin}");

	@media #{$phone} {
		@include height-vh-patch("100vh - #{$navbarHeight} - #{$viewTitleHeight} - #{$panel-side-margin} - #{$sidebarIconHeight}");
	}

	> .kanban-lane-list {
		height: 100%;
	}
}
.quick-filter-empty-placeholder {
	position: fixed;
	text-align: center;
	top: 30vh;
	width: calc(100vw - #{$sidebarWidth});
	color: rgba(211, 211, 211, 0.5);
	// https://www.otispm.com/otis/index.html#!/pjm/p/725/backlog-list?showTodoDetail=57098
	// Backlogs View - filter empty tip - Users cannot check the 'More: Including Closed Plans'
	// It can NOT higher than .right-side-panel
	z-index: 10;	
	
	.icon-filter-big  {
		font-size: 10em;
		margin-top: 25px;
	}

	.quick-filter {
		&.no-results {
			font-size: 3em;
			font-weight: bold;
		}

		&.no-results-tips {
			font-size: 1.4em;
			.tips {
				&:hover {
					color: bisque;
				}
			}
			.note {
				color: #002952;
			}
			label {
				color: burlywood;
			}
		}
	} 
} 

@keyframes fadeInTransitions{
	from{opacity: 0;}
	to{opacity: 1;}
}
