/* drag and drop */
$_cardMargin: 8px;
.dd{
	// NOT a correct solution. It will casue chrome and firefox scroll problem. [Benx 2018-09-16 13:05:36]
	// position: fixed; // todo#39069:https://www.otispm.com/otis/index.html#!/pjm/p/725/backlog-list?showTodoDetail=39069 [jane 2018-09-10]
	position: relative;
    display: block;
    margin: 0;
    padding: 0;
	list-style: none;
	
}

.dd-width {
	margin: 0;
	margin-left: 10px;
	margin-right: 3px;
	
	&.dd-swimlane-first {
		margin-left: 0px;
	}
}

.dd-list-bottom{
	@include swimLanWidth();
	padding: $_cardMargin;
	border-radius:0px 0px 8px 8px;
	
}

.dd-list-bottom-absolute{
    min-height: 80px;
}

.dd-swimlane.dndDraggingSource {
  display: none;
}

.dd-list{
	@include swimLanWidth();
	height: auto;
	display: block;
    position: relative;
    margin: 0;
	padding: 0;
	//max-height: 0;
    list-style: none;
    overflow-y: auto;
	// transition: max-height 2s;
	// -webkit-transition: max-height 2s;
    .todo-card{
        // cursor:move;
		position: relative;
        padding:0vh;
        margin:$_cardMargin;
        transition:  all 0.5s cubic-bezier(0.255, 0.900, 0.395, 0.975);
		opacity:1;
		//max-height: 100vh;
		color: black;
		&.ng-leave-active{
			max-height: 0vh;
			background: gray;
			color: white  !important;
			.dd3-content{
				$_mainColor:  lighten($main-text,20%);
				$_mainBackgroundColor:  rgba(220,220,220,1);
				background: $_mainBackgroundColor;
				color: $_mainColor  !important;
				.todoText{
					text-decoration:line-through;
				}
				a,.text-primary,.btn{
					color: inherit;
				}
				.icon-check-off:before{
					content: "\e915";
				}
			
				&:hover{
					background: darken($_mainBackgroundColor,20)  !important;
						color : rgba(0,0,0,0.7) !important;
				}
			}

		}

    }
    .dndPlaceholder {
	    background-color: lighten($main-text, 50%) !important;
	    min-height: 68px;
	    display: block;
	}
	.dndDraggingSource {
		display: none;
	}
	.dndDragging{
		background:yellow !important;
		border-radius:10px !important;
		&:not(.dndDraggingSource)	{
			border-radius:0px !important;
		}
	}


}

.dd-list-header{
	@include swimLanWidth();
	position:relative;
    text-align: left;
    padding: 0 0.6em;
    background: lighten($listHeaderGeneral, $unStartSprintLight);
    color: $main-text;
    border-radius: 8px 8px 0 0;
    //font-weight:200;
	line-height: $ddListHeaderHeight !important;
	height: $ddListHeaderHeight !important;
    font-size:1em;
    //display:inline-flex;

    .one-line-header{
		line-height: $swimLanHeaderHeight;
		display:inline-block;
		width: 80%; // todo#38139: UAT中,sprint主題的"圖示"變型。 [Jane 2018-08-29 12:15:19]
		// todo#37832: patch for sprint date and count info render. [Benx 2018-08-23 19:29:24]
		// width: calc(100% - 40px);
		// width: -webkit-calc(100% - 40px);
    }
}

.dd-swimlane-first .list-header .one-line-header {
	// 由於  todo#38139: UAT中,sprint主題的"圖示"變型 之修正 （width: 80%;），會導致 backlog view 的未分類待辦清單數字掉到第二行
	// 因此特別將 .dd-swimlane-first 下的 .one-line-header 設定為 inline，不要有寬度，就不會讓數字掉下了 [Benx 2018-08-31 01:30:07]
    display: inline; 
}

.dd-list-header--draggable {

	&:hover{
		cursor: move;
	}

	&:before {
	    content: '..';
	    position: relative;
	    top:-2.5px;
	    left: -3px;
	    text-shadow: 0px 5px currentColor, 0px -5px currentColor,0px -10px currentColor;
	    opacity: 0.65;
	    margin-right: 4px;
	}
}

.noShowDndplaceholder{
  .dndPlaceholder{
    display: none;
  }
}



.dd3-content {
    padding: 10px 0px ;
    min-height: 4em;
}


.dd-swimlane {
	display:inline-block;
	position:relative;
	vertical-align:top;
	max-height: 100%;
	overflow:hidden;
	@include swimLanWidth();
	@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
	   overflow:visible;  // onlye work above ie 10
	}
    &.selected{
    	$_borderColor: white;
    	$_borderWidth : 2px;
    	$main-text:$warm;
		.dd-list-header{
			@include swimLanWidth();
		    text-align: left;
		    padding: 0 1vw;
		    border-top: $_borderWidth  solid  $_borderColor !important;
		    border-left: $_borderWidth solid  $_borderColor !important;
		    border-right: $_borderWidth solid  $_borderColor !important;
		    //font-weight:200;
		    @include labelSize();
		    a{
		    	color: lighten($main-text, 60%) !important;
		    	font-weight:bold;
		    }

		    .one-line-header{
		    	    line-height: $swimLanHeaderHeight;
		    }
		}
		.dd-list{
			@include swimLanWidth();
		    border-left: $_borderWidth solid  $_borderColor !important;
		    border-right: $_borderWidth solid  $_borderColor !important;
		}
		.dd-list-bottom{
			border-bottom: $_borderWidth solid  $_borderColor !important;
		    border-left: $_borderWidth solid  $_borderColor !important;
		    border-right: $_borderWidth solid  $_borderColor !important;
		}
    }
    &.dndPlaceholder{//backlog.html:<span class="dndPlaceholder dd-swimlane dd-width "></span>
    	background-color: rgba(50,50,50,0.20);
		border-radius: 3px;
	}
	
	.dd-list {
		max-height: calc(100% - #{$ddListHeaderHeight} - #{$ddListBottomHeight} - 20px);
	}
}

