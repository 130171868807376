/* pollex sidebar scss 2016.01.30 by smith sun */ 

.systemSetting-sidebar { 
	@include menuDropdownSize();
	$_sidebarWidth : $systemSettingSidebarWidth;
	$_navSidebarColor: $systemSettingSidebarBkColor;
	$_navSidebarHoverColor: #337AB7;
	background: $_navSidebarColor;
	color: white;
	position: fixed;
	top: $navbarHeight;
	left: 0;
	height: 100%; 
    z-index:100;
	width: $_sidebarWidth;  
    // box-shadow: 5px -5px 5px -7px #333;  // TODO: remove it [Benx 2019-04-17 12:18:04]
	border-right: 1px solid rgba(darken($_navSidebarColor, 10%), 0.3);
 
 
		.sidebar-menu {
			overflow: visible; 
			padding-top:$_sidebarWidth*0.5;
			a {
				color:white;
			}
		}

		.nav.nav-sidebar {
			 margin: 0;
			 width: $_sidebarWidth;
			 padding: 0;
 
			 li {
			 	position:relative;
				
				&:hover {
					background: $_navSidebarHoverColor;
					& i {
						color: $nav-sidebar-icon-hover;
					}
				}
		 		&.active,
				&:focus,
				&.opened .hover{
					&, & a {
						color: $nav-sidebar-icon-hover;
						background: lighten($_navSidebarHoverColor, 5%); 
					}
					& i {
						color: $nav-sidebar-icon-hover;
					}
					
				} 
				.layerTwo-active{
					background:lightsteelblue !important;
					color: $_navSidebarColor !important;
					&, & a {
						color: $_navSidebarColor; 
					}
					& i {
						color: $_navSidebarColor;
					}
				}

				a { 
					position: relative;
			 	 
					 
					// @media all and (-ms-high-contrast: active), (-ms-high-contrast: none) {
					//     	height: $sidebarIconHeight * 1.5 ;
				   	// 		line-height: $sidebarIconHeight * 1.5;
					// }
					
					//padding:0px; 

					&.open {
						cursor: default;
					}

					.text {
						position: absolute; 
						top: 0px;
						// left:-3px;
						display: none !important;
						text-align: left;
						@include mainTextSize(); 
					}
					i {
						// width:  $_sidebarWidth ;
						text-align: center;
					    color: white;
						// color: $nav-sidebar-icon-color;
						@include bigIconSize();
					}

				}

				&:hover,&:focus {

					> a {
						position:relative;
						cursor: pointer;
						width: $_sidebarWidth;
						background: $_navSidebarHoverColor;
						// border-left: 8px solid darken($_navSidebarColor,30%) !important;
						// border-left: 8px solid  $_navSidebarColor !important;
						// margin-left: -4px !important;


						.text {
							width: 10em;
							display: block !important;
							margin:0px 0px 0px $_sidebarWidth;
							padding-right:2px;
							border-left: 0; 
							height: $sidebarIconHeight;
							line-height: $sidebarIconHeight; 
							// @media all and (-ms-high-contrast: active), (-ms-high-contrast: none) {
							//     	height: $sidebarIconHeight * 1.5 ;
						   	// 		line-height: $sidebarIconHeight * 1.5;
							// } ;
							color: white;
							padding-left: 10px;
							border-radius: 0px 8px 8px 0px;
							background:rgba(darken($_navSidebarColor,30%),0.75);
							 
						}
					}
				}
			}
		} 
}


ul{
	&.no-list-style{
		list-style-type: none;
	}
	&.no-padding-start{
		padding-inline-start:0px;
	}
}