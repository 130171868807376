/* template scss  */
.project-template{
	position: fixed;
    left: 0;
    width: 100vw;
    padding: 0 15px;
    overflow: hidden;
    display: block;
}

.tpl-menu{
	.tpl-label{
		 color: inherit !important;
	}
	.share-template{
		padding: 6px 16px;

	}
	.float_member_function{
		padding:0px 10px;
		margin-bottom:-6px; 
		//position:relative;
		.member-icon{
			margin:0px 6px 6px 0px;
		}
	}
	li{
		&:hover{
			.tpl-label{
				color:$viewTitleTextColor !important;
			}

		}
	}

}
 .share-template{
	.dropdown{
		&.open{
			.add-member-user-list{
				.share-description{
					background: lighten($main-background-hover, 20%);
					color:$viewTitleTextColor !important;
					@include mainTextSize();
				}
				.share-to-public, .share-tpl-text{
					color:$viewTitleTextColor !important;
					font-weight:initial;
					@include mainTextSize();
				}
				&:hover{
					color:$viewTitleTextColor !important;
				}
			}

		}
	}
}

.project-tpl-list{
	height:calc(100%);
	overflow-y:auto;
	padding-left:$panel-side-margin;
	padding-right:$panel-side-margin;
	@media #{$phone}{
		padding:0;
	}
	.page-title{
		margin-top:0.5em;
		font-weight:bold;
		color: $viewTitleTextColor;
		@include h1TextSize();
		text-align: center;
	}
	.sub-title{
		font-weight:bold;
		color: $viewTitleTextColor;
		margin-top:1em;
		margin-left:1em;
	  	@include h2TextSize();
	}

	.template-list-div{
		$_font-size:$h3;
		$_line-height:1.4;
		$_line-to-show:2;


		//display:inline-flex; // will make template-card shrink in shared-tpl
		padding: 0 20px 0 20px;
		@include mainTextSize();
		width:100%;
		$_mainColor: $main-text;
		ul{
			list-style-type: none;
			margin:0;
			padding:0;
			.template-card{
                    $_fontColor: $main-text;
                    @include cardWidth();
                    //$_background: darken($projectTplBackground, 0%);
					$_background:whitesmoke;
					$_height: $_line-height  * $_line-to-show * $_font-size + (2 * $fontUnit * 3);

				 	display:inline-block;
					position:relative;
					text-align:center;
					vertical-align:top;
					background: lighten($_background, 0%);
					color:lighten($main-text, 30%);
					width: 14em;
					margin: 0.5em; 
					border:1px solid darken($_background, 10%);
					height: $_height;
					cursor:hand;
					cursor:pointer;
                    a{
                        color: $_fontColor;
                    }

					&:hover{
						background: lighten($_background, 10%);
						a{
							color:$_fontColor !important;
						}
					}
					.template-name-clickable{
						width:calc(100%);
						height:calc(100%);
					}

                    .template-name-div{
                        display:inline-flex;
                        //vertical-align:middle;
                        padding:1em;
                        height: $_height;
				
                        .template-name{
							display: flex;
							display:-webkit-flex;
                            line-height: $_line-height;
                            -webkit-line-clamp: $_line-to-show;
                            -webkit-box-orient: vertical;
							align-self:center;
                           
                            overflow:hidden;
                            text-overflow: ellipsis;
                            color: $_fontColor;
                            @include multiline-ellipsis($_line-height,2);
                        }
                    }
					a{

						&.edit-icon{
							position:absolute;
							right:0;
							padding: 6px;
                            color: $_fontColor;
                            &:hover{
                                background: darken($_background, 30%);
                                color: white !important;
                            }
                            &:active{
                                color: gold;
                            }

						}
					}
					.add-btn{
						display:block;
						color: $_fontColor;
						width:100%;
						font-size:1em;
					}


			}
		}

	}
}
.template-div{
	display:inline-flex;
	@include mainTextSize();
	overflow-x: hidden;
	overflow-y: hidden;
	.dd-swimlane{
		@include swimLanWidth();
	}

	.tpl-file-panel{
		$_mainColor: $warm;
		@include swimLanWidth();
		padding-right:15px;
	}
	.tpl-panel-body{
		margin-top:-1px;
		.no-item-hint{
			padding:6px 0px;
			color: indianred ;
		}
		.file-attachment{
			width:100% !important;
		}
	}

	.tpl-sprint-panel{
		$_mainColor:  darken($main-background-color,15%);
		@include swimLanWidth();
		padding-right:15px;
		margin-bottom:15px;


		.header-title{
			display:inline-block;
            width:100%;
            align-items:center;
			padding:0 10px;
			height: $swimLanHeaderHeight;
			line-height: $swimLanHeaderHeight;
			background: lighten($_mainColor, 0%);
			color: $viewTitleTextColor;;
			font-size:1em;
			text-align: center;
		}


		.edit-tpl-sprint{
			cursor:pointer;
			float:right;
		}


		.tpl-panel-body{
			overflow:hidden;
            background: lighten($_mainColor, 5%);
			border-right: 1px solid $_mainColor;
			border-left: 1px solid $_mainColor;
            padding: 0px 10px;

			ul{
				list-style-type: none;
				margin:0; 
				padding:0;



					li{
					padding:10px;
					margin:10px 0;
					background: lighten($_mainColor, 55%);
					font-size:1em;
					color:  lighten($main-text, 0%);
					border-radius: 1px;

					a{
						color: inherit;

						i{
							margin-right:0.3em;
						}
					}



				}


			}

		}
		.add-btn{
			display:block;
			padding:0 10px;
			height: $swimLanHeaderHeight;
			line-height: $swimLanHeaderHeight;
 			background: darken($_mainColor, 0%);
			color: $viewTitleTextColor;
			width:100%;
			font-size:1em;
			border-radius:0px;
			margin-bottom:1em;
			&:hover{
				 background: darken($_mainColor, 5%);
			}
			&:active{
				 background: lighten($_mainColor,5%);
			}


		}
	}

	.tpl-file-panel{
		$_mainColor: darken($main-background-color,10%);
		.header-title{
			display:block;
			padding:0 10px;
			height: $swimLanHeaderHeight;
			line-height: $swimLanHeaderHeight;
			background: darken($_mainColor, 0%);
			color:$viewTitleTextColor;
			font-size:1em;
			text-align: center;

		}
		.tpl-panel-body{
			margin-bottom:0;
			background: lighten($_mainColor, 5%);
			border-right:1px solid darken($_mainColor, 5%);
			border-right:1px solid darken($_mainColor, 5%);
			padding:10px;
			.attachment-file-list{
				margin:0;

			 	.attachment{
					padding: 0.1em;
					//font-size:0.7em;
					border-radius:1px;
					margin-bottom:8px;
					//background: whitesmoke;
				}
				a{
					color:$main-text;
				}
			}

		}
		.add-btn{
			display:block;
			padding:0 10px;
			height: $swimLanHeaderHeight;
			line-height: $swimLanHeaderHeight;
 			background: darken($_mainColor, 0%);
			color: $viewTitleTextColor;
			width:100%;
			font-size:1em;
			border-radius:0px;
			&:hover{
				 background: darken($_mainColor, 5%);
			}
			&:active{
				 background:light($_mainColor,5%);
			}

		}
	}

	.tpl-todo-zone{
		display: inline-flex;
		overflow-x:auto;
		@include pageScrollArrow();
		.scroll-X-btn .arrow-left {
  			  margin-left: -12px;
  			  left: initial;
  		}
		.tpl-todo-swimLan{
			@include swimLanWidth();
			&.last-tpl-sprint{
				padding-right : calc(100% + #{$sidebarWidth} - #{$swimLanWidth} - 15px );
			}

		}
		.tpl-todo-panel{
			$_mainColor: lighten($generalSprintColor,0%);
			@include swimLanWidth();

			.header-title{
				display:inline-flex;
				width:100%;
                align-items:center;
                padding:0 10px;
				height: $swimLanHeaderHeight;

				background: darken($_mainColor, 5%);
				color: $viewTitleTextColor;;
				font-size:1em;
				text-align: center;
				.btn-link{
					color: $viewTitleTextColor;;
				}
				i{
					margin-right:0.3em;
				}

			}
			.tpl-panel-body{
				overflow:hidden;
				color: $viewTitleTextColor;
				background: $_mainColor;

				padding:0 10px;
				.subtodo-list-div{
					margin :0 -10px;
					padding-top:5px;
					border-top: 1px solid lightgray;
				}

				ul{
					list-style-type: none;
					margin:0;
					padding:0;
					color: lighten($main-text, 0%);

					&.subtodo-list{
						list-style-type:decimal;

					}
					li{
						padding:10px 10px 0 10px;
						margin:10px 0;
						background: rgba(255,255,255,0.98);
    					border-radius: 3px;
						font-size:1em;

						&.subtodo-item{
						    padding: 0.2em 0em;
						    margin: 0;
						    margin-left: 2em;
						}

						a{
							&.tpl-todo-item{
								color: black !important;
							}
							color: $main-text;
							i{
								margin-right:0.3em;
							}
						}
					}
				}

			}
			.add-btn{
				display:block;
				padding:0 10px;
				height: $swimLanHeaderHeight  ;
				line-height: $swimLanHeaderHeight  ;
	 			background: darken($_mainColor, 5%);
				color:  $viewTitleTextColor;;
				width:100%;
				font-size:1em;
				border-radius:0px;
				&:hover{
					 background: darken($_mainColor, 10%);
				}
				&:active{
					 background: lighten($_mainColor,0%);
				}


			}
			.subtodo-add-btn{
				display:inline-block;
				padding:0px 10px 10px 10px;
				//height: $swimLanHeaderHeight * 0.5 ;
				//line-height: $swimLanHeaderHeight  * 0.5 ;
				color: $main-text;
				font-size:0.6em;
				border-radius:0px;
				background:transparent;
				border-radius: 3px;
				//margin:3px 3px 0px 10px;
				width:100%;
				text-align:left;

				&:hover{
					 background: darken($_mainColor, 5%);
					 color: white;
				}
				&:active{
					 background: lighten($_mainColor,5%);
					 color: white;
				}


			}
		}
	}




}

.sprint-todo-edit-area{
	$_mainColor: $main-background-color;
	overflow-x: scroll;
    overflow-y: hidden;
    width: 58vw;
    min-width: 21em;
    height: $todoPanelHeight - $swimLanHeaderHeight-vh;

	ul{
		display:inline-flex;
		list-style-type: none;
		margin:0;
		padding:0;

		li{
			display:inline-flex;
			padding:10px;
			margin-right:10px;
			background: lighten($_mainColor, 55%);
			font-size:1em;
			width:18em;

			&.even-background{
				background: lighten($_mainColor, 40%);
				a{
					color:white;
				}
			}

			a{
				color: lighten($_mainColor, 60%);
				i{
					margin-right:0.3em;
				}
			}


			.tpl-panel-body{

				ul{
					list-style-type: none;
					margin:0;
					padding:0;


					li{
						padding:10px 10px 0 10px ;
						margin:10px 0;
						background: lighten($_mainColor, 55%);
						font-size:1em;

						&.even-background{
							background: lighten($_mainColor, 40%);
							a{
								color:white;
							}
						}

						a{
							color: lighten($_mainColor, 0%);
							i{
								margin-right:0.3em;
							}
						}


					}
				}

				.add-btn{
					display:block;
					padding:0 10px;
					height: $swimLanHeaderHeight * 0.8 ;
					line-height: $swimLanHeaderHeight  * 0.8 ;
		 			background: lighten($_mainColor, 70%);
					color: $_mainColor;
					width:100%;
					font-size:1em;
					border-radius:0px;
					&:hover{
						 background: lighten($_mainColor, 60%);
					}
					&:active{
						 background: lighten($_mainColor, 55%);
					}


				}


			}


		}
	}
}


.input-block-level{
	width:100%;
	padding: 6px;
}


@media #{$phone}{
	.template-list-div{
		padding: 0 0.5em !important;

	}
	.project-tpl-list .template-list-div ul .template-card {
		.template-name {
			width: calc(100%) !important;
		}

	}

}
