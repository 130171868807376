/*  project list */
.change-theme{
	margin-right:6px;
	cursor:pointer;
	width: 8em;
	display: inline-block;
	font-size: 12px;
	line-height: 12px;
	padding: 3px 6px;
 	&:hover{
 		background:$warm;
 		color:white;
 	}
	&:active{
		background:lighten($warm, 10%);
	}
}


.todo-quick-add, .project-quick-add{
	right:20px;
    bottom: 60px;
    position: fixed;
    z-index: 100;
    cursor:pointer;
	&:active{
		.todo-add-icon{
			background:lighten(lightseagreen, 5%) !important;
		}
	}
	@media #{$desktop-md-above}{
		&:hover{
			.todo-add-icon{
				background:darken(lightseagreen, 10%);
			}
		}
	}
	.add-icon{
		border-radius: 50%;
		line-height: 3em;
		display: block;
		width: 3em;
		text-align:center;
		background: lightseagreen;
		color:white;
		font-size:1.2em;
		
	}
	.background-icon{
		position: absolute;
		top: 0;
		text-align: center;
		width: 100%;
		height: 2em;
		line-height: 1.68;
		font-size: 2em;
		opacity: 0.4;
		color: white;
	}
}

.action-add-project {
    padding: 2px 5px;
    cursor: pointer;
    font-size: 16px;
    margin-top: 5px;
}

.project-quick-add{
	bottom: 130px;
	.add-icon{
		background:rgb(100,100,100);
	}
}

#myProjectXs{
	//padding-bottom: 30vh;
}
#recent-project .box-header ,#recent-project .box-container{
	margin-left: 6px;
}

.box-header{
	// color: $viewTitleTextColor !important;
	color: white;
	height: 25px;
	line-height: 25px;
	
	@media #{$phone}{
		color: rgb(56, 56, 56);
	}
}

.org-zone-name{
	background: rgba(120,120,120,0.8);
    color: white;
    font-size: 10px;
    padding: 0 1px;
    font-weight: 200;
    max-width: 6.8em;
    width: auto;
    line-height: normal;
    overflow: hidden;
}

.label.project-label {
	background: #e4ddcf;
  color: #7d7a74;
	padding: 2px 3px;
	margin: 0px 2px;
	display: inline-block;
}

.myProjectList-modal  {
		
	.myDashboard {
		width: 100%;
		position: inherit;
		background-color: transparent;
		height: auto;
		padding-top: 30px;
		
		
		input {
			color: white;

			&::placeholder {
				color: rgba(100, 100, 100, 0.6);
				opacity: 1;
			}
		}

		@media #{$phone}{
			background-color: white;
			height: 100%;

			input {
				color: rgb(56, 56, 56);
			
			}
			
		}
	}
	
	.otis-modal-content-lg {
		background: none;
		width: 100vw;
		@include height-vh-patch("100vh");
		left: 0;
		margin-left: 0;
		margin-top: 0;
	}
	
}


.myDashboard{
	width: 100vw;
    position: fixed;
    padding-bottom: 10vh;
    left: 0;
    //overflow: auto;
    @include height-vh-patch("100vh - #{$navbarHeight}");
    @media #{$phone}{
    	overflow:hidden;
    	//margin-top: -$navbarHeight; // remove this to make sidebar align to bottom - by smith @ 2017-09-08
    }

    .tab-content{
			//line-height:0;
			//
			//$barColor: lightgray;
			$barColor: transparent;
			
			@include scrollTinyBar($barColor,$barSize);
		}

	#project-list-box{
		padding:0;
		@media #{$phone}{
			padding: 0;
		}
		.projectlist-brick-div{
			@include max-height-vh-patch("100vh - #{$navbarHeight} ");
			padding-bottom:10em;
		}

		.project-progress{
	  		margin-right:3px;
	  		display:inline-block;
		}
		.project-card{
			color: $main-text;
			@include cardWidth();
			.recent-bell{
				z-index:50 !important;
			}
			.project-activities-div{
				font-size: 0.88em;
				.activity-item .pop-goto-item {
					color:steelblue !important;
				}
			}
			.project-recent-update{
				position: absolute;
			    background: rgb(100, 100, 100);
			    color: white;
			    padding: 10px 0px 10px 10px;
			    z-index: 150;
			    left: 2px;
			    right: 2px;
			    border-radius: 3px;
			    border-bottom-left-radius: 3px;
			    top: 2em;
			    border-bottom-right-radius: 3px;
			    box-shadow: white 0px 3px 6px 0px;

				.pjt-activity .timeslot-left.alt .time{
					padding: 2px 46px 0px 0px !important;
					left: 0em !important;
					-webkit-transform: scale(0.8) !important;
					transform: scale(0.8) !important;

				}
				.btn-link{
					color:white !important;
					&:hover,&:active{
						color:gold !important;
					}
				}
			}
		}

		#recent-project{
			$_mainColor: $recentProjectCardColor;
			$_mainText: $main-text;

			color: darken($_mainColor, 50%);
			width: 100%;
			padding-top:1em;

			.project-card {
			    border: 1px solid lighten($_mainColor, 35%) !important;
			    background: lighten($_mainColor, 40%) !important;
			    &.overdued{
			    	$_overdueColor: darken($overdueColor, 10%);
			    	$_fontColor: white;
			    	@include  _projectOverdue($_overdueColor, $_fontColor);
			    }
			    &.dueInOneDay{
			   		$_overdueColor: orange;
			    	$_fontColor: white;
			    	@include  _projectOverdue($_overdueColor, $_fontColor);
			    }
			    &.dueInTenDays{
			  		$_overdueColor: $warm;
			    	$_fontColor: white;
			    	@include  _projectOverdue($_overdueColor, $_fontColor);
			    }
				.project-due-status-tag{
					position: absolute;
					background: inherit !important;
				    height: 20px;
				    bottom: 3.4em;
				    height: em;
				    height: 1.5em;
				    line-height: 1.5em;
				    width: 65%;
				    //border-radius: 2px;
				    padding: 0 5px;
				}
			  	.watch-project{
						color: darken($_mainColor, 20%);
						&:hover{
							color: $_mainColor;
						}
					}
			  	.project-data-row-3 {
			  		height: $projectCardRow3;
				    width: 100%;
				    display: inline-flex;
				    .project-data-row-3-2{
						display: inline-block;
						$_row-height: 1.22em;
						$_line-height:1.5em;
						$_font-size: 0.88em;
						$_line-to-show: 2;
						margin-top:-0.5em;
						overflow:hidden;
						width: calc(100% - 45px);

				    	.milestone-progress{
							display: flex;
							display:-webkit-flex;
							padding: 3px 0;
							@include multiline-ellipsis($_line-height , 2);
							line-height: $_line-height !important;
							word-break:break-word;
							text-align:left;
							font-size:$_font-size;
							margin-right: 10px;
							margin-bottom: 6px;

				    	}
				    	&.progressOverdue{
				    		.milestone-progress{
				    			color:lighten($overdueColor, 10%);
				    		}
				    	}
				    	.addTags{
						    color: darken(gray,10%) !important;
						    border:1px solid gray;
						    line-height:1.2em;
				    	}
				    }
			  	}
			  	a {
			  		color:	inherit;
			  		&:hover{
			  			text-decoration:none;
			  		}
			  	}
			  	&:hover{
			  		background: $projectCardHover-background !important;
		  			.project-card-tag-row{
		  				text-decoration:none !important;
		  			}
			  	}
			  	&:active{
			  		background: lighten($projectCardHover-background, 5%) !important;
			  	}
			 }
		}

		#joined-project{
			$_mainColor: $joinedProjectCardColor;
			$_mainText: $main-text;

			color: darken($_mainText, 50%);
			margin-top: 15px;
			width: 100%;
			margin-left: 6px;

			.project-card {
			    border: 1px solid lighten($_mainText, 60%) !important;
			    background: lighten($_mainColor, 40%) !important;;
 				&.overdued{
			    	$_overdueColor: darken($overdueColor, 10%);
			    	$_fontColor: white;
			    	@include  _projectOverdue($_overdueColor, $_fontColor);
			    }
			    &.dueInOneDay{
			   		$_overdueColor: orange;
			    	$_fontColor: white;
			    	@include  _projectOverdue($_overdueColor, $_fontColor);
			    }
			    &.dueInTenDays{
			  		$_overdueColor: $warm;
			    	$_fontColor: white;
			    	@include  _projectOverdue($_overdueColor, $_fontColor);
			    }
				.project-due-status-tag{
					position: absolute;
				    background: inherit !important;
				    height: 20px;
				    bottom: 3.4em;
				    height: em;
				    height: 1.5em;
				    line-height: 1.5em;
				    width: 65%;
				    //border-radius: 2px;
				    padding: 0 5px;
				}
			  	.watch-project{
						color: $projectCardWatchIcon-color;
						&:hover{
							color:white;
				   	 	background: lighten($warm, 30%) !important;
						}
					}

			  	.project-data-row-3 {
			  		height: $projectCardRow3;
				    width: 100%;
				    display: inline-flex;
				    .project-data-row-3-2{
						display: inline-block;
						$_row-height: 1.22em;
						$_line-height:1.5em;
						$_font-size: 0.88em;
						$_line-to-show: 2;
						margin-top:-0.5em;
						overflow:hidden;
						width: calc(100% - 45px);

				    	.milestone-progress{
							display: flex;
							display:-webkit-flex;
							padding: 3px 0;
							@include multiline-ellipsis($_line-height , 2);
							line-height: $_line-height;
							word-break:break-word;
							text-align:left;
							font-size:$_font-size;
							margin-right: 10px;
							margin-bottom: 6px;
				    	}
				    	&.progressOverdue{
					    		.milestone-progress{
					    			color:darken($overdueColor, 10%);
					    		}
				    	}
				    	.addTags{
						     color: darken($_mainText,10%) !important;
						     line-height:1.2em;
				    	}
				    }
			  	}
			  	a {
			  		color:	darken($_mainText, 40%);
			  		&:hover{
			  			text-decoration:none;
			  		}
			  	}
			  	&:hover{
			  		background: $projectCardHover-background !important;

			  	}
			  	&:active{
			  		background: lighten($projectCardHover-background, 5%) !important;
			  	}
			 }
		}
		#archived-project{
			$_mainColor: $archivedProjectCardColor;
			$_mainText: $main-text;

			color: darken($_mainColor, 50%);
		 	margin-top: 15px;
			width: 100%;
			margin-left: 6px;

			.project-card {
			    border: 1px solid lighten($_mainColor, 40%) !important;
			    background: lighten($_mainColor, 40%) !important;;
			  	.project-data-row-3 {
			  		height: $projectCardRow3;
				    width: 100%;
				    display: inline-flex;
				    .project-data-row-3-2{
						display: inline-block;
						$_row-height: 1.22em;
						$_line-height:1.5em;
						$_font-size: 0.88em;
						$_line-to-show: 2;
						margin-top:-0.5em;
						overflow:hidden;
						width: calc(100% - 45px);
				    	.milestone-progress{
							display: flex;
							display:-webkit-flex;
							padding: 3px 0;
							@include multiline-ellipsis($_line-height , 2);
							line-height: $_line-height !important;
							word-break:break-word;
							text-align:left;
							font-size:$_font-size;
							margin-right: 10px;
							margin-bottom: 6px;
				    	}
				    	.addTags{
							    color: darken(gray,10%) !important;
							    line-height: 1.2em;
				    	}
				    }
			  	}
			  	a {
			  		color:	darken($_mainColor, 5%);
			  		&:hover{
			  			text-decoration:none;
			  		}
			  	}
			  	&:hover{
			  		background: lighten($_mainColor, 30%) !important;

			  	}
			  	&:active{
			  		background: lighten($_mainColor, 35%) !important;
			  	}
			 }
		}

		.box-container{

			$_cardMargin: 7px;
			width: 100%;



			$_mainColor: $addProjectCardColor;
			.project-tag-row{

			}
			.add-project-card{
				display: inline-block;
				vertical-align: top;
				height: calc( #{$projectCardRow1} + #{$projectCardRow2} + #{$projectCardRow3} +   ( #{$projectCardPadding} * 2 ) + 2px );
				line-height: calc( #{$projectCardRow1} + #{$projectCardRow2} + #{$projectCardRow3} + ( #{$projectCardPadding} * 2 )  + 2px );

			    @include cardWidth();
			    margin: $_cardMargin;
			    border: 1px solid lighten($_mainColor, 40%);
			    background: lighten($_mainColor, 40%);
				text-align:center;
			    box-shadow:0px 1px 3px rgba(0,0,0,0.3);
				//border-radius:2px;
			    align-self: center;
			    cursor:pointer;



	    		.add-icon{
	    				font-size: $h1;
	    				color:$main-text;
	    				height: 100%;
	    				line-height:100%;
				}
	    		&:hover{
  			    background: lighten($_mainColor, 20%);
  			    color:white;
	  			    i{
	  			    	color:white;
	  			    }
	    		}
	    		&:active{
  			    background: lighten($_mainColor, 25%);
  			    color:white;
	  			    i{
	  			    	color:white;
	  			    }
	    		}
			}

			.project-card {
				display: inline-block;
			    margin:$_cardMargin;
			    padding: $projectCardPadding;
				box-shadow:0px 1px 3px rgba(0,0,0,0.3);
				border-radius:3px;
			    .edit-project{
			   	 	position: relative;
			   	 	cursor:pointer;
			   	 	.edit-icon{
		                float:right;
			   	 		height: initial;
			   	 		line-height: initial;
			   	 		line-height:normal;
			   	 		margin-top: -$projectCardPadding;
			   	 		margin-right: -$projectCardPadding;
			   	 		padding: 6px 6px 6px 6px;
			   	 		&:hover{
				   	 		color:white;
				   	 		background: lighten($warm, 30%) !important;
				   	 	}
				   	 	&:active{
				   	 		color:white;
				   	 		background: lighten($warm, 50%) !important;
				   	 	}

			   	   }
				}
			    .project-data-row-1{
			    	height: $projectCardRow1;
			    	$_fontSize: 1em;
			    	$_textLineHeight:1em;
			    	$_lineHeight:1.3em;
			    	$_lines: 2;

			    	.project-name-data{
			    		display: flex;
			    		.project-name{
					    	font-size:$_fontSize;
						    @include multiline-ellipsis($_lineHeight, $_lines);
						    line-height: $_lineHeight !important;
						    max-height : $_lineHeight * $_lines !important;
						}

				    }
			    }
			    .project-date-data{
						font-size: 12px;
						line-height:14px;
					}
			  	.project-data-row-3 {
				    height: $projectCardRow3;
				    width: 100%;
				    display: inline-flex;
				    .project-data-row-3-1{
				    	canvas{
				    		margin-right:6px;
				    		text-align:center;
				    		margin-top:-0.5em;
				    	}
				    }
				    .project-data-row-3-2{
						display: inline-block;
						$_row-height: 1.22em;
						$_line-height:1.5em;
						$_font-size: 0.88em;
						$_line-to-show: 2;
						margin-top:-0.5em;
						overflow:hidden;
						width: calc(100% - 45px);

				    	.milestone-progress{
							display: flex;
							display:-webkit-flex;
							padding: 3px 0;
							@include multiline-ellipsis($_line-height , 2);
							line-height: initial !important;
							word-break:break-word;
							text-align:left;
							font-size:$_font-size;
							margin-right: 10px;
				    	}
				    	.project-card-tag-row{
				    		margin-top:-1px;
				    	}
				    	.addTags{
				    		display: inline-block;
						    width: 55px;
						    text-align: center;
						    font-size: 0.78em;
						    padding: 0px 1px;
						    text-overflow: ellipsis;
						    white-space:nowrap;
						    overflow: hidden;
						    border:1px solid;
				    	}
				    }
			  	}

			    .project-data-column-0{
				    	position: relative;
				    	.unread-message{
				    		i{
					   	 		position: absolute;
					   	 		right:0;
					   	 		top:16;
					   	 	}
				    	}
			    		.watch-project{
					   	 	position: relative;
	              float:right;
	              height: initial;
	              line-height: initial;
	              line-height: normal;
	              padding:6px;
	              margin: -0.5em 0 0 0 ;
					 	 }
			    }
			    .project-data-column-2{
			    	height:$projectCardRow2;
			    }
			}
		}
		.box-header{
			display: inline-block;
			@include labelSize();
		}
		.project-list-right-pan{
			padding: 0  ;
		}

	}


}

.dashboard-currentUser{
	&.header-member-list{
		right: 80px !important;
		cursor: initial !important;
		.member-icon{
			cursor: initial !important;
			&:hover{
				background-color:#8EB2D6 !important;
				color: white !important;

			}
		}
	}
}




.projectlist-brick-div{

	.pjt-list-tab-pane{
		display:block !important;
		width: 90vw;
		margin-left: auto;
		margin-right: auto;
		position: relative;

		@media #{$phone}{
			padding-left: 10px;
    		padding-right: 10px;
		}

		.project-list-filter{
			padding-right: 10px;
			margin-top:-5px;
			// display: block;
			float: right;

			@media #{$phone}{
				padding:0 10px;
				float: none;
				display: block;
			}
		}
	}
}
@media #{$phone}{

//   .myDashbaord{
// 	  width:100vw;
// 		.addTags{
// 		    width: 9em !important;
// 		}
// 	  padding :0;
// 	  margin: 0;
// 	  .nav-tabs{
// 			width:100%;
// 			li{
// 				width:33.333%;
// 				a{
// 					color:$viewTitleTextColor;
// 					padding: 6px 0 !important;
//     				text-align: center !important;
//   				    margin-top: 5px;
//   				    width: 100%;
// 				}
// 				&.active{
// 					a{
// 						background: $warm ;
// 						color:white;
// 						&:hover{
// 							background-color:$main-background-focus;
// 							color:white;
// 						}
// 					}
// 				}
// 			}
// 		}

// 		.tab-content{
// 			.pjt-list-tab-pane{
// 				display:none !important;
// 				&.active{
// 					display:block !important;
// 				}
// 			}
// 			.tab-pane {
// 	  		 display: none !important;
// 			}
// 			.active{
// 				display:block !important;
// 			}
// 		}
// 	}

	#project-list-box{
		.box-container {
			margin-left:0vw !important;
			.project-card{
				@include cardWidth();
			}
			.add-project-card{
				display: none;
				@include cardWidth();
				height:initial;
			}
		}

		#joined-project{
			margin-left:0vw !important;
		}

		.box-header{
			margin-left:2vw;
			line-height: 40px;
		}


	}

}

.sprintActiveTag{
	background-color: $mainColor;
	color: white;
	pointer-events: none;
	padding:5px;
	font-size: inherit;
}
