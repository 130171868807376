/* latestInfo scss */
.latest-info{
	$_mainText: $main-text;
	.sub-title{
		margin-top:0.5em;
		font-weight:bold;
		margin-bottom: 1em;
		font-size:1.2em;
	}
	.latestInfo-card{
		margin-bottom: 1em;
		padding: 0.5em 0.5em 0 0.5em;
		cursor:pointer;
		//border-top-right-radius:5px;
		//border-top-left-radius:5px;
		box-shadow: 0px 0px 1px lightslategray;
		@include smartBreakWord();
		@include todoCardShadow();
		color: $_mainText;
		.recent-file-card{
			//line-height: 1em;
			padding: 0.2em 0;
			@include smartBreakWord();
		}

		.recent-file-name{
			width:calc( 100% - 3em );
			display:inline-block;
			@include smartBreakWord();
		}
        .recentComment-text{
            $_lineHeight: 1.2em;
            $_lines: 10;
            @include multiline-ellipsis($_lineHeight, $_lines);
			color: $warm;
			.recentComment-comment-text {
				text-overflow: ellipsis;
				overflow:hidden;
				display: -webkit-box;
				-webkit-line-clamp: $_lines;
				-webkit-box-orient: vertical;
				line-height: 1.2em;
				$_lines: 10;
				max-height: 11.5em;
			}
        }

		@include hover-supported() {
			a{
				color:white;
			}
			.info-row{
				color: rgb(240,240,240);
				border-top: 1px solid white;
			}
		}

		&:active{

			a{
				color:white;
			}
		}

		.file-img-div{
			background:white;
			max-height:15em;
			overflow:hidden;
			.file-img{
				width:100%;
			}
		}
		a{
			@include smartBreakWord();
			font-size:0.9em;
			color: inherit;
			line-height: none;
			line-height: initial;
		}

		
		&.chat-left{
			
			.info-row{
				border-top:none !important;
			}
		}
	 

		.info-row{
			width:100%;
			text-align:right;
			@include tinyTextSize();

			color:lighten($_mainText, 10%);
			//border-top: 1px solid lighten($_mainText, 35%);

			.uploader{
				text-align:right;
				padding:0 6px;
			}
			.upload-time{
				text-align:left;
			}
		}

	}
}