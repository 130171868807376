#systemSettings-div{
	position:fixed;
	left:$systemSettingSidebarWidth;
	top: $navbarHeight;
	@include height-vh-patch("100vh - #{$navbarHeight}");
	width: calc(100vw - #{$systemSettingSidebarWidth});
	background: #181F2E;
	color:white;
}

.system-setting-title{
	height: $systemSettingTitleHeight;
	line-height: $systemSettingTitleHeight;
	padding-left:15px;
	border-bottom:1px solid white;
	@include menuDropdownSize();
	font-weight:800;
}
.system-setting-select{ 
	color:white;
	option{
		color: rgba(10,10,10,0.8);
	}
}

.system-setting-div{
	height : calc(100vh - #{$navbarHeight} - #{$systemSettingTitleHeight});
	padding-bottom:20em;
    overflow-y:auto;
    $barColor: gray;
    @include scrollTinyBar($barColor,$barSize);
	@include otisPlaceholderColor(whitesmoke); 
	.otis-input{
		color:inherit !important;
	}

	 

	.table-scrollable{
		@include scrollTinyBar(white,$barSize);
		$_pagePadding : 30px;
		width: calc(100vw - #{$systemSettingSidebarWidth} - #{$_pagePadding} );
		overflow-x:auto;
	}

	.setting-info-card{
		background:rgb(95,100,110);
		border-radius:4px;
	 
		padding:20px;
		color:white;
	}

	.system-search {
		border-color: #fff;
		color: white;
	}

	.system-setting-search{
		padding: 3px 6px;
		width: auto;
	} 
 
    .btn{
  		@include mainTextSize();
    }
    .title-row{
    	margin-bottom: 10px;
    }

	.modal-footer{
	  	padding: 6px !important;
	}

    // @media #{$tablet}{
    // 	width: 90vw;
	//     position: absolute;
	//     margin: auto;
	//     top: 2em;
	//     margin-left: 3vw;

    // }

   .otis-input{
	   	&.filter-icon{

			&:after{
			    right:6px !important;
			    top: 3px !important;
				// color: gray;
			}
		}
		
   }

   .org-card{
		$_cardColor : #404A5F;
		border-radius:4px;
		border:1px solid whitesmoke;
		background: $_cardColor;
		
		padding :15px;
		margin:10px;
		width:20em;
		&:hover{
			background:ligthen( $_cardColor, 10%);
		}
	  	&:active, active{
			background:darken( $_cardColor, 5%);
		}
   }
 
   

	#addExistingAccountToOrgModal{
	   tbody{
		   max-height:340px;
		   display:block;
		   overflow:auto;
	   }
	   tr{
		   width:100%;
		   display:table;
		   table-layout:fixed;
	   }
	   td{
		   overflow-x:hidden;
	   }
   }
   #deleteUserModal{
   		.participate-project-div, .my-project-div{
   			margin-left: 1.5em;
   		}

   		.project-list{
   			display: inline-block;
   			width: 18em;
   		}
   }

	#listAccountModal{
		.modal-header{
			h3{
				display:inline-block !important;
			}
			.otis-input{
				margin-right:15px;
			}
		}


	}

    #zoneProjectsModal{
    	.zone-project-list-name{
    		padding: 10px 0px;
	    }
	    .zone-member-list{
	    	display:inline-flex;
	    	flex-wrap:wrap;
	    }
	   .modal-footer{
	   		padding:0px !important;
	   }

    }

    .result-container{
    	width:100%;
    	margin:auto;
    	.zone-user-list{
    		width: 10em;
   			display: inline-block;
    	}
    	.table-div{
    		max-height:calc(100vh - 250px);
    		@media #{$desktop-md-above}{
    			max-height:60vh !important;
    		}
    		overflow:auto;
    		&.table-div-sm{
    			max-height: 42vh !important;
    		}
    	}
    	.memberSelectionItem{
    		.show-hide-icon{
    			visibility:hidden;
    		}
    		&.selected {
    			.show-hide-icon{
					visibility : visible;
	    		}
	    	}
    	}

    }
	.ng-table th.sortable .sort-indicator{
		&:before{
			border-color: white transparent;
		}
		&:after{
			border-color: white transparent;
		}
	} 
	.ng-table-pager{
		background:$systemSettingSidebarBkColor;
		display: flex;
		justify-content: space-between;
		flex-direction: row-reverse;
		.ng-table-pagination{
			.page-item:first-child{
				.page-link{
					 
					&:before{
						font-family: 'icomoon' !important;
						content: "\e905";
						background: $systemSettingSidebarBkColor;
						position: absolute;
						left: 10px;
					}
				}
			}
			.page-item:last-child{
				.page-link{
					 
					&:after{
						font-family: 'icomoon' !important;
						content: "\e906";
						background: $systemSettingSidebarBkColor;
						position: absolute;
						right: 10px;
					}
				}
			}
		}
	}
	.ng-table-counts{
		button{
			background:transparent;
			color:white;
			border:none;
		}
	}
    table{
    	// color:darken($main-text, 30%);
    	// margin-bottom: 15px;
		margin-bottom:0px;
		color:white;
		thead{
			th{
				// background-color: rgba(255,255,255,0.7) !important;
				background-color:$systemSettingSidebarBkColor; 
				text-transform:capitalize;
				&:first-letter{
					text-transform:uppercase;
				}
			}
		}
		tr{
			td{
				vertical-align:middle;
				.btn-link{
					@include mainTextSize();
					padding:0.1em;
					color:white;
					&:hover{
						color:rbga(255,255,255,0.2)
					}
				}
			}
			&.even{
				// background-color:rgba(255,255,255,1);
				background-color:transparent;
			}
			&.odd{
				// background-color:rgba(255,255,255,0.8);
				background-color:transparent;
			}
			&:hover{
				background-color:rgba(255,255,255,0.2);
			}
		}
	}

    $_navTabsBackground: rgba(50,50,50,0.8);
    $_subNavTabsBackground: $warm;
    .nav{
		
    	$_color: #fff;
    	margin-bottom:0 !important;
    	&.nav-tabs{
    		// li{
    		// 	margin-right:10px;
    		// 	margin-left:10px;
    		// }
    		.active{
          		background: $_navTabsBackground;
    			border-top-right-radius:6px;
    			border-top-left-radius: 6px;
    		}
    		&.sub-nav{
    			li{
    				margin-left:0px !important;
    			}
    			.active{
    				background: $_subNavTabsBackground;
    			}
    		}
    		a{
    			color: $_color;
    			background: inherit;
				margin:0px !important;
				&:hover,&:active,&:focus{
					color: $_color;
					background: transparent;
				}
    		}
    		.dropdown-menu{
    			a{
    				color: $warm !important;
    				&:hover{
    					color: $_color !important;
    				}
    			}
    		}
    	}

    	.open{
    		a{
    			background:initial;
    			&:hover{
    				background:lighten($warm, 20%);
    			}
    		}
    	}

    }
    .control-group{
    	display:inline-block !important;
    	padding-left:0 ;
    }
    form{
    	margin:0 !important;
    }
	 

	 
	
	.pagination{ 
		margin:0 !important;  
		li{
			display: inline;
			&.disabled{
				a{
					color:#999;
					cursor:not-allowed;
					// background-color:#fff;
					// border-color:#ddd;
					background-color: transparent;
					border:none;
				}
			}
			a{
				position:relative;
				float:left;
				padding:6px 12px;
				margin-left:-1px;
				line-height:1.428571429;
				text-decoration:none;
				// background-color:#fff;
				// border:1px solid #ddd;
				background-color: transparent;
				border:none;
			}
			:first-child{
				a{
					margin-left:0;
					border-bottom-left-radius:4px;
					border-top-left-radius:4px;
				}
			}
			:last-child{
				a{
					border-top-right-radius:4px;
					border-bottom-right-radius:4px;
				}
			}
		} 

	}
 

    .sub-nav-div{
 		margin: auto;
 		padding:1em;
 		.title-div{
 			display:inline-flex;

 			width:100%;
 			.title-row{
	 			margin-bottom:0px;
 				display:inline-flex !important;
 				color:white;
	 			.title{
		 			@include h2TextSize();
		 			font-weight: bold;
				    margin-right: 0.5em;
				    margin-bottom: 1em;
				    width: auto;
		 		}
	 		}
	 		.controls{
	 			margin-left:0 !important;
	 		}
 		}

 		.btn-div{
 			text-align:right;
 			margin:0.2em;
 			.btn-add{
	 			border-radius:0px;
	 		}
 		}


		.box_header{
			color: #fff ;
			font-size:1em;
			margin-bottom: 10px;

		}
		.box_body{
			.function-btn{
				margin:15px 0;
				display:inline-block;
			}
		}
		#data_box, .data_box {
			padding-right:15px;
			padding-left:15px;
			// background: $_subNavTabsBackground;
			// border:1px solid lightgray;
			border-top:none;

			.box_header_label{
				display:inline-block;
			}
			.box_header_functions{
					// display:inline-block;
					// float:right;
					// padding-left:15px;
					// padding-right: 1px;

					input{
						font-size:1.2em;
						border-radius:0px;
						padding: 6px 12px;
					}
					.btn{
						font-size:1.2em;
						border-radius:0px;
						padding: 6px 12px;
					}
					&.btn{
						font-size:1.2em;
						border-radius:0px;
						padding:6px 12px !important;
					}
					&:after{
						clear:both;
					}
				}
			.box_body{
				border-radius:2px;
				color: #fff;
			}


		}


		

		.mainContent{
			text-align: center;
			height: auto;
			background-color: inherit;
			vertical-align: middle;
		}
	}
}

.p1_std{
	height: 100%;
	width: 100%;
	.mainContent {
   		width:100%

 	}
}

.system-setting-tab{
  margin-left:10px;
  margin-right:10px;
}
.system-setting-dropdown-menu-list{
  padding: 3px 10px;
}
 
.system-setting-modal{
	.scroll-tiny-bar{
		@include scrollTinyBar(rgb(100,100,100),$barSize);
	}
	table{
    	// color:darken($main-text, 30%);
    	// margin-bottom: 15px;
		margin-bottom:0px;
		
		thead{
			th{
				// background-color: rgba(255,255,255,0.7) !important;
				background-color:$systemSettingSidebarBkColor; 
				text-transform:capitalize;
				color:white;
				&:first-letter{
					text-transform:uppercase;
				}
			}
		}
		tr{
			td{
				vertical-align:middle;
				.btn-link{
					@include mainTextSize();
					padding:0.1em; 
					&:hover{
						color:rbga(10,10,10,0.2)
					}
				}
			}
			&.even{
				// background-color:rgba(255,255,255,1);
				background-color:transparent;
			}
			&.odd{
				// background-color:rgba(255,255,255,0.8);
				background-color:transparent;
			}
			&:hover{
				background-color:rgba(255,255,255,0.2);
			}
		}
	}
	.ng-table th.sortable .sort-indicator{
		&:before{
			border-color: white transparent;
		}
		&:after{
			border-color: white transparent;
		}
	} 
	.ng-table-pager{
		color:white;
		background:$systemSettingSidebarBkColor;
		display: flex;
		justify-content: space-between;
		flex-direction: row-reverse;
		.ng-table-pagination{
			.page-item:first-child{
				.page-link{
					 
					&:before{
						font-family: 'icomoon' !important;
						content: "\e905";
						background: $systemSettingSidebarBkColor;
						position: absolute;
						left: 10px;
					}
				}
			}
			.page-item:last-child{
				.page-link{
					 
					&:after{
						font-family: 'icomoon' !important;
						content: "\e906";
						background: $systemSettingSidebarBkColor;
						position: absolute;
						right: 10px;
					}
				}
			}
		}
	}
	.ng-table-counts{
		button{
			background:transparent;
			color:white;
			border:none;
		}
	}
	.pagination{ 
		margin:0 !important;  
		li{
			display: inline;
			&.disabled{
				a{
					color:#999;
					cursor:not-allowed;
					// background-color:#fff;
					// border-color:#ddd;
					background-color: transparent;
					border:none;
				}
			}
			a{
				position:relative;
				float:left;
				padding:6px 12px;
				margin-left:-1px;
				line-height:1.428571429;
				text-decoration:none;
				// background-color:#fff;
				// border:1px solid #ddd;
				background-color: transparent;
				border:none;
			}
			:first-child{
				a{
					margin-left:0;
					border-bottom-left-radius:4px;
					border-top-left-radius:4px;
				}
			}
			:last-child{
				a{
					border-top-right-radius:4px;
					border-bottom-right-radius:4px;
				}
			}
		} 

	}

	.dropdown-header{
		$_color : $systemSettingSidebarBkColor;
		background: lighten($_color, 20%);
		color:white !important;
	}
	.dropdown-hover{
		$_color : $systemSettingSidebarBkColor;
		&:hover{
			color:white !important;
			background:$_color !important; 
			a{
				color:white !important;
			}
		}
	}

	input[type=checkbox], input[type=radio] {
		$_color : $systemSettingSidebarBkColor;
		width: 1.2em;
		height: 1.2em;
		margin-top: 0;
		&:checked:after { 
			background-color: $_color;
		}
		
	}

}

.system-report{
	$_colorLightBlue : #DCE9F5;
	$_colorLightOrange : #FDF3E9;
	$_colorLightPurple : #E7E1FB ;
	$_colorLightGreen : #E7FAEA;
	$-colorLightGray: #EFEFEF; 

	$_colorBlue : #33A2EB;
	$_colorOrange : #FF9343;
	$_colorPurple : #4300DB ;
	$_colorGreen : #00DBA7;
	$-colorGray: #70767A; 

 
	.report-card{
		position:relative;
		display: flex;
		flex-direction: column; 
		justify-content: space-between;
		height: 17rem;
		width: 17rem;
		border: 1px solid whitesmoke;
		border-radius: 4px;
		&.report-card-rect{
			flex-grow : 1;
			flex-direction:row;
			width:auto;
			height: 10rem;
			flex-wrap:wrap;
			justify-content:space-around;
		}
		&.report-card-sm{
			flex-grow : 1;
			flex-direction:row;
			height:7rem;
			width:auto;
			flex-wrap:wrap;
			justify-content:space-around;
		}
		.z-index-10{
			z-index:10;
		}
		.background-image{
			position:absolute;
			right:0;
			bottom:0;
		}
	
		&.bk-lightBlue{
			background: $_colorLightBlue;
		};
		&.bk-lightOrange{
			background: $_colorLightOrange;
		}
		&.bk-lightPurple{
			background: $_colorLightPurple;
		}
		&.bk-lightGreen{
			background: $_colorLightGreen;
		}
		&.bk-lightGray{
			background: $_colorLightGray;
		}
		.color-blue{
			color:$_colorBlue;
		}
		.color-green{
			color:$_colorGreen;
		}
		.color-orange{
			color:$_colorOrange;
		}
		.color-purple{
			color:$_colorPurple;
		}
		.color-gray{
			color:$_colorGray;
		}
	 
	}
}

.bk-table-paginator{
	background: #31415f;
}

