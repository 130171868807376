/* media view scss */
pop-video{
	display:block;
	video{
		height:inherit;
	}
}

pop-file-detail-info{
	pop-video{
		video{
			max-height:20em;
			text-align:left;
			max-width:100%;
		}
	}
}

pop-attachment-list{
	pop-video{
		video{
			max-height:100%;
			text-align:left;
			max-width:100%;
		}
	}
}

pop-project-item-comments{
	pop-video{
		 video{
			max-height:20em;
			text-align:left;
			max-width:100%;
		 }
	}
	youtube-video{
		div{
			text-align:center;
			iframe{
				height:50vh;
				width:80%;
			}
		}

	}
	external-link-video{
		div{
			text-align:center;
			iframe{
				height:50vh;
				width:80%;
			}
		}

	}
}

pop-todo-video-render{
	iframe{
		width:100% !important;
		border-bottom: 1px solid lightgray;
		border-top: 1px solid lightgray;
	}
}


pop-todo-item{
	pop-video{
		width:100%;
	    text-align:center;
	}
	youtube-video{
		div{
			text-align:center;
			iframe{
				width:100%;
			}
		}

	}
	external-link-video{
		div{
			text-align:center;
			iframe{
				width:100%;
			}
		}

	}
}

@media #{$phone}{
	pop-project-item-comments{
		pop-video{
			//height:80vh;
		}
		youtube-video{
			div{
				text-align:center;
				iframe{
					//height:80vh;
					width:100%;
				}
			}

		}
	}


}

