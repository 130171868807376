/* todo item */
.todoDetailAbsolute{
	position: fixed; // for ie z-index issue
	top: 0;
	left: 0;
	z-index: 305 ; 
	width:100vw;
	@include height-vh-patch("100vh");
	// @media #{$phone}{
	// 	@include height-vh-patch("100vh - #{$sidebarIconHeight}");
	// }
	 
}

.pop-todo-card-add{
	@include mainTextSize();
}

.todo-status-dropdown{
	&.dropdown-menu{
		left:-10px !important;
		li{
			cursor:pointer;
			a{
				padding: 3px 10px !important;
			}
		}
	}
}

.dispatch{
	padding: 6px;
    margin: 10px 20px;
    border-radius: 8px;
    background: cornsilk;
    border: 1px solid lightgray;
	box-shadow: 0px 5px 5px -5px #333; 
	@media #{$phone}{
		margin: 6px;
	}
	.dispatch-destination{
		padding-left:15px;
		margin-top:6px;
	}
}

.todoItem{
	transition: max-height 0.5s, opacity 0.5s cubic-bezier(0.255, 0.900, 0.395, 0.975);
	opacity:1;
	//max-height: 100vh;
	position: relative;

  .date-picker {
    border: none;
    font-size: inherit !important;
    font-weight: 200;
    background: transparent;
	$_elementWidth: 7.2em;
	@include  retinaElementWidth($_elementWidth)
  }

	&.dd3-content{
		// cursor:move;
		background:white;
		border-radius:2px;
		text-decoration: none;
		transition: all 0.3s ease-out;
		@include todoCardShadow();
	}
	.due-status-tag{
		$_padding: 15px;
		$_margin:10px;
	    margin-top: -$_margin;
	    padding: 3px $_padding;
	    margin-bottom: 6px;
	    border-top-left-radius: 2px;
			border-top-right-radius: 2px;
			
		&.--no-dueDate {
			display: none;
		}
	}
	.todo-cover-image{
		max-height: 50vh;
		display:block;
		overflow:hidden;
		// comment out [Benx 2018-10-22 18:14:45]
		// box-shadow: 0px 1px lightgray, 0px -1px lightgray; // change from $dropdownColor to lightgray 20180208 by smith sun 
		margin-top: 5px;
		margin-bottom: 5px;
		&:empty{//*this element must be empty(no element, text, space)
			display: none;
		}
	}

	.title-info-div{
		display :inline-flex;
		align-items:center;
		flex-wrap:wrap;
		text-align: left;
	}

	&.finish{
		$_mainColor:  lighten($main-text,20%);
		$_mainBackgroundColor:  rgba(220,220,220,1);
		background: $_mainBackgroundColor;
		color: $_mainColor  !important;
		.todoText{
			color: inherit !important;
			text-decoration:line-through;
			font-style:italic;
		}

		.subtodo-assignee, .subtodo-date {
			color: inherit;
			opacity: 0.5;
		}	

		.priority-start{
			color: lighten($_mainColor, 40%)  !important;
		}
		.overdue{
			background:gray;
		}
		a{
			color: inherit !important;
		}
		.title-info-tags{
			color:inherit !important;
		}

		.title-info{
			.no-real-effort{
				color:inherit;
				margin-right:6px;
			}
			.doneNrealEffort-info{
				color:inherit !important;
			}
			.tody,.inThreeDays,.created-by-div,.created-by-text{
				color:inherit !important;
			}
		}
		input.date-picker{
			$_color: whitesmoke;
			color:inherit;


				&::-webkit-input-placeholder { /* Chrome/Opera/Safari */
				color: $_color !important;
				}
				&::-moz-placeholder { /* Firefox 19+ */
				color: $_color  !important;
				}
				&:-ms-input-placeholder { /* IE 10+ */
				color: $_color  !important;
				}
				&:-moz-placeholder { /* Firefox 18- */
				color: $_color  !important;
				}

		}


		.text-primary{
			color: inherit !important;
		}

		.btn{
			color: inherit !important;
		}
		img{
			opacity:0.25;
		}

	}

	&.overdue{
		color:$overdueColor;

		a.todoText,
		.title-info input.todo-dueDate,
		.title-info .icon-days {
			color:$overdueColor !important;
		}

		.todoItem.subTodo.finish {
			input.todo-dueDate,
			input.subTodo-dueDate,
			.todo-date .icon-days,
			.subtodo-date .icon-days {
				color:  lighten($main-text,10%)  !important;
			}
			&:hover{
				color: $warm;
			}

		}

	}
	.subTodos{
		width: calc(100%);
		ol{
			list-style-type: decimal;
      padding: 6px 30px;
			width:calc(100%);
	   		//@include swimLanWidth();
			@include smallTextSize();
	   		background: rgba(50,50,50, 0.08);
			li{
				background:transparent !important;

				&.subTodo{
						margin:0px 0;
						padding:0px 0;
						color: $main-text;
						@include smartBreakWord();
						&.overdue{
							.todoText{
								color:$overdueColor;
							}
							input.date-picker{
								color:$overdueColor;
							}
						}
						.subtodo-detail{
							vertical-align: top;
								.title-info-tags{
									@include smallTextSize();
								}
						}

					}
			}
		}

	}

	.subTodo.overdue .subtodo-detail {
		input.subTodo-dueDate,
		.icon-days {
			color:$overdueColor !important;
		}
	}
	&.due-today{
		color:$dueTodayColor;
		border:1px solid lighten($dueTodayColor,30%);
		a{
			&.todoText{
				color:$dueTodayColor !important;
			}
		}
	}
	&.due-inOneWeek{
		color:$dueTodayColor;
		border:1px solid lighten($dueTodayColor,30%);
		a{
			&.todoText{
				color:$dueTodayColor !important;
			}
		}
	}
	&.overdue-some{
		.icon-subtodo{
			color:lighten($overdueColor,15%) !important;
		}
	}


	&.finish.subTodo {
		background:  inherit  !important;
	}
	a.title.todoText {
		white-space: pre-wrap;
		word-wrap: break-word;
	}

	.coverImg {
		// width: 100%;
		// changed [Benx 2018-10-22 18:15:37]
		max-width: 92%;
		display: block;
		max-height: 250px;
		margin-left: auto;
		margin-right: auto;
		border: 1px solid #e8e8e882;
	}
	&.ng-leave-active{
		max-height: 0vh;
		opacity: 0;
	}
}

.todo-info{
	margin-right:6px;
	.priority-start{
		margin-left: 0.2em;
	}
	.watch{
		a{
			&:hover{
				text-decoration: none !important;
			}
		}
	}

	.mySprintName {
		width: 150px; 
		color: #b7b2b2;
		font-size: 12px;
		padding: 0px 15px 3px 15px;
		position: relative;
		top: -2px;
		display: none;
	}

}
.todo-title{
	padding: 0px 0px 0px 15px ;
	margin:0 !important;
	white-space: normal;
	word-break: break-word;
	$_lineHeight:1.4em;
	$_lines:15;
	@include multiline-ellipsis($_lineHeight, $_lines);
	overflow:hidden;

	.todoText{
		white-space: pre-wrap;
		word-break: break-word;
   		overflow-x: hidden;
	}
	.todoCardIsLock{
		position: absolute;
		left: 0.5px;
		@include mainTextSize();
	}
}
.title-info{
	width: 100% !important;
    padding: 0px 15px 3px 15px;
    margin: 0px;
  	@include smallTextSize();
    //text-align: right;
    //min-height: 12px;
    .title-info-tags{
		color:initial;
		text-indent:0px;
		padding-right:0.5em;
		line-height: initial;
		line-height: normal;
		@include smallTextSize();

	}
    .no-real-effort{
    	color: orangered;
    }
    .created-by-div{
    	$_todoCardRightPadding : 15px;
     	width:calc(100% + #{$_todoCardRightPadding});
     	text-align: right;
		color:darken(gray, 10%);
		margin:5px 0 -5px 0;

	    .created-by-text{
		    opacity: 0.8;
		    font-weight: 200;
		    transform: scale(0.86);
		    transform-origin: right;
			margin-left:-14%;
		    .creater-name{
		    	padding:0 3px;
		    }

	    }
	 }

	.doneNrealEffort-info{
		transform: scale(0.86);
		transform-origin: right;
		display:block;
		margin-top:5px;
	}

	.todo-date {
		padding: 0px 3px;
	}
}

.sprint-report-btn{
	@include menuDropdownSize();
}

.sprint-view{
	$_mainColor: $warm;
	$_secondColor: $main-text;

	.sprint-list{
		cursor:pointer;
	}


	.dd-list-bottom{
	    &.dd-list-bottom-absolute {
	  	    position:absolute;
	  	    bottom:0px;
	    }
	}

	.dd-list-done-bottom{
		height: $ddListBottomHeight;
		display:block;
		background:$haveDoneListBkColor !important;;
		border-bottom-left-radius: 8px;
   	    border-bottom-right-radius: 8px;

	}

  	.sprint-doing-div{
  		overflow-x:auto;
  		@media #{$phone}{
			width: 92vw;
		}
  	}

	.sprint-view-workingZone{
		height: inherit ;
		//overflow: hidden;
		//background: $sprintViewBackground;
		@media #{$phone}{
			width:100vw !important;
		}

		.view-page-header{
			@include menuDropdownSize();
			margin-right:1em;
		}

		.sprint-working-div{
			// Todo: 依執行狀態顯示高度有問題 [Jane 2018-08-31 12:17:36]
			padding:1px; // 微調[Jane 2018-08-31 12:16:20]
			//padding-right:10vw;
			@include height-vh-patch("100vh - #{$navbarHeight} - #{$viewTitleHeight} - 15px ");
			@media #{$phone}{
				@include height-vh-patch("100vh - #{$navbarHeight} - #{$viewTitleHeight} - 5px ");
			}
		}
	}

	.todo-view-panel-title{
		height: $viewTitleHeight;
		line-height: $viewTitleHeight;
		.animated{
			-webkit-animation-duration: 2.5s;
			animation-duration: 2.5s;
			-webkit-animation-iteration-count: 1;
			animation-iteration-count: 1;
		}


		.sprint-view-title{

			.selSprint-title{
			    max-width: 16em;
			    width:auto;

			    display:inline-block;
			    vertical-align: bottom;
			    @media #{$phone}{
			    	max-width:8em;
			    }
			}
			@media #{$phone}{
				.dropdown-menu{
					min-width: 30em !important;
				}
			}

		}

		.sprint-dates input {
			font-size: 75%;
		}
		//.dropdown{
				//position: relative;
			    //padding-right: 0.5em;
		//}
		button{
			@include menuDropdownSize();
		}
	   

	}





	.title {
		@include mainTextSize();
		line-height: $h4 * 1.5;
		word-break:break-word;
	}



	.sprint-view-dropdown-icon{
	    height: $viewTitleHeight;
	    line-height: $viewTitleHeight;

	}
	.dd-swimlane{


		&.sprint-view-backlog{
			$_mainColor:$activeSprintColor;
			$_mainText: $warm;
			.list-header{
				$_mainColor: darken($activeSprintColor,50%);
				background: $waitTodoListBkColorHeader;
				 
				color: $inverseViewTitleTextColor;
		    }
		    .one-line-header{
				margin-right: -1em;
		    }
		    .list-item{
		    	color: $_mainText;
		    }
			.dd-list-header{

				.sprint-list{
					border-bottom:1px solid white;
					color:lighten($_mainColor,60%);
					cursor:pointer;
     				&:hover{
						color:white;
						font-weight:bold;
					}
				}
			}
			.dd-list{ 
				$_background:  $waitTodoListBkColor;
				background: $_background;
				margin: -1px 0;
				&.scroll-tiny-bar{
					@include scrollTinyBar($_background,$barSize);
				}

			}
			.dd-list-bottom{
				background: $waitTodoListBkColorBottom;
				//border-radius: 0 0 2px 2px;
				color:$inverseViewTitleTextColor;;
			}
			
			.dd3-content {
				background: $waitTodoListBkColorContent;
				border: $todoListBorderStyle;
				&:hover{
					background: lighten($activeSprintColor,20%) !important;
				}

				a{
					color:lighten($_mainText, 10%);
				}
			}

		}
	
		.dd-list-bottom{
			background: lighten($generalSprintColor, 40%);
		}
	}

	.dd-swimlane{

		&.sprint-view-doing{
			$_mainColor: $doingTodoListBkColor;
			.list-header{
		    	background: $doingTodoListBkColorHeader;
		    	color:$doingTodoListTextColor;
		    	font-weight:600;
		    }

			.scroll-tiny-bar{
					@include scrollTinyBar($_mainColor,$barSize);
			}
			.dd-list{
				background:$doingTodoListBkColor !important;
				margin: -1px 0;
			}
			.dd-list-bottom{
				background: $doingTodoListBkColorBottom !important;
			}
			.dd3-content {
				background: $doingTodoListBkColorContent;
				border: $todoListBorderStyle;
				&:hover{
					background:lighten($_mainColor,25%) !important;
				}
			}
		}

		&.sprint-view-done{
			$_mainColor: $haveDoneListBkColor;


			.list-header{
		    	background: $haveDoneListBkColorHeader;
		    	color: $haveDoneListTextColor;
		    	font-weight:600;
		    }


			.scroll-tiny-bar{
					@include scrollTinyBar($_mainColor,$barSize);
			}
			.dd-list{
				background:$haveDoneListBkColor !important;
				margin: -1px 0;
				li{
					background: transparent !important;
				}
			}
			.dd-list-bottom{
				background:$haveDoneListBkColorBottom !important;
			}
			.dd3-content {
				background: $haveDoneListBkColorContent !important;
				color: $haveDoneListTextColor !important;
				border: $todoListBorderStyle;

				&:hover{
					background: darken($haveDoneListBkColorContent, 20%) !important;
					color: lighten($haveDoneListTextColor,100%) !important;
				}

				.todoText{
					text-decoration: none !important;
					font-style: initial !important;
				}
				
				pop-link-preview {
					color: dimgray;
    				opacity: 0.5;
				}

			}
		}
	}


	.scroll-tiny-bar{
		$_mainColor: lighten($_secondColor, 30%);
		@include scrollTinyBar($_mainColor,$barSize);
	}

	.todo-add-btn, .subtodo-add-btn{
		margin-right:10px;
		height:20px;
	}


	.todo-delete-icon{
		margin-top:8px;
		margin-left:10px;
		@include mainIconSize();

	}

	.subtodo-cancel-icon{
		display:none;
		margin-top:18px;
		z-index:100;
		margin-right:-2px;
		background:white;
	}

	.subtodo-add-icon{
		margin-top:18px;
		position:absolute;
		right: -0.6em;
	}

	.todo-cancel-btn, .subtodo-cancel-btn{

    height:20px;
		@include tinyTextSize();
		padding:2px;
	}

	.getassignee-div{
		margin-top:-5px
	}

	.user-filter-icon{
		top:2px;
		position:relative;
		margin-left:10px;
		display:inline-block;
	}

	.sprint-filter-icon{
		top:2px;
		position:relative;
		margin-left:10px;
		display:inline-block;
	}

	.todo-add-icon{
		top:2px;
		position:relative;
		z-index:3;
		margin-left:10px;
		display:inline-block;
	}

	.todo-cancel-icon{
	 	display:none;
		top:2px;
		position:relative;
		margin-left:10px;
	}

	.todo-collaps-icon{
		top:2px;
		position:relative;
		margin-left:10px;
		display:inline-block;
	}


	.todo-search{
		display:inline-block;
		background: transparent !important;
		margin-top:6px;
		border:2px  solid #b1b1b1;
		border-right:0px;
		border-top-left-radius:2px !important;
		border-bottom-left-radius:2px !important;
		z-index:3;
		height:28px;

		&:focus{
			border:2px  solid #b1b1b1;
			border-right:0px;
		}
	}

	.todo-search-box{
		display:none;
		z-index:6;
	}

	.sprint-todo-header .todo-search{
		margin-top: -2px;
	}


	.btn-todo-search{
		background: transparent !important;
	 	border:0px;
	 	z-index:6;
		position:relative;
		margin-left:10px;
		display:inline-block;
		position:relative;

	}

	.btn-todo-search-close{
		background: transparent !important;
		border-top:2px solid #b1b1b1;;
		border-bottom:2px solid #b1b1b1;
		border-top-right-radius:2px;
		border-bottom-right-radius:2px;
	 	height:28px !important;;
	 	margin-top:-2px;
	 	margin-left:-2px;
	 	margin-right:0px;
		z-index:6;
		position:relative;
	}
	.btn-todo-search-confirm{
		background: transparent !important;

		border:2px solid #b1b1b1;;
		border-top-right-radius:2px;
		border-bottom-right-radius:2px;
	 	height:28px !important;;
	 	margin-top:-2px;
	 	margin-left:-6px;
	 	margin-right:0px;
		z-index:6;
		position:relative;
	}



	.sprint-view-body{
	    @include OtisPagePadding();
    	overflow-y: hidden;
    	overflow-x: auto;
	}

}

.sprint-report-div{
	$_mainColor: $warm;
	$_secondColor: $main-text;
	max-height:90vh !important;
	height: auto !important;
	//padding-bottom:15px;
	.sprint-report-body{
		padding: 15px !important;
		@include labelSize();
	}

	.sprint-chart-div{
		//max-height:$_height - 10vh;
		.sprint-burn-chart{
			//height:  ($_height - 10vh ) * 0.5 ;
			@media #{$desktop-tablet-md} {
				 height:  300px;
			}
		}
		.sprint-bar-chart{
			//height:  ($_height - 10vh ) * 0.5 ;
			@media #{$desktop-tablet-md} {
				height: 300px;
			}
		}
	}

	.nav-tabs{
		margin-right:1em;
		li{
			a{
				@include hover-supported() {
					background-color: $tab-background-hover;
					color: $tab-active;
				}
				
				&:active{
					background-color: $tab-background-active;
					color: $tab-active;
				}
			}
			&.active{
				color:$tab-active;
				 a{
					background-color: $tab-background-active;
					color: $tab-active;
				 }
			}
		}
	}

}



.editable-wrap .editable-controls>input,
.editable-wrap .editable-controls>select,
.editable-wrap .editable-controls>textarea {
    margin-bottom: 0;
    margin-top: -10px;
}




.todo-detail-panel-background-image{
	display:block;
}

.sprint-detail-panel, .reviewlist-detail-panel {
	display:none;
}


.todo-detail-footer{
	background:#f2f2f2 !important;
	height:30px;
}

.todo-expand-all, .expand-all{
	@include bigIconSize();
	color:#585858;
	margin-left:10px;
	line-height:30px;
	height:30px;
	border:2px solid white;
	width:30px;
	display:none;
	margin-top:4px;
}

.todo-collapse-all, .collapse-all{
	@include bigIconSize();
	color:#585858;
	margin-left:10px;
	line-height:30px;
	height:30px;
	border:2px solid white;
	width:30px;
	margin-top:4px;
}

.member-select-dropdown {
	.dropdown-menu {
		li > a.opt.member-group {
			padding: 5px 20px;
		  }
	}
  
}

.member-assign-dropdown{
	.dropdown-menu{
		padding:0;
		.assign-dropdown-title{
			padding: 6px 10px;
			background: rgba(20,20,20,0.8);
			color:white;
		}
		.searchbox{
			border:none;
			input{
				font-size: 1em !important;
			}
		}
		.member-assign-list{
			overflow-y: auto;
		    max-height: 20vh; // fixed height to make auto dropup or dropdone active
        	//height:auto;
           padding:3px 0px;
           
        li > a.opt.member-group {
          display: block;
        }
		    .member-group{
		    	width: 100%;
		    	height: auto;
		    	padding: 2px 10px;
         		@include mainTextSize();

		    	span{
		    		display: inline-block;
		    		overflow: hidden;
		    	}
		    }
			.selected{
				color: $dropdown-item-selected-color !important;
			}
		}
		.member-assign-list-footer{
			border-top: 2px solid lightgray;
			padding: 6px;
		}
		
		.sep {
			margin: 4px 10px;
			border-top: 1px dashed gainsboro;
		}
	}
}

@media (max-width: 468px) {

   .todo-view-panel-scroll{

   }


	.todo-search-zone{
	 margin-bottom:-24px;
	}

    .btn-todo-search{
		position:absolute !important;
		top:-33px  !important;
		right:60px  !important;
	 }

	.todo-search{
		background:white !important;
		clear:both;
		position:absolute;
		margin-top:-34px;
		right:0px;
		margin-left:-0px;
		z-index:999;
		height:28px !important;
	}
	 .btn-todo-search-close{
		clear:both;
		position:absolute;
	 	margin-top:-42px;
	 	margin-right:50px;
	}
	.btn-todo-search-confirm{
		clear:both;
		position:absolute;
	 	margin-top:-42px;
	 	margin-right:50px;
	}

	.sprint-todo-header .btn-todo-search {
		position:absolute !important;
	 	top: 0px !important;
		right:0px  !important;
	}

	.sprint-todo-header .btn-todo-search-close {
		position:absolute !important;
 		margin-top:-2px  !important;
		right:-32px  !important;
	}

	.sprint-todo-header .btn-todo-search-confirm {
		position:absolute !important;
	 	margin-top:-2px !important;;
		right:-56px  !important;
	}

	.sprint-todo-header .todo-search{
		position:absolute !important;
		right:36px  !important;
	}
}

.sprint-recent-update{
	 position:absolute;
	 right:-100%;
     background:rgba(65,64,64,0.8);
	 $_padding:0 0.5em 0.5em 0.5em;

	 @include labelSize();

     line-height: initial;
	 @include latestInfo($_padding);
	 width: 23em;
	 min-width: 30vw;
	 z-index : 50;
	 @media #{$phone}{
	 	width:100vw;
	 	margin-top:0px
	 }

	 latest-comment-modal{
	 	width:100%;
	 	.sub-title{
	 		color: $main-text;
	 	}

	 }
	 latest-file-modal{
	 	width:100%;
	 	padding-bottom: 2.5em !important;
	 	.sub-title{
	 		color: $main-text;
	 	}

	 }

	 &.move-in{
	 	right: 0;
	 	transition: right 2s;
	 	transition-timing-function: ease-in-out;
	 }
	 &.move-out{
	 	right:-100%;
	 	transition: right 3s;
	 	transition-timing-function: ease-in-out;
	 }


	 $barColor: lightgray;
	 @include scrollTinyBar($barColor,$barSize);
}

.sprint-recentUpdate-icon{
	position: fixed;
	margin-top:0.2em;
    @media #{$phone}{
    	margin-top:1.5em;
    };
    right:0;
    z-index:100;
    &.icon-absolute-right{
		$_height: 2 * $fontUnit;
		$_color: $warm;
	    background: $_color;
	    color: white;
	    width: $_height * 1.25;
	    display: inline-block;
	    height: $_height;
	    border-radius: $_height 0 0 $_height;
	    line-height: $_height ;
	    padding-left: $_height * 0.25;
	    cursor: pointer;

	    &:hover{
	    	background:lighten($_color , 10%);
	    	border:1px solid white;
	    }
	    &.active{
	    	color:white;
	    	background:$_color;
	    }
	}
}

.sprint-editor-icon{
	position: fixed;
    right: 0;
    margin-top:0.2em;

    @media #{$phone}{
    	margin-top:2em;
    }
    &.icon-absolute-right{
		$_height: 2 * $fontUnit;
		$_color: $warm;
	    background: $_color;
	    color: white;
	    width: $_height * 1.25;
	    display: inline-block;
	    height: $_height;
	    border-radius: $_height 0 0 $_height;
	    line-height: $_height ;
	    padding-left: $_height * 0.25;
	    cursor: pointer;

	    &:hover{
	    	background:lighten($_color , 10%);
	    	border:1px solid white;
	    }
	    &.active{
	    	color:white;
	    	background:$_color;
	    }
	}
}





.newTodoCard{
  position: relative;
  user-select: none;
    textarea{
	    width: 100% !important;
	    height: 4em;
	    padding: 6px !important;
	    color: $main-text;
		line-height: 1.3em;
		border-radius: 2px;
    }
    .btn{
		@include smallTextSize();
    }
}


.addTodoBtn-Div{
    text-align: right;
	.btn {

    	@include smallTextSize();
	    padding: 0px 10px;
	    background: rgba(19, 85, 165, 0.6);

		&:hover{
			 background: darken($main-background-color, 10%);
		}

		&[disable]{
			color:darkgray;
			background:gray;
		}
	}
}


.sprint-header-switchView{
	text-align:right;
	@include menuDropdownSize();

	button{
		color:inherit !important;
		background:transparent !important;
		border:0 ;
	}
	.filterDrop{

		min-width:150px!important;
		max-width: 280px!important;
		.active{
			background-color: gold!important;
		}
	}
	.dropdown{
		.dropdown-menu{
			li{
				a{
					padding:3px 10px;
				}
			}
		}
	}
	@media #{$phone}{
		right: initial !important;
	}
}

@media #{$phone}{

	.sprint-view {
		.sprint-view-dropdown-icon{
   			 width:initial;
		}
		.sprint-header{
			width: initial !important;
			.selSprint-title{
				float:left !important;
			}
		}
	}

	.sprint-header-switchView{
		font-size:0.88em;
		ul{
			&:before{
				right:0 !important;
			}
		}
	}

	.sprint-detail-div{
		padding:0;
		width: 100vw;
		opacity:1 !important;
	}

	.sprint-comments-editor-section{
	 	//position: fixed !important;
	    width: 100% !important;
	    padding: 0px !important;
	    bottom: 0em !important;
	    z-index: 100 !important;
	 }
}

//todo.relation.add.directive
.related-todo-section{
	margin:10px 20px;
	//padding:10px !important;
	@media #{$phone}{
		margin: 6px;
	}
}

.todo-relation-main {
	text-align:right;
	position:absolute;
	right:5px;
	top:0;
	z-index:200;
	//height: 65px;
	//margin-top: 10px;
}

.related-todo-item{
	margin: 5px 0px;
	.todoText{
		a{
			@include smartBreakWord();
		}
	}
}


.todo-relation-label{
	font-weight: normal;
	//color: darken($main-text, 5%);
	width: 100%;
	margin:0;
	cursor: pointer;
	//font-weight:800;
}

.todo-relation-autocomplete {
	margin-bottom: 5px;
	.dropdown-menu{
		li{
			a{
				padding:3px !important;
			}
		}
	}

	.btn {
		border-color:#ccc !important; 
		@include mainTextSize();
	}
}

.todo-relation-input{
	input{
		width: 100%;
		border:1px solid lightgray !important;
		word-break:break-word;
		padding:6px 10px;
		//height: 30px;
		//line-height: 30px;
		vertical-align: middle;
		// position: relative;
	}
	position: relative;
	z-index: 2;
	display: inline-block;
	width: 100%;
}
.related-todos,.duplicate-todos{
	@include smallTextSize(); 
	//background:rgba(50,50,50,0.2);
	border:1px solid lightgray;
	background:whitesmoke;
	margin-bottom:6px;
	padding:6px ;
	border-radius:8px;
	box-shadow: 0 5px 5px -5px #333;  
}

.todo-relation-menu{
	display: block;
	word-break: break-all;
	max-height: 300px; //calc(100vh - 100px - 300px);
	width: 100%;
	&:empty{
			padding:0px;
			box-shadow: none;
			border-color: transparent;
		}

	.list-item {
		cursor: pointer;
	}
}

.todo-relation-list{
	padding: 5px 10px;
	.todo-relation-list-text{
		@include smartBreakWord();
		$_lineHeight:1.2em ;
		$_lines: 2;
		@include multiline-ellipsis($_lineHeight, $_lines);
	}

	&:hover{
		background-color: $odd-background-color;
	}
	&.active{
		background-color: $odd-background-color;
	}
}

.related-todo-item, .todo-relation-list-text {
	.sprint-name {
		color: lightslategray;
	}
}

.todo-relation-select{
	margin-right: -1px;
	.btn-default{
		margin:0 !important;
	}
	.bs-caret{
		position: relative;
		top: -5px;
		.caret{
			display:inline-block !important;
		}
	}
}


.img-title{
	&:hover{
		&::after{
			max-width:20em;
		}
	}
}