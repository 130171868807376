/* project Header scss */

.show-menu-div{
	$_mainColor: $dynamicBackground;
	$_fontColor: $viewTitleTextColor;
	position: fixed;
    top:  $navbarHeight +  ($viewTitleHeight * 0.1) ;
    right: 15px;
    height: $viewTitleHeight * 0.8;
    line-height: $viewTitleHeight * 0.8;
    padding: 0 10px;
    background: $_mainColor;

    border-radius: 2px;
    text-align: center;
    z-index:10;

    .share-tpl-text{
    	font-size: $fontUnit * 1;
    	font-weight: bold;
    }
    .tpl-shared-members{
    	input{
    		color: $main-text;
    		padding: 0px 6px;
    	}
    }


	&.tpl-menu{

		ul{
			list-style-type:none;
			padding: 0;
			li{

				cursor: pointer;
				text-align:left;
				padding: 6px 16px;
				border-top: 1px solid lightgray;
				.delete-template{
					margin-left:1em;
					.btn {
						width: 100% !important;
						margin-bottom: 6px;
					}
				}
				.tpl-label{
					@include mainTextSize();
				}
				a{
					text-align: left;
					&.delete-template-label{
						width:100%;
						display:inline-block;
					}


				}
				&:hover{
					background: lighten($main-background-hover, 20%);
					a{
						background: inherit;
						text-decoration:initial !important;
					}
				}
				&:focus{
					background-color:$main-background-focus;
				}
				label{
					color:$main-text;
				}

			}
		}
	}
	.show-menu-btn{
		background: transparent;
	    padding: 0;
	    margin: 0;
	    white-space: normal;
	    line-height: 1em;
	    color:white;
		font-size:0.88em;
		word-break:break-word;

	}
}

.side-menu-div{
		min-width:21em;
		width:20vw;
		background: lighten($cool, 60%);
		max-height:60vh;
		height:auto;
 	    box-shadow: 0px 2px 10px gray;

	    position: absolute;
	    right: 0;

		.side-menu-btn{
			text-align:left;
			padding:6px 12px;
			background: lighten($main-text, 50%);
			cursor:hand;
			cursor:pointer;
			color:black;
		}

		.member-list{
			padding: 10px;
			// display: inline-block !important;
		}
		.btn-mini{
			font-size:$h3;
			padding:0.5em 0;
	}

}

.show-member-div{
    $_background: $memberIconBkColor;
    position:relative;
    display:inline-block;
    vertical-align:top;
	//position: fixed;
    //top: ($navbarHeight - $generalMemberHeight) / 2;

    //right: 7.2em;
    z-index: 300;

    //width: $generalMemberHeight;
    //height: $generalMemberHeight;
    //line-height: $generalMemberHeight;
    //background: $_background;

    //border-radius: 2px;
    //color: white;
    //text-align: center;
    cursor:pointer;
	//margin-right:10px; remark by smith sun @20180208
	font-size:inherit !important;

	&:hover{
		//border: 1px solid white; remark by smith sun @20181015
	}
	&:active, &.active{
		//background: lighten( $_background, 10%);remark by smith sun @20181015
		//border: 1px solid white;;remark by smith sun @20181015
	}

	&.tpl-menu{

		ul{
			list-style-type:none;
			padding:1em;
			li{
				text-align:left;
				min-height:3em;
				display:inline-block;
				width:100%;

			}
		}
	}
	.show-member-btn{

	    padding: 0 !important;
	    //margin-top: -6px !important; // remark by smith @ 2018/10/12
	    //margin-left:-2px !important;
	    //margin-right:-1px !important;
		white-space: normal;
	    line-height: initial !important;
		color: white;
		//@include smallTextSize();
	    //color: $viewTitleTextColor; //both light and dark theme using dark bk on member icon

	   	word-break:break-word;

	}
	.icon-close {
		z-index: 110;
	}
}


.show-member-div-whenxs{

	position: absolute;

    right: 0;
    z-index: 100;


	&.tpl-menu{

		ul{
			list-style-type:none;
			padding:1em;
			li{
				text-align:left;
				min-height:3em;
				display:inline-block;
				width:100%;

			}
		}
	}

	.show-member-btn{
		position: absolute;
    	right: 0em;
    	font-size: 1em;
    	width:40px;
    	text-align: center;
    	color: $viewTitleTextColor;
    	height: $viewTitleHeight;
	}
}





.side-member-div{
		width:300px;
		margin-top: $navbarHeight;
		@media #{$phone}{
			width: 100vw !important;
			position:fixed;
		}
		&:focus{
			outline:initial !important;
		}

		background: lighten($cool, 60%);
		height:auto;
 	    box-shadow: 0px 2px 10px lightgray;
		z-index:100;
	    position: absolute;
	    right: 0;
	    cursor: default;

		.side-member-title{
			display: flex;
			width: inherit;
			min-width: inherit;
			text-align:left;
			padding: 8px 10px;
			background: $projectSetting-menu-header-bg;
			z-index: 100;
			color: $projectSetting-menu-header-color;
			.icon-add {
				margin-left: 10px;
			}
		}

		.member-list{
			max-height: 420px;
			overflow-y: auto;
			padding: 10px;
			width:100%;
			display: inline-block !important;
			&:focus{
				outline:initial !important;
			}
			.float_member_function{ 
				//position:relative;
				.member-icon{
					float:left;
				}
			}
		}
		.btn-mini{
			font-size:$h4;
	}
}

.header-member-list{
	//position: fixed;
    //top: ($navbarHeight - $generalMemberHeight) / 2;
    //right:140px;
    //z-index: 300;
    //max-width: ($generalMemberHeight + 10px ) * 5;
    //height: $generalMemberHeight;
    //line-height:0;
}
