/* panel */

.panel {
	border:0;
	background:transparent ;
	margin-bottom:0;

	.panel-body{
		padding:0;
		
		& > .dd-container {
			width: 100%;
			height: 100%;
		}
	}

	.panel-heading {
	    position: relative;
	    display: inline-block;
	    width:100%;
	    padding: 0px;
	    border-radius: 0px;
	    border-bottom: none;
	    @include h2TextSize();
	    height:45px;
	    line-height:45px;

	    background: transparent;
	    margin-top: 1vh;

		i {
		  width: auto;
		  display: inline-block;
		  vertical-align:middle;
		}
	}

	.panel-footer {
		background: $body-background;
		height:initial;
		padding: 3px;
		@include mainTextSize();
	}
}


.creatByWho{
	padding:0px !important;
}
.panel-icons{
	display:inline-block;

}
