/* member scss */

#member-scroll-container{
	@include pageScrollArrow();
}

.clearfix{
	clear:both;
}

pop-project-member-list{
	.float_member_function{
		display:flex;
		flex-wrap:wrap;
		width: 100%;
		justify-content: left; 
		//position:relative;
	}
}


.project-member-display{

	padding-top:0px;
	margin-bottom:10px;
	margin-top: $navbarMemberMarginTop;
	position:relative;
	margin-left:6px;
	right:0px;
}



.memberItems{
	 
	&.dropdown {
		position: inherit;
	}
	.dropdown-menu{
    	min-width: 200px;
		width: inherit !important;
		margin-top: 3px;
		border: 0px;
		top:inherit;
		li{
			.selected-role{
				position   : absolute;
				margin-left: -20px;
				color      : inherit;
			}
		}
	}

	&.member-icon{
		display:inline-block;
		height: $generalMemberHeight ;
		width: $generalMemberHeight;
		$_lineHeight: 1.2em;
		background-color: $memberIconBkColor;
		border-radius: 50% !important; // chang from 2px to 50% !importnat, enforce all become round
        border: $otisBorder solid lighten( $memberIconBkColor, 20%);
	    color: $memberColor;
		margin:0 8px 5px 0;

		&.member-myself{
		    background-color: $myselfIconBkColor;
		    border: $otisBorder solid lighten( white, 0%);
		    border-top:0;
		    color: white;
            border-radius: 50% !important;

            &.member-projectPM, &.member-projectPL{
                border-top: 0 ;
            }
		}

		&:hover {
			background-color: darken($memberIconBkColor, 10%);
			color: $memberViewText-hover;
		}

		&:active {
			background-color: darken($memberIconBkColor, 40%);
			color: white;
		}
		.member-name-div{
			height:$generalMemberHeight;
			width: $generalMemberHeight;
			overflow:hidden;
            display:table-cell;
            vertical-align:middle;
            text-align:center;
			line-height: $_lineHeight;
		
		} 
		.member-name {
			$_lines: 2;
			@include memberTextSize();
			-webkit-transform: scale(0.9);
			@media #{$phone}{
					  -webkit-transform: scale(1);
			};
			font-weight: 400;
			//padding:1px;
			height:auto;
			width: calc(#{$generalMemberHeight} - 1px );
			//margin-left: -2px;
			line-height: $_lineHeight;
			text-align: center;
	   		word-break: break-word;
	        @include  multiline-ellipsis($_lineHeight, $_lines);
	        line-height: $_lineHeight !important;
	        max-height:$_lineHeight * $_lines !important;
	        display: block;
	        text-overflow: clip;
	        // &:first-letter {		// not to uppercase first letter for not confused [Benx 2018-08-17 20:20:29]
			//     text-transform: uppercase;
			// }
		}

		&.member-projectPM {
			border-bottom: 4px solid $pmColor ;
			border-top-left-radius: 2px;
		    border-top-right-radius: 2px;
		    border-bottom-left-radius: 0;
		    border-bottom-right-radius: 0;
		}
		&.member-supervisor {
			border-bottom: 4px solid lighten($plColor, 5%);
		}
		&.member-stakeholder{
			background-color: $clientIconBkColor !important;
		    border: $otisBorder solid lighten( $clientIconBkColor, 20%)  !important;
		    color: $warm  !important;
		    border-radius: 2px ;
		}

	}


}

.reply-member .member-icon {

	&:hover, &:active {
		background-color: $memberIconBkColor;
		color: $memberColor;
	}
}

.member-add-icon{
	display:inline-block; 
	height: $generalMemberHeight;
	width: $generalMemberHeight;
	line-height: $generalMemberHeight;
	text-align:center;
	font-size: $h2;
	background: lighten($warm, 20%);
	color:white;
	border-radius: 50%;
	&:hover{
		background: $warm;
	}
}

.memberSelectionModal{
	$_memberHeight: 40px;
	$_mainColor: $cool;
	$_dialogWidth: 45em;

	.modal-dialog{
		width: $_dialogWidth;
	}
	.modal-header{

		.memberItems{

		    float:left !important;

			&.member-icon{
			 	border-radius:0;
			} 

		}
		.header{
		 	$_height:30px;
			.header-text{
				display:inline-block;
				text-align:left;
			}
			.seach-input-div{
				display:inline-block;
				text-align:right;


				input[type="text"] {
					width:100%;
					height: $_height;
					line-height:  $_height;
					padding:0 6px;
					 &::-webkit-input-placeholder {
	    				/*&:after{
							content:'\f2f5';
							font-family:ionicons;
							margin: 0px 3px;
							font-size: $h-icon;
							text-align:center;
							transform: rotate(90deg);
							position: absolute;
							right: 0;
							margin-right: $h-icon;


						}*/

					}
				}
			}
		}

		.invite-others-div{
			margin-top:14px;

			.invite-others-link{
				text-align:right;
				z-index:100;
				& > a {
					color: $invite-others-link;
				}

				&.dropdown.open {
					background: transparent;
					box-shadow: none;
					text-decoration: underline;
				}

				table {
					color: #333;
				}
				ul{
					padding:12px;
					box-shadow: 0px 3px 14px gray;
					font-size:1em;
					width: $_dialogWidth * 0.8;
					max-width: 95vw;

					.input-row{
						margin-bottom: 10px;
						height:30px;
						input{
							width:100%;
							padding:5px;
							border: 1px solid lightgrey;
							color: #333;
						}
					}

					select{
						width: 100%;
						padding:6px !important;
						border: 1px solid lightgrey;
	    					color: #333;
					}

					.inviting-list{
						//font-size: 0.8em;
						overflow-x:hidden;
						td,th{
							padding:0.5em;
							vertical-align:middle;
						    &.invitee-email{
						    	width: 38%;
							    white-space: normal;
							    @include smartBreakWord();
						    }
						    &.invitee-status{
						    	width:20%;
						    }

						}
						td{
							//font-size: 0.88em;
							 .btn-xs{
						    	font-size: inherit !important;
						    }
						}
					}

					textarea{
						width:100%;
						padding:5px;
						color: #333;
					}
				}
			}


		}
  }
  


	.modal-body{

		&.invite-members{
			.member-name{
				font-size:0.8em;
			}
			.member-icon{
				float: inherit;
				display: inline-block;
				vertical-align: middle;
				height: $_memberHeight;
				weight: $_memberHeight;
			}
			.my-backdrop-container {
				position: relative;
				display: inline-flex;

				.my-backdrop{
					position: absolute;
					top: 0;
					left: 0;
					bottom:0;
					right:0;

					background-color: lighten($_mainColor, 20%);
					margin: 3px 0;
					opacity: 0.2;
				}

				.list-selectableMembers {
					margin-top: 20px;
				}
				.item-selectableMember-name {
					font-size: 1em;
					display: inline-block;
					width: 8em;
					white-space:pre-wrap;
					overflow: hidden;
					//line-height: $_memberHeight * 0.8;
					text-indent: - 14px;
					padding: 6px 0 6px 18px;
					word-break: break-word;
					.icon-otis-user {
						padding-right: 5px;
					}

				}
			}

			.groupTypeName {
				text-align:center;
				font-size: 120%;
				font-Weight: 800;
				color: $_mainColor;
				i{
					font-size: $h-icon-md;

				}
			}
			.groupName {
				font-weight: bold;
			}
		}
	}

	.search-text {
		color: #333;
	}
}

@media #{$phone}{
	.memberSelectionModal .modal-dialog {
		width: initial;
		//.modal-header .invite-others-div ul{
		//	width: 86vw;
		//}
		ul{
			-webkit-padding-start: 0px;
		}
		.input-selmember-type{
			margin: 0.5em 0;
			padding: 0.5em 0;
		}
	}

}
