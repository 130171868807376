/* projectInfo scss */

.pjtMenu-panel{
	//margin-bottom: 15px;
	padding: 10px;
	//box-shadow: 0px 1px 3px rgba(0,0,0,0.4);
	cursor: pointer;
	flex-grow:1;
	flex-basis:0;
	min-width:8em;
}

.pjtMenu-panel-title{
	display: flex;
	margin-bottom: 7px;  // adjust this   to make same height with left row
	justify-content: space-between;
	align-items:center;
	font-size: 24px;
}

.sprint-chart-title, .project-chart-title{
	position:absolute;
	margin:6px;
	color:black;
	z-index:1;
	@media #{$phone}{
		position:relative;
		margin:0px;
		margin-bottom: 15px;
	}
}

.addon-label{
	@include otisLabel();
	padding:0 6px;
	color: white;
	background: rgba(100,100,100,0.65);

}

.project-basic-info{
	label{
		font-weight:600 !important;
		margin-bottom:initial !important;
		color: rgba(50,50,50,0.86);
	}
}

.project-info-card{
	padding:10px;
	background: $calm !important;
	color:white !important;
	position:relative;
	cursor:pointer;
}

.project-execution-remark{
	.otis-info-card{
		border-radius:0px;
		background: lighten($cool, 20%);
		box-shadow:none;
	}
	.otis-info-title{
		color:white;
		font-size:1.1em;
		font-weight:600;
	}
}

.project-info-div{
	$_color: $viewTitleTextColor;
	//color: $_color;
	$_mainColor: $warm;
	@include unSelectable();
	@include height-vh-patch("100vh - #{$navbarHeight} ");
	@media #{$phone}{
		@include height-vh-patch("100vh - #{$navbarHeight} - #{$sidebarIconHeight}  "); //remove -50px by smith @ 20170912
		.modal-body{
			padding: 0px !important;
		}
	}
	$_backgroundColor: rgba($darkenMDBackground, 0.6);
	// @include scrollTinyBar($_mainColor,$barSize);

	.otis-tips {
		font-size:0.9em !important;
		ul{
			-webkit-padding-start: 1.5em;
		}
	}

	.recent-bell{
		 cursor:initial !important;
	}
	.pjt-activity, .recent-deletedInfo{

	}




	.burn-chart-selector, .project-burn-chart {
		select{
			width:100% !important;
			border-radius: 6px !important;
			background-color: whitesmoke !important;
		}
	}

	select{
		$_mainColor: $warm;

      	overflow:hidden;
      	@include mainTextSize();
	    color: black;
	    padding: 0.5em 2em 0.5em 0.5em;
	    margin-bottom:0.5em;

	    option{
	    	color:$main-text;
	    }
	}




	.project-info-title{
	    color:$viewTitleTextColor;
		@include menuDropdownSize();
		// text-align:center;
	    //max-width: 95vw;
	    overflow: hidden;
	}
	.effort-header{
		@include mainTextSize();
		text-align:center;
		padding:0.5em;
		background: lighten($_mainColor, 30%);
		color:white;
	}

	// .project-info-subTitle{
	//     color:$viewTitleTextColor;
	// 	@include h2TextSize();
	//     float:left;
	// }

	.project-info-body{
		 padding: $panel-side-margin;
		 @media #{$phone}{
		 	padding: 0;
		 }

		.tab-content{
			height:auto !important;
		}
		.current-sprint-overview{

			//margin-bottom: 1em;
			padding-left: 0;
			@media #{$phone}{
				padding: 10px;
			}
			.bootstrap-select{
				@media #{$phone}{
					width: 100% !important;
					margin:6px 0px !important;
				}
			}
		}
		.project-overview{

			margin-bottom: 0.5em;
			padding-left:0;
			@media #{$phone}{
				padding: 10px;
				//margin-bottom:30px;
			}

			.project-overview-info{
				.member-effort-chart{
					margin-bottom:0.5em;
				}
				.project-effort{
					margin-bottom:0.5em;
				}

			}
		}
		.effort-dashboard{
			display:inline-flex;
			width:100%;
			color: $main-text;
		//	margin:0 0 6px 6px ;

		}
		.dashboard-block{
			//margin-right:1em;
			//width:15em;
			width:100%;
			overflow:hidden;
			border-radius:8px;
			border:1px solid lightgray;
			$_mainColor: $calm;

			.effort-number{
				@include h1TextSize();
				text-align:center;
				padding:10px;
				background:white;
			}
		}

		.done-text-tag{
			margin-top:-1.6em;
			font-size:1em;
			color:gray;
		}

	}

	.recent-deletedInfo{
		$_mainColor: gray;
		$_subColor: $warm;
		display: inlne-block;

		.sub-title{
			margin-top:0.5em;
			margin-bottom:1em;
			font-weight:bold;
           // color: $projectInfoActive-color !important;
		}

		.recent-deletedInfo-card{
			margin-bottom: 1em;
			padding: 0.5em 0.5em 0 0.5em;
			//cursor:pointer;
			display: inline-block;
			margin:4px;
			background:whitesmoke;

   			width: calc( (100% - 32px ) * 0.25);
    		border:1px solid lightgray;
			//background: rgba(255,255,255,0.4);
			@media #{$tablet}{
				max-width: initial;
				width: calc( (100% - 24px ) * 0.333);
			}

			@media #{$phone}{
				max-width: initial;
				width: 100%;
			}
			&:hover{
    			border:1px solid gold;
			}
    		.del-itemName-div{
    			$_line-height:1.2;
				$_font-size: 0.88em;
				$_line-to-show: 2;
				$_padding: 0.2em;

				padding: $_padding;
				display:flex;
				background:initial !important;
				color: $main-text  !important;
				//flex-wrap:wrap;


    			.delAct-itemName{
					display: flex;
					display:-webkit-flex;
					line-height: $_line-height;
					-webkit-line-clamp: $_line-to-show;
					-webkit-box-orient: vertical;
					height: ($_line-height  * $_line-to-show * $_font-size ) + $_padding ;
					overflow:hidden;
					text-overflow: ellipsis;
					width: calc(100% - 40px);
					margin-left: 5px;
					@include mainTextSize();
					@include multiline-ellipsis($_line-height,2);
				}
    		}
    		.restore-btn{
    			//height: 1.4em;
			    font-size: 0.8em !important;
			    // line-height: 1.4em;
			    //padding: 0 0.2em;
				float: right;
				white-space:nowrap;
    			//margin-top: 0.4em;
    		}
    		.info-row{
				@include tinyTextSize();
    			color: $main-text  !important;
                padding: 10px 0;
			}





		}

	}
	.project-info-list-div{
		.project-info-list{
			$_mainText: $viewTitleTextColor;
			$_mainColor:$dynamicBackground;
			min-width: 6em;
			width:auto;
	    	display: inline-block;
	    	padding:0.5em;
	    	text-align:center;
	    	cursor:pointer;
	    	border-top-right-radius:5px;
	    	border-top-left-radius:5px;
	    	color: $projectInfoTab-color;
	    	&:hover{
	    		background:$projectInfoActive-background-hover;
	    	}
	    	&.activeTab{
	    		color:$projectInfoActive-color;
	    		background:$projectInfoActive-background;
                border:1px solid white;
                border-bottom:none;
	    	}
	    	&:active{
	    		color: lighten($_mainColor, 20%);
	    	}

		}
	}



	latest-delete-info{
		 background: $_backgroundColor;
   		 display: inline-block;
   	     padding: 0.2em;
   	     width:100%;
   	     .otis-tips{
			margin:1em !important;
		}
		.modal-body{
			max-height:calc( 100% - 54px );
		}
	}
}

latest-activity{
//background: $_backgroundColor;
	display: inline-block;
	padding: 0.2em;
	width:100%;
	// position: fixed !important; // because when popup .otis-overlay will not over nav bar
	@include height-vh-patch("100vh");

	//line-height:1.1em;
	//color:white;
   .sub-title{
	  //color: $projectInfoActive-color !important;
   }
   .modal-body{
	   max-height:calc( 100% - 54px );
   }
}

.sprint-dropdown-list{
	$listWidth: 12em;
	position:absolute;

	top:30px;
	left:50%;
	margin-left:  calc( -#{$listWidth} * 0.5 - 20px );
	z-index:1;
	@media #{$phone}{
		top: -6px;
		right: 0;

		left: initial;
		width: 	$listWidth;
		margin-right: 6px;
	}
	.bootstrap-select{
		width: $listWidth !important;
	}
	.dropdown-toggle{
		color: black;
		background: white;
		width: $listWidth !important;
		display:inline;
	}

}

.highcharts-legend{
	@media #{$phone}{
		display:none !important;
	}
}


latest-info-modal{
	.latest-command-modal{
		width:auto;
	}
	.otis-tips{
		margin:1em !important;
		&.tips-latest-comments{
			margin:0 0em !important;
			padding: 1em 0;
			box-shadow:initial;
		}
		&.tips-latest-files{
			margin:0 0em !important;
			padding: 1em 0;
			box-shadow:initial;
		}
		&.tips-latest-todos{
			margin:0 0em !important;
			padding: 1em 0;
			box-shadow:initial;
		}
	}
	.sub-title{
		color: $main-text !important;
	}

}

.project-latestInfo{
	$_padding: 10px;
	$_tabHeight:35px;
	$_modalHeader: 48px;
	@include mainTextSize();
	@include latestInfo($_padding);
	.modal-body{
		height:calc( 100% - 54px );
		@media #{$phone}{
			padding:0px;
			max-height:initial;
			height:calc( 100% - 54px );

		}
	}

	#latestComments,#latestFiles,#latestTodos{
		@include height-vh-patch("100vh - #{$_modalHeader} - #{$_tabHeight} ");
		overflow-y:auto;
	}
	.nav-tabs{
		$_mainColor: $mainColor;
		//background: lighten($_mainColor, 10%);
		text-align: center;
		width:100%;
		cursor:pointer;
		&.nav-sub-tabs{
			$_mainColor: $projectInfoActive-background;
			background: lighten($_mainColor, 35%);
			text-align: center;
			width:100%;
			cursor:pointer;
			li{
				width:33.3333% !important;
				margin-bottom: 0px;
				.tabs{
					margin-right:0px !important;
					border-radius:0 !important;
					color:white;
					border:0 !important;
					background:  lighten($_mainColor, 30%);
				}
				&:hover{
					.tabs{
						background: lighten($_mainColor, 25%) !important;
						color:white;
					}
				}
				&.active{
					.tabs{
						background: lighten($_mainColor, 20%) !important;
						color:gold;
						&:hover{
							color:white;
						}
					}
				}
			}
		}

		.tabs{
			height: $_tabHeight;
			line-height:$_tabHeight;
			//color:$mainText;
			font-size: 1.2em;
			padding: 0;
			text-overflow: ellipsis;
			white-space: nowrap;
			width: 100%;
		}

		li{

			&:hover{
				.tabs{
					background: $projectInfoActive-background-hover !important;
				}
			}
			&.active{
				.tabs{
					background: $projectInfoActive-background !important;
					color:$projectInfoActive-color;
					&:hover{
						color:white;
					}
				}
			}
		}
	}

	pop-file-preview{
		img, video{
		width:100%;
		}
	}

}

#ProjectInforRestoredDeletedXs{
	@include height-vh-patch("100vh - 120px");
	overflow-y:scroll;
}

@media #{$phone}{
	.project-info-div{
		padding:6px 6px 160px 6px; // 50px is for bottom mobile nav menu by smith @20170918;
		//overflow:hidden !important;
		.project-info-title{
			margin-right:0;
			.view-page-header{
				padding-left:0;
			}
		}
		.project-info-div-xs-tab{
			@include mainTextSize();
			li{
				width:25%;
				text-align:center;
				font-size:0.9em;
				&.active{
					a{
						background:$warm;
						color: white;

					}
				}
				a{
					padding: 0 !important;
				}
			}
		}

	}
	.effort-dashboard{
		display:block !important;

	}
	.current-sprint-overview{
		//margin:0;
		padding:0.5em !important;
	}
	.project-overview{
		//margin:0;
		padding:0.5em  !important;;
	}
	.dashboard-block {
		width: 100% !important;
		margin-bottom: 5px !important;

		.effort-number{
			padding:10px !important;
		}
	}
	.project-info-div .project-info-body .project-latestInfo {
       	width: 100%;
    }


	latest-info-modal{

		.sub-title{
			margin-top:0 !important;
		}

	}
}


.project-info-panel{
	padding: 10px 20px;
	margin-bottom: 10px;
	flex: 1 1;
	@media #{$phone}{
		padding: 10px;
	}
}

$_color_bg_open: #64b5f6;
$_color_bg_overdue: #e57373;
$_color_bg_doing: #4db6ac;
$_color_bg_review: #ffb74d;
$_color_bg_done: #cccdce;

.panel-bg--info, .panel-bg--todo-open {
	background-color: $_color_bg_open;
	color: #ffffff;
}
.panel-bg--danger, .panel-bg--todo-overdue {
	background-color: $_color_bg_overdue;
	color: #ffffff;
}
.panel-bg--success, .panel-bg--todo-doing {
	background-color: $_color_bg_doing;
	color: #ffffff;
}
.panel-bg--warning, .panel-bg--todo-pendingReview {
	background-color: $_color_bg_review;
	color: #ffffff;
}
.panel-bg--sub, .panel-bg--todo-recentDone {
	background-color: $_color_bg_done;
	color:#ffffff;
}

.project-info-panel-title{
	font-size: 24px;
}
.project-info-subTitle{
	color:gray;
}

.project-info-dropmenu{
	@media #{$phone}{
		display: none;
	}
}
.open .project-info-dropmenu{
	@media #{$phone}{
		display: block;
	}
}
.project-info-droptoogle{
	border-top: solid 1px $dynamicColorGray
}
.open .project-info-droptoogle{
	border: none;
	i{
		display: inline-block;
		transform: scale(1,-1);
	}
}

pop-project-remark-note-item {
	width: 100%;
	display: block;
	
	.action-buttons .btn {
		padding: initial;
	}
	
	.note-timestamps {
		.note-createTime {
			transform: scale(0.8);
			display: inline-block;
			margin-left: -3px;
		}
	}
}

.notes-zone {
	pop-project-remark-note-item {
		margin-top: 5px;
		
		&:first-child {
			margin-top: 0px;
		}
	}
}


pop-project-todo-summary-list {
	$_main_bg_todo_item: white;
	
	.myTodo-list-div {
		padding-top: 20px;
		
		.todo-list-brick .todo-list {
			padding-bottom: 20vh;

			.dashboard-list {
				padding: 10px 15px;
				margin: 0 10px 10px 10px;
				border: 1px solid lightgray;
				border-radius: 3px;
				box-shadow: 0px 10px 10px -10px #9d9b9b;
				line-height: 1.3em;
				background: $_main_bg_todo_item;
				
				&:hover {
					box-shadow: 0px 15px 10px -15px #111;
				}
				
			}
		}

		.myTodo-list-item {
			width: 48%;
			@media #{$phone}{
				width: 100%;
			}
			
			.anno-label {
				float: right;
				margin-left: 10px;
			}
			
			&.todo-doing {
				.dashboard-list {
					background: lighten($doingTodoColor, 15%);
					
					&:hover {
						background: lighten($doingTodoColor, 20%);
					}
				}
				
			}
			
			&.todo-done {
				.dashboard-list {
					background: lightgray;
					border-left: 0px !important;
				}
				
				.anno-label {
					display: none;
				}
			}
			
			&.--no-dueDate {
				.todo-dueDate {
					color: gray;
					opacity: 0.2;
				}
				
			}
			
			&.due-thisWeek {
				.anno-label {
					background: lighten($dueTodayColor, 10%);
				}
				
				.todo-dueDate {
					color: $dueTodayColor;
				}

// 				.dashboard-list {
// 					border-left: 3px solid $dueTodayColor;
// 				}
			}
			
			&.due-today {
				.anno-label {
// 					background: lighten($dueTodayColor, 25%);
					background: orange;
				}
				
				.todo-dueDate {
					color: $dueTodayColor;

				}

				.dashboard-list {
					border-left: 3px solid orange;
				}
			}
			
			&.overdue {

				.todo-dueDate {
					color: $overdueColor;
				}

				&.todo-done .todo-dueDate {
					color: inherit;
				}
				
				.dashboard-list {
					border-left: 3px solid $overdueColor;
				}
			}
			
		}
		
	}

	
	&[type="pendingReview"] {
		.myTodo-list-div {
			.myTodo-list-item {
				&.todo-done {
					.dashboard-list {
						background: $_main_bg_todo_item;
					}
				}
			}
		}
		
	}

}