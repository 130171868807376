.fileEditModal{
  .modal-body{
    @include max-height-vh-patch("100vh - 80px - 120px");
    @media #{$phone}{
      @include max-height-vh-patch("100vh - 120px");
    }
  }
}
.file-history{
  vertical-align: middle;
  background: floralwhite;
  box-shadow: 0 0 1px grey;
  $_iconSize: 3em;
  .history-file-name{
    width: calc(100% - 5px);
    display:inline-flex;
    flex-wrap:wrap;
  }
  .file-history-list{
    display:inline-flex;
    width:100%;
    height:100%;
    flex-wrap:wrap;
    flex-direction:column;
    justify-content: space-between;
  }

  .file-preview{
    display:inline-block;
    height:100%;

    .fileIcon{
      cursor:initial;
      height: 10em;
      width:  10em;
      background-size: cover !important;
    }

    video { 
      min-width: 15em;
      max-width: 33%; 
      padding: 3px;
      padding: 3px;
      cursor:pointer !important;
    }

    

    img { 
      max-height: 10em;
      max-width:100%;
      padding: 3px;
      width:auto;
      height:auto;
      cursor:pointer !important;
      //max-width: 100%;
    }
  }
  .sub-title{
    @include labelSize();
    font-weight:600;
    padding: 10px 0;

  }
}