/* backlog view */
@include pageScrollArrow();

#sprint-scroll-container {
  position: relative;
  overflow-x: auto;
  overflow-y: hidden;
  width: 100%;
}


#backlogTodoList{
  $barColor: $main-background-color;
  @include scrollTinyBar($barColor,$barSize);
  @include xsShowScroll();
  margin-top:8px;
  .todo-card{
    margin-top:0px !important; //remove 8px to outer div
  }
  @media #{$phone} {
    &.dd-list {
      background: transparent;
    }
  }
}

.sprint-member-view-body {
  .mySprintName {
    display: block;
  }
}

.sprint-drag-tip {
  position: absolute;
  width:100%;
  height: 100%;
  z-index: 500;
  pointer-events:none;
  .sprint-dragTip-left{
    position: absolute;
    left: 265px;	// $_swimLanWidth  + $sidebarWidth + $panel-side-margin;
    @media #{$desktop-md-above}{
     left: 320px;   // $_swimLanWidth * 1.2  + $sidebarWidth + $panel-side-margin;
    }
    background-color: rgba(20,20,20,0.2);
    width:100px;
    height: 100%;
    content: '';
    display: flex;
    align-items:center;
    justify-content:center;
  }
  .sprint-dragTip-right{
    position: absolute;
    right: 0px;
    background-color: rgba(20,20,20,0.2);
    width:100px;
    height: 100%;
    content: '';
    display: flex;
    align-items:center;
    justify-content:center;

  }
  .js-active{
    background-color: rgba(20,20,20,0.55);
    i{
      color: rgba(250,250,250,0.85);
    }
  }
  i{
    font-size: 36px;
    color:rgba(250,250,250,0.5);
  }
}

.swimlane-dragTip-sticky,.swimlane-dragTip-last{
  display: none;
  position: absolute;
  left:0px;
  line-height:$swimLanHeaderHeight;
  width:100%;
  height: $swimLanHeaderHeight;
  text-align: center;
  background-color: rgb(158, 158, 158);
  color:rgb(196, 196, 196);
  z-index: 3;
  pointer-events:none;
  &.active{
    background-color:rgb(20, 20, 20);
    color:rgb(255, 255, 255);
  }
}
.swimlane-dragTip-sticky{
  top: 0px;
}
.swimlane-dragTip-last{
 top:0px;
}
.dd-list-bottom{
  //swimlane-dragTip-last need dd-list-bottom be relative to set position.
  position: relative;
  min-height: $swimLanHeaderHeight;
  margin: -1px 0px 0px;// todo#39401:當水道沒有工作時，其下緣有些許的畫面異常。[Jane 2018-09-11 11:57:55] note: 此問題在IE上出現
  @media #{$phone}{
    min-height: initial !important;
    .newTodoCard{
      //display:none; //todo#41617: 使用 Mobile，在 Plan View 無法新增工作。[Jane 2018-10-05 11:52:22]
    }
  }
}
.onDragging-setPosition{
  position: relative!important;
  .swimlane-dragTip-last{
    top:0px!important;
  }
}

.dd-swimlane-onDragover .swimlane-dragTip-sticky,.dd-swimlane-onDragover .swimlane-dragTip-last{
  display:block;
}


.todo-filter-panel{
    position: fixed;
    top: #{$navbarHeight};
    right: 0;
    border-radius:0;
    z-index: 200;
    // background: whitesmoke !important;
    color: black;
    box-shadow: -1px 3px 10px grey;
    width: 350px;
    @include height-vh-patch("100vh - #{$navbarHeight}");
    @media #{$phone}{
      $_fingerTouchSpace: 65px;
      width:calc(100vw - #{$_fingerTouchSpace});
      min-width: calc(100vw - #{$_fingerTouchSpace});
      max-width: calc(100vw - #{$_fingerTouchSpace});
      box-shadow: -2px -1px 6px 0px grey;
      @include height-vh-patch("100vh -  #{$navbarHeight} - #{$sidebarIconHeight}");
      background: whitesmoke !important;
    }

    .icon-btn.dynamic {
      &:hover {
        background: rgba(0, 0, 0, 0.1);
      }
    }

    .menu-header{
      text-align:left;
      // border-bottom:1px solid rgb(60,60,60);
      // background: rgba(100,100,100,0.1);
      background: $projectSetting-menu-header-bg;
      color: $projectSetting-menu-header-color;
      font-weight:800;
      width:100%;
      padding:0px 15px;
    }

    .section-header{
      text-align:left;
      // margin-top:10px;
      //padding: 5px 15px;
      font-weight:600;
      width:100%;
      // background: rgba(100,100,100,0.1);
      @media #{$phone}{
        // height: calc(100% - #{$navbarHeight}   );
        // height: -webkit-calc(100% - #{$navbarHeight}  );

        //padding:0;
      }
    }
    .project-setting-content{
        background: $projectSetting-content-bg;
        color: $projectSetting-content-color;
        padding: 0  15px 15px 15px ;
        height: calc(100% - #{$navbarHeight});
        height: -webkit-calc(100% - #{$navbarHeight});

      .todo-content-filter {
        input {
          width: 100%;
        }
      }

      .filter-block{
        height: initial;
        height: auto; // for IE

        &.dropdown.open {
          background: $filter-block-dropdown-open;

          @media #{$phone} {
            color: #fff;
          }
        }
      }

      .filter-toggle, .filter-label-dropdown-toggle{
        // background: $projectSetting-filter-bg !important;
        color: $projectSetting-filter-color!important;
	display: block;
      }

      .selected-phase-plan{
        .selected{
          color:white ;
        }
      }

      @media #{$phone}{
        height: calc(100% - #{$navbarHeight}  );
        height: -webkit-calc(100% - #{$navbarHeight} );
        font-size: 13px;
      }


    }
    .filter-section{
      padding: 0px 0px;
      //color:whitesmoke;

      .todo-content-filter {
        input {
          color: $projectSetting-filter-input;
          width:100%;
          padding:6px;
        }
      }
    }
    .filter-icon-toggle{
        position: absolute;
        background: white;
        color: $main-text;
        background: rgb(60,60,60);
        margin-top:-5px;
        margin-left: 0px;
        border-radius: 3px;
        padding: 0px 10px;
    }

    .selected-list, .selected-date ,.selected{
     	color: $selected;
    }

    // .label.filter-item.label-on {
    //   background: $selected;
    //   color: $label-on-color;
    // }

    .dropdown-menu>.active>a{
      background: $dynamic-dropdown-menu-active;
    }
}
.todoFilterWidgets-toggle-icon{
  &.disabled-element {
    background-color: darken($navSidebarColor, 30%);
    color: darken( lightgray, 15%);
    opacity: 0.6;
  }
}
.todo-filter-inline{
    display:inline-block;
    .filter-block{
        width: auto;
        vertical-align:initial;
        background: transparent;
        .filter-toggle{
            padding:0 10px !important;
            background: transparent;
            overflow:initial;
            overflow:visible; // for ie
            height:35px;
            line-height:35px;
        }
        .dropdown-menu{
          width: initial;
        }
    }
    .dropdown-menu-right{
        left:0 !important;
        right:auto !important;
    }

    .filter-item-clear-btn,.selected-list,.selected,.filter-item-title{
        display:none !important;
    }


    .font-xxl{
       font-size: 1.3em !important;
    }
}

.filter-user-dropdown{
    display: inline-block;
    height: $viewTitleHeight;
    line-height:$viewTitleHeight;
}



.filter-block{
   $_width: 100%;
   display:inline-block;
   width: $_width;
   vertical-align:text-top;
   line-height:1;
   color: $projectSetting-filter-color ;
   background: $filter-block-background;
   position:relative;
   border: 1px solid $projectSetting-filter-border;

  .filter-item-clear-btn{
    $_size: 15px;
    width: $_size;
    height: $_size;
    line-height: $_size;
    position:absolute;
    right: 0px;
    top: 0;
    text-align: center;
    cursor: pointer;
    color: $selected;
  }

  .filter-toggle{
    padding-right: 30px !important;
  }


  .dropdown{
    padding:0px !important;
  }

  .dropdown-menu{
    width: 100% !important;
  }

}

.filter-on {
	.swimlane-item-counts {
		font-style: italic;
	}
}

.selected{
    color: gold;
}

.filter-toggle {
	$_hoverBgColor: darken($navSidebarColor, 10%);
	$_toggleBgColor: darken($navSidebarColor, 20%);

	&:focus{
      outline:0;
    }
    &:hover{
      color: $toggle-color;
      background: $_hoverBgColor ;
    }
    &:active, &.active{
      background: $_hoverBgColor !important;
      color: $toggle-color;
    }
    &.open, .dropdown.open > & {
      background: $_toggleBgColor  !important;
    }

	&.disabled-element {
		pointer-events: none;
	}
}

.filter-toggle {

    cursor:pointer;
    padding:0 10px !important;
    display:inline-block;
    width:100%;
    //text-overflow:ellipsis;
    white-space: nowrap;
    overflow:hidden;
    margin:0px !important;
    min-height: 35px;
    text-align:left;

}


.todo-filter-menu{
    border:1px solid lightgray;
    border-radius:3px;
    height:30px;
    line-height:30px; // revise from 26 to 30 for menu btn instead of filter btn
    text-align:center;
    margin-top:3px;
    //margin-right:4px;
    width:30px;
    font-size: 1.2em;// revise from 1.5 to 1.2 for menu btn instead of filter btn
    display:inline-block;
    &:hover{
      background: rgba(0,0,0,0.3);
    }
    &.icon-menu.filter-on {
      background: rgba(0,0,0,0.5);
      color:orange;

      &:before {
      	content: "\e92c";
      }
    }
    &.active{
      visibility: hidden;
    }

}

.start-sprint-icon{
    height: $swimLanHeaderHeight * 0.8 !important;
    line-height:  $swimLanHeaderHeight * 0.8 !important;
    width :  $swimLanHeaderHeight * 0.8 !important;
    border-radius: 50%;
    text-align: center;
    background: $start-sprint-icon-color;
    color:white;
    margin-top:  $swimLanHeaderHeight * 0.1 !important;
    display:inline-block;
    &.start-sprint-icon-reverse{
        background: white;
        color: $main-text;
        border: 1px solid lightgray;
    }
}

pop-todo-item {
	.todo-doing {
		background: lighten($doingTodoColor, 5%) !important;
		&:hover {
		  background: lighten($doingTodoColor, 20%) !important;
		}
	  }

}

pop-quick-filter-input-widget {
  // todo#57202: backlog/plan filter 樣式調整與fileview filter樣式一致 [Jane 2019-04-19 10:11:05]
  .otis-input {
    // input {
    //   color: $viewTitleTextColor;
    //   height: 30px;
    //   border: 1px solid rgba(211, 211, 211, 0.5);
    // }
  }
}

pop-todo-filter-widgets {
  .otis-input input{
      color:  #2f2e2e;
  }
}

.backlog-title{
  background: rgba(100,100,100,0.15);
  border-radius: 20px;
  border: 1px solid lightgray;
  padding: 6px 12px;
  box-shadow: 0 5px 5px -5px #333;
  font-weight: initial;
  color:$viewTitleTextColor;
  @media #{$phone}{
    color:$main-text;
  }
}

.backlog-page-title {
  display: inline-block;
}

.backlog-panel {
  $_mainColor: $uncalssifiedBacklogColor;
  overflow-y: hidden;

  .view-title {

    i {
      font-size: inherit;
      //height: 0em;
    }
  }
  .sprint-tedtor-tips {
    width: 21em;
    margin-left: -2em;
  }
  .backLog-view-body {
     @include OtisPagePadding();
    //  @include height-vh-patch("100vh - #{$navbarHeight} - #{$viewTitleHeight}");

     .sprint-member-view-container{
       padding: 0px;
     }
  }

  .dd-swimlane {
    &.backlog-div {
      @include swimLanWidth();
      @include backlog-list-header();
      .dd-list-bottom {
        background: $_mainColor ;
				border-color:lighten($viewTitleTextColor, 40%);
				color:$viewTitleTextColor ;
				border-radius:0px ;
        &.list-bottom-absolute {
          bottom: 50px;
        }
      }

	  .dd-list-header {
		  .one-line-header {
			  width: initial;
		  }
	  }

      .list-header {
       // background: $_mainColor;

        //color: white; //$viewTitleTextColor;
		//border-radius: 2px 2px 0 0;

		// todo#37832: patch for sprint date and count info render. [Benx 2018-08-23 19:29:24]
        // display:inline-flex;

		//new test
      }
      .list-item {
        color: $_mainColor;
      }
      .dd-list {
        //background: $_mainColor !important;
        // margin: -1px 0; // margin -1px will make body have 1px gap in background color
        @include swimLanWidth();
        max-height: 60vh;	// set init max-height in css. It will be re-calc in OtisUI [Benx 2018-10-11 13:13:55]
     }
      .dd-list-bottom {
        // background: $main-background-color;
        color: $viewTitleTextColor;
        background: transparent;
        &.dd-list-bottom-absolute {
          position: absolute;
          bottom: 0px;
          background: $dynamic-dd-list-bottom-background;
        }
      }
      .dd3-content {
        &:hover {
          background: lighten($_mainColor, 55%);
        }
        .todoText {
          color: darken($_mainColor, 20%);
        }
      }
      .dd-list {
        &.scroll-tiny-bar{
          @include scrollTinyBar($_mainColor,$barSize);
          @include xsShowScroll();
        }
      }
    }
  }
  .sprint-div {
    display: inline-block;
    min-width: 100%;
    height: 100%;
    $_mainColor: lighten($main-text, 30%);

    // .plan-add-div {
    //   .plan-add-brick {
    //     height: $swimLanHeaderHeight;
    //     line-height: $swimLanHeaderHeight;
    //     @include swimLanWidth();
    //     text-align: center;
    //     cursor: pointer;
    //     background: $titleBarColor;
    //     &:hover {
    //       background: darken($titleBarColor, 2%);
    //       color: darken($main-text, 2%);
    //     }
    //     &:active {
    //       background: darken($titleBarColor, 15%);
    //     }
    //   }
    //   @media #{$phone}{
    //     padding-right:30px;
    //   }
    // }

    .sprint-title-dropdown-icon {
      // height: $swimLanHeaderHeight;
      // line-height: $swimLanHeaderHeight;
      height: 24px;
      line-height: 24px;
      position:absolute;
      right: 3px;
      top: 5px;
      width:20px;
      text-align:center;
      border-radius: 5px;
      color: invert($_mainColor);
      cursor: pointer;
    }

    .dd-swimlane {
      .sprint-swimlane-header{
      // width: calc( 100% - 10em);
      width: 0px;		// todo#37832: patch for sprint date and count info render. [Benx 2018-08-23 19:29:24]
      }
      .dd-list {
        $_backgroundColor: lighten($titleBarColor, $unStartSprintLight);
        background:  $_backgroundColor;
        margin: -1px 0;

        &.scroll-tiny-bar{
          @include scrollTinyBar( $_backgroundColor,$barSize);
          @include xsShowScroll();
        }

      }
      .dd-list-bottom {
        background: darken($titleBarColor, $unStartSprintDark);
      }
      .sprint-activated {
        cursor: pointer;
      }
      .sprint-activated,
      .sprint-inactivate {
        //max-width: 75%;
        // todo#24308: 建議滑鼠移動過去的時候，圖示比照已啟動的階段計畫呈現「可點擊圖示」。[Jane 2018-10-05 17:39:36]
        cursor: pointer;
        display: inherit;
        flex-grow: 1;
        .icon {
          height: $swimLanHeaderHeight;
          line-height: $swimLanHeaderHeight;
          margin-right: 6px;
        }
      }
      &.sprint-general {
        .dd3-content {
          &:hover {
            background: darken(white, 5%);
          }
        }
        .dd-list-bottom {
          //border-radius: 0 0 8px 8px;
          &.dd-list-bottom-absolute {
            position: absolute;
            bottom: 0;
          }
        }
      }
	  &.sprint-finished {
		  .dd-list-header {
			  background: $finishedSprintColor;
		  }
		  .dd-list-bottom {
			  min-height: 10px;
			  height: 10px;
			  background: $finishedSprintColor;
		  }
	  }

    &.sprint-started {
      .dd-list-header {
        $_mainColor: $activeSprintColor;
        background: $activeSprintColor;

        .sprint-title-dropdown-icon {
          ul{
              background: rgba(0,0,0,0.8) !important;
              .sprint-title-dropdown-li{
                  color:white !important;
              }
              li{
                  &:hover{
                      .sprint-title-dropdown-li{
                          color:white  !important;
                          background:rgba(0,0,0,1) !important;
                      }
                  }

              }
          }

          @include hover-supported() {
            background:rgba(128, 128, 128, 0.5);
            color: white;
            text-decoration: none;
            .sprint-title-dropdown-li{
                color:inherit !important;
            }
          }
          &:active{
            background: lighten(gray, 5%);
              color: gold;
              .sprint-title-dropdown-li{
                  color:inherit !important;
              }
          }
          &.open{
            background: lighten(gray, 5%);
              color: white;
              .sprint-title-dropdown-li{
                  color:inherit !important;
              }
          }
          .sprint-title-dropdown-li{
            color: $main-text !important;
            font-weight: initial !important;
            font-size: 1em;

          }
        }
      }
      .dd-list {
        background: $activeSprintColor;
        margin: -1px 0;
        &.scroll-tiny-bar{
          @include scrollTinyBar($activeSprintColor,$barSize);
          @include xsShowScroll();
        }
      }
      .dd-list-bottom {
        background: $activeSprintColor;
        //border-radius: 0 0 2px 2px;
        &.dd-list-bottom-absolute {
          position: absolute;
          bottom: 0px;
          @media #{$phone} {
              bottom: 5px;
          }
        }
      }
      .dd3-content {
        &:hover {
          background: lighten($activeSprintColor, 20%);
        }
        .todoText {
          color: darken($activeSprintColor, 50%);
        }
      }

    }

    }
    .dd-list-header {
      .sprint-title-dropdown-icon {
        $_mainColor: $generalSprintColor;

        @include hover-supported() {
          background-color:lighten( $_mainColor, 5%);
          color: $white;
          a{
            color: $sprintTitleOpition-hoverColor !important;
            text-decoration: none;
          }
        }

      	&:active{
      		background-color:darken( $_mainColor, 5%);
      		a{
      			color: gold !important;;
      		}
        }

        &.open{
          background-color:lighten( $_mainColor, 5%);
          a{
            color: $sprintTitleOpition-hoverColor !important;
            text-decoration: none;
          }
        }
       // a {
       //   color: $main-text !important;
       //   font-weight: initial !important;
       //   font-size: 1em;
       // }
      }
	  /*
	//   .sprint-header-right-part{
    //       display:inline-flex;
    //       align-items:center;
    //       position:absolute;
    //       right:15px;
    //   }
    //     .sprint-todo-counts{
    //       -webkit-transform:scale(0.76);
    //       transform: scale(0.76);
    //       transform-origin:left;
    //       //@errorpadding-right:5px;
    //       display:inline-block;
    //     }
    //     .sprint-date-div{
    //       width:6em;
    //       align-items:center;
    //    	  text-align:right;
    //    	  flex-grow:1;
    //       padding-right: 5px;
    //       -webkit-transform:scale(0.76);
    //       transform: scale(0.76);
    //       transform-origin:right;
    //       display:inline-block;
    //    	  //width:10em;
    //    	  //line-height:initial !important;
    //    	  line-height: normal !important;
    //    	  //position:absolute;
    //    	  //right:1.6em;
    //    	  //font-size: 0.6em;

    //       .sprint-date {
  	// 	      //height: $swimLanHeaderHeight;
  	// 	      line-height: calc(#{$swimLanHeaderHeight} +  4px);
  	// 	    }
	  //   }
	  */

        .sprint-date-div {
			// todo#37832: patch for sprint date and count info render. [Benx 2018-08-23 19:29:24]
        	display: inline-block;
        	text-align: right;
        	// padding-right: 5px;
        	// line-height: normal !important;

        	&.small {
        		transform: scale(0.76);
        		-webkit-transform: scale(0.76);
        		// flex-grow: 1;
			}

			.sprint-date {
        		//height: $swimLanHeaderHeight;
				line-height: calc(#{$swimLanHeaderHeight} + 4px);

				// todo#37832: patch for sprint date and count info render. [Benx 2018-08-23 19:29:24]
				// HACK: 一整個很怪～ 使用 inline-flex 和 flew-glow 變得非常奇怪
				// 不知道為何要設定 min-width 90px，不這麼做， sprint header 上面的日期和 count 會跑到怪怪的位置。
				// TODO: 要找時間了解一下， flex 到底在搞啥... @@
				width: fit-content;
				width: -moz-fit-content;
				display: inline-block;
				padding-right: 3px;
			}

			.sprint-todo-counts {
				font-size: 125%;
				font-weight: bold;
			}
        }
    }

    .dd-list {
      &.scrollTinyBar {
        @include scrollTinyBar($_mainColor, $barSize);
        @include xsShowScroll();
      }
    }
  }

}

.sprint-manage-div{
  $_mainColor: $main-background-color;

  .filter-toggle{
    width:auto;
  }

  .otis-toggle:active {
    background: $backlog-page-title-active;
  }

  &.open .otis-toggle ,.otis-toggle:hover {
    background: $backlog-page-title-toggle;
    color: $white;
  }
  .otis-toggle{
    .sprint-manage-select {
      flex:1 1 auto;
    }
    .icon-arrow{
      flex: 0 0 auto;
    }
  }
  .selected-phase-title {
    display:inline-flex;
    align-items: center;
    .phase-name{
        max-width: 20em;
        vertical-align: inherit;
        white-space:normal;
        padding:0 3px;
        $_lineHeight: 1.3em;
        $_lines : 2;
        @include  multiline-ellipsis($_lineHeight, $_lines);
        flex:1 1 auto;
        white-space: nowrap;
        display: inline-block;
        @media #{$phone} {
          max-width: 160px;
        }
        @media #{$miniPhone} {
          max-width: 118px;
		  font-size: 12.8px;
		  padding: 0px;
        }
    }
	.sprint-date{
		flex: 0 0 auto;
		font-size: 10px;
	}
  }

  .sprint-view & {
	.selected-phase-title {

		@media #{$miniPhone} {
			max-width: 200px;

			.phase-name {
				max-width: 90px;
			}
		}

		@media #{$phone} {
			max-width: 250px;
		}

	}

  }

 .dropdown {
    .btn {
      //width: auto;
      height: 2em;
      line-height: 2em;
      padding: 0 0.5em;
      vertical-align: middle;
      font-size: 1em;
      margin-bottom: 0.2em;
    }
  }

  .add-sprint {
    margin:-12px;
    color: $addSprint-text-color;
    background: lighten($main-background-hover, 10%);
    text-align: center;
    &:hover {
      background-color:inherit;
    }
  }

  ul {
    .scrollTinyBar {
      @include scrollTinyBar($_mainColor, $barSize);
      @include xsShowScroll();
    }
    &.sprint-manage-body {
      $_dropdownOpenHeight: $swimLanHeight - 20vh;
      max-height: $_dropdownOpenHeight;
      width: 23em;
      // z-index:102; // bottom sidebar z-index is 100
      position: absolute;
      padding:0;

      &.show-closed-sprint-item {
        max-height: $swimLanHeight;
      }

      > li {
        background: $even-background-color;
        border-bottom: 1px solid lightgray;
        display: block;
        padding: 12px;
        @include menuDropdownSize();

        &.closed-sprint {
          $_mainColor: gray;
          background: lighten($_mainColor, 45%);
          color: $main-text;
          &.odd-background {
            background: lighten($_mainColor, 35%);

          }
          &:hover {
            background: lighten($_mainColor, 25%) !important;
            color: $main-text !important;
          }
          .sprint-block{
            color:inherit !important;
          }
        }

        &.odd-background {
          background: $odd-background-color;
        }

        &.selected {
          background: lighten($main-background-hover, 30%);
          color: $viewTitleTextColor !important;
          .sprint-block{
            color:$viewTitleTextColor !important;
          }
        }

        @include hover-supported() {
          background: lighten($main-background-hover, 20%);  // TODO: convert this to global var [Benx 2019-04-13 09:41:35]
          color: $viewTitleTextColor;
          cursor: pointer;

          .icon {
            color: white;
          }

          .sprint-block {
            color:$viewTitleTextColor;
          }

        }

        .sprint-block {
          line-height: 1.2em;
          color: $main-text;
          position: relative;

          &:hover {
            color: $viewTitleTextColor;
          }

         .sprint-dropdown-title{
            display:inline-block;
            width:calc(100% - 2em - 3px);
            @include smartBreakWord();
          }
          .sprint-date {
            font-size: 0.6em;
            @media #{$desktop-md-above}{
              fotn-size: 0.8em;
            }
          }

          i {
            position: absolute;
            right: 0;
            bottom: 0;
            padding: 2px 5px;
            @include hover-supported() {
              background: lighten($main-background-hover, 20%);  // TODO: convert this to global var [Benx 2019-04-13 09:41:35]
            }
          }

        }
      }


      @media #{$phone} {
        $_topOffset:  $navbarHeight + $viewTitleHeight ;
        width: 88vw;
        position: absolute;
        top: auto;
        left: 0;
        @include max-height-vh-patch("90vh - #{$_topOffset} - #{$sidebarIconHeight} ");

      }

    }
  }
  .show-closed-sprint {
    padding: 0 !important;
    background: gray !important;
    color: rgba(255,255,255,0.5);
    text-align: center;
    &:hover ,&.open {
      color: rgba(255,255,255,0.9) !important;
    }

  }
}

.viewTitle-icon-margin{
   margin-left:15px;
   @media #{$phone}{
     margin-left:10px;
   }
   @media #{$miniPhone}{
    margin-left:10px!important;
  }
}

@media #{$phone} {
  .backlog-panel {
    .sprint-div .dd-list-header .sprint-title-dropdown-icon {
      margin-right:auto !important; // for ie
      margin-right: initial !important;
    }
    .sprint-manage-div {
      margin-left: auto !important; // for ie
      margin-left: initial !important;
      .dropdown {
        padding-right: auto !important; // for ie
        padding-right: initial;
      }
    }
  }

  .view-title {
    margin-top: auto !important; // for ie
    margin-top: initial !important;
  }
}

.disabled-element{

  .sprint-description-area,.sprint-description-content,.select-div .bootstrap-select{
    background-color:#efefef;
    color:gray;
  }
  .otis-input {
    input {
      border-color: transparent;
    }
  }
}

.dd-swimlane {

	.scroll-load-more-spinner {
		text-align: right;
		margin-bottom: 20px;
		padding-right: 15px;

		pop-spinner-bounce {
			.spinner > div {
				width: 8px;
				height: 8px;
				margin: 2px;
				background-color: gray;
			}
		}

	}


}

.todo-content-filter,
pop-quick-filter-input-widget,
pop-todo-filter-widgets {

	input.ng-not-empty {
		border-color: gold;
	}
}

pop-todo-filter-widgets {
  .todo-content-filter {
    input {
      border-color: $todo-content-filter-input;
    }
  }
}