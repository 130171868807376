/* discussion.scss */
.discussion-panel{
	@include height-vh-patch("100vh - #{$navbarHeight}");
	line-height:initial;
	@media #{$phone}{
		// @include height-vh-patch("100vh - #{$navbarHeight} - #{$sidebarIconHeight}");
		@include height-vh-patch("100vh - #{$navbarHeight} - 35.2px");

		// height: calc( 100% - #{$sidebarIconHeight} );
	}
}

.discussion-title{
	margin-top: 1vh;
	@include mainTextSize();
	height: $swimLanHeaderHeight;
	line-height: $swimLanHeaderHeight;
	font-weight:bold;
}

.discussion-view-body{
	padding: 0px $panel-side-margin;
	height:calc(100% - #{$viewTitleHeight} - 10px);
	@media #{$phone}{
		padding:0 !important;
	}
}

.discussion-pane-left{
	padding-left:0;
	$background-color: $discussion-sel-topic-title;
	height:calc(100%);
	.discussion-topic-add{

		display:inline-flex;
		width:100%;
		margin-bottom:10px;
		@include mainTextSize();
		@media #{$phone}{
			margin: 0 10px 10px 10px
		}
		.form-group{
			margin:0;
			position: relative;
			 
			&.flex-input{
				flex-grow: 1;
				@media #{$phone}{
					flex-grow: 0;
					width: calc(100% - 60px);
				}
			}

			.form-control{
				padding: 6px 12px;
				font-size: 1.2em !important ;
				height: 40px;
				margin: auto;
				border-radius:8px 0px 0px 8px !important;
				@media #{$phone}{
					border-radius:0px;
				}

				&:focus {
					outline: 0;
					border-radius: 8px 0px 0px 8px;
					box-shadow: 0 0 0 1px #4d90fe !important;
				}
			}
		}
		.discussion-topic-add-button{
			height: 40px;
			width: 40px;
			border-radius:0px 8px 8px 0px !important;
			background: lighten($main-theme-color, 10%);
			border-color: lighten($main-theme-color, 20%);
			color: $white;
			@include mainTextSize();
			&:active  {
				color: $white;
			}
		}
		textarea.ng-not-empty {
			border-color: gold;
		}
	}

	.discussion-topic-list {
		list-style: none;
		padding: 0px;
		overflow-y: auto;
		overflow-x: hidden;

		.discussion-topic-entry{
			// background: $discussion-topic-odd-panel;
			background: #fff;
			border:1px solid rgba(211, 211, 211, 0.5);
			margin: 0px 10px 10px 0px;
			border-radius:8px;
			box-shadow: 0px 15px 10px -15px #111;
			cursor: default;
			position:relative;
			width: 100%; 
			@include mainTextSize();
			word-break:break-word;
			@media #{$phone}{
				margin:0 !important;
				border-bottom: 1px solid lightgray;
				border-radius:0px;
			}
			&.not-loaded{
				display:none;
			}
			&.loaded{
				display:block;
			}

			.discussion-topic-title-div{
				padding: 15px 20px 5px 20px;
				display: flex;
				flex-direction: column;
				cursor: pointer;
				.discussion-topic-header {
					display: flex;
					align-items: flex-start;
					.discussion-topic-info {
						width: 10%;
						display: flex;
						align-items: center;
						flex-direction: row-reverse;
						position: relative;
						padding-right: 16px;
					}
				}
				.discussion-topic-body {
					margin-top: 10px;
					margin-right: 15px;
					padding: 5px 25px;
					.discussion-latest-message {
						display: flex;
						align-items: center;
						$_lineHeight:1.3em;
						$_lines: 3;
						font-style: italic;
						@include multiline-ellipsis($_lineHeight, $_lines);
						i {
							padding-right: 8px;
						}
						.latest-message-user {
							font-weight: bold;
						}
					}
				}
			}
			.discussion-topic-title{
				font-weight: bold;
				width: 100%;
				$_lineHeight:1.3em;
				$_lines: 2;
				@include multiline-ellipsis($_lineHeight, $_lines);
				i {
					font-size: 115%;
					margin-right: 5px;
					position: absolute;
					top: 14px;
				}
				.discussion-topic-title-content {
					padding-left: 25px;
				}
			}
			.discussion-pin{
				margin-left: 8px;
				transform: rotate(45deg);
				width: 18px;
				height: 18px;
				padding-left: 2px;
				padding-top: 2px;
				line-height:15px;
				position: absolute;
				top: 0;
			}
			.discussion-pin--sticky, .discussion-pin:hover {
				color:  red;
				transform: rotate(0deg);
				background:rgba(255,255,255,0.9);
				border-radius:50%;
			}


			.recent-bell{
				// right: 24px !important;
				// top: auto !important; // for ie
				// top: initial !important;
				// margin-top: 6px !important;
				// position: relative !important;
			}

			// &.even-background{
			// 	background: $discussion-topic-event-panel;
			// }

			.discussion-infos {
				padding: 5px 20px 15px 20px;
				min-height: $h4;
				opacity: 0.6;

				.timestamps {
					color: gray;
					@include tinyTextSize();
					vertical-align: top;
					margin-top:1px;
					display:inline-block;
				}
				.last-reply{
					color: #333;
					opacity: 1;
				}

			}

			&.selected{
				.timestamps{
					color: $discussion-panel-selected-color
				}
				background-color: $discussion-panel-selected-background;
				color: $discussion-panel-selected-color;

				@include hover-supported() {
					background-color: $discussion-panel-selected-background;
				}
			}

			@include hover-supported() {
				background-color: $cold-gary;
				cursor: pointer;
			}

			&:hover {
				background-color: $discussion-panel-background-hover;
				color: $discussion-panel-color-hover;
			}

			&.selected{
				.timestamps{
					color: $discussion-panel-selected-color;
				}
				.last-reply{
					color: $discussion-panel-selected-color;
				}
				background-color: $discussion-panel-selected-background;
				color: $discussion-panel-selected-color;
			}

		}

	}

}


.discussion-pane-right{
	$_selTopicHeight:40px;
	$_ddListBottomHeight:58px;
	padding-right:5px ;
	height: calc(100%);
	@media #{$phone}{
		padding-right: 0px !important;
		height: calc(100% + 40px);
	}
	// $_chatBackgroundColor:  $discussionChatColor;
	// todo47930: 在專案討論區，使用Theme Style: Light，看不見Reply的邊線。 
	// note: 顏色先跟theme style dark 採用的顏色一樣，之後會請設計師重新配色，with Tomas discuss。[Jane 2018-12-27 17:25:02]
	$_chatBackgroundColor:  #f0f8ff;
	@include chatLeft($_chatBackgroundColor);
	.media-body{
		@include mainTextSize();
		overflow:initial;
		overflow:visible;
		word-break: break-word;
		&.ng-hide-animate {
			display: none !important;
			opacity: 0 !important;
		}
		.comment-editor-bar{
			display:hidden;
		}
		&:hover{
			.comment-editor-bar{
				display:inline-block;
			}
		}


		//chat left

	}

	.discussion-detail{
		padding: 20px 10px;
		$_commentEditorHeight: 100px;
		height:calc(100%  - 100px - 40px );
		//padding-bottom: 150px;
		@media #{$phone}{
			height:calc(100%   - 40px  - 60px  );
			//padding-bottom: 100px;
		}
		//margin-top:10px;
		//background: rgba(255,255,255,0.8);

		$_height: 100px;
		@include overflowBottomSpace($_height);

		textarea {
			@include mainTextSize();
		}

	}

	.reply-area{
		padding-right:1em;
		padding-left:1em;
		//padding-bottom:5em;

		.info-assignment{
			color:$main-text !important;
		}
		.attachment{
			padding-left: 0px;
			padding-right:10px;
		}
		.file-uploader{
			display: none;
		}
		.marked-text-html{
			@include smartBreakWord();
		}
	}

	.editing-comments{
		// width:calc(100% - #{$generalMemberHeight} - 13px) !important;
		width: 100%;
		display:inline-block;
		background: lighten($comment-editor-background, 10%);
		border-radius:8px;
		&.ng-hide-animate {
			display: none !important;
			opacity: 0 !important;
		}
		.attached-file-list{
			color:white;
		}
		.file-uploader-span{
			color:darken(whitesmoke, 10%);
		}
	}

	.discussion-sel-topic-title{
		// background: $discussion-sel-topic-title;
		// border-left: $discussionTopicTitleWidth solid $discussion-sel-topic-title-left;
		background: lighten($main-theme-color, 10%);
		border-left: $discussionTopicTitleWidth solid $main-theme-color;
		min-height: $swimLanHeaderHeight;
		border-radius:8px;
		box-shadow: 0 5px 5px -5px #333;
		@media #{$phone}{
			border-radius: 0px;
			box-shadow: initial;
		}

		width:100%;
		//display:table;
		.sel-topic-div{
			display:block;
			width: 100%;
			height: $_selTopicHeight;
			//vertical-align: middle;
			position:relative;
			.sel-topic-icon {
				position: absolute;
				transform: translate(-26px, 0);
				font-size: 18px;
				color: #fff;
				@media #{$phone}{
					display: none;
				}
			}
			.sel-topic{
				.sel-complete-topic, .sel-abstract-topic {
					opacity: 1;
					&.sel-topic-hidden {
						opacity: 0;
					}
				}
				.sel-abstract-topic {
					margin-top: 40px;
					// position: relative;
					width: 70%;
					$_lineHeight:1.2em;
					$_lines: 1;
					position: absolute;
					top: -30px;
					cursor: pointer;
					@include multiline-ellipsis($_lineHeight, $_lines);
					.sel-abstract-topic-content {
						display: block;
						width: 80%;
						// position: relative;
						.sel-abstract-topic-append-more {
							position: absolute;
							cursor: pointer;
							right: 14%;
							bottom: 0;
							z-index: 60;
							@media #{$phone}{
								right: 4%;
							}
						}
					}
				}

				width: 100%;
				padding: 2px 0px 0px 6px;
				overflow: hidden;
				@include mainTextSize();
				margin:0;
				// color:$viewTitleTextColor;
				color:#fff;

				@include smartBreakWord();
				pop-comment-text{
					padding: 6px 0px 0px 6px ; //todo#40867:ui issue in project discusison [Jane 2018-10-01]
					display:block;
					p {
						line-height:1.2em;
						height: 2.4em;
						margin-bottom: 0;
						width: 95%;
					}

				}
				.overWording{
					max-height: 50vh;
				}
				a{
					color: inherit;
				}

				.more-link{
					position: absolute;
					top: 8px;
					right: 30px;
					color: #333;
				}

				.edit-icon{
					right:6px;
					top: 6px;
					position:absolute;
					font-size: inherit;
				}
				&.topic-expanded {
						max-height: 500px;
						overflow: auto;
					}

			}
		}
		.form-group{
			margin-bottom:3px !important;
		}
		.selTopic-create-info {
			font-size: 80%;
			text-align: right;
			position: absolute;
			right: 30px;
			// color: $viewTitleTextColor;
			color: #fff;
			@media #{$phone}{
				display: none;
			}
		}

	}

}
.discussion-sel-topic-full-text{
	$_pageRightPadding: 15px;
	padding:6px 10px;
	width: calc(100% - #{$discussionTopicTitleWidth} - #{$_pageRightPadding});
	position: absolute;
	background: $discussion-sel-topic-title;
	right: 0px;
	top: 0px;
	z-index: 80;
	color: $white;
	@media #{$phone}{
		background: whitesmoke;
		color:$main-text !important;
		box-shadow: rgb(149 157 165 / 80%) 0px 8px 24px;
		top: 40px;
	}
	.edit-icon {
		margin-left: 6px;
	}
}

.discussion-sel-topic-editor{
	$_pageRightPadding: 15px;
	width: 100%;
	position: absolute;
	/* left: 60px; */
	width: calc(100% - #{$discussionTopicTitleWidth} - #{$_pageRightPadding});
	top: 0;
	right:0px;
	border-radius: 8px;
	background: $discussion-sel-topic-title;
	padding: 10px;
	z-index: 100;

	@media #{$phone}{
		width: calc( 100% - #{$swimLanHeaderHeight} * 1.1 );
		background: whitesmoke;
		color:$main-text !important;
		border-radius:0px;
		.btn-link {
			color: $main-text !important;
			&:hover{
				color:$main-text !important;
			}
		}
	}
	button {
		color: whitesmoke;
		&:hover{
			color:white;
		}
	}
	h1{
		margin-top:16px;
	}
}

.project-comments-title{
	$_lineHeight:1.2em;
	$_lines: 3;
	@include multiline-ellipsis($_lineHeight, $_lines);
}

.topic-expanded{
	$_lineHeight:1.2em;
	$_lines: 20;
	@include multiline-ellipsis($_lineHeight, $_lines);
}

.media{
	.memberItems{
		height: $generalMemberHeight;
		display: table-cell;
		vertical-align:middle;
	}
	.memberItems.member-icon{
		margin:0;
	}
	.reply-member{
		padding-right: 5px;
	}
}

.comment-entry{
	text-align:left;
	
	.attached-file-list .media-zone {
		background-color: initial;
	}
	
	h4.attachment-header {
		margin-top: 20px;
		margin-bottom: 0px;
	}

	.comment-text .comment-loop-users {
		// margin-top: -6px;
		margin-bottom: 3px;
	}

	.comments-reply-list {
		.comment-text .comment-loop-users {
			margin-top: 0px;
		}
	}
	
	.comment-text{
		// display:inline-block;
		// text-align: left;
		// width: 100%;
		// color:rgb(50,50,50);
		//color:white;
		//border: 1px solid lightgray;
		// @include mainTextSize();
		// position: relative;
		// padding: 10px;
		// padding-bottom: 0px;
		// pop-comment-text{
		// 	white-space:pre-wrap; //remove it for markdone in
		// 	@include smartBreakWord();
			
		// 	.loop-user-name {
		// 		// background: beige;
		// 		display: inline-block;
		// 		padding: 0px 4px;
		// 		border-radius: 3px;
		// 		font-style: italic;
		// 		.loop-symbol {
		// 			color: gray;
		// 		}
		// 	}
		// }
		
		// .comment-editor-bar{
		// 	position:absolute;
		// 	top: 0px;
		// 	right: 0px;
		// 	.reportTag{
		// 		border-top-right-radius: 4px;
		// 		border-bottom-left-radius: 0px;
		// 		$_angleWidth: 8px;
		// 		$_bkColor:$discussionColor ;
		// 		margin-left:6px;
		// 	}
		// 	.editTag{
		// 		margin-left:6px
		// 	}

		// }

		a{
			color:$commentsHyperlinkColor;
		}

		.comment-edit-icon{
			a{
				color:white !important;
			}
		}
		.mark-report{
			font-weight:400;
			&:hover{
				background: rgba(0,0,0,0.8);
				color: white;
			}
			&:active{
				background: gold;
				color: $main-text;
			}
		}

		&.selected {
			//border: 6px solid gold !important; // to shinne, remove it make screen simple, by smith sun @ 20170921
			//background: darken($discussionColor, 10%);
		}
	}

	.deleteTag{
		//margin-left:6px;
	}
	.timestamps-div{
		position: absolute;
		top: 5px;
		left: 15px;
		font-size: 12px;
		.timestamps{
			color: rgba(37,37,37,0.8);
		}

		.recent-bell{
			// position:relative !important;
			// right:  auto !important; // for ie
			// right:initial !important;
			// margin-top: auto !important; // for ie
			// margin-top: initial !important;
			// margin-left:-0.5em!important;
		}
	}


}


.reply-comments{
	$_chatBackgroundColor:white;
	@include chatLeft($_chatBackgroundColor);
	.comment-editor{
		margin-top:3px;
		border-radius:6px;
		padding:5px;
		background:rgba(50,50,50,0.5);
		.btn-link{
			color:white;
		}
		.panel-footer{
				.info-rule{
						color: white;
						.info-assigment {
							color:white;
						}

						.info-assignment-select {
						color:white;
						button{
							//border-color:gray !important;
						}
						.filter-option{
							color:white;
						}
						}
					&.on-editing {

					}
				}
			}
		}

	}

	.reportTag{
		&.mark-report{.reportTag{

				border-top-right-radius: 4px;
				border-bottom-left-radius: 0px;


				$_angleWidth: 8px;
				$_bkColor:$discussionColor ;

			}
			.editTag{
				position: absolute;
				top: 0px;
				right: 90px;
			}
			.deleteTag{
				position:absolute;
				top:0px;
				right:66px;
			}
			$_angleWidth: 8px;
			$_bkColor:white ;
		}
	}
	.media{
		&.comment-entry{
			// margin-left: $generalMemberHeight + 5px;
			margin-top: 16px;
			display: flex;
			&.last-comments{
				padding-bottom:0px;
				@media #{$phone}{
					padding-bottom: 40px !important;
				}
			}
			.reply-member{
					// float:right !important;
					// padding-right: 0 !important;
					// padding-left:10px !important;
				}
			.memberItems.member-icon{
				float:initial !important;
				margin:0;
				border-radius:50%; // to make replayer circule view , diff from the creater
			}
			.media-body{
				&.ng-hide-animate {
						display: none !important;
						opacity: 0 !important;
					}
				.comment-text{
					margin:0;
					//background:white !important;
					color:rgb(50,50,50) !important;
					a{
						color:steelblue !important;
					}

				}
				.timestamps {
					font-size: 0.88em;
				}
				.comment-edit-icon {
					color:$main-text !important;
					.editor-icon{
						color:inherit !important;
					}
				}
			}

		}
	}

.comment-header{
	display: flex;
	float:right;
	justify-content:space-between;
	align-items:center;
	position: relative;
}

.comments-reply-item .comment-tools.dropdown {
	margin-left: 7px;
	margin-right: 3px;
	display: none;
	@media #{$phone}{
		display: inline-block;
	} 
	&:hover, &.dropdown.open {
		background-color: $darkenMDBackground;
		color: $white;
		border-radius: 2px;
	}
	a {
		&:hover {
			color: $white;
		}
	}
}

.comment-tools{
	@include unSelectable();
	cursor: pointer;
	text-align: right;

	.comment-timestamps {	// 修正桌機瀏覽器對齊不正的問題 [Benx 2019-04-08 05:26:32]
		position: relative;
		top: -2.5px;
		font-weight: bold;

		.timeAgo.date {
			font-weight: bold;
		}

		@media #{$phone}{
			top: 0px;
		} 
	}

	.selectOption-icon {
		background: transparent;
		box-shadow: none;
	}
}
.comment-timestamps{
	font-size: 12px;
	color: $discussionTimeColor;
	font-style: italic;
	cursor: initial;
}
.comment-iconBtn{
	padding:3px;
	color: inherit;
	@include unSelectable();
	&:hover{
		text-decoration: none;
	}
}
.comment-set-report{
	@include unSelectable();
}
.comment-optionMenu{
	padding:5px 10px;
	color: #000;
	&:hover{
		background: rgba(0,0,0,0.8);
		color: white;
	}
	&:active{
		background: gold;
		color: $main-text;
	}
}
.reply-comments .comment-report{
	display: none;
}

.comment-editor{
	width:100%;
	display:inline-block; 
	min-height:50px;
	line-height: initial;
	$_commentsBtnWidth :32px;
	$_commentRowHeight: 32px;

	@media #{$phone} {
		.CodeMirror, .editor-toolbar {
			display: none;
		}
	}
	.comment-editor--mobile {
		display: none;
		overflow: hidden !important;
		@media #{$phone} {
			display: inline-block;
		}
	}

	.comments-btn{
		$background-color: $discussionBtnColor;
		margin:0 ;
		border-radius: 0;
		color:white;
		border: 0;
		box-shadow: initial;
		display: inline-block;
		text-align: center;

		&.add-comments{
			transform: scale(1.2);
			background: $add-comment-btn;
			padding: 0px 10px;
			margin-top: -8px;
			margin-right: 6px;
			font-size: 18px;
			height:1.3em;
			line-height:1.3em;
			border:1px solid $add-comment-btn !important;
			&:hover{
				background: $add-comment-btn;
			}

			&:active{
				background: $add-comment-btn;
				border : 1px solid $add-comment-btn;
			}
		}
		&.file-upload{
			display: none;
			background: transparent;
			width:$_commentsBtnWidth;
			height:$_commentsBtnWidth;
			position:absolute;
			top:$_commentsBtnWidth * 0.15;
			padding: 0 ;
			$_color: $warm;
			color: gray;
			font-size: $_commentsBtnWidth * 0.7 !important;

			@media #{$phone} {
				color: #bbb;
				display: block;
			}


			&:hover{
				//color: lighten($background-color, 0%);
				color:lighten(gray, 30%);
			}

			&:active{
				//color: darken($background-color, 5%);
				color:white;
				//border : 1px solid  lighten($background-color, 50%);
			}
			input{
				cursor:pointer !important;
				@media #{$phone}{
					display: none;
				}
			}
		}
	}


	.panel-header{
		flex-grow:1;
		height:initial;
		position:relative;
		padding-bottom:6px;
		.file-upload{
			position:absolute;
			margin-left:3px;
			cursor:pointer;
			z-index:50;  // comments is 50, my notice is 90, sidebar is 100, bell is 50
			border:none;
			input{
				cursor:pointer;
			}
		}
	}
	textarea{
		width:100%;
		height: $_commentRowHeight;
		padding-left: calc( #{$_commentsBtnWidth} + 3px);
		overflow-x:hidden;
		border-radius: 8px;
	}

	.panel-footer{
		padding:2px 0px;
		padding-top:6px;
		height: $_commentRowHeight;
		width: $_commentsBtnWidth;
		border:0;
		background: transparent;
		display:inline-flex;
		width:100%;
		display: flex;
		align-items: center;
		@media #{$phone}{
			align-items: baseline;
		}
		i{
			overflow:hidden;
			width: $_commentRowHeight * 0.8;
			text-align:center;
		}
		.addComments-div{
			display:inline-block;
			position:relative;
			//width:50%;
			flex-grow:1;
			.cancelComments{
				.btn{
					margin-top:-5px;
					font-size:12px;
				}
			}
		}
		.info-rule{
			display:inline-block;
			min-width:250px;
			//width: 100%;
			flex-grow:1;

			@include mainTextSize();

			.info-assignment{
				display:inline-block;
				overflow:hidden;
				 font-weight: initial;
				.info-assignment-title{
					padding: 0 5px;
					display:inline-block;
				}
				   .info-assignment-select{
					 margin-right:0;
					 width: 160px;
				   }
				button{
					 padding: 0px 6px !important;
					line-height: 1.5  !important;
					font-size: 1em  !important;

				}
			}
			.info-self{
				flex-grow:1;
				text-align: right;
			width:9em;
			}
		}

	}

}

.info-assignment-title{
	color: #fff;
}

.panel-end{

}

.media{
	overflow:initial;
	overflow:visible; // for ie
	.media-body{
		overflow:initial;
		overflow:visible; // for ie

	}
}
.replyNEditShow{
	display:none;
}

@media #{$phone}{
	.xs-hide{
		display:none !important;
	}
	.discussion-pane-right{
		padding:0  !important;
		.discussion-detail {
	   		   padding: 0.5em 0 !important;
	   	}
	}
	.info-self{
		display:none !important;
	}

	.reply-comments{
		.comment-editor{
			margin-top:15px !important;
		}
		width:100%;
	}

	.editing-comments{
		display:inline-block;
		width:calc(100% - #{$generalMemberHeight} - 13px) !important;
		.info-assignment{
			color:$main-text;
		}
	}

	.reply-comments,.editing-comments{
		position: initial !important;
		position: relative !important;
		bottom: normal !important;
   		bottom: initial !important;
		z-index: initial !important;
  		width: 100%;
		.replyNEditHidden{
			display:none !important;
		}
		.comment-editor{
			//margin-top:15px;
			.panel-header{
				//background: lighten($todoCommentEditorBackground, 10%);
				border:1px solid lightgray !important;
				background: white;
				//margin-top:5px;
				textarea {
					width: calc(100%) !important;
					//background: lighten($todoCommentEditorBackground, 30%);
					background:transparent;
					padding-left:40px;
					border:none !important;
					line-height:1.2em;
					$_color: $main-text;
					@include placeholderColor($_color);

				}
				.comments-btn{
					$_height: 28px;
					&.file-upload {
						height: $_height !important;
						line-height: $_height !important;
						width: initial !important;
						margin-left:10px !important;

						.fileupload-icon{
							color: $main-text !important;
						}
					}
				}
				.addComments{
					position:absolute;
					right:0;
				}
			}
			.replyNEditShow{
				display:block;
				.cancelComments {
					position:absolute;
					top:-0.3em;
					right:-0.5em;
				}
				.addComments{
					position:absolute;
					bottom:8px;
					right:3px;
				}
			}

			.panel-footer{

				.addComments{
					display:inline !important;
					position: absolute;
					right: 0px;
					top: 5px;
				}




			}
		}

	}

	.tab-footer,.file-comments-editor,.discussion-comments-editor,.sprint-comments-editor-section{

		.comment-editor{
			display:block !important;
			height:initial !important;
			line-height:initial !important;

			.panel-header{
				padding: 0 !important;
				$_sideMargin: 40px;
				textarea {
					width: calc(100vw - #{$_sideMargin} * 2) !important;
					margin-top:5px;
					margin-bottom: 5px;
					margin-left:$_sideMargin;
					margin-right:$_sideMargin;
					min-height:50px;
					height: 50px;
					background: lighten($todoCommentEditorBackground, 10%);
					padding-left:6px;
					color: white;
					border: none;
					$_color: white;
					@include placeholderColor($_color);

				}
				.file-upload{
					top: auto !important; // for ie
					top: initial !important;
					bottom: 12px !important;
					border:none;
				}
			}

			.panel-footer{
				display:inline !important;
				height: auto !important;
				padding:0 !important;
				position:relative !important;
				line-height: auto !important;
				float:right;
				.addComments-div{
					margin-right: 5px;
					margin-top:-45px;
					float:right;
					.cancelComments{
						position: absolute;
						top: -8px;
						right: 0px;
					}
				}
				.addComments{
					display:inline !important;
					position: absolute;
					right: 0px;
					top: 0.5em;
				}
				.comments-btn{
					.icon-send {
						@media #{$phone}{ 
							font-size: 24px !important;
						}
					}
					$_height: 28px;
					width: 30px !important;
					height: $_height !important;
					line-height:$_height + 3px !important;
					margin-right: 0px !important;
					padding: 0px !important;
					background: transparent !important;
					color: white !important;
					position: absolute;
					right: 0px;
					top: 4px;
					border:none !important;
					&.file-upload {
						height: $_height !important;
						line-height: $_height !important;
						width: initial !important;

					}
					i{
							height:initial !important;
							line-height:initial !important;
							@include bigIconSize();
					}
				}

			}
		}
	}
	.panel-end{
			.info-rule{
				width: 50%  !important;
			}
	}

	.discussion-panel {
		padding-right: initial !important;
		padding-left: initial !important;
		.discussion-title {
				padding: 0 10px !important;
			}
			.discussion-pane-left {
				padding-right: 0 !important;
			}
			.discussion-pane-right {
				.back-to-discussion-title {
					display: block;
				}
			}
		}
}

.discussion-pane-right {
	position: relative;
	overflow: hidden;

	.discussion-detail {
		overflow: auto;
		@include smartBreakWord();
		.comments-reply-list .comments-reply-item {
			background: $comment-reply-background;  
		}
	}

	.discussion-comments-editor {
		background: $comment-editor-background;
		$_pagePadding:10px;
		padding:5px;
		position:relative;
		width: calc(100% - #{$_pagePadding} * 2);
		border-radius:8px;
		position:absolute;
		bottom:0;
		z-index:200;
		@media #{$phone}{
			padding: 0px !important;
			width: 100% !important;
			display:block;
			bottom: 0px !important;
			border-radius: 0px;
		}
		.info-rule {
			color: $discussion-comment-editor-info !important;
		}
	}
}

.selected-chat, .selected-chat:before {
	transition: background-color 2s ease-out;
	-webkit-transition: background-color 2s ease-out;
	-moz-transition: background-color 2s ease-out;
	-o-transition: background-color 2s ease-out;
}

.selected-chat{	
	&.selected-chat-add {
		$_selectedChatFlashColor: #c9e8a6; //lightgoldenrodyellow;
		background-color: $_selectedChatFlashColor !important;
		&.chat-left{
			&:before{
				background-color: $_selectedChatFlashColor !important;
			}
		}
	}
	
}

.discussion-topic-list {
	height: calc(100% - 50px);
}

.discussion-tab-bar {
	display: flex;
	align-items: center;
	.tab-item {
		color: $white;
		background-color: $main-theme-color;
		display: flex;
		align-items: center;
		height: 26px;
		font-size: 14px;
		padding-left: 9px;
		padding-right: 9px;
		&:first-of-type  {
			border-radius: 5px 0 0 5px;
		}
		&:last-of-type {
			border-radius: 0 5px 5px 0;
		}
		&:hover {
			background-color: darken($main-theme-color, 10%);
			text-decoration: none;
		}
		.label {
			font-size: 100%; // over-ride bootstrap
			font-weight: normal;
		}
		&.selected {
			background-color: darken($main-theme-color, 20%);
			.label {
				font-weight: bold;
			}
		}
	}
	.add-topic-btn {
		margin-left: 20px;
	}
}

.comment-text {
	display: flex;
	flex-direction: column;
	@include mainTextSize();
	padding: 10px 5px 10px 20px;
	.comment-tools {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 4px;
		.label-comment {
			margin-top: 0;
			margin-right: 0;
		}
	}
	pop-comment-text{
		@include smartBreakWord();
		// white-space:pre-wrap; //remove it for markdone in
		// .loop-user-name {
		// 	// background: beige;
		// 	display: inline-block;
		// 	padding: 0px 4px;
		// 	border-radius: 3px;
		// 	font-style: italic;
		// 	.loop-symbol {
		// 		color: gray;
		// 	}
		// }
	}
}

.comment-entry {
	display: inline-flex;
	width: 100%;
}
.comment-footer {
	display: flex;
	flex-direction: column;
	.comment-reply-activity {
		display: flex;
		justify-content: flex-end;
		padding: 5px 15px 5px 10px;
		.icon { 
			color: $commentReplyIconColor; 
			font-size: 14px;
		}
		&.comment-reply-pan {
			justify-content: space-between;
		}
	}
	.comments-reply-list {
		.comments-reply-item {
			display: flex;
			flex-direction: column;
			.reply-item-container {
				display: flex;
				flex-direction: flex;
				.replay-item-main {
					width: 100%;
					display: flex;
					flex-direction: column;
					position: relative;
					.reply-item-header {
						position: absolute;
						display: flex;
						width: 100%;
						justify-content: space-between;
						font-weight: bold;
						.reply-postedBy {
							color: $commentReplyIconColor; 
							font-size: 115%;
							margin-right: 8px;
							.timeAgo {
								&.date {
									font-size: 110%;
								}
							}
						}
					}
					.comment-text {
						margin-top: 24px;
						width: 95%;
					}
					.reply-button {
						position: absolute;
						bottom: 8px;
						right: 8px;
						display: none;
						@media #{$phone} {
							display: block;
						}
					}
					.reply-item-footer { 
						display: flex;
						flex-direction: column;
						width: 100%; 		
						color: #2e7e99;
						margin: 0px;
						padding: 0px 7px;
						.reply-button {
							cursor: pointer;
							display: flex;
							justify-content: flex-end;
						}
					}
				}
			}
			&:hover {
				.comment-tools {
					display: block !important;
				}
				.reply-button {
					display: block !important;
				}
			}
		}
	}
}

.discussion-recent-bell{
	margin-left: 8px;
	cursor:pointer;
	cursor:hand;
	&.today{
		background: red !important;
	}
	&.inThreeDays{
		background: orange !important;
	}
}

.discussion-sort-date-btn {
	font-size: 80%;
	.btn-link {
		color: $viewTitleTextColor !important;
	}
	@media #{$phone}{
		display: none;
	}
}

.discussion-toolbar--mobile {
	display: flex;
    align-items: center;
    justify-content: space-between;
	height: 40px;
	position: relative;
	.icon-arrow-left {
		display: flex;
		width: 22px;
		height: 22px;
		border: 1px solid;
		border-radius: 50%;
		align-items: center;
		justify-content: center;
		margin-left: 8px;
	}
	.category-icon {
		position: absolute;
		font-size: 20px !important;
		top: 48px;
		left: 10px;
		color: white;
	}
}

.discussion-sort-date-btn--mobile {
	display: flex;
	justify-content: flex-end;
	height: 40px;
}

.no-filtered-topic-container {
	position: absolute;
	display: flex;
	flex-direction: column;
	align-items: center;
	color: rgba(211,211,211,.5);
	left: 50%;
    top: 20vh;
    transform: translate(-50%, 0);
	.icon-filter-big {
		font-size: 10em;
		margin-top: 25px;
	}
	.no-filtered-topic-placeholder {
		font-size: 3em;
		font-weight: bold;
	}
}

.no-comment-container {
	position: absolute;
	display: flex;
	width: 100%;
	flex-direction: column;
	align-items: center;
	color: rgba(211,211,211,.5);
	left: 50%;
    top: 10vh;
    transform: translate(-50%, 0);
	.icon-discuss {
		font-size: 10em;
		margin-top: 25px;
	}
	.no-comment-placeholder {
		font-size: 3em;
		font-weight: bold;
		text-align: center;
	}
}

.editing-comments {
	.cmt-editor-btn {
		color: lighten($cmt-editor-btn-color, 20%);
	}
	.info-assignment-title{
		// color: $edit-comment-color;
	}
}

.comments-reply-item {
	.editing-comments {
		@media #{$phone}{ 
			width: 100% !important;
		}
		.cmt-editor-btn {
			color: #333;
		}
		.info-assignment-title{
			color: #333;
		}
	}
}

pop-todo-detail-modal, pop-sprint-detail-modal, pop-file-detail-info {
	.info-assignment {
		.info-assignment-title {
			color: $white;
		}
	}

	.editing-comments {
		.info-assignment-title, .cmt-editor-btn{
			color: $modal-editing-cmt-info-color;
		}
	}
}

.discussion-topic-bar {
	display: flex;
	justify-content: space-between;
	.topic-bar-main {
		display: flex;
		@media #{$phone}{
		justify-content: space-between;
		width: 100%;
		}
	}
	.discussion-topic-left-div {
		display: flex;
	}
	.view-page-header {
		display: flex;
    	align-items: center;
	}
	.mobile-add-topic-btn {
		margin-top: 2px;
		font-size: 18px;
	}
	.discussion-category-dropdown {
		.discussion-category-dropdown-btn {
			width: auto;
			padding: 5px;
			height: initial;
			line-height: initial;
			background: transparent;
			text-align: right;
			padding-right: 24px;
			&.otis-toggle:hover:hover {
				box-shadow: none;
			}
		}
		.dropdown-arrow {
			position: absolute;
			right: .1em;
			top: .8em;
		}
		.dropdown-menu {
			top: 40px;
			min-width: 120px;
			li {
				a {
					padding: 3px 18px;
				}
			}
		}
	}
}