$panel-header-border-radius: 5px 5px 0px 0px ;
$panel-footer-border-radius: 0px 0px 5px 5px ;

/* overWrite */
.thumbnail{
	margin-bottom:0 !important;
}

blockquote {
	font-size: 0.9em;
	border-left: 3px solid #ccc;
}

.header-text{
	@include h1TextSize();
	font-weight: 600;
}

.header-text-h2{
	@include headerTexth2();
	//font-weight: 600;
}


.header-text-h3{
	@include headerTexth3();
	//font-weight: 600;
}


.datepicker table tr td, .datepicker table tr th{
	border-radius:0;
}

textarea {
	@include h2TextSize();
	border-radius: 0;
	-webkit-appearance: none;
}

textarea[pop-auto-height] {
	min-height: 40px;
	max-height: 30vh;
	resize: none;
}

input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 1000px lighten($warm, 60%) inset !important;
}

.input-checkbox-lg{
	font-size:1.2em;
	input[type=checkbox], input[type=radio] {
		width: 1.2em;
		height: 1.2em;
		margin-right:0.2em;
		margin-top:0;
	}
}

input[type=checkbox], input[type=radio] {
    width: 1em;
    height: 1em;
}

input {
	@include mainTextSize();
	border-radius:0 !important;
	// -webkit-appearance: none;
	&.ng-dirty.ng-invalid, .invalid-input-message{
		color:red !important;
	}
	padding:6px;
}

input[type=color] {
	padding: 0;
}

ul.inline {
    margin-left: 0;
    list-style: none;
}

ul.inline > li, ol.inline > li {
    display: inline-block;
    padding-right: 5px;
    padding-left: 5px;
}

label{
	@include unSelectable();
}

@media #{$phone}{
	.dropdown-menu-left{
		left:auto;
		right:0;
	}
}
.form-control{
	border-radius: 0;
	font-size:inherit !important;
	box-shadow:none !important;
}
.progress{
	height:16px;
	width:100%;
	background:lightgray;
	.progress-bar{
		height:16px;
		line-height:16px;
	}
}

.highcharts-tooltip{
	span{
		display:inline-block;
		white-space:normal !important;
	}
}

.file-view-panel{
	.badge{
		$_font-size:12px;
		$_padding: 3px;

		font-style: normal !important;
		font-style: initial !important;
		font-weight:200;
		padding: $_padding;
		font-size: $_font-size;
		@media #{$desktop-md-above}{
			font-size: 12px * 1.1;
		}
		min-width:  $_font-size + $_padding * 2;

	}
}


 h1{
 	font-size: $h1 !important;
 	@media #{$desktop-md-above}{
 		font-size: $h1 * 1.1 !important;
 	}
 }
 h2{
 	font-size: $h2 !important;
  	@media #{$desktop-md-above}{
 		font-size: $h2 * 1.2 !important;
 	}
 }
 h3{
 	font-size: $h3 !important;
 	@media #{$desktop-md-above}{
 		font-size: $h3 * 1.2 !important;
 	}
 }
 h4{
 	font-size: $h4 /* !important */;
 	@media #{$desktop-md-above}{
 		font-size: $h4 * 1.2 /* !important */;
 	}
 }
 h5{
 	font-size: $h5 !important;
 	@media #{$desktop-md-above}{
 		font-size: $h5 * 1.2 !important;
 	}
 }
 h6{
 	font-size: $h6 !important;
 	@media #{$desktop-md-above}{
 		font-size: $h6 * 1.2 !important;
 	}
 }
 h7{
 	font-size: $h7 !important;
 	@media #{$desktop-md-above}{
 		font-size: $h7 * 1.2 !important;
 	}
 }
 h8{
 	font-size: $h8 !important;
 	@media #{$desktop-md-above}{
 		font-size: $h8 * 1.2 !important;
 	}
 }
 h9{
 	font-size: $h9 !important;
 	@media #{$desktop-md-above}{
 		font-size: $h9 * 1.2 !important;
 	}
 }
 h10{
 	font-size: $h10 !important;
 	@media #{$desktop-md-above}{
 		font-size: $h10 * 1.2 !important;
 	}
 }
 .h1{
 	font-size: $h1 !important;
 	@media #{$desktop-md-above}{
 		font-size: $h1 * 1.1 !important;
 	}
 }
 .h2{
 	font-size: $h2 !important;
 	@media #{$desktop-md-above}{
 		font-size: $h2 * 1.1 !important;
 	}
 }
 .h3{
 	font-size: $h3 !important;
 	@media #{$desktop-md-above}{
 		font-size: $h3 * 1.2 !important;
 	}
 }
 .h4{
 	font-size: $h4 !important;
 	@media #{$desktop-md-above}{
 		font-size: $h4 * 1.2 !important;
 	}
 }
 .h5{
 	font-size: $h5 !important;
 	@media #{$desktop-md-above}{
 		font-size: $h5 * 1.2 !important;
 	}
 }
 .h6{
 	font-size: $h6 !important;
 	@media #{$desktop-md-above}{
 		font-size: $h6 * 1.2 !important;
 	}
 }
 .h7{
 	font-size: $h7 !important;
 	@media #{$desktop-md-above}{
 		font-size: $h7 * 1.2 !important;
 	}
 }
 .h8{
 	font-size: $h8 !important;
 	@media #{$desktop-md-above}{
 		font-size: $h8 * 1.2 !important;
 	}
 }
 .h9{
 	font-size: $h9 !important;
 	@media #{$desktop-md-above}{
 		font-size: $h9 * 1.2 !important;
 	}
 }
 .h10{
 	font-size: $h10 !important;
 	@media #{$desktop-md-above}{
 		font-size: $h10 * 1.2 !important;
 	}
 }


.dropdown{
	&.filter-user{
		a{
			padding: 3px 10px !important;
		}
	}
}

.dropdown-menu{
	.divider{
		margin: 3px 0 !important;
		padding: 0px;
		background-color: inherit;
	}
	.searchbox{
		margin:10px;
		border-bottom:none !important;
		input{
			padding: 6px !important;
		}

	}
}

// .dropdown-header{	// TODO: clean up. Use default only [Benx 2018-08-23 02:03:22]
// 	cursor:none !important;
// }


dl{
	@include smallTextSize();
}

dd{
	@include mainTextSize();
}

dt{
	@include mainTextSize();
}

.dropdown .dropdown-menu >li
  {

  	a{
		  -webkit-border-radius: 0px;
		  -moz-border-radius: 0px;
		  border-radius: 0px;
		  word-wrap: break-word;
		  border-left: 0 !important;
		  text-align: left;
		  white-space: normal !important;
		  font-size:inherit !important;

		  //@include mainTextSize();
	  }
}
hr{
	margin-bottom: 10px;
	margin-top:10px;
}

textarea{
	border:1px solid lightgray;
}

.nav-tabs>li.active>a, .nav-tabs>li.active>a:focus, .nav-tabs>li.active>a:hover{
	background-color:$dynamicBackground;
	color:inherit;
}

.user-setting{
	.nav-tabs>li.active>a, .nav-tabs>li.active>a:focus, .nav-tabs>li.active>a:hover{
		//background-color:$dynamicBackground;
 		color:white;
	}
}

.ui-notification {
    opacity: .95;

    &.clickable:hover {
      opacity: 1;
    }

    &.clickable {
      cursor: default !important;
    }
}

.marked-text-html img{
  max-width: 100%;
}

.icon-tags {
	padding: 0px 2px;
}

button, input, textarea, select{
	font-family:$context-font !important;
}

.disabled-element {
	.otis-toggle {
		&:active {
			background: transparent !important;
			color: inherit;
			cursor: default;
		}

		@include hover-supported() {
			background: transparent !important;
			color: inherit;
			cursor: default;
		}
	}

	&.otis-toggle {
		&:active {
			background: transparent !important;
			color: inherit;
			cursor: default;
		}

		@include hover-supported() {
			background: transparent !important;
			color: inherit;
			cursor: default;
		}
	}

	.dropdown-toggle {
		border: 0px !important;
	}

	// todoItem
	.todo-status-dropdown {
		.icon-check-off::before {
			background: lightgray;
			opacity: 0.3;
		}
	}
}

.option-menu {
	background-color: $darkenMDBackground;
	color: $white;
	border-radius: 3px;
	li{
		padding: 5px 10px;
		color: #fff;
		&:hover{
			background-color: lighten($darkenMDBackground,20%);
			cursor: pointer;
		}

		i {
			margin-right: 3px;
		}
	}
}

.form-control{
	&:focus {
		outline: -webkit-focus-ring-color auto 5px;
		outline-color: -webkit-focus-ring-color;
		outline-style: auto;
		outline-width: 5px;
	}
}

.dropdown-toggle,.otis-toggle {
	&:focus {
		outline: none;
	}
}
.bootstrap-select .dropdown-toggle:focus {
	outline: none !important;
}

dir-pagination-controls{
	.pagination {
		margin:0;
	}
}

.info-rule{
	.bootstrap-select.btn-group .dropdown-toggle .filter-option {
		color:white;
	 }
}
