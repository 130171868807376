/* pollex sidebar scss 2016.01.30 by smith sun */ 

.sidebar { 
	background: $navSidebarColor;
	color: white;
	position: fixed;
	top: $navbarHeight;
	left: 0;
	height: 100%;
	width: $sidebarWidth; 
    z-index:100;
	width: $sidebarWidth;  
    // box-shadow: 5px -5px 5px -7px #333;  // TODO: remove it [Benx 2019-04-17 12:18:04]
	border-right: 1px solid rgba(darken($navSidebarColor, 10%), 0.3);
 
 
		.sidebar-menu {
			overflow: visible; 
			padding-top:$sidebarWidth*0.5
		}

		ul.nav.nav-sidebar {
			 margin: 0;
			 width: $sidebarWidth;
			 padding: 0;
 
			 li {
			 	position:relative;
				
				&:hover {
					background: $nav-sidebar-background-hover;
					& i {
						color: $nav-sidebar-icon-hover;
					}
				}
		 		&.active,
				&:focus,
				&.opened .hover{
					&, & a {
						color: $nav-sidebar-icon-hover;
						background: $nav-sidebar-background-seleted; 
					}
					& i {
						color: $nav-sidebar-icon-hover;
					}
					
				} 

				a { 
					position: relative;
			 		height: $sidebarIconHeight;
				    line-height: $sidebarIconHeight;
					text-align: center;
					// @media all and (-ms-high-contrast: active), (-ms-high-contrast: none) {
					//     	height: $sidebarIconHeight * 1.5 ;
				   	// 		line-height: $sidebarIconHeight * 1.5;
					// }
					
					padding:0px; 

					&.open {
						cursor: default;
					}

					.text {
						position: absolute; 
						top: 0px;
						// left:-3px;
						display: none !important;
						text-align: left;
						@include mainTextSize(); 
					}
					i {
						width:  $sidebarWidth ;
						text-align: center;
						// color: white;
						color: $nav-sidebar-icon-color;
						@include bigIconSize();
					}

				}

				&:hover,&:focus {

					> a {
						position:relative;
						cursor: pointer;
						width: $sidebarWidth;
						background: $nav-sidebar-background-hover;
						// border-left: 8px solid darken($navSidebarColor,30%) !important;
						// border-left: 8px solid  $navSidebarColor !important;
						// margin-left: -4px !important;


						.text {
							width: 10em;
							display: block !important;
							margin:0px 0px 0px $sidebarWidth;
							padding-right:2px;
							border-left: 0; 
							height: $sidebarIconHeight;
							line-height: $sidebarIconHeight; 
							// @media all and (-ms-high-contrast: active), (-ms-high-contrast: none) {
							//     	height: $sidebarIconHeight * 1.5 ;
						   	// 		line-height: $sidebarIconHeight * 1.5;
							// } ;
							color: white;
							padding-left: 10px;
							border-radius: 0px 8px 8px 0px;
							background:rgba(darken($navSidebarColor,30%),0.75);
							 
						}
					}
				}
			}
		} 
}

@media #{$phone}{
	.sidebar{
		height: $sidebarIconHeight;
		line-height: $sidebarIconHeight;
		width: 100%;
		top: auto; // for ie
		top: initial;
		bottom:0;
		z-index: 100;
		// box-shadow: 0px 8px 9px 5px #666;  // TODO: remove it [Benx 2019-04-17 12:18:04]
		.sidebar-menu{
			padding:0px;
		}

		ul.nav.nav-sidebar {
			width: 100%;
			display: flex;
		    justify-content: space-between;
		    align-items: center;
		    li {
		    	flex: 1 1;
				text-align: center;
			
    			//padding-top: 5px;
    			a{
					background-color: inherit;
					height: $sidebarIconHeight !important;
					line-height: $sidebarIconHeight !important;
					i{						
						height: $sidebarIconHeight !important;
						line-height: $sidebarIconHeight !important;
					}					
    			}
    			&:hover a {
					width: auto ;// for ie;
    				width: initial;
    				border-left: 0px none transparent !important;
    				margin-left: 0px !important;
	    			.text{
	    				display: none!important;
	    			}
    			}
    			&:focus a {
    				width: initial;
    				border-left: 0px none transparent !important;
    				margin-left: 0px !important;
	    			.text{
	    				display: none!important;
	    			}
    			}
		    }
		}

	}
} 