/* check list scss */
.checklist-div{
	.checklist-title{
		white-space: pre-wrap;
	}
	.add-checklist{
		//display:block; 
		margin-left: 15px; 
    	a {
    		text-decoration:none;
    		//color:inherit;
		    border: none;
		    padding: 0.5em;
		}
	}
}
