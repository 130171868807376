/* todo details */
$_smallMember: $generalMemberHeight;
$_mainColor: $main-text;
$_tabConentPadding: 12px 40px;
$_panelBodyHeight:50vh;

.todo-details-modal{

	height:80vh;
	overflow:hidden;
	border-radius: 0px !important;

	.watch{
		position:absolute;
		right:30px;
		line-height:30px;
		height:30px;
		@media #{$phone}{
			right:35px;
		}
	}

	.todo-detail-panel-scroll{
		$_height:70vh;
		$_barColor: lighten($main-text, 30%);
		background-color: #fff;
		@include scrollTinyBar($_barColor,$barSize);
		height:$_height;
		//@include height-vh-patch("94vh - 94px - 40px - 92px");
		@include height-vh-patch("94vh - 200px");	// [Benx 2018-10-03 19:05:52]
		@media #{$phone}{
		//@include height-vh-patch("100vh - 94px - 40px - 50px");
		@include height-vh-patch("100vh - 190px");  // [Benx 2018-10-03 19:05:52]
	   }

  	}

  	&.other-project{
   		.todo-detail-panel-scroll{
     		@include height-vh-patch("94vh - 95px - 81px");
     		@media #{$phone} {
				@include height-vh-patch("100vh - 95px - 81px");
      		}
    	}
	}
	  
	.todo-files-section, .todo-subTodos-section, .todo-comments-section, .todo-activities-section {
		margin-top: 20px;
	}

	@media #{$phone} {
		.todo-files-section, .todo-comments-section, .todo-activities-section {
			margin-top: 0px;
		}
	}

	.todo-view-panel-title{
		background-color: lightgray;
		padding:0px 15px 0px 15px;
		border-bottom: 1.5px solid lightgray;
		clear:both;

		.todo-detail{
			padding: 0.5em 2em 0 0 ; //change right padding form 1em to 2em to give watch icon a place.
			overflow: auto;
			overflow-y:hidden;
			@include smartBreakWord();
			$_height: 50px;
			min-height: $_height;
			.circle-icon{

				//margin-right:10px;
				vertical-align:top;
			}
			.todo-view-panel-title{
				display:inline-block;
				width: calc(100% - 50px - 20px) ;

			}
			&.title {
				@include menuDropdownSize();
				$_lineHeight: 1.4em;
				text-indent: 0 ;
				line-height: $_lineHeight;
				$_lines: 3;
				line-height:1.4em !important;
				overflow:hidden;

				.todo-detail-title-editor {
					.todo-detail-title-text {
						padding-right: 6px;
						width: calc(100% - 50px);
						$_lineHeight: 1.2em;
						$_lines: 3;
						@include multiline-ellipsis($_lineHeight, $_lines);
						display: inline-block;

						@media #{$phone} {
							$_lines: 2;
							@include multiline-ellipsis($_lineHeight, $_lines);
							display: inline-block;
						}
					}

					.topic-expanded {
						max-height: 40vh !important;
					}
				}

				textarea {
					max-height: 120px !important;
					font-size:inherit;

					&.edit-todo-title{
						width:calc(100% - 50px - 20px) !important;

					}
				}
			}

			&:hover {
				.edit-icon{
					visibility: visible;
				}
			}

			.edit-icon{
				display:inline-block;
        		visibility: hidden;
				line-height:inherit !important;
				margin-top:1px;
			}

			form{

				&[editable-form] {
				 	div {
					    margin-top: -$h3;
					    width: 100%;
				    }
			    }
		    }

			.editable-click {
				border-bottom: 0;
			}

			.editable-click, a.editable-click {
			    text-decoration: none;
			    color: #428bca;
			}

		}
		.created-by-text, .subscriber{
			text-align: right;
			color: lighten($main-text, 20%);
			@include smallTextSize();
			.creater-name{
				padding:0 3px;
			}

    	}

	}

	.todo-detail-nav-tab{
	 	display:inline-flex;
	 	padding: 0px ;
	 	width:100%;
	 	cursor:pointer;
		background: $todo-nav-option-bg;
		@include unSelectable();

		.nav-item{
	 		padding: 0.5em 0px;
			flex-grow:1;
			text-align:center;
			a{
				text-decoration:none;
				display:inline-block;
				color: $todo-nav-option-color;

				&:visited{
					background: $_mainColor;
					color:white;
				}

				i{
					padding:10px 6px;
					@include bigIconSize();
					font-weight: 800;
					span{
						font-family: $context-font;
						@include menuDropdownSize();
						margin-left:4px;
						font-style: normal;

						&.small, .small {
							font-size: 75% !important;
						}
					}

				}
			}
			&:hover {
					background: $todo-nav-option-hover;

					a{
						color: #fff;
					}
				}
			&:active {
					background: $todo-nav-option-active;

					a{
						color: #fff;
					}
				}
		}
		.nav-item-other-icon{
			cursor: pointer;
			text-align: center;
			width: 40px;
			display: inline-flex;
			align-items: center;
			&:hover, &.dropdown.open , &:active{
				background-color: $darkenMDBackground;
				color: #fff;

				.icon-option {
					color: #fff;
				}
			}

			.icon-option{
				padding:0px 0px 0 0;
				@include bigIconSize();
				height: inherit;
				font-weight: 400;
				color: $todo-nav-option-color;



			}
			.todo-tab-icon{
				padding:0;
				margin-right:6px;
				@include bigIconSize();
			}

			.option-menu {
				width: 200px;
				border: 0px;
				border-radius: 0px;
				.divider { 
					padding: 0px;
					background: transparent; 
				}
			}

			a{
				width:100%;
				text-decoration:none;
				color:  #fff;
				padding: 5px 10px;
				&:hover {
					background-color: transparent;
				}
			}

		}
	}

	.todo-comments-section{
		padding: $_tabConentPadding;
		border-top: 1.5px solid lighten($_mainColor, 50%) ;
		$_chatBackgroundColor: #f0f8ff;
		@include chatLeft($_chatBackgroundColor);
		.comments-list .chat-left {
			padding: 0px;
			padding-bottom: 10px;
		}

	}

	.tab-content{
		.comment-entry{
			.editing-comments{
				 display:inline-block;
				//  width:calc(100% - #{$generalMemberHeight} - 13px) !important;
				 .info-assignment{
					 color:$main-text;
				 }
			}
		}
		.todo-detail-sub-title{
			@include labelSize();
			@include unSelectable();
			color: $_mainColor;
			font-weight: 800;
			margin-left:-15px;
			margin-bottom:10px;
		}
	}

	.tab-footer{
    	//border-top: 2px solid #e9e9e9;
	    position: absolute;
	    bottom: 0;
	    width: 100%;
	    padding: 0 !important;
	    margin: 0;
	    z-index:100;

		.comment-entry{
			display:none;
		}
		.todo-comments-section{
			border:0px;
			padding: 6px 16px;
			background: $comment-editor-background;
		}
	}

	.subtodo-assignee {
		padding: 4px 20px 0px 6px;
	}

	.subTodo.finish .subtodo-assignee {
		padding: 0px 20px 0px 6px;
	}

}

.todo-info-section{
	padding: $_tabConentPadding;
	color: $_mainColor;
	position:relative;

	.todo-detail-info{

		.item-list {
			// border: 1px solid lightgray;
			width: 100%;
			min-height: 30px;
			height: 100%;
			line-height: 30px;
			text-align: left;
			padding: 0px;
		}

		input{
			border:none;
			color:$main-text;
			font-weight:initial;
			width: 100%;

		}

		.btn-link.special-list-toggle-btn {
			text-decoration: none;
		}

		.dropdown-toggle{
			border:1px solid $form-input-border-color;
		}
		.row{
			margin:6px 0;
			padding:0 10px;
		}

		.todo-status-title, .todo-resolution-title{
			padding-right:10px;
			@media #{$phone, $tablet}{
				padding-right: initial !important;
				text-align:left;
			}

		}

		dt{
			color: black; //darken($_mainColor, 10%);
			font-weight:400;
			@include labelSize();
			margin-top:0;
			@include unSelectable();
		}

		.todo-description-div{
			color: $warm;
			@include mainTextSize();
			min-height:5em;
			margin-top:6px;
			margin-bottom:6px;

			.edit-todo-description{
				height: auto;
			}

			.todo-description-content{
				border:1px solid $form-input-border-color;
				padding: 3px 15px 3px 6px;
				min-height: inherit !important;
				word-break:break-word;
				white-space:normal;

				overflow:hidden;
				$_lines: 16;
				$_lineHeight:1.3em;

				&.reader-mode {
					border-color: $form-input-read-only-border-color;
				}

				.todo-description-text{

					@media #{$desktop-md-above}{
						   line-height: $_lineHeight * 1.2;
					}
				}
				.edit-icon{
					position:absolute;
					right: 6px;
				}

			}
			textarea[pop-auto-height]{
				color: inherit;
				  padding: 3px 6px;
				  padding-bottom: 20px;//for markdownTips;
				font-size: inherit !important;
				min-height: inherit !important;
				&.overflow-todo-description{
					padding-bottom: 30vh;
					$_lines: 16;
					$_lineHeight:1.3em;
				}

			}
			&.ng-hide {
				transition: 0s none;
				animation: 0s none;
				display: none !important;
				&.ng-hide-add, &.ng-hide-add-active {
				  display: none !important;
				  opacity: 0;
				}

			}

		}

		input{
			font-weight:400;
			color: lighten($_mainColor, 10%);
		}

		.todo-timeline{
			margin-bottom:15px;
			input{
				padding:0 !important;
			}
			.form-control{
				height:inherit;
			}
			.progress{
				border-radius: 0;
				.progress-bar.progress-bar-success {
					   background-color: darken($_mainColor, 0%);
					   border-radius: 0;
				   }
			}

			.start-due-date-text{
				font-size: 1em;
				   width: 7em;
			}

			.timeline-Date-left {
				display: inline-block;
				width: 6.5em;
				border: none;
				background: transparent;
				cursor: pointer;


			}

			.otis-toggle{ 
				border: 1px solid $form-input-border-color;
				padding: 5px 3px;
				height: 30px;
				&:hover, &:hover input {
					color: $toggle-color;
				}
			}

			.otis-toggle-on {
				color: $toggle-color;
				background: $toggle-hover;
				input {
					color: $toggle-color;
				}
			}

		}

		.bootstrap-select{
			&.btn-group{
				.dropdown-toggle{
					.filter-option{
						display:inline-block;
						overflow:hidden;
					}

					.caret{
						display:none;
					}
				}
			}


			.btn-default{
				height: 30px;
				line-height: 30px;
				font-weight: 200;
				padding: 0px 3px;
				font-weight: 200;
				border-radius:0;
				background: transparent;
				color: #333;
				//margin:-6px 0 6px 0;
				@include mainTextSize();
				overflow: hidden;
				@media #{$phone}{
					border:1px solid lightgray;
				}
				&:hover {
					color: $toggle-color;
				}

				&.disabled,&[disabled]{
					background-color: lighten($_mainColor, 40%);
					color: $projectInfoTab-color;
					font-weight:800;
					overflow: visible;
				}

			}
		}

		.effort-estimate{

			.co-estimation-div{
				display: inline-flex;
				flex-wrap: wrap;

				.memeber-estimations{
					display:inline-flex;
					margin-top:10px;
					.memberItems{
						border:1px solid  darken($member-background-color, 10%);

						&.member-icon{
							height: $_smallMember;
							width: $_smallMember;
							float: none;
							margin: 0px;
							border-radius:0px !important;

							.member-name{
								padding:0;
								height: auto;
								width: $_smallMember;
								font-weight:400;
								color:white;

							}
						}
					}
					.effort-value{
						width: $_smallMember;
						height: $_smallMember;
						line-height: $_smallMember;
						padding:0px;
						@include smallTextSize();
						text-align: center;
						display:inline-block;
						margin-right:10px;
						border:1px solid darken($member-background-color, 10%);
						border-left:0px;
					}
				}
			}

			.effort-value-average{
				margin-right: 10px;
			}

		}

		.effort-history-div{
			margin:10px;
			@include otisInfoCard();
			clear:both;
			.show-hide-link{
				cursor: pointer;
			}
			.co-estimation-div{
				font-size:0.88em;
				margin-top: 5px;

				.effort-history{
					color:$main-text;
					&.last-estimation{
						color:$main-text;
					}
					hr{
						border-color:lightgray;
					}

				}

				.historyDetail {
					display: flex;
					align-items: center;
					//border: 1px solid;
					margin-bottom:3px;
					.assignee {
						padding: 4px 0px 4px 15px;
						border-radius: 15px  0 0 15px;
						background-color: lighten($cool, 50%);
						//background-color:rgba(100,100,100,0.6);

					}
				}

				.system-estimation{
					text-align:center;
				}
				.manul-estimation{
					color: green;
					text-align:center;
					padding: 0px 1px;
					input{
						border: 1px solid lightgray;
						border-radius: 3px !important;
						padding: 3px;
						max-width: 6em;
						text-align:center;
					}
				}

			}

		}

		.access-restriction{
			.dropdown-menu{
				 @include mainTextSize;
				 .dropdown-header{
					 color: lighten($warm, 0%);
					 font-weight:800;
					 font-size: inherit;
				 }

			}

		}

		.todo-modify-confirm-btn,.todo-modify-cancel-btn{
			border-radius:0;
			background: lighten($_mainColor, 20%);
			padding:2px 10px;
			color: white;
			@include smallTextSize();
			height:auto;
			margin-left:10px;

			&:hover{
				background: lighten($_mainColor, 15%);
			}
			&:active{
				background: lighten($_mainColor, 10%);
				border: 1px lighten($_mainColor, 55%);
			}
		}
	}

	.bootstrap-select.open .dropdown-toggle {
		color: $toggle-color;
		background: $toggle-hover;
	}

 }

.todo-files-section{
	padding: $_tabConentPadding;
	border-top: 2px solid lighten($_mainColor, 50%);
}

.todo-review-section{
	position: absolute;
	right: 25px;
	top: 10px;
	color: white;
	margin-left: 120px;
	border-radius:15px;
	box-shadow: -1px 0px 10px darkgrey;
	.need-review{
		background: olivedrab;
		padding: 3px 12px;
		border-radius:15px;
		&:hover,&.open {
			background: #ffdd9d;
			   color: #333;
		}
	}
	.pass-reviewed{
		background:lightslategray;
		word-break: normal;
		padding: 3px 12px;
		border-radius:15px;
	}
}

.todo-subTodos-section{
	$_textIndent:20px;
	padding: $_tabConentPadding;
	border-top: 1.5px solid lighten($_mainColor, 50%) ;
	ul{
		list-style-type: none;
		padding: 0;
		margin:10px 0px;

		   li{
			   //margin:6px 0;

			&.subTodo{

				.todoText{
					&.overdue{
						color:$overdueColor;
					}
				}
			}
			.subtodo-detail{
				.subtodo-text{

					&:hover{
						background: lighten($warm, 60%);
					}
				}


			}
			input.date-picker{
				&.overdue{
					color:$overdueColor;
				}
			}
			&.background-even{
				background: lighten($_mainColor, 30%);
			}
		   }

		   .nav-item-other-icon{
			   color:gray;
			.dropdown{
				a{
					text-indent:0;
				}
			}
			.dropdown-menu{
				li{
					text-indent:0 !important;
				}
			}

			a{
				padding:0px 6px;
				text-indent:0px;
				display:inline-block;
				cursor:pointer;
				cursor:hand;
				&:hover{
					color:darken ($_mainColor, 5%);
					text-decoration: initial ;
				 }

			}
		   }

		&.closed-subtodo{
			color: lighten($main-text, 20%);
			.subtodo-date{
				margin-left: $_textIndent;
				font-size:0.8em;
				input{
					font-size:1em;
					border: 0px;
					width: 6em;
				}
			}
		}

	}

	.subtodo-edit-row{
		font-size:1em;
		padding-bottom: 3px;
		padding-right: 3px;
		background:  lighten($main-text,52%) ;
		.editable-text-area{
			display:inline-flex;
			width:100%;
			i{
				color:lighten($_mainColor, 15%);
			}

			textarea{
				width:100% !important;

			}
		}
		.subtodo-assignee{
			margin-left: $_textIndent;
			button{
				z-index:300;
				.member-assign-dropdown{
					.assign-dropdown-title{
						padding:0.2em 1em;
						margin:0;
					}

					li{
						text-indent:0 ;
						padding:0px;
						margin:0;
						a{
							padding:0.2em 1em;
						}
					}
				}
			}
		}
		.date-confirm-area{
			margin-left: $_textIndent;
			.start-due-date-text{
					font-size: 0.8em;
					line-height: 12px;
					margin-right: 10px;
					width: 2em;
					display:inline-block;
					text-indent: 0;

			}
			input{
				width:7em ;
				border: none;
				background: lightyellow;
			}
			.editable-buttons{
				float:right;
			}
		}
	}

	.newTodoCard{
		color: darken($_mainColor, 5%);
	}

	.todo-detail-subTodos {
		margin-left: 15px;
	}
}

.todo-relatedTodo-section{
	padding: $_tabConentPadding;
	border-top: 1.5px solid lighten($_mainColor,50%);
	clear:both;
}

.todo-activities-section{
	padding: $_tabConentPadding;
	// NOTE: 加大 padding-bottom 是為了讓 scroll 定位到 File/SubTodo/Comment 等區塊時，可以捲動到上面
	// 否則捲軸不夠長時，就無法捲動 [Benx 2018-10-06 03:16:38]
	padding-bottom: 40vh;	

	border-top: 1.5px solid lighten($_mainColor,50%);
	clear:both;
	.memberItems{
		border:1px solid  lighten($warm, 20%);

		&.member-icon{
			height: $_smallMember;
			width: $_smallMember;
			border-radius:0;
			float: none;
			margin: 0px;
			position: absolute;
			top: 0;
			left: 30%;
			margin-left: -( $_smallMember * 0.5);
			z-index:0;
			background: lighten($warm, 20%);

			.member-name{
				padding:0;
				height: auto;
				font-weight:400;						
			}
		}
	}

}


.todo-detail-info .otis-toggle{
	margin-top: -5px; 
	&:hover, &:hover .text-primary{
		color: $toggle-color;
	}
	&.otis-toggle-on {
		background: $toggle-hover !important;
		color: $toggle-color;
		box-shadow: inset 0 3px 5px rgba(0,0,0,0.125);
		.text-primary,.text-placeholder {
			color: $toggle-color;
		}
	}
}

.option-priority-high{
	color:red;
	&:after{
		content:  " "  "\e933";
		font-family: icomoon;
		color:red;
		position: absolute;
		margin-left: 6px;
		margin-top: 1px;
	}
}
.option-priority-middle{
	color:blue;
}
.option-priority-low{
	color:gray;
	&:after{
		content:  " "  "\e93e";
		font-family: icomoon;
		 color:gray;
		position: absolute;
		margin-left: 6px;
		margin-top: 1px;
	}
}

.date-picker-input-xs-width{
	@media #{$phone}{
		width:4em !important;
	}
}

.date-picker-title-width{
	width:62px;
}

.specialList-detail-div{
	margin:15px 0;
}

.assigned-list{
	white-space: normal;
    text-align: left;
    min-width: 30px;
	width: 100%;

	&[pop-todo-assignee-dropdown-toggle] {
		padding: 0px 3px !important;
	}
}

.belong-project {
	display:inline-flex;
	align-items: center;
	a:hover{
		text-decoration: none;
	}
	.belong-project--iconLink{
		position: relative;
		top:-2px;
	}
	.project-name {
		max-width: 250px;
		display: inline-block;
		margin-right: 2px;

		&.text-ellipsis {
			width: initial !important;
		}

		@media #{$phone} {
			max-width: 100px;
		}

	}
}

.todo-title-event-link{
	.dropdown-toggle{
		padding: 0 8px !important;
		box-shadow:none !important;
		&.btn-default{
			background-color:transparent !important;
			border:none !important;
		}

	}

	.bootstrap-select.open .dropdown-toggle {
		box-shadow: 0 0 0 2px orange !important;
		border-radius: 1px !important;
	}

	.bs-caret{
		display:none !important;
	}
	.bootstrap-select .dropdown-toggle .filter-option{
		max-width: 300px;
		color:#337ab7;
		text-overflow: ellipsis;
		padding-bottom: 6px;

		@media #{$phone}{
			max-width: 200px;
		}
	}
	.bootstrap-select .dropdown-menu {
		width: 300px;
	}
	.bootstrap-select{
		width: auto !important;
	}


}

.comments-list { 
	// $commentReplyIconColor: #25576d;
 	
	.chat-left {
		padding: 0px;
		padding-bottom: 10px;
	}
	.comment-reply {
		.icon {
			color: #3a8baf ; 
			margin-left: 10px; 
			font-size: 14px;
		}
	}

	.comment-reply-pan {
		background: #dce6ef;
	}
	
	// .comment-reply-activity {
	// 	padding: 5px 15px 5px 10px;
	// 	.icon { 
	// 		color: $commentReplyIconColor ; 
	// 		font-size: 14px;
	// 	}
	// }
	
	.comment-reply-member {
		color: $commentReplyIconColor ; 
		// padding-right: 0px;
		margin-top: 2px;
		padding-right: 8px;
		padding-left: 8px;

		.icon {
			color: $commentReplyIconColor ; 
			font-size: 14px;
			text-align: center;

			
			@media #{$phone} {
				text-align: left;
    			display: inline;
			}
		}
		.memberItems {
			height: auto;
			&.member-icon {
				background: transparent;
				color: $commentReplyIconColor; 
				border: 0px;
				.member-name-div {
					height: 0px;
					width: auto;
				}
				.member-name{	
					width: 50px;	
					font-weight:800;
					font-size: 14px ;
					white-space:nowrap;
					text-overflow: ellipsis;
				}
			}
		}
	}
	
	.comments-reply-list {
		width: 90%;
		margin-left: auto;
		margin-right: auto;
		.comments-reply-item {
			background: $comment-reply-background;
			border-radius:5px; 
			padding: 10px 5px 5px 5px;
			margin-top: 10px;
			min-height: 50px;
		}
		.comment-text {
			display: block;
			// width: 85%;
    		// margin-left: 10%;
			padding: 0px;
			margin-bottom: 10px;

			@media #{$phone} {
				padding-top: 10px;
				clear: both;
			}
		}
		
		.comment-reply-tag {
			border-left: 2px solid lightgray; 
			background: #fff;
		}

		// .comment-reply-footer { 
		// 	display: inline-block;
		// 	width: 100%; 		
		// 	color: #2e7e99;
		// 	margin: 0px;
		// 	padding: 0px 7px;
		// }

		.attached-file-list {
			.attachment-entry {
				.fileIcon {
					height: 22px;
					width: 22px;
					background-size: 22px;
				}
				.file-name-link {
					font-size: 0.8em;
				}
			}
			.thumbnail { 
				@media #{$phone} {
					max-width: 85%;
					max-height: 85%;
				}
			}
			.media-attachment {
				@media #{$phone} {
					max-width: 100%;
				}
				@media screen and (min-width: 768px) and (max-width:1279px) {
					width: 155px;
				}
				@media screen and (min-width: 1280px) and (max-width:1440px)  { 
					width: 180px;
				}
				@media screen and (min-width: 1441px) and (max-width:1680px) {
					width: 150px;
				}
				@media screen and (min-width: 1680px){
					width: 180px;
				} 
			}
		}
		
	}

	.comment-footer {
		// margin: 10px 15px;
	}
}

.areaStyle{
	$_height: 40vh;
	border: 1.5px dashed lightblue;
	border-radius: 8px;
	min-height: $_height;
	max-height:60vh;
	height:auto;
	background-color:white;
	margin-bottom: 0.5em;
	overflow-x:hidden;
	overflow-y:auto;
	padding:0 ;
	.pastHere{
		text-align: center;
		@include h1TextSize();
		min-height: calc(#{$_height} -10px);
		height: auto;
		max-height: 85vh;
		line-height: calc(#{$_height} -10px);
		color: lightgray;
		position: absolute;
		width: 100%;
	}
	img{
		position: relative;
	    width: auto;
		height:auto;
		max-width:100%;
		max-height:100%;
	    text-align:center;

	    padding: 6px;
	}
}

.todo-detail-info {
	.todoDetails-assignee, .todoDetails-reviewer {
		padding-left: 5px;
		@media #{$phone} {
			padding: 0px;
		}
	}
}


// ANCHOR: SubTodo

.subtodo-modal {
	.parent-todo-info{
		display: inline-block;
		border-radius: 0 0 2px 0;
		font-size: 1.1em;
		padding:6px;
		background: $warm;
		max-width: 95%;
		width: initial;
		color:white;
		a{
			color:white;
		}
	}
}

.todo-details-modal .todoItem.subTodo {
	&input {
		@include mainTextSize();
	}

	.subtodo-other-info {
		margin-left: 20px;
	}
	
} 

.subtodo-detail{
	display:inline-block;
	.title-info-tags{
		display:inline-block;
		color: $warm;
		padding-left: 6px;
		padding-right: 0;
	}
	.subtodo-assignee{
		display:inline-block;
		color: $warm;
		padding-left: 6px;
		vertical-align:top;
		line-height:initial;
	}
	.subtodo-date{
		display:inline-block;
		color: $warm;
		padding-left: 6px;
		input.ng-invalid {
			color: red;
		}
	}
	.todoText {
		white-space: pre-wrap;
		&:hover{
			color:$warm;
		}

	}

}

.todoDetail-subTodo-panel {
	.todoDetail-subTodo-icon {
		margin-right: 5px;
		padding-top:1px; // revised by smith @2018/0727 reflect icon line-height change
		
		&.disabled-element {
			.icon-check-off::before {
				background: lightgray;
				opacity: 0.3;
			}
		}
	}
}

.todoDetail-subTodo-info{
	margin-bottom: 8px;
}

.todoDetail-subTodo-date {
	margin-left: 15px;
	&:only-child{
		margin-left: 0px;
	}
}

.todoDetail-subTodo-options {
	position: absolute;
	right: -5px;
	background-color: $darkenMDBackground;
	box-shadow: 1px 1px 2px $member-background-color;
	// z-index: 1;
	color: $white;
	border-radius: 3px;
	li{
		padding: 5px 10px;
		i {
			margin-right: 3px;
		}
		&:hover{
			background-color: lighten($darkenMDBackground,20%);
			cursor: pointer;
		}
	}
}

// ANCHOR: media #{$phone}
@media #{$phone}{
	pop-todo-detail-modal, my-todo-list , pop-sprint-detail-modal{
		.otis-modal-content-md, .otis-modal{
			@include height-vh-patch("100vh");
		}
	}
	.todo-details-modal{

		.todo-info-section,.todo-subTodos-section,.todo-files-section,.todo-comments-section,
		.todo-relatedTodo-section,.todo-activities-section{
			  padding: 12px 18px;
		}
		.tab-footer{

			.todo-comments-section{
				padding:0px !important;
				position: absolute;
   				bottom: 0;
   				width: 100%;
			}
		}

		.todo-detail-sub-title{
			margin-left:-8px !important;
		}

		.todo-detail-info dt{
			word-break: break-word;
		}

		.todo-view-panel-title {
			.todo-detail{
				&.title{
					text-indent: 0 ;
				}
			}
			.todo-detail-panel-close {
				margin-top: -0.5em;
				margin-right: -0.5em;
			}

		}
		.time-line-progress{
			width: 8em;
		}


	}
	.parent-todo-info{
		max-width: 90% !important;
	}

}

// ANCHOR: media #{$tablet}
@media #{$tablet}{
	.todo-details-modal{

		.todo-info-section,.todo-subTodos-section,.todo-files-section,.todo-comments-section,
		.todo-relatedTodo-section, .todo-activities-section{
			  padding: 12px 18px !important;
		}
		.tab-footer{

			.todo-comments-section{
				padding:1px !important;
				padding-top: 0.5em !important;
			}

		}

		.todo-detail-sub-title{
			 margin-left:-8px !important;
		}
		.todo-detail-info dt{
			word-break: break-word;
		}
		.todo-view-panel-title {
			.todo-detail{
				margin-top:0;
				padding:0;

			}
			.todo-detail{
				&.title{
					text-indent: 0 ;
					font-size:1.02em;
                    padding:10px 10px 10px 0;
				}
			}
			.todo-detail-panel-close {
				margin-top: -0.5em;
				margin-right: -0.5em;
			}

		}


	}

}

// ANCHOR: media #{$desktop-xs}
@media #{$desktop-xs}{
	.todo-details-modal{

		.todo-info-section,.todo-subTodos-section,.todo-files-section,.todo-comments-section,
		.todo-relatedTodo-section, .todo-activities-section{
			  padding: 12px 18px !important;
		}
		.tab-footer{
			.todo-comments-section{
				padding:10px !important;
				padding-top: 0.5em !important;
			}

		}
		.todo-detail-sub-title{
			 margin-left:-8px !important;
		}
		.todo-detail-info dt{
			word-break: break-word;
		}
		.todo-view-panel-title {
			.todo-detail{
				margin-top:0;
				//padding:0;

			}
			.todo-detail{
				&.title{
					text-indent: 0 ;
					font-size:1.02em;
				}
			}
			.todo-detail-panel-close {
				margin-top: -0.5em;
				margin-right: -0.5em;
			}

		}


	}
}

.todo-setResolution-modal{
	$_modalHeaderHeight: 50px;
	$_modalTodoTextHeaderHeight: 70px;
	$_modalFooterHeight: 45px;
	
	.modal-header{
		padding:0.5em 1em;
		height: #{$_modalHeaderHeight};
	}
	
	.modal-target-item {
		max-height: #{$_modalTodoTextHeaderHeight};
	}
	
	.modal-footer {
		height: #{$_modalFooterHeight};
	}
	
	.modal-body{
		min-height: 25vh;
		// Fixed: 處理小結上傳截圖時, 無滾軸, 造成 "儲存" 鈕被隱蔵
		// https://www.otispm.com/otis/index.html#!/pjm/p/725/backlog-list?showTodoDetail=37703
		// max-height: 74vh;
		@include max-height-vh-patch("90vh - #{$_modalHeaderHeight} - #{$_modalTodoTextHeaderHeight} - #{$_modalFooterHeight}");
		overflow: auto;
	}
	textarea{
		width:100%;
		min-height:5em;
		max-height: 150px;
	}
	.bootstrap-select{
		width: 100% !important;
	}
	.assigned-list {
		padding: 6px 0px !important;
	}
	@media #{$phone}{
		textarea{
			max-height: 106px;
			min-height: 0px;
		}
	}
	@media #{$miniPhone} {
		textarea{
			max-height: 60px;
			min-height: 0px;
		}
	}

}

.todo-setReview-modal {
	.modal-body {
		min-height: 25vh;
	}

	.todo-setReview-contents {
		.setReview-doing-status {
			padding: 0px; 
			margin-bottom: 8px;
			.doing-status-select {
				padding: 0px;
				width: 100%
			}
		}

		.setReview-assignee {
			margin-bottom: 8px;
			label {
				width: 100%;
			}
		}

		.setReview-working-stage {
			margin-bottom: 8px;
			padding: 0px;
		}

		.setReview-comments {
			padding: 0px;
		}
	}
}

.todoText {
  &.done {
    text-decoration: line-through;
  }

}

pop-todo-relation-add {
	height: 40px;
	display: block;
}

.todo-dispatchDetailFiles-modal{
	.modal-body{
		min-height: 25vh;
		overflow-y: scroll;
		height: 75vh;
	}
}

// todo#36822:『檔案和討論面板』面板的優化。 [Jane 2018-08-30 12:46:58]
//https://www.otispm.com/otis/index.html#!/pjm/p/725/todo?sprint=8787&showTodoDetail=36822
.file-and-comment-scroll{
	overflow: auto; 
	@include height-vh-patch("100vh - 200px"); 
	.file-and-comment-font{
		font-weight: bold;
	}
}

.file-line{
	border-bottom: 1.5px solid #e9e9e9;
	margin: 10px 0px;
}

.todo-openFileAndComment-modal{
	$_color: white;
	$_chatBackgroundColor:  $discussionChatColor;
	@include chatLeft($_color );
}

// todo#31336:執行'回復刪除'，若刪除的工作數量超出面板容納範圍，會超出面板且沒有捲動軸可拖拉瀏覽。[Jane 2018-09-04 15:23:10]
.latestDeletelInfo-scroll{
	overflow: auto;
	height: calc(100% - 55px);
}

pop-effort-history-timeframe-render.doing {
	font-style: italic;
}