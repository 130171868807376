
.week-effort-sum__table {
	$_rowHeaderFontSize: 14px;
	width              : 75%;
	margin             : 15px auto;
	border-collapse    :separate;
    border-radius      :3px;
	-moz-border-radius :3px;
	border             : 1px solid #ffc107;

	.week-effort-sum__row {
		text-align : center;
	}

	.week-effort-sum__date-row {
		font-weight: bold;

		& > td {
			padding-bottom: 3px;
		}

		.week-effort-sum__date-row__header-cell{
			font-size     : $_rowHeaderFontSize;
			border-bottom : 1px solid #ffc800;
			td {
				padding   :8px 8px 0px 8px;
			}
			.week-effort-sum__effort-day {
				display   : inline-block;
				opacity   : 0.6;
				margin-top: 3px;
			}
		}
		
	}
	// .week-effort-sum__normal-effort-row {
	// }
	// .week-effort-sum__overtime-effort-row {
	// }

	.week-effort-sum__col-header {
		font-weight        : bold;
		font-size          : $_rowHeaderFontSize;
	}

	.week-effort-sum__col {
		background-color   : #fff3a9 !important;
		border             : 0.1px solid #ffe18640 !important;
	}
	
	

	td {
		// background-color: #fff3a9;
		border             : 0.1px solid #ffe18640 !important;
	}
}

table.weekdays-table {
	col.workday-cell {
		background-color   : #fbed9b !important;
		border             : 0.1px solid #ffe18640 !important;

	}
    col.holiday-cell {
		// background-color   : #fbed9b !important;
		// border             : 0.1px solid #ffe18640 !important;
	}

	col.focus {
		background-color: #F1BA48 !important;
	}
}


.effort-detail-table {
	margin-bottom: 0px !important;
	
	.effort-detail-table__header-row {
		.effort-detail-table__header-row__week-label {
			width         : 18%;
			vertical-align: middle;

            .label {
                font-size: 12px;
            }

		}
	}

	.effort-detail-table__overtime-row > td {
		input {
			color: $_color_timesheet-overtime;
		}
	}

	// cus-element
	tr > th, td {
		border-top: 0px !important;
		text-align: center !important;	
	}

	input {
		height    : auto;
		width     : 100%;
		border    : 0px;
		padding   : 0;
		margin    : 0;
		text-align: center;
		&:disabled {
			cursor : not-allowed !important;
			color  : lightslategray !important;
			opacity: 0.6 !important;
		}
		&[type=number]::-webkit-inner-spin-button,
		[type=number]::-webkit-outer-spin-button {
			-webkit-appearance: none;
			margin            : 0;
		}
		&[type=number] {
			-moz-appearance: textfield;
		}
	}
	
	select {
		&:disabled {
			cursor : not-allowed !important;
			color  : lightslategray !important;
			opacity: 0.6 !important;
		}
	}
}