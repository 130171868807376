// 54px is sprint-panel-header, 
// 76px is sprint-panel-heading, 
// 39px is sprint-panel-title, 
// 105px is sprint-panel-footer [Jane 2018-11-27 10:48:48]
$_panelBodyHeight: "94vh - 54px - 76px -  39px - 105px"; 
$_phonePanelBodyHeight: "100vh - 50px - 59px - 42px - 60px";
$_sprintDetailHeight: $todoPanelHeight - 5vh;
$_mainColor: $warm;

.sprint-edit-modal{
	label{
		margin-right: 6px;
	}
	.markclosed{
		label{
			input{
					display:inline;
				}
			.form-control{
				width: auto;

				span{
					width:100%;
				}
			}

		}
	}
	.modal-header{
		padding: 0.5em 1em;
	}
	.modal-footer{
		padding: 0.5em 1em;
	}
	input{
		&.ng-invalid-date-range{
		color:red;
		position:relative;
		}
	}

	form{
		.ng-invalid-date-range{  
			&:before{
					display:inline-block;
					padding:3px;
					color: $warningMessageTextColor;
					//position: absolute;
					//right:0;
					//top:0;
					margin:3px 3px 0 0 ;
					content: "\e93f" " " attr(warningTitle);
					font-family: icomoon,$context-font;
					//white-space: pre-wrap;
					line-height: 1em;
					text-align: left;
					-moz-border-radius: 3px;
					-webkit-border-radius: 3px;
					border-radius: 3px;
					-moz-box-shadow: 0px 0px 2px #c0c1c2;
					-webkit-box-shadow: 0px 0px 2px #c0c1c2;
					box-shadow: 0px 0px 2px #c0c1c2;
					background: $warningMessageBackground;
				}
		}
	}

}

.sprint-copyTo-modal {
	.span-left {
		text-align: right;
		padding: 0px;
		font-weight: bold;
	}
	.sprint-copy-item {
		.todos-copy-item-include {
			margin-left: 40px;
		}
	}
}

.sprint-detail-div{
	
	@include mainTextSize();
	$_mainText:$body-background;
	$_background:lighten($_mainText, 0%) !important;
	position:fixed; // for ie
	box-shadow: 0px 5px 10px white;
	//right:0;
	//width:36em;
	left: 0 ;
	// $_sprintDetailWidth : calc( #{$_swimLanWidth} + #{$sidebarWidth} + 15px);
	// width: $_sprintDetailWidth ;
	// min-width:$_sprintDetailWidth ;

	// @media #{$desktop-md-above}{
	// 	  width:calc( #{$_swimLanWidth} * 1.2  + #{$sidebarWidth} + 15px) ;
	// 	  min-width:calc( #{$_swimLanWidth} * 1.2 + #{$sidebarWidth} + 15px);
	// }

	// @media all and (-ms-high-contrast: active), (-ms-high-contrast: none) {
	//  	  width: calc( #{$_swimLanWidth} * 1.52  + #{$sidebarWidth} + 15px - 7px) ;
	// 	  min-width:calc( #{$_swimLanWidth} * 1.52  + #{$sidebarWidth} + 15px - 7px) ;
	// }
	//  @media #{$phone} {
	// 	  width: 100vw !important ;
	//  }
	width: 100vw;
	@include height-vh-patch("100vh");
	z-index:300;
	display: block;
	// @media #{$phone} {
	// 	@include height-vh-patch("100vh - #{$sidebarIconHeight} ");
	// }
 
	.otis-modal-content-md {
		border-radius: 0px;
	}

	.close{
		padding:6px;
	}

    .edit-sprint-header{
	padding: 6px;
 	text-align:center;
	background: $modal-information-header-bg;
	color: $modal-information-header-color;
	border-radius: 0px;
	@include h1TextSize();
	@include unSelectable();
 	border-bottom: 1px solid gray;
 	font-weight: 800;
    }
	/*
	&.ng-hide-remove{
   		animation: inDisa 1s;
	}
	&.ng-hide-add{
   		animation:  outDisa 1s;
	}

	@keyframes outDisa{
		from{right: 0em;}
		to{right: -36em;}
	}
	@keyframes inDisa{
		from{right: -36em;}
		to{right: 0em;}
	*/

	.panel{
		$_mainColor: rgba($sprintDetailColor,0.98);
		height:auto;
		@include mainTextSize();
		$_headerPadding: 5px;
		//background:$_mainColor;

		margin-bottom:0 !important;
		.panel-heading{
			height:100% !important;
			line-height:initial !important;
			line-height:normal;

			background: whitesmoke;
			padding-top: $_headerPadding !important;
            padding: 10px 15px;
			//background:rgba(65,64,64,1);
			//color:lighten($main-text, 40%);
			margin-top:0 !important;
            display:block;

		}
		.sprint-detail-header{
			height:100%;
			$_sprintTitltMinHeight: 44px;
			.rlPadding-sm{
				padding:0 0.5em;
			}
			.phase-title{
				padding: 0 0.5em;
				display:inline-flex;
				min-height: $_sprintTitltMinHeight;
				@media #{$phone}{
					padding: 0;
				}
				&.ng-hide-animate {
				  display: none !important;
				  opacity: 0 !important;
				}
		        .placeSprintTitle{
			          overflow: hidden;
			          display: inline-block;
			          //max-width: calc(100% - 4em - 90px);
					  left: 4em;
					  width: calc(100% - 60px);
					  padding-right:15px;
			          $_lineHeight: $h3 * 1.3;
			          $_lines: 2;
			          @include multiline-ellipsis($_lineHeight, $_lines);

					  @include menuDropdownSize();
			          @media #{$phone}{
			          	line-height: $phoneMenuDropdownSize * 1.2;
			          	max-height: $phoneMenuDropdownSize * 1.2 * $_lines;

			          }
		        }

			}
			.edit-icon{
				display:inline-block;
			}
			.circle-icon{
				margin-right: 10px;
				vertical-align: top;
				position: relative;
			}

			.phase-title-editor{
				height:100%;
				padding: 0px 0.5em;
				min-height:	$_sprintTitltMinHeight;
				textarea{
					width: calc(100% - 50px);
					display: inline-block;
					padding:3px 3px !important;
					height:30px;
					font-size:1em;
					// background:inherit !important;
					display:inline-block;
			  		height: initial;
					vertical-align:text-top;
				}
			}

			.sprint-dates{
				$_iconWidth: 40px;
				$_colPadding: 10px;
				cursor:pointer;
				font-size:0.88em;
				display:inline-block;
				text-align:left;
				padding-left: $_colPadding ;
			}

		}

	}

	.panel-title{
		//background: lighten($_mainColor, 3%) !important;
		font-size: 1em;
		padding-right: 0em;

		.sprint-name{
			padding:0.2em 1em;
		}
		.nav-option-icons{
			$_navOptionBackground: gray;
			display:inline-flex;
			width: 100%;
			color: $modal-nav-option-color;
			  font-size: 1em;
			background: $modal-nav-option-bg;
			.nav-item{
				flex-grow:1;
				padding: 0.5em 0em;
				text-align: center;
				font-size:1.1em;
				cursor:pointer;
				&:hover{
					background: $modal-nav-option-hover;
					color: #fff;
				}
				&.active{
					background: $modal-nav-option-active;
					color: #fff;
				}

				i{
					padding:10px 6px;
					@include bigIconSize();
					font-weight: 800;
					span{
						font-family: $context-font;
						@include menuDropdownSize();
						margin-left:4px;
						font-style: normal;

						&.small, .small {
							font-size: 75% !important;
						}
					}

				}
			}

			&.dropdown{
				.dropdown-menu{

					li{
						a{
							@include smallTextSize();
						}
					}

				}
			}
			
			.nav-item-other-icon {
				cursor: pointer;
				text-align: center;
				width: 40px;
				display: inline-flex;
				align-items: center;
				&:hover ,&.dropdown.open {
					background-color: $darkenMDBackground;
					color: #fff;
				}
				
				a {
					color: #fff;
					&:hover {
						background: transparent;
						color: #fff;
					}
				}

				.nav-item-button {
					width: 100%;
				}
			}
		}

	}

	.panel-body{
		padding: 0px 0px;
		color:darken($main-text, 40%);
		// @include height-vh-patch("#{$_panelBodyHeight}");
		@include height-vh-patch("94vh - 200px");
		@include scrollTinyBar(gray, $barSize);

		@media #{$phone}{
			// @include height-vh-patch("#{$_phonePanelBodyHeight}");
			@include height-vh-patch("100vh - 190px");
			padding:0;
		}


		.sprint-detail-content-height{
			@include min-height-vh-patch("100vh - #{$navbarHeight} - 239px")
		}

		.sprint-info-section,
		.sprint-subTodos-section,
		.sprint-files-section,.sprint-comments-section,.sprint-activities-section{
			padding: 12px 18px !important;
		}

	}

	.panel-footer{
		&.sprint-comments-editor-section{
			border:none;
		 	position: absolute;
			width: 100%;
			border-radius:0;
			padding: 0.5em 1em  ;
			bottom: -2px;
			z-index: 100;
			background: $system-operation-comment-background;
			border-radius: 0px;
		 }
		.comment-entry{
			display:none;
		}
	}
}

.sprint-info-section{
	border-bottom:1px solid  lightgray;
	padding: 0.5em;
	.moveUndoneTodo-btn{
		position: absolute;
		width: 8em;
		margin-left: 1em;
		right: 0;
		margin-top: -1.5em;
		font-size: 0.8em;
	}

	.sprint-detail-info{

		.sprint-progress-status{
			@include labelSize();
		}

		label{
			@include labelSize();
			font-weight: initial;
			display:block; 

			.form-control{
				height:1.5em;
				background:transparent;
				outline:none;
			}
		}

		.sprint-access-restriction{
			.select-div{
				align-items: center;

				.btn-group{
					flex-grow:1;
				}

				&:hover, &:active, .btn-default:hover {
					background:  $toggle-hover;
					color: $toggle-color
				}
			}

			.open>.dropdown-toggle{
				background:  $toggle-hover;
				color: $toggle-color;
				border: 1px solid transparent;
			}
		}

		.sprint-dates input {
			height: 31px;
			@media #{$desktop-md-above} {
				height: 34px;
			}

			@include hover-supported() {
				background: $toggle-hover;
				color: $toggle-color;
			}
			
			&.otis-toggle-on {
				background: $toggle-hover;
				color: $toggle-color;
			}
		}

		.sprint-dates {
			.otis-input:hover .icon-event  {
				color: $toggle-color;
			}
			.icon-event {
				position: absolute;
				top: 7px;
				left: 8px;
				z-index: 5;
				&.otis-toggle-on {
					color: $toggle-color;
				}
			}
		}
		
	}
}


.sprint-activities-section{
	.pjt-activity{
		padding:0 20px;
	}
	padding: 0.5em;
	@include min-height-vh-patch("#{$_panelBodyHeight}");

	@media #{$phone}{
		@include min-height-vh-patch("#{$_phonePanelBodyHeight}");
		padding:0;
	}

	padding-bottom: $_sprintDetailHeight - 25vh;

	.pjt-activity{

		.label-activity-File,.label-activity-Comment,.label-activity-Project,.label-activity-Event,.label-comming-Event,.label-activity-Checklist{
		   //background: lightslategray !important;
		   padding: 1px 3px !important;
		}
		[class^="label-activity-"],
		[class*=" label-activity-"]{
			 font-weight: initial;
			 line-height:initial;
			 border-radius:0px;
			 color:initial;
		}


	}

}

.sprint-files-section{
	border-bottom:1px solid lightgray;
	padding: 0.5em;
	.fileUpload-container{
		border-color: darken($_mainColor,40%) !important;
		margin-left:20px;
	}
	.attachment-file-list{
		margin-left:20px;
	}
	pop-attachment-list{
		font-size:0.88em;
	}
	.otis-tips{
		font-size:0.88em;
	}
}

.sprint-comments-section{
	padding: 0.5em;
	border-bottom: 1px solid lightgray;
	$_chatBackgroundColor: #f0f8ff;
	@include chatLeft($_chatBackgroundColor);
	.reply-area{
		margin-left:20px;
	}
	.otis-tips{
		font-size:0.88em;
	}
}

.sprint-checklist-section{
	border-bottom:1px solid  lightgray;
	padding: 12px 18px !important;

	.add-checklist{
		margin-left:15px;
		//border-color: darken($_mainColor,40%) !important;
	}
	pop-checklist{
		font-size: 1.1em;
	}
	.otis-tips{
		font-size:0.88em;
	}

}


.sprint-detail-info{
	padding: 0 15px;
	@media #{$phone}{
		padding: 0px;
	}

	.item-list {
		border: 1px solid;
		width: 100%;
		height: 100%;
		text-align: left;
		padding: 0px;
		padding: 6px 3px !important;
	}

	.otis-toggle, .bootstrap-select .dropdown-toggle, .otis-input input {
		border-color: $form-input-border-color;
	}

	.disabled-element {
		.otis-input>input {
			border: 0px;
		}
	}
}

.sprint-description{
	position:relative;//for markdowntips
	$_lineHeight: 1.5em;
	$_lines: 10;

    textarea{
        width: 100%;
        max-height:10em;
        resize:none;
		min-height: 5em;
	}
	
	.icon {
		padding-left:15px;
	}

	.sprint-description-area{
		@include mainTextSize();

		padding: 3px 15px 3px 6px;
		position:relative;
		min-height:5em;
		width: calc(100% - 15px);
		&.ng-hide-animate {
		  display: none !important;
		  opacity: 0 !important;
		}

		.edit-icon:hover{
			color:$warm
		}
	}
}

.sprint-detail-sub-title{
	font-weight: 800;
	padding-bottom: 10px; 
	@include unSelectable();
	@include menuDropdownSize();
}

.sprint-status {
	.otis-toggle:active {
		background: $toggle-hover;
	}
}

.sprint-info-section .otis-toggle {
	[class^="icon-"], [class*=" icon-"] {
		margin-left: 3px;
		margin-right: 2px;
	}
}

