/* project Edit */

.project-edit-div {
  .modal-body {
    position: relative;
    padding: 15px;
    max-height: 75vh;
    display: block;
    clear: both;
    @media #{$phone} {
      padding: 15px 0px;
      @include max-height-vh-patch("100vh - 57px - 57px - 70px");
    }
  }
  .header-text {
    margin-top: 5px;
    @include h1TextSize();
    font-weight: 800;
  }
  .project-addon{
    position:absolute;
    right:6px;
    bottom:4px;
    //background: rgba(10,10,10,0.44);
    //color: white;
    padding: 4px 12px !important;
  }

  .span-left {
    padding-top: 5px;
    @include mainTextSize();
    font-weight: 600;
    line-height: 1em;
    margin-bottom:8px;
  }
  .select-project-type {
    //margin-bottom: 6px;
    .dropdown-toggle {
      border-color: lightgray !important;
    }
  }
  .span-right {
    @include mainTextSize();
    color: $main-text;
    $_padding: 6px 3px;
    select {
      width: 100%;
      color: $main-text;
      padding: $_padding !important;
    }
    input {
      padding: $_padding !important; //margin: 6px 0 !important;
      width: 100%;
      border-style: solid;
      border-width: 1px;
      border-radius: 2px;
      border-color: $titleBarColor;
    }
    textarea {
      padding: $_padding !important;
      width: 100% !important;
      border-style: solid;
      border-width: 1px;
      border-radius: 2px;
      border-color: $titleBarColor;
    }
    .project-zone-name {
      padding: $_padding !important;
      font-size: inherit !important;
    }
    .template-title {
      padding: $_padding !important;
      font-size: inherit !important;
      border-style: solid;
      border-width: 1px;
      border-radius: 2px;
      border-color: $titleBarColor;
      background-color: rgba(0, 0, 0, 0.1);
      color: rgba(0, 0, 0, 0.3);
    }
    .add-member {
      padding: $_padding !important;
    }
    &.stakeholder-setting {
      padding: 6px 12px;
      background: rgba(220, 220, 220, 0.4);
      .stakeholder-role {
        padding: 3px !important;
        color: $warm;
      }
      .error-message {
        position: relative;
        padding: 0 !important;
        margin-bottom: 0 !important;
        .error-text {
          padding: 6px 10px;
        }
        .remove-roles {
          padding: 6px 10px;
          color: $main-text !important;
        }
      }
    }
  }

  input.date-picker {
    display: inline-block;
    width: 6.5em;
    border: none;
    background: transparent;
    cursor: pointer;
  }

}

.project-edit-showMore {
  padding-top: 5px;
  clear: both;
  padding-right: 15px;
  text-align: right;
}

.option-entry-item {
  .mark-deleted {
    text-decoration: line-through;
    font-style: italic;
  }
}

.projectRemarkNote-entry {
  .action-buttons {

    &.pull-right {
      margin-left: 10px;
    }

    .btn {
      padding: 2px 3px 2px 0px;
    }
  }
}

.project-risk-modal, .project-chart-modal{
  $_headerHeight : 48.27px;

  .modal-body{
    min-height:40vh;
    @include max-height-vh-patch("90vh -  #{$_headerHeight} ");

	@media #{$phone}{
      @include height-vh-patch("100vh -  #{$_headerHeight} ");
      max-height:initial !important;
    }
  }

}

.project-memberlist-modal{
  $_headerHeight : 48.27px;

  .otis-modal-content-sm{
    height:auto !important;

  }
  .modal-body{
    min-height:40vh;
    @include max-height-vh-patch("90vh -  #{$_headerHeight} - #{$sidebarIconHeight} ");

	@media #{$phone}{
      @include height-vh-patch("100vh -  #{$_headerHeight} - #{$sidebarIconHeight} ");
      max-height:initial !important;
    }
  }
}

.project-addonEdit-modal {
	.modal-body {
		min-height: 25vh;
		max-height: 80vh;
		overflow: hidden;
		overflow: auto;
	}

	.stakeholder-role .error-message {
		position: relative;

		& .close.icon-close {
			top: 0;
		}
	}
}

.info-labels__editor {
	position: relative;
	&.on-delete::after {
		content: "";
		width: 70%;
		border-bottom: 2px solid black;
		position: absolute;
		left: 0;
		top: 50%;
	}
	.info-labels__color-picker {
		width: 15%;
		margin-right: 16px;
	}
	.info-labels__rule-input {
		width: 50%;
		margin-right: 16px;
	}
	.info-labels__delete-btn {
		margin-top: 8px;
	}
}

input[type=color].invalid-option-value {
	border: 1px solid red;
}