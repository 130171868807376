/* tips scss */
$_arrowHeight: 13px;

.otis-tips{ 
	font-weight:400;
	font-size:$h3;
	@include mainTextSize();
	
	white-space:normal;
	ul{
		-webkit-padding-start: 0;  
	}
	
	
	@media #{$phone}{
		display:hidden;
	} 
	&.projectCardList{
		height:40vh;
		border:2px dashed $warm;
		position: absolute; 
		padding:1em; 
		line-height: initial;
		line-height: normal;
		width:99%;
		z-index: -1;		
	}
	&.tips-addproject{
		font-size:$h3 !important; 
		@include mainTextSize();
		position: absolute;
		right: 6px ;
		@media #{$phone}{
			left: initial;
			left:auto;
			right:0.5em;			
		}
	    //width: auto;
    	min-width: 12em;
    	max-width: 15em;
	    bottom: 0.5em;
	    z-index: 300;
	    line-height: 1em;
	    padding: 0.5em 26px !important;
		margin:0.5em 0 0 0;
	    border-radius:2px;   
   	    box-shadow: 1px 3px 7px gray;
	    &:before{
	    	top:auto !important;
	    	bottom:5px !important;
	    	left:-20px !important;
	    }
	    &:after{
	    	top:auto !important;
	    	bottom:11px !important; 
	    	left:-20px !important;
	    }
	} 
	
	
	&.tips-eventlist{
		border-radius:2px;
		font-size:$h3 !important; 
		@include mainTextSize();
		line-height: initial;
		line-height: auto;
    	margin: 0;
		}
	&.tips-todolist{
		border-radius:2px;
		font-size:$h3 !important;
		@include mainTextSize();
		line-height: initial; 
		line-height: auto;
    	margin: 0;
		 
	}
	&.tips-projectlist{
		border-radius:2px;
		line-height: initial;
		line-height: auto;
		font-size:$h3 !important;
		@include mainTextSize();
		box-shadow: 1px 3px 7px gray;
		 
	}
	&.tips-projectCardList{
		border-radius:2px;
		font-size:$h3 !important;
		@include mainTextSize();
		box-shadow: 1px 3px 7px gray;
		position:absolute;
		bottom:1em;
		line-height: initial;
		line-height: auto;
		
	}
	&.tips-addPlan{ 
		line-height: initial; 
		line-height: auto;
	    border-radius:2px;   
	    
	}
	&.tips-backlog{ 
        border-radius:2px; 
		box-shadow:initial;
		box-shadow:none;
		margin:0 0px;
		line-height: initial;
		line-height: auto;
		margin-bottom:1em;// adopt to traparent dd-list-bottom 
		@include swimLanWidth();
		 
	    
	}
	
	&.tips-addTodo{
		border-radius:2px;
		box-shadow:initial; 
		box-shadow:none;
		line-height: initial;
		line-height: auto;
		//box-shadow: 1px 3px 7px gray;
		margin:0 0px; 
		@include swimLanWidth();
	}
	
	&.tips-fileView{
		border-radius:2px;
		line-height: initial;
		line-height: auto;
		box-shadow: 1px 3px 7px gray; 
		margin: 0 1em 1em 1em;
	}
	
	&.tips-plan-selector{
		position: absolute;
		line-height: initial;
		line-height: auto;
	    width: 25em;
	    bottom: 0.5em;
	    z-index: 300;  
	    padding: 0.5em 26px !important;
	    margin: 0.5em 0 0 0 !important;
	    border-radius: 2px;
	    box-shadow: 1px 3px 7px gray;
	    font-size: 0.8em;
	    &:before{
	    	top:auto !important;
	    	bottom:5px !important;
	    	left:-20px !important;
	    }
	    &:after{
	    	top:auto !important;
	    	bottom:11px !important; 
	    	left:-20px !important;
	    }
	}
	
	&.tips-userDefineFolder{
		width:80%;
		line-height: initial;
		line-height: auto;
	    border-radius:2px; 
	     &.top{
		  	&:before{
		  		  top:- $_arrowHeight; /* value = - border-top-width - border-bottom-width */
				  bottom:auto;
				  left:auto;
				  left:40px; /* controls horizontal position */
				  border-width:0 $_arrowHeight $_arrowHeight;
		  	}
		  	&:after{
		  		  top: - $_arrowHeight; /* value = - border-top-width - border-bottom-width */
				  bottom:auto;
				  left:auto;
				  left:47px; /* value = (:before right) + (:before border-right) - (:after border-right) */
				  border-width:0 13px $_arrowHeight;
		  	}
		}
    } 
    
	&.tips-fileUploaderFolder{
		width:80%;
		line-height: initial; 
		line-height: auto;
	    border-radius:2px; 
	}
	&.tips-fileTagFolder{
		width:80%;
		line-height: initial;
		line-height: auto;
	    border-radius:2px; 
	    
	}
		
}


.triangle-border {
  
  position:relative; 
  padding:1em;

  border-radius:2px; 
  color:#333;
  background:#fff; 
  font-size:1em;
  font-style: italic;
  box-shadow: 1px 3px 7px gray;
   
  margin: 13px 13px 0 13px;
  
  
  li{
  	background:transparent !important;
  	margin-left:1em;
  }

  
  &.tips-gray{
  	$_mainColor: lighten($main-text, 47%);
  	border:none;
  	background: $_mainColor;
  	&:before{
  		border-color:$_mainColor transparent; 
  	}
  	&:after{
  		border-color:$_mainColor transparent; 
  	}
  	&.left{
  		&:before{
  			 border-color:transparent $_mainColor ;
  		}
  		&:after{
  			 border-color:transparent $_mainColor ;
  		}
  	}
  	&.right{
  		&:before{
  			 border-color:transparent $_mainColor ;
  		}
  		&:after{
  			 border-color:transparent $_mainColor ;
  		}
  	}
  }
  
  &.tips-warm{
  	$_mainColor: lighten($warm, 40%);
  	border:none;
  	background: $_mainColor;
  	color:white;
  	&:before{
  		border-color:$_mainColor transparent; 
  	}
  	&:after{
  		border-color:$_mainColor transparent; 
  	}
  	&.left{
  		&:before{
  			 border-color:transparent $_mainColor ;
  		}
  		&:after{
  			 border-color:transparent $_mainColor ;
  		}
  	}
  	&.right{
  		&:before{
  			 border-color:transparent $_mainColor ;
  		}
  		&:after{
  			 border-color:transparent $_mainColor ;
  		}
  	}
  }
  
  &.tips-cool{
  	$_mainColor: lighten($cool, 40%);
  	border:none;
  	background: $_mainColor;
  	color: lighten($_mainColor, 35%);
  	&:before{
  		border-color:$_mainColor transparent; 
  	}
  	&:after{
  		border-color:$_mainColor transparent; 
  	}
  	&.left{
  		&:before{
  			 border-color:transparent $_mainColor ;
  		}
  		&:after{
  			 border-color:transparent $_mainColor ;
  		}
  	}
  	&.right{
  		&:before{
  			 border-color:transparent $_mainColor ;
  		}
  		&:after{
  			 border-color:transparent $_mainColor ;
  		}
  	}
  }
  
  
  &.tips-calm{
  	$_mainColor: lighten($calm, 40%);
  	border:none;
  	background: $_mainColor;
  	&:before{
  		border-color:$_mainColor transparent; 
  	}
  	&:after{
  		border-color:$_mainColor transparent; 
  	}
  	&.left{
  		&:before{
  			 border-color:transparent $_mainColor ;
  		}
  		&:after{
  			 border-color:transparent $_mainColor ;
  		}
  	}
  	&.right{
  		&:before{
  			 border-color:transparent $_mainColor ;
  		}
  		&:after{
  			 border-color:transparent $_mainColor ;
  		}
  	}
  }

  &.tips-fresh{
  	$_mainColor: $fresh;
  	border:none;
  	background: $_mainColor;
  	&:before{
  		border-color:$_mainColor transparent; 
  	}
  	&:after{
  		border-color:$_mainColor transparent; 
  	}
  	&.left{
  		&:before{
  			 border-color:transparent $_mainColor ;
  		}
  		&:after{
  			 border-color:transparent $_mainColor ;
  		}
  	}
  	&.right{
  		&:before{
  			 border-color:transparent $_mainColor ;
  		}
  		&:after{
  			 border-color:transparent $_mainColor ;
  		}
  	}
  }
  
  &.left{
  	margin-left:30px;
  	margin:0px;
  	padding:0px 0.5em;
  	&:before{
  		  top:10px; /* controls vertical position */
		  bottom:auto;
		  left:-30px; /* value = - border-left-width - border-right-width */
		  border-width:15px 30px 15px 0;
		  border-color:transparent #fff;
		
  	}
  	&:after{
  		  top:16px; /* value = (:before top) + (:before border-top) - (:after border-top) */
		  bottom:auto;
		  left:-21px; /* value = - border-left-width - border-right-width */
		  border-width:9px 21px 9px 0;
		  border-color:transparent #fff;
		   
  	}
  }
  &.right{
  	margin-right:30px;
  	&:before{
  		  top:10px; /* controls vertical position */
		  bottom:auto;
		  left:auto;
		  right:-30px; /* value = - border-left-width - border-right-width */
		  border-width:15px 0 15px 30px;
		  border-color:transparent #fff;
  	}
  	&:after{
  		  top:16px; /* value = (:before top) + (:before border-top) - (:after border-top) */
		  bottom:auto;
		  left:auto;
		  right:-21px; /* value = - border-left-width - border-right-width */
		  border-width:9px 0 9px 21px;
		  border-color:transparent #fff;
  	}
  }
  &:before{
  	  content:"";
	  position:absolute;
	  bottom:- $_arrowHeight; /* value = - border-top-width - border-bottom-width */
	  left:40px; /* controls horizontal position */
	  border-width:20px 20px 0;
	  border-style:solid;
	  border-color:#fff transparent;
	  /* reduce the damage in FF3.0 */
	  display:block;
	  width:0;
  }
  &:after{
      content:"";
	  position:absolute; 
	  bottom:-13px; /* value = - border-top-width - border-bottom-width */
	  left:47px; /* value = (:before left) + (:before border-left) - (:after border-left) */
	  border-width:13px 13px 0;
	  border-style:solid;
	  border-color:#fff transparent;
	  /* reduce the damage in FF3.0 */
	  display:block;
	  width:0;
  }
  &.top{
  	&:before{
  		  top:- $_arrowHeight; /* value = - border-top-width - border-bottom-width */
		  bottom:auto;
		  right:auto;
		  left:46px; /* controls horizontal position */
		  border-width:0 $_arrowHeight $_arrowHeight;
  	}
  	&:after{
  		  top:- $_arrowHeight; /* value = - border-top-width - border-bottom-width */
		  bottom:auto;
		  right:auto;
		  left:46px; /* value = (:before right) + (:before border-right) - (:after border-right) */
		  border-width:0 $_arrowHeight $_arrowHeight;
  	}
  }
}

.ui-notification{
		border-radius:2px;
		overflow: hidden; 
		background-color: #90CAF9;
		padding:10px;
		color: rgba(0,0,0,0.8);
		font-size:0.8em;
		a{
			color: inherit;
		}
			

		hr{
			border-color: #fff;
			margin: 5px 0px;
		}
		.message-detail{
			text-decoration:underline;
			max-width: 220px !important;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			display:inline-block;
			overflow: hidden;
			color: rgba(0,0,0,0.8);
		}
		&.UPD{
				background-color: #1DE9B6;
		}
		&.ADD{
				background-color: #4FC3F7;
		}
		&.done{
				background-color: #B2FF59;
			.Todo{
				&:before{content: "\e915";
				font-family: "icomoon" !important;}
			}
		}
		&.doing{
			background-color: #FFCA28;
			
		}
		&.finished{
				background-color: #4527A0;
		}
		&.DEL{
				background-color: #FF5252;
		}
		.File{
			&:before{content: "\e927";
			font-family: "icomoon" !important;}
		}
		.Comment{
			&:before{content: "\e918";
			font-family: "icomoon" !important;}
		}
		.Event{
			&:before{content: "\e924";
			font-family: "icomoon" !important;}
		}
		.Todo{
			&:before{content: "\e90f";
			font-family: "icomoon" !important;}
		}
		.Sprint{
			&:before{content: "\e955";
			font-family: "icomoon" !important;}
		}
		.Phase{
			&:before{content: "\e957";
			font-family: "icomoon" !important;}
		}
		
}
