/*  commonStyle.scss */

a[ng-click] {
    cursor: pointer;
}

::placeholder {
    opacity: 0.4;
}

:-ms-input-placeholder, ::-ms-input-placeholder {
	opacity: 0.4;
}

// 禁用捏合縮放
.no-pinch-zoom {
	touch-action: none;
}

.check-mark-gray {
  color: lightgray;
}

.clickable {
  cursor: pointer;
  
  &[disabled] {
	  cursor: not-allowed;
  }

  &.disabled-element {
	  cursor: default;
  }
}

.no-clickable {
  cursor: default !important;
}

.date-picker {
  cursor: pointer;
}

input.date-picker {
    // font-family: auto !important;
}

.timeAgo.date {
	margin-right: 5px;
	font-size: 110%;
}

.badge.otis-version {
	background: lightcoral;

	&.pull-right {
		margin-top: 5px;
	}
}

.hide-able{
	&.hide{
		@media #{$phone}{
			display:inline-block !important;
		}
	}
}

.threeLine-ellipsis{
	$_lineHeight:1.2em;
	$_lines: 3;
	@include multiline-ellipsis($_lineHeight, $_lines);
	@include smartBreakWord();
}

.twoLine-ellipsis{
	$_lineHeight:1.2em;
	$_lines: 2;
	@include multiline-ellipsis($_lineHeight, $_lines)
}

.toggled-color{
		color: $dynamicToggledColor;
}


.color-black{
	color:black;
}

.color-gray{
	color:gray;
}


.color-lightgray{
	color:lightgray;
}

.color-white{
	color:white;
}

.color-primaryGreen{
	color:#12A7A3;
}

.color-warning{
	color:indianred;
}

.color-overdue {
	// color: $overdueColor;
	color: #a80000;
}


.highlighted {
	background-color: yellow;
}

/* otis btn input */

.input-addon-before {
	&::before{
		position:absolute;
		margin-top:6px;  // revise by smith sun @ 2018/0727 reflect to icon height /lineheight change
		margin-left:5px;
	}
	input{
		padding-left:30px !important;
	}
	.dropdown-toggle{
		padding-left:30px !important;
	}

	&:hover::before{
		color: $toggle-color;
	}
}

a.blocked-link {
  display: block;
}

.btn{
	font-size: inherit !important;
}

.btn-link {
	&.btn-link-no-decoration {
		text-decoration: none;
	}
}
.hover-view-title{ 
	&:hover{
		color: $viewTitleTextHovered;
		background: $toggle-hover;
	};
	&:active{
		color: $viewTitleTextHovered;
		background: $toggle-active;
	};
	&.active, .active{
		color: $viewTitleTextHovered;
	}
}

.hover-dynamic{
	&:hover{
		color: $toggle-color;
		background: $toggle-hover;
	};
	&:active{
		color: $toggle-color;
		background: $toggle-active;
	};
}

// .hover-dynamic-icon{
 
// 	&:hover{
// 		&[class*=" icon-"]:before{
// 			color: $toggle-color;
// 			background: $toggle-hover;
// 		} 
// 	};
// 	&:active{
// 		&[class*=" icon-"]:before{
// 			color: $toggle-color;
// 			background: $toggle-active;
// 		} 
// 	}; 

// }

.hover-gold{
	&:hover{
		color:gold !important;
		
		border-radius:6px;
	}
}

.hover-yellow{
	&:hover{
		color:yellow !important;
		outline:1px solid yellow;
		border-radius:6px; 
	}
}

.hover-navy_blue{
	&:hover{
		color: white;
		background:#6e8090;
		border-radius:6px;
	}
}

.hover-danger{
	&:hover{
		color:white !important;
		background:indianred !important;
		border-radius:6px; 
	}
}

.selected-dark{
	&.selected{
		background:rgba(10,10,10,0.2);
		color:white;
		a{color:white}
	}
}

.hover-bk-gold{
	&:hover{ 
		background:gold !important;
	 
	}
}


.action-more {
	cursor: pointer;

	&.btn-link {
		text-decoration: none;
	}
}

.border-lightgray{
	border:1px solid $color-border-lightgray;
}

.border-gold{
	border-color:gold !important;
}

.width-auto{
	width: auto !important;
}

.width-full{
	width:100%;
}

.maxWidth-md{
	$_maxWidth : 40em;
	@include maxWidthAndCenter($_maxWidth)
}

.maxWidth-sm{
	$_maxWidth : 20em;
	@include maxWidthAndCenter($_maxWidth)
}

.maxHeight-lg{
	 max-height:70vh;
}

.minHeight-sm{
	$_minHeight: 10em;
	@include minHeight($_minHeight)
}

.minHeight-md{
	$_minHeight: 30em;
	@include minHeight($_minHeight)
}

.margin-left-auto{
	margin-left: auto;
}

.otis-input{

	$_searchIconSpace: 1em; 
	line-height: initial;
	line-height: normal;
	display: flex;
	position:relative !important;
	border-radius: 2px !important;
	&.input-border-none{
		input{
			border:0 !important;
		}
	}
	&.input-border-lightgray{
		input{
			border-color: lightgray !important;
		}
	}

	&.input-border-gray{
		input{
			border-color: gray !important;
		}
	}

	&.input-border-white{
		input{
			border-color: white !important;
		}
	}
	&.input-border-dynamic {
		input{
			border-color: $projectSetting-filter-border !important;
		}
	}


	&.input-border-darkgray{
		input{
			border-color: darkgray !important;
		}
	}

	&.input-border-whitesmoke{
		input{
			border-color: whitesmoke !important;
		}
	}

	&.input-border-dynamicColor{
		input{
			// color: whitesmoke !important;
			border-color: lighten($viewTitleTextColor,50%) !important;
		}
	}
	&.width-auto{
		input{
			width:auto !important;
			@media #{$phone}{
				width:100% !important;
			}
		}
	}

	&.input-width-full{
		input{
			width:100% !important;
			@media #{$phone}{
				width:100% !important;
			}
		}
	}
	&.width-sm{
		max-width: 30em;
		input{
			$_elementWidth: 6em;
			width: $_elementWidth;
			padding: 3px 3px !important;
		    //@include retinaElementWidth($_elementWidth);
			@media #{$phone}{
				//width:100% !important;
			}
		}
	}
	&.placeholder-dynamic-theme{
		$mainTextColor: $viewTitleTextColor;
		color:$mainTextColor;
		$_placeHolderColor: $dynamic-theme-placeholder-color;
		@include otisPlaceholderColor($_placeHolderColor);
	}
	&.placeholder-dynamicColor{
		$mainTextColor: $viewTitleTextColor;
		color:$mainTextColor;
		$_placeHolderColor: $dynamicPlaceHolderColor;
		@include otisPlaceholderColor($_placeHolderColor);
	}
	&.placeholder-white{
		$_placeHolderColor: white;
		@include otisPlaceholderColor($_placeHolderColor);
	}
	&.placeholder-gray{
		$_placeHolderColor: gray;
		@include otisPlaceholderColor($_placeHolderColor);
	}

	&.placeholder-lightgray{
		$_placeHolderColor: lightgray;
		@include otisPlaceholderColor($_placeHolderColor);
	}
	&.placeholder-dynamic-theme-date{
		$_placeHolderColor: $dynamic-theme-placeholder-date;
		@include otisPlaceholderColor($_placeHolderColor);
	}
	&.placeholder-transparent{
		$_placeHolderColor: transparent;
		@include otisPlaceholderColor($_placeHolderColor);
	}

	&.input-color-main{
		input{
			color: rgb(50,50,50);
		}
	}
	input{
		/* remove ios and chrome input style */
		-webkit-appearance: none;
		-moz-appearance: none;
		appearance: none;

		/* Then Style */

		font-family:$context-font;
		position: relative !important;
		background:transparent;
	    border: 1px solid $viewTitleTextColor;
	    //padding: 10px;
	    padding-right: $_searchIconSpace;
	    width: 10em;
		font-size: inherit !important;
		$_placeHolderColor: $dynamicPlaceHolderColor;
		&::-webkit-input-placeholder { /* Chrome/Opera/Safari */
		  color: $_placeHolderColor !important;
		  font-family:$context-font;
		}
		&::-moz-placeholder { /* Firefox 19+ */
		  color: $_placeHolderColor !important;
		  font-family:$context-font;
		}
		&:-ms-input-placeholder { /* IE 10+ */
		  color: $_placeHolderColor !important;
		  font-family:$context-font;
		}
		&:-moz-placeholder { /* Firefox 18- */
		  color: $_placeHolderColor !important;
		  font-family:$context-font;
		}

		@media #{$phone}{
			  width: 6em;
			  padding:10px;
	  	}

	}
	&.filter-icon-dynamicColor{
		&:after{
		    
			color: $dynamicPlaceHolderColor !important;
		  	@media #{$phone}{
		 	 	// margin-left:- ($_searchIconSpace * 1.1);
				color: gray;
		  	}
		}
	}

	&.filter-icon{

		&:after{
		    content: "\e92c";
		    font-family: "icomoon" !important;
		  	position: absolute;
			top: 0px;
			right: 5px;
			color: lightgrey;
		  	@media #{$phone}{
		 	 	// margin-left:- ($_searchIconSpace * 1.1);
				color: gray;
		  	}
		}
	}
	&.filter-icon-lg{

		&:after{
		    content: "\e92c";
		    font-family: "icomoon" !important;
		  	position: absolute;
			top: 0px;
			right: 5px;
			color: lightgrey;
			font-size:1.6em;
		  	@media #{$phone}{
		 	 	// margin-left:- ($_searchIconSpace * 1.1);
				color: gray;
		  	}
		}
	}
  	.input-addon-right {
	    position: absolute;
	    right: 6px;
	    height:100%;
		z-index:10;
		display:flex;
		align-items:center;
	    color:inherit;
	    //font-size: 0.8em !important;
	    cursor:pointer;
	    //z-index:100;
	}

}

.otis-dropdown-toggle {
	color: $toggle-color;
	&:focus{
		outline:0;
	}

	&:hover{
		background: $toggle-hover;
		color: $toggle-color;
	}

	&:active{
		background: $toggle-active ;
	}

	&.open{
		background: $toggle-select;
	}
	.dropdown-menu {
		li:hover, li>a:hover {
			background: $toggle-hover;
			color: $toggle-color;
		}
	}

}


.project-display-name{
	position:relative;
	color:#585858;
	text-align:left;
	line-height:1.3em;
	word-wrap:break-word;
	font-size:1.3em;
	margin:0px;
	margin-top:-4px;
	text-overflow:ellipsis;
	padding:1vh 0.5vw;
}

.recent-bell{
	position: absolute !important;
    right: -0.5em;
    margin-top: -1em;
    cursor:pointer;
	cursor:hand;
    &.today{
  		background: red !important;
    }
    &.inThreeDays{
    	background: orange !important;
    }
}

.recent-update{
		&.today{
			font-weight:800 !important;
			color:$overdueColor;
			opacity:1;
		}
		&.inThreeDays{
			font-weight:800 !important;
			color:black;
			opacity:1;
		}
}



.pull-left{
	.btn{
		margin-right: 6px!important;
	}
}

.pull-right{
	.btn{
		margin-left: 6px !important;
	}
}


/* color related */
.dynamic-color-gray{
	color: $dynamicColorGray;
	$mainTextColor:$viewTitleTextColor;
	select{
		border-color: $mainTextColor !important;
		background: transparent !important;
		color: $viewTitleTextColor !important;
	}
	button{
		border-color: $mainTextColor !important;
		background: transparent !important;
		color: $viewTitleTextColor !important;
	}
	input{
		border-color: $mainTextColor !important;
		background: transparent !important;
		color: $viewTitleTextColor !important;
		&::-webkit-input-placeholder { /* Chrome/Opera/Safari */
		  color: $dynamicColorGray !important;
		}
		&::-moz-placeholder { /* Firefox 19+ */
		  color: $dynamicColorGray  !important;
		}
		&:-ms-input-placeholder { /* IE 10+ */
		  color: $dynamicColorGray  !important;
		}
		&:-moz-placeholder { /* Firefox 18- */
		  color: $dynamicColorGray  !important;
		}
	}
}

.dynamic-link-color{
	color: darken( $dynamicLinkColor, 20%);
	&:hover{
		color: $dynamic-link-hover-color;
	}
	&:focus{
		color: inherit;
	}
}

.color-gray{
	color:gray;
}

.color-yellow{
	color:yellow;
}

.color-warn{
	color:indianred;
}


.dynamic-text-color{
	color: $viewTitleTextColor !important;
	&:hover{
		color:  $viewTitleTextColor !important;
	}
}


/* font style */
.tiny-text{
	@include tinyTextSize();
}
.header-text-h2{
	@include headerTexth2();
}
/* text style */
.main-text{
	@include mainTextSize();
}

.scroll-no-bar{
	$barColor: lighten($main-text,30%);
	@include scrollTinyBar($barColor,$barSize);
}

.div-vertical-align-middle{
	margin:0px 0px 0px 0px;
	font-size:1.2em;
	position:relative;
	padding:0px;

}

.red_mark{
	color:red;
}

.text-ellipsis{
    text-overflow: ellipsis;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
}

.text-nowrap{
	white-space:nowrap;
}

.div-vertical-align-middle-xs{
	position:relative;
 	padding:0px;
 	margin:0px;
	display:table;
	font-size:1em;
	display:inline-block
}


.strong-font{
	font-weight: 800;
}

.marked-text-html{
	p{
		white-space:pre-wrap;
	}
	li{
		white-space:pre-wrap;
	}
	ol,ul{
		-webkit-padding-start: 30px;
	}
}

.ol{
	padding:0px !important;
}


.firstLetterUpperCase{
	display:inline-block;
	&:first-letter{
	    text-transform: uppercase;
	}
}


.firstLetter-lg{
	display:inline-block;
	&:first-letter{
	    font-size:1.2em;
	}
}

.cursor-pointer{
	cursor:pointer;
}

.otis-info-title{
	 @include otisInfoTitle();
	 @include unSelectable();
}

.info-color-background{
	background-color: antiquewhite;
}

.otis-tbl-header{
	font-weight: 800;
	//min-width:8em;
	line-height: 1em;
	padding: 3px 0;
	margin-bottom: 5px;
	@include h2TextSize();
	border-bottom: 1px solid lightgray;
}

.smallTextSize{
	@include smallTextSize();
}

.otis-info-card{
	@include otisInfoCard();
}

.otis-round-btn{
	$_iconSize: 1.5em;
	//@include otisRoundBtn();
	[class^="icon-"], [class*=" icon-"]{
		height:$_iconSize;
		width:$_iconSize;
		display:inline-block;
		text-align:center;
		line-height: $_iconSize;
		border-radius:50%;
		border: 1px solid   ;
		border-color:lightgray;

	}
	&:hover{
		[class^="icon-"], [class*=" icon-"]{

			border-color:#337ab7;

		}
	}
}

.otis-round-btn-dynamic{
	$_iconSize: 1.5em;
	//@include otisRoundBtn();
	[class^="icon-"], [class*=" icon-"]{
		height:$_iconSize;
		width:$_iconSize;
		display:inline-block;
		text-align:center;
		line-height: $_iconSize;
		border-radius:50%;
		border: 1px solid   ;
		border-color: $btn-round-color;

	}
	&:hover{
		[class^="icon-"], [class*=" icon-"]{

			border-color: $btn-round-color-hover;

		}
	}
}

.otis-btn-sm{
	padding:3px 6px !important;
}

.otis-btn-link {
	color: $btn-link-color;
	&:hover {
		color: $btn-link-color-hover;
	}
	&:active {
		box-shadow: none;
	}
}

.otis-underline-tab{
	padding: 6px 12px;
	&.active, &active{
		background:rgba(10,10,10,0.2);
		border-bottom: 2px solid;
   		margin-bottom: -2px;
		border-color:#337ab7;
	}
}


.otis-stepper-link {
	$_stepper-link-color:#70767A ;
	$_stepper-link-color-hover: lighten(#337AB7, 10%);
	$_stepper-link-color-active: #337AB7;
	$_stepper-link-color-checked: #337AB7;

	color: $_stepper-link-color !important; 
 
	&:hover {
		color: $_stepper-link-color-hover !important;
		 
	}
	&:active {
		box-shadow: none;
		 
		color: $_stepper-link-color-active !important;
	}
	&.active{
		color: $_stepper-link-color-active !important;
	}
	&.checked{
		color: $_stepper-link-color-checked !important;
	}
}


.view-title-text{
	color: $viewTitleTextColor !important;
}

.view-title-height{
	height: $viewTitleHeight;
	line-height: $viewTitleHeight;
}

.short-text{
	white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

/* display and layout */

.ie-fixed-full-page{
	position:fixed;
	top:0;
	left:0;
	height:100vh;
	width:100vw;
	z-index:300;

}

.hidden-xxs{
	@media #{$miniPhone}{
		display: none;
	}
}

.due-status-tag{
	@include overdueColorSet();
	@include unSelectable();
	min-height:1.8em;

	&.--no-dueDate {
		display: none;
	}
}

.dropdown-toggle {
	cursor: pointer;

	&[disabled] {
		cursor: default;
	}
}

.dropdown-menu{
	@include unSelectable();
	
	li>i:first-child {
		&[class^="icon-"],
		&[class*=" icon-"] {
			min-width: 20px;
			display: inline-block;
		}
	}
}

.unselectable{
	@include unSelectable();
}

.inline-flex{
	display: inline-flex !important;
}

.inline-flex-fullWidth{
	display: inline-flex !important;
	width: 100% !important;
}

.justify-content-space-between{
	justify-content: space-between;
}

.justify-content-space-around{
	justify-content: space-around;
}

.justify-content-center{
	justify-content: center;
}

.justify-content-end{
	justify-content: flex-end;
}
.align-center--baseline{
	align-content: baseline;
}
.flex{
	display: flex !important;
}
.flex-grow{
	flex-grow:1;
}

.flex-wrap{
	flex-wrap:wrap;
}

.flex-center{
	align-items:center;
}

.flex-baseline{
	align-items:baseline;
}

.flex-end{
	align-items:end;
}

.flex-start{
	align-items:start;
}
.display-hidden{
	display:none !important;
}

.relative{
	position:relative;
}

.display-block{
	display:block !important;
}

.inline-block{
	display:inline-block;
}

.inline-block-middle{
	display: inline-block;
	vertical-align:middle;
}
.display-none{
	display:none;
}

.visible{
	display:block !important;
}

.show-fullWidth{
	display:block;
	width:100% !important;
}

.show-fullWidth-xs{
	@media #{$phone}{
		display:block;
		width:100%;
	}
}

.rtl{
	direction:rtl !important;
}

.ltr{
	direction:ltr !important;
}

.absolute{
	position:absolute !important;
}

.border-none{
	border:none;
}

.border-bottom-dynamicColorGray{
	border-bottom:1px solid $dynamicColorGray;
}

.short-divider{
	height:1px;
	margin: 0 2em 1em 2em;
	border-bottom: 1px solid lightgray;
}

.clear-both{
	clear:both;
}

/*
.overlay{
	background: rgba($overlay-color, 0.5);
	width:100vw;
	height:100%;
	position:fixed;
	top:0;
	left:0;

}
*/
.layerOne{
		z-index:100;
	}
.layerTwo{
		z-index:200;
	}
.layerThree{
		z-index:300;
	}
.layerFour{
		z-index:400;
	}

/* margin padding */


.height-zero{
	height:0 !important;
}

.margin-right-sm{
	margin-right:0.5em;
	text-indent:0px;
}

.margin-right-md{
	margin-right:1em;
	text-indent:0px;
}

.padding-all-sm{
	padding: 3px 6px !important;
}

.padding-md{
	padding: 6px 12px !important;
}

.padding-sm{
	$_padding:15px;
	padding:0 $_padding * 0.5 !important;
}

.padding-xs{
	$_padding:15px;
	padding:0 $_padding * 0.2 !important;
}

.col-padding-sm{
	$_padding:15px;
	padding:0 $_padding * 0.5;
}

.col-padding-xs{
	$_padding:15px;
	padding:0  $_padding * 0.2;
}

.no-padding{
	padding:0px
}

.no-padding-right{
	padding-right:0px !important;
}

.no-padding-left{
	padding-left:0px !important;
}

.no-padding-absolute{
	padding:0px !important;
}

.no-margin-absolute{
	margin:0px !important;
}

.margin-r-3{
	margin-right:3px;
}
.mg-r-5{
	margin-right: 5px;
}
.mg-l-5{
	margin-left: 5px;
}
.margin-rl-4{
	margin:0 7px 0 4px;
}

.margin-rl-sm{
	margin:0px 10px;
}

.margin-rl-xs{
	margin:0px 5px;
}

.margin-xs{
	margin:5px !important;
}

.margin-sm{
	margin:10px !important;
}

.margin-md{

	margin:15px !important;
}

.margin-top-lg{
	margin-top:15px;
}

.margin-top-md{
	margin-top:8px;
}

.margin-top-xs{
	margin-top:5px;
}

.margin-top-only{
	margin-bottom:0 !important;
	margin-left: 0 !important;
	margin-right: 0 !important;
}
.margin-bottom-lg{
	margin-bottom:15px;
}
.margin-bottom-md{
	margin-bottom:8px;
}

.margin-bottom-sm{
	margin-bottom:6px;
}

.margin-bottom-xs{
	margin-bottom: 5px;
}

.no-margin{
	margin:0px ;
}

.no-margin-right{
	margin-right:0 !important;
}

.no-margin-mb{
	@media #{$phone}{
		margin:0 !important;
	}
}

.no-padding-mb{
	@media #{$phone}{
		padding:0 !important;
	}
}

.margin-bottom-sm-mb{
	@media #{$phone}{
		margin-bottom:5px !important;
	}
}

.small-padding{
	padding:2px;
}

.row-rl-sm{
	margin-left:-10px;
	margin-right:-10px;
}
.row-rl-xs{
	margin-left:-5px;
	margin-right:-5px;
}

.padding-right-sm{
	padding:0 10px 0 0 ;
}

.padding-left-xs{
	padding:0 0 0 5px;
}
.p-sm{
	padding: 10px;
}
.padding-rl-sm{
	padding:0 10px;
}
.padding-tb-sm{
	padding:10px 0;
}
.padding-rl-xs{
	padding:0 5px;
}
.padding-tb-xs{
	padding:5px 0;
}
.padding-left-md{
	padding:0 0 0 10px;
}

.padding-right-xs {
  padding:0 5px 0 0 ;
}

.margin-right-xs {
  margin-right: 5px;
}

.font-xs{
  font-size:0.8em !important;
}

.xxs-font-xs{
	@media #{$miniPhone}{
		font-size:0.7em !important;
	}
}

.xxs-font-sm{
	@media #{$miniPhone}{
		font-size:0.8em !important;
	}
}

.font-lg{
	font-size:1.2em !important;
}

.font-xl{
	font-size:1.5em !important;
}

.font-xxl{
	font-size: 1.8em !important;
}


.font-xxxl{
	font-size:2em !important;
}

.font-sm{
	font-size:0.9em !important;
}

.font-xsm{
	font-size:0.6em !important;
}

.font-lg-r{
	font-size:1.4rem !important;
}

.font-xl-r{
	font-size:1.6rem !important;
}

.font-xxl-r{
	font-size: 2.6rem !important;
}

.font-sm-r{
	font-size:1rem !important;
}

.font-xsm-r{
	font-size:0.6rem !important;
}

.align-right{
	text-align: right !important;
}

.align-left{
	text-align: left !important;
}


.align-center{
	text-align:center !important;;
}


.scroll-padding-bottom{
	padding-bottom:20vh !important;
}



/* icon related */

.icon-btn{
	$_size:30px;
	$_background:gray;
	$_color:lightgray;
	width:$_size;
	height:$_size;
	text-align:center;
	display:inline-block;
	line-height:$_size;
	border-radius: 3px;
	border:1px solid $_color;
	color:$_background;
	background:$_color;
	&:hover{
		color: $_color;
		background:$_background;
	}
	&:active{
		color:$_background;
		background:$_color;
	}
	&.dynamic {
		background: transparent;
		color: #d3d3d3;
		&:hover {
			background: $dynamic-icon-btn-hover;
		}
	}
}

.circle-icon{
	$_scale:40px;
	height: $_scale;
	width: $_scale;
	background: $dropDrown-color;
	color:white;
	line-height: $_scale;
	border-radius:50%;
	text-align:center;
	display:inline-block;
	flex:0 0 auto;
	&.primary-green{
		color:#00DBA7;
		background:transparent;
		border:1px solid #00DBA7;
	}
	&.icon-sm{
		$_scale:25px;
		height: $_scale !important;
		width: $_scale !important;
		line-height: $_scale !important;
	}
	&.icon-md{
		$_scale:36px;
		height: $_scale !important;
		width: $_scale !important;
		line-height: $_scale !important;
	}
}

.square-icon{
	$_scale:40px;
	height: $_scale;
	width: $_scale;
	background:white;
	border-radius:6px;
	text-align: center;
	color:gray;
	&.icon-sm{
		$_scale:25px;
		height: $_scale !important;
		width: $_scale !important;
		line-height: $_scale !important;
	}
	&.icon-md{
		$_scale:36px;
		height: $_scale !important;
		width: $_scale !important;
		line-height: $_scale !important;
	}
}

.icon-black{
    background: black !important;
    color:white !important;
}

.icon-gray{
	background: gray !important;
	color:white !important;
}

.icon-sm{
    $_scale:25px;
    height: $_scale !important;
    width: $_scale !important;
    line-height: $_scale !important;
}

.icon-xl{
	$_scale:2em;
    height: $_scale !important;
    width: $_scale !important;
    line-height: $_scale !important;
}

.icon-lg{
	$_scale:22px;
    height: $_scale !important;
    width: $_scale !important;
    line-height: $_scale !important;
}

.icon-xs{
    $_scale:18px;
    height: $_scale !important;
    width: $_scale !important;
    line-height: $_scale !important;
}

.icon-xxs{
    $_scale:16px;
    height: $_scale !important;
    width: $_scale !important;
    line-height: $_scale !important;
}

.image-reverse-Y{
	-webkit-transform: scaleY(-1);
    transform: scaleY(-1);
}

.gray-icon{
	color:gray;
	opacity:1;
}


.linkify-icon {
    font-size: 60%;
    padding-left: 3px;
    color: gray;
}

.otis-width-full{
	width:100% !important;
}

/* otis modal related */
.close{
	@include  mainIconSize();
    padding: 6px !important;
    //top:0px; // remove this to meet subtodo modal style, check if impact to other
    position: absolute !important;
    right: 0 !important;
    z-index: 100;
	opacity: 0.8;
	line-height: 1em;
	&.close-white{
		color:white !important;
	}

	&:active {
		background: $close-btn-hover !important;
		color: #fff;
		opacity: 1;
		text-shadow:none;
	}
	
	@include hover-supported {
		background: $close-btn-hover;
		// border-radius: 0px 4px 0px 0px;  // TODO: remove it after refatoring [Benx 2019-04-12 11:01:06]
		color: #fff;
		opacity: 1;
		text-shadow:none;
		// @media #{$phone} {  // TODO: remove it after refatoring [Benx 2019-04-12 11:01:06]
		// 	border-radius: 0px;
		// }
	}


	&.close-sm{
		font-size:0.8em !important;
		padding: 3px !important;
	}
	
	&.close-xs{
   		font-size: 0.6em !important;
   		padding: 0px !important;
	}
	&.close-xxs{
		font-size: 0.5em !important;
   		padding: 0px !important;
	}
	&.close-clear{
		opacity: 0.8 !important;
	}

	&.align-top{
		top:0;
	}
}

// .icon-delete {
// 	@include hover-supported {
// 		color: #ac0404;
// 	}

// 	&:active {
// 		color: #ac0404;
// 	}
// }

.otis-table{

	thead {
		color:$dynamic-thead-color;
		background:  $dynamic-thead-background;
	}

	tbody{
		td{
			background:white;
		}
	}
	.ng-table th.sortable.sort-desc .sort-indicator:after, .ng-table th.sortable.sort-asc .sort-indicator:after {
		border-color: $table-sort-indicator-active transparent;
	}

	.pagination{
		margin:0 !important;

		@media #{$phone} {
			margin: 10px 0px !important;
		}
	}
	&.padding-sm{
		td{
			padding: 3px 6px !important;
		}
	}
}

.otis-th-row{
	background: rgba(100,100,100,0.8);
	color: white;
	padding:10px 0;
}
.otis-td-row{
	.otis-td{
		padding: 5px 0;
		background-color: #e0e9f4;
		&:nth-child(odd){
			background-color:rgba(255,255,255,0.85);
		}
	}
	.otis-td-child{
		&:nth-child(even){
			background-color:rgba(100,100,100,0.1);
			padding: 5px 0;
			margin-bottom: -5px;
		}
	}
}

.activity:before {
  border-right: 0px solid #d1d4d7 !important;
}


.no-box-shadow{
	box-shadow:initial !important;
}

.strength {
	color: #006666 !important;
	@include mainTextSize();
	&:before {
		position: absolute;
		top: 1.25rem;
		right: 1rem;
		font-size: 1.5rem;
		font-family: 'icomoon' !important;
		font-style: normal !important;
		font-weight: normal !important;
		font-variant: normal !important;
		text-transform: none !important;
		speak: none;
		-webkit-font-smoothing: antialiased;
		content: '\e90f';
		color: lighten(black, 60%);
	}
	&.hide_strength:before {
		color: lighten(black, 20%);
	}
}

.strength-meter{
	position:relative !important;
	left:40%;
	top:-20px !important;;
	width:60% !important;
	height:20px;
	line-height:20px;
	border-radius:10px;
}

.even-background{
		background: $even-background-color;

}

.odd-background{
		background: $odd-background-color;
}


.register-form{
	padding:20px;
	display:inline-block;
}

.divider{
	border-top: 1px solid lightgray;
	margin: 6px 0;
}

.dropdown-menu.option-menu .divider {
	border-top: 1px solid #5a5a5a;
}

.dropdown-menu-header{
	background: $main-background-hover;
	color: rgba($header-text, 0.5);
	padding: 6px;
	cursor: initial;
	//line-height:initial !important; cause shrink line height in  list view selected sprint dropdown menu.
	&:hover{
		background: $main-background-hover;
	}
}

.dropdown-menu-header-small{
	height:30px;
	line-height:30px;
	text-align:center;
	border-bottom: 2px solid #f2f2f2 !important;
}


/*-----disable IE input control, hide the X in input and eye in password */

::-ms-clear{
	display:none;
}

::-ms-reveal{
	display:none;
}

.mid-display{
	line-height:30px;
	height:30px;
	max-width:200px;
	white-space:nowrap;
	overflow:hidden;
	text-overflow:ellipsis;
	padding-top:4px;
	text-align:left;
}


.dropdown-width-middle{
	max-height:60vh !important;
	//width:18vw !important;
	min-width:400px !important;
	@media #{$phone}{
		min-width:60vw !important;
	}

}

.dropdown-width-small{
	max-height:60vh !important;
	width:12vw !important;
	min-width:10em !important;
}

.dropdown-width-tiny{
	max-height:60vh !important;
	width:8vw !important;
	min-width: 8em !important;
}


/* sprint view  css  */


.margin-top-small{
	margin-top:8px !important;
}


/* revised from Smith */

.img-responsive{
	width:100%;
}

.filter-trails{
	position:relative;
	margin:0px 0px -35px 0px;
	z-index: 1;
}

.filter-trails-btn{
	display:inline-block;
	background-color:#EFEFEF;
	border:1px solid #DDD;
	padding:0em 1em;
	margin-left:10px;
	margin-top:-4px;
	color:#08c;
	text-decoration:none;
	height:2.4em;
	line-height:2.4em;
}

.filter-trails-btn:hover{
	background-color:#08c;
	color:#EFEFEF;
	cursor:hand;
	cursor:pointer;
}

.filter-trails-close-icon{
	 margin-left:5px;
	 font-size:1.2em;
}


.hover-ctrl:hover .hover-show {
	display: '';
}


.filterOn {
	color: red !important;
}

.hover-show {
	visibility: hidden;
}

.hover-ctrl:hover .hover-show {
	visibility: visible;
}

.text-placeholder {
  color: gray;
}

.note-content {
	word-break: break-word;
}

.read-only{
	background:whitesmoke;
	padding:6px;
	cursor:not-allowed;
	min-height: 36px;
}

label, .label{
	@include unSelectable();
}

iframe.video {
	width: 100%;
}

.dot {
    height: 10px;
    width: 10px;
    background-color: #bbb;
    border-radius: 50%;
	display: inline-block;
	
	&.invisible, &.hidden {
		background-color: transparent;
		opacity: 0;
	}
}

.cfp-hotkeys-container {
	.cfp-hotkeys > table {
		display: flex;
		overflow-y: auto;
		justify-content: center;
		max-height: calc( 100% - 33px);
	}
}

.bg-gray {
	background-color: gray;
}

.date-interval {
	margin: 0px 10px;
}

.access-restriction-icon {
	font-size: 13px;
	padding-left: 4px;
}

pop-comment-editor {
	// Note: reference https://stackoverflow.com/questions/5394116/outline-radius [Jane 2019-04-17 15:06:36]
	textarea:focus {
		outline: 0;
		border-radius: 8px;
		box-shadow: 0 0 0 1px rgb(77,144,254);
	}
}

.d-flex {
	display: flex !important;
}

.d-inline-block {
	display: inline-block;
}


.highlighted {
	background: rgb(245, 230, 92);
	color: black;

	&.__match-1 {
		background: rgb(171, 252, 118);
	}

	&.__match-2 {
		background: rgb(253, 183, 236);
	}

	&.__match-3 {
		background: rgb(144, 203, 252);
	}

	&.__match-4 {
		background: rgb(252, 134, 88);
	}
}

.icon-topic-2 {
	font-size: 130% !important;
}

.nav-pollex-icon{
	font-size: 35px;
	color:  $white;
	margin-top: 0.5rem;
	svg > path {
		fill: $white;
	}
}

.w-auto{
	width:auto !important;
}

.w-100{
	width: 100% !important;
}

.w-60{
	width: 60% !important;
}

.w-10em{
	width:10em;
}

.w-15em{
	width:15em;
}

.w-20em{
	width:20em;
}

.w-30em{
	width:30em;
}

.r-1{
	right: $scale1 !important;
}

.r-2{
	right: $scale2 !important;
}

.r-3{
	right: $scale3 !important;
}

.r-4{
	right: $scale4 !important;
}


.p-3{
	padding: $scale3 !important;
}
.p-2{
	padding: $scale2 !important;
}
.p-1{
	padding: $scale1 !important;
}

.px-3{
	padding-right:$scale3 !important;
	padding-left:$scale3 !important;
}

.px-2{
	padding-right:$scale2 !important;
	padding-left:$scale2 !important;
}

.px-1{
	padding-right:$scale1 !important;
	padding-left:$scale1 !important;
}

.py-3{
	padding-top:$scale3 !important;
	padding-bottom:$scale3 !important;
}

.py-2{
	padding-top:$scale2 !important;
	padding-bottom:$scale2 !important;
}

.py-1{
	padding-top:$scale1 !important;
	padding-bottom:$scale1 !important;
}


.pt-3{
	padding-top:$scale3 !important; 
}

.pt-2{
	padding-top:$scale2 !important; 
}

.pt-1{
	padding-top:$scale1 !important; 
}

.pb-10em{
	padding-bottom:10em !important;
}

.pb-6{
	padding-bottom:$scale6 !important;
}

.pb-5{
	padding-bottom:$scale5 !important;
}

.pb-4{
	padding-bottom:$scale4 !important;
}

.pb-3{
	padding-bottom:$scale3 !important;
}

.pb-2{
	padding-bottom:$scale2 !important;
}

.pb-1{
	padding-bottom:$scale1 !important;
}


.pl-6{ 
	padding-left:$scale6 !important;
}

.pl-5{ 
	padding-left:$scale5 !important;
}

.pl-4{ 
	padding-left:$scale4 !important;
}

.pl-3{ 
	padding-left:$scale3 !important;
}

.pl-2{ 
	padding-left:$scale2 !important;
}

.pl-1{ 
	padding-left:$scale1 !important;
}


.pr-6{
	padding-right:$scale6 !important; 
}

.pr-5{
	padding-right:$scale5 !important; 
}


.pr-3{
	padding-right:$scale3 !important; 
}

.pr-2{
	padding-right:$scale2 !important; 
}

.pr-1{
	padding-right:$scale1 !important; 
}

.p-0{
	padding:0 !important;
}

.pb-0{
	padding-bottom:0 !important;
}

.m-3{
    margin: $scale3 !important;
}
.m-2{
    margin: $scale2 !important;
}
.m-1{
    margin: $scale1 !important;
}

.mx-3{
    margin-right:$scale3 !important;
    margin-left:$scale3 !important;
}

.mx-2{
    margin-right:$scale2 !important;
    margin-left:$scale2 !important;
}

.mx-1{
    margin-right:$scale1 !important;
    margin-left:$scale1 !important;
}

.my-3{
    margin-top:$scale3 !important;
    margin-bottom:$scale3 !important;
}

.my-2{
    margin-top:$scale2 !important;
    margin-bottom:$scale2 !important;
}

.my-1{
    margin-top:$scale1 !important;
    margin-bottom:$scale1 !important;
}

.mt-3{
    margin-top:$scale3 !important; 
}

.mt-2{
    margin-top:$scale2 !important; 
}

.mt-1{
    margin-top:$scale1 !important; 
}

.mb-3{
    margin-bottom:$scale3 !important;
}

.mb-2{
    margin-bottom:$scale2 !important;
}

.mb-1{
    margin-bottom:$scale1 !important;
}

.ml-3{ 
    margin-left:$scale3 !important;
}

.ml-2{ 
    margin-left:$scale2 !important;
}

.ml-1{ 
    margin-left:$scale1 !important;
}

.m-3{
	margin: $scale3 !important;
}
.m-2{
	margin: $scale2 !important;
}
.m-1{
	margin: $scale1 !important;
}

.mx-3{
	margin-right:$scale3 !important;
	margin-left:$scale3 !important;
}

.mx-2{
	margin-right:$scale2 !important;
	margin-left:$scale2 !important;
}

.mx-1{
	margin-right:$scale1 !important;
	margin-left:$scale1 !important;
}

.my-3{
	margin-top:$scale3 !important;
	margin-bottom:$scale3 !important;
}

.my-2{
	margin-top:$scale2 !important;
	margin-bottom:$scale2 !important;
}

.my-1{
	margin-top:$scale1 !important;
	margin-bottom:$scale1 !important;
}


.mt-3{
	margin-top:$scale3 !important; 
}

.mt-2{
	margin-top:$scale2 !important; 
}

.mt-1{
	margin-top:$scale1 !important; 
}


.mb-3{
	margin-bottom:$scale3 !important;
}

.mb-2{
	margin-bottom:$scale2 !important;
}

.mb-1{
	margin-bottom:$scale1 !important;
}

.ml-3{ 
	margin-left:$scale3 !important;
}

.ml-2{ 
	margin-left:$scale2 !important;
}

.ml-1{ 
	margin-left:$scale1 !important;
}

.mr-3{
	margin-right:$scale3 !important; 
}

.mr-2{
	margin-right:$scale2 !important; 
}

.mr-1{
	margin-right:$scale1 !important; 
}

.m-0{
	margin:0 !important;
}

.mb-0{
	margin-bottom:0 !important;
}

.overflow-auto{
	overflow:auto !important;
}

.bk-white{
	background:white !important;  
}

.bk-transparent{
	background:transparent !important;
}

.position-relative{
	position:relative !important;
}

.position-absolute{
	position:absolute !important;
}

.pre-wrap{
	white-space:pre-wrap !important;
}

 

