.attached-file-list{ 
    $_mediaHeight: 250px;
    $_borderWidth: 1px;
    $_mediaPadding: 10px; 
    display:flex;
    flex-wrap:wrap;
    //width:100%;
    .file-attachment{
        width:30%;
        margin-left: 2%;
        @media #{$tablet}{
            width:50%;
        }
        @media #{$phone}{
            width:100%;
        }
    }
    .media-attachment{
        margin-left: 2%;
        @media #{$phone} {
            width: 100%;
        }
        @media screen and (min-width: 768px) and (max-width:1279px) {
            width: 210px;
        }
        @media screen and (min-width: 1280px) and (max-width:1440px) { 
            width: 230px;
        }
        @media screen and (min-width: 1441px) and (max-width:1680px) { 
            width: 210px;
        }
        @media screen and (min-width: 1680px){ 
            width: 255px;
        }
        .attachment-entry{
            margin-top:2px;
        }
    }
    .file-name-link{
        width: calc(100% - 30px);  
        font-size: 0.95em; 
        color: inherit;
        display:inline-block; 
        line-height:1.2em;
        overflow-x: hidden;
        padding: 0 0 5px 5px ;
        .delete-file {
            margin: 0px 2px;
        }
        .edit-file {
            margin: 0px 2px;
        }
    }
    .file-uploader-span{ 
        color:gray; 
        font-size:0.8em; 
    }
    .file-name-span{ 
        color: inherit;
    }
    .media-zone{
        display:block;
        width: 100%;
        overflow:hidden; // this can restrice the max-height
        background: whitesmoke; 
    }
    .media-file{
        //max-height: $_mediaHeight;  // this will cut picture
        width:100%;
        padding: $_mediaPadding; 
        vertical-align: middle;
        margin: auto;
        display: block;
        text-align:center;
    }  
    .thumbnail {
        width: auto ;
        max-height: 100%;
        max-width: 100% ;
        display:inline-block;
    }
    pop-video{
        video{
            max-height: calc( #{$_mediaHeight} - #{$_mediaPadding} * 2 - #{$_borderWidth} * 2) !important;
        }
    }
    .media-attachment{
        .file-name-span{
            width: calc( 100% - 60px);
            display:inline-block;
            @media #{$phone} {
                width: calc( 100% - 35px);
            }
        }
    }
}

pop-attachment-list {
	.btn-attached-download-all {
		font-size: smaller;
	}
}