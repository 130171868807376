.fb-embedded-block{
    background: gold; 
    @include todoCardShadow();
    border:0 !important;
}
 
.fb-info-header{
    padding: 5px 15px;
    background:lighten( lightblue, 5%);
}

.fb-info-body{
    //padding: 15px; 
    background:lighten( lightblue, 10%);
}

.fb-plugin-section{
    border-bottom: 1px solid lightgray;
    padding: 10px 15px;
}

.fb-info-title{ 
    color: white;
    background: rgba(10,10,10,0.8);
    border: 0;
    padding: 3px 20px;
}

.fb-pushpin{
    color: white;
    border-radius: 50%;
    background: indianred;
    width: 20px;
    height: 20px;
    font-size: 15px;
    text-align: center;
    display: inline-block;
    margin-right: 10px; 
}
$_blockSize: 4rem;

 

.fb-stage-block{ 
    min-width: $_blockSize * 1.3; 
    white-space: normal;
    //height:$_blockSize;
    color:white;
    text-align:center;
    display:inline-block;
    background: rgba(100,100,100,0.6);
    padding: 6px;
    position:relative;
    margin-right:15px;
    @include otisToggle();
    &.disable{
        color:darkgray;
    }
    &.active{
        background: lighten( $cool, 15%) !important;
        color:white;
    }
    &.done{
        background:lightgray;
        color: lightslategray;
        // &:after{
        //     $_size: 30px; 
        //     content:'\e914'; 
        //     font-family: pollex;
        //     color: white;
        //     position: absolute;
        //     font-size: $_size;
        //     font-weight: 200;
        //     left: calc( 50% -  #{$_size} * 0.5 );
        //     top: calc( 50% - #{$_size} * 0.75 );
        // }
    }
    // &:not(:first-child):before {
    //     $_size: 20px; 
    //     content:'\e906'; 
    //     font-family: pollex;
    //     color: rgba(150,150,150,1);
    //     position: absolute;
    //     font-size: $_size;
    //     font-weight: 200;
    //     left: - $_size - 5px ;
    //     top: calc( 50% - #{$_size} );
    // }
}

.fb-flow-block{
    margin-right:15px;

}

.next-arrow{
    color:gray;
    font-size:20px;
    line-height: $_blockSize;
}

.fb-process-title{
    //border-bottom: 1px solid darkgray;
    margin:0 10px 10px 0;
    //text-align: center;
}

.fb-signature{
    @include tinyTextSize();
    line-height: 1em;
    color: rgba(0,0,0,0.9);
    margin-right: 15px;
}

.fb-arc{
    &:before{
        border: 1px solid gray;
        border-radius: 50%;
        height: 20px;
        width: 20px;
        /* border-top-color: transparent; */
        /* border-left-color: transparent; */
        border-top-color: transparent;
        transform: rotate(213deg);
    }     
}

.fb-check-approval{
    font-size: 2em;
    color:lightgray;
    padding:6px;
    @include otisToggle();
    &.approved{
        color:Gold;
    }
}