$_color_status_all: lighten(black, 50%);
$_color_status_submitted: dodgerblue;
$_color_status_verified:lightseagreen;
$_color_status_rejected:red;
$_color_status_pending: lighten(black, 20%);
$_color_status_normal-effort: mediumblue;
$_color_timesheet-overtime: darkred;

@import './_timesheet_week_effort_table.scss';

.ts-status--PENDING {
    color: lightcoral;
}

.ts-status--PENDING.ts-this-week {
    color: inherit;
}

.ts-status--SUBMITTED{
	color: $_color_status_submitted !important;
}
.ts-status--REJECTED{
	color: $_color_status_rejected !important;
}
.ts-status--VERIFIED{
	color: $_color_status_verified !important;
}

.timesheet__item-value--overtime {
	color: $_color_timesheet-overtime;
}

.btn-effort-punch--START {
	color           : white;
	border-radius   : 3px !important;
	background-color: #337ab7;
	border-color    : #2e6da4;
}

.btn-effort-punch--STOP {
	color           : white;
	border-radius   : 3px !important;
	background-color: #f0ad4e;
    border-color    : #eea236;
}

.field-required::before {
	content: '*';
	color: red;
}

.timesheet__item--expand {
	width           : 80%;
	margin          : 10px auto;
	border          : 1px solid #ffc107;
	background-color: #fff3a9;
	border-radius   : 3px !important;
	&.ng-enter {
		-webkit-transition: opacity ease-in-out 0.25s;
		-moz-transition: opacity ease-in-out 0.25s;
		-ms-transition: opacity ease-in-out 0.25s;
		-o-transition: opacity ease-in-out 0.25s;
		transition: opacity ease-in-out 0.25s;
	}
	&.ng-leave {
		-webkit-transition: opacity ease-in-out 0;
		-moz-transition: opacity ease-in-out 0;
		-ms-transition: opacity ease-in-out 0;
		-o-transition: opacity ease-in-out 0;
		transition: opacity ease-in-out 0;
	}
	&.ng-enter, &.ng-leave.ng-leave-active {
		opacity: 0;
	}
	&.ng-leave, &.ng-enter.ng-enter-active {
		opacity: 1;
	}
	padding         : 5px;
	// &.ng-enter {
	// 	transition: all ease-out 0.5s;
	// }
	// &.ng-leave {
	// 	transition: all ease-in 0.5s;
	// }
	// &.ng-enter, &.ng-leave.ng-leave-active {
	// 	height: 0;
	// 	opacity: 0;
	// }
	// &.ng-leave, &.ng-enter.ng-enter-active {
	// 	height: 100px;
	// 	opacity: 1;
	// }
}

.timesheet-delete-icon {
	// TODO:調整icon-delete position
	// position: absolute;
	// transform: translateY(200%);
}

.timesheet-list__item--clickable {
	cursor: pointer;
	color: #337ab7;
}

.timesheet-list__item--del {
	text-decoration: line-through;
}

.timesheet-list__item--RECORDING {
	position: relative;
	color   : #f0ad4e !important;
	.icon-work {
		position : absolute;
		font-size: 10pt;
		top      : 100%;
		transform: translate(5px, -150%);
	}
}

.add-new-effort-item {

	width   : 100%;
	display : inline-flex;

	.add-new-effort-item__project-or-free-dropDown {
		width     : 30%;
	}

	.add-new-effort-item__todo-content-input {
		width     : calc(100% - 40%) !important;
		input {
		}
	}

	.add-new-effort-item__add-btn {
		width     : 10%;
		button {
			width : 100%;
		}
	}
}

pop-timecard-free-item-editor {
	select.form-control {
		color: rgb(8, 183, 170);
		font-weight: bold;
		font-size: 12px !important;
	}
}

.timesheet-modal {
    $_modal_body_outset_height: 54px /*header*/ + 46px /*footer*/ + 30px /*margin-top*/;
	$_week_effort_summary_on_height: 150px;
	
	.modal-body {
		// @include max-height-vh-patch("90vh - 46px - 47px");
		@include max-height-vh-patch("95vh - #{$_modal_body_outset_height}");

		@media #{$phone} {
			@include max-height-vh-patch("100vh - #{$_modal_body_outset_height}");
			padding: 15px 10px !important;
		}

        
	}

    &.week-effort-summary-on {
        .modal-body {
            @include max-height-vh-patch("95vh - #{$_modal_body_outset_height} - #{$_week_effort_summary_on_height}");

            @media #{$phone} {
                @include max-height-vh-patch("100vh - #{$_modal_body_outset_height} - #{$_week_effort_summary_on_height}");
            }
        }
        
    }
	
	.modal-header {
		position: relative;
		box-shadow: 1px 1px 3px rgba(0,0,0,0.5);
		z-index: 999;
		.time-sheet-title {
			color   : black;
		}

		.week-effort {
			float    : right;
			transform: translateX(-10px);
		}

	}  // end of .modal-header
	
	.modal-header, .timesheet-week-period {
	
		&.ts-status--SUBMITTED{
			color: $_color_status_submitted;
		}
		&.ts-status--REJECTED{
			color: $_color_status_rejected;
		}
		&.ts-status--VERIFIED{
			color: $_color_status_verified;
		}

		.timesheet__modal-header__week-effort-detail-btn-container {
			text-align: center;
			color: darkgray;
			.timesheet__modal-header__week-effort-detail-btn {
				cursor: pointer;
				padding: 3px;
				text-align: center;
				color: darkgray;
			}
		}

		
	}

	.timeline-container {
		padding: 0px 20px 10px 50px;
	
		.activity-caption {
			margin-top: 4px;
			display: inline-block;
		}
		
		.pjt-activity .timeslot-left.alt .activity-item>.time {
			top: 8px;
			font-size: 80%;
		}
		
		.activity-details {
			padding-left: 3px;
			padding-top:5px;
			color: dimgray;
		}
	}

	.timesheet-projectHeader{
		
		align-items:flex-end;
		
		.efforts-auto-calc{
			text-align: center;
		}

		.efforts-user-input{
			color:mediumblue;
			text-align: center;

			&.has-empty-real-effort {
				font-style: italic;
			}
		}

		.efforts-user-overtime{
			color: $_color_timesheet-overtime;
			text-align: center;
		}

		&.timesheet-top-header {
			border-bottom: 2px solid lightgray;
   			margin-bottom: 10px;

			.otis-tbl-header {
				border-bottom: none;
			}
		}

		.otis-tbl-header {
			&.group-title, &.item-number {
				font-size: 14px !important;
				color: #611561;	// we may use kind of purple color to represent as Project annoatation [Benx 2018-10-23 13:37:17]

				.project-zone-name {
					color: gray;
					font-size: 12px !important;
					margin-bottom: 4px;
				}
			}

		}
		

		&.timesheet-freeItems {
			.otis-tbl-header {
				&.group-title, &.item-number {
					font-style: italic;
					color: indianred;
				}
				
			}
		}
	}	
	
	.timesheet-list {
		padding: 6px 0;
		color: initial;
		.warning{
		   border: 1px red solid !important;
		}
		
		.col-effort-auto {
			color: darkgray;
			font-size: 80%;
			padding-top: 2px;
		}
	}
	
	.timesheet-item {
		$_lineHeight: 1.2em;
		$_lines: 2;
		@include multiline-ellipsis($_lineHeight, $_lines);
		text-align: left;
		padding: 0 5px;
	
		input {
			color: initial;
			background: white;
			border: lightgray;
			text-align: center;
			padding: 5px 0px !important;
			text-align: right;
   			width: 100%;

			&.empty-real-effort {
				font-style: normal;
			}
		}
	
		.input-sm {
			//text-align: center !important;
			//width:90%;
		}

		// .icon-delete {
		// 	display: inline-block;
    	// 	margin-top: 6px;
		// }

		.empty-real-effort {
			font-style: italic;
			color: gray;
		}

	}

	.timesheet-info-none{
		text-align: center;
		margin:10px;
	}
	
	.timesheet-week-period {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		padding-top: 10px;
		padding-bottom: 10px;
		margin-left: -5px;
		margin-right: -5px;
		
		.col {
			padding-left: 10px;
		}

		.ts-status-icon {
			margin: 0px 5px;
		}
	
		.ts-remark {
			// border:1px solid lightgray;
			padding: 6px;
			overflow:hidden;
			text-overflow:ellipsis;
			
			@media #{$phone} {
				padding: 0px;
				color: gray;
			}
		}
	
		.member-name {
			cursor: pointer;

			.ts-userId {
				font-size: 0.8em;
				opacity: 0.4; 
			}
		}

		
	
		.month-of-week {
			font-size: 100%;
		}
	
		.weekNum {
			color: darkgray;
			margin-left: -15px;
			margin-top: -12px;
			font-size: 80%;
			visibility: hidden;
			float: left;
			display: none;
		}
	
		&:hover {
			.weekNum {
				visibility: visible;
			}
		}
	
		.col-ts-week {
			.ts-status-icon {
				font-size: 130%;
				margin-left: -10px;
				margin-right: 5px;
			}
	
		}
	
	}  // end of .timesheet-week-period
	
	.timesheet-remark-section {
		margin-top: 30px;

		textarea{
			width:100%; 
			padding:6px;
		}
		
		.remark-content {
			white-space: pre-wrap;
			padding: 10px;
			margin-left: 20px;
			border-radius: 2px;
			
			&.empty {
				color: gray;
				font-style: italic;
			}
		}
	}

	.item-number {
		text-align: right;

		&.otis-tbl-header {
			text-align: center;
		}
	}
	
/*------------------------------------*\
    $ITMS-EFFORT
\*------------------------------------*/

    .itms-effort {
        margin-bottom: 15px;

        input {
            border: 1px solid lightgray;

            &.ng-invalid:focus {
                outline-color: red;
            }
        }
    }

	.itms-effort__option-block--selected {
		color           : #fff;
		background-color: #08B7AA !important;
		border          : 1px solid #08B7AA !important;
	}

	.itms-effort__title {
		font-size  : 13px;
		margin-left: 10px;
        font-weight: bold;
	}

    .effort-cate1 {
       .itms-effort__option-block {
           width: calc( 25% - 10px);
            height: 42px;
            font-size: 13px;
       } 
    }

	.itms-effort__option-block {
		position        : relative;
		display         : inline-grid;
		text-align      : center;
		font-size       : 12px;
		margin-top      : 8px;
		margin-right    : 5px;
		width           : calc( 20% - 10px );
		height          : 48px;
		vertical-align  : middle;
		background-color: #FBFBFB;
		border          : 1px solid #828282;
		border-radius   : 6px;
		&:hover {
			background-color: lightgray;
			border          : 1px solid lightgray;
			cursor          : pointer;
		}
		.itms-effort__option-name {
			width        : 100%;
			position     : absolute;
			top          : 50%;
			left         : 50%;
			padding-right: 5px;
			padding-left : 5px;
			transform    : translate(-50%, -50%);
		}
	}

	.itms-effort__record {
		width           : 100%;
		background-color: #DEDEDE;
		.itms-effort__record-table {
			margin         : 0 auto;
			width          : calc(70%);
			display        : flex;
			justify-content: space-between;
			.itms-effort__record-item {
				display   : inline-block;
				width     : calc(14% - 10px);
				text-align: center;
			}
		}
	
	}
}

pop-timesheet-panel {
	
	.otis-modal {
		.modal-body {
			@include max-height-vh-patch("90vh - 52px");
	
			@media #{$phone} {
				@include max-height-vh-patch("100vh - 52px");
				padding: 15px 10px !important;
			}
		}
	}
	
	.timesheet-week-list-container {
		.ts-week {
			@media #{$phone} {
				padding-left: 10px;
			}
		}
	}
}



pop-zone-members-timesheet-list {
	.timesheet-week-picker {

		.current-week-info {
			margin: 0 15px;
			line-height: 30px;
	
			@media #{$phone} {
				margin: 0 5px;
			}
			
		}
	}
	
	.department-select {
		margin-right: 15px;

		label {
			margin-left: 5px;
			margin-right: 5px;
		}

		@media #{$phone} {
			.bootstrap-select {
				width: 120px !important;
			}
		}

	}
	
	.timesheet-status-selector {
		//margin: 10px auto;
		text-align: center;

		.option-item {
			padding: 10px;
			display: inline-block;
			cursor: pointer;

			&.selected {
				color: white ;
				font-weight: 600;
				&.filter-criteria-status-all{
					background: $_color_status_all;
				}
				&.filter-criteria-status-sent{
					background: $_color_status_submitted; 
				}
				&.filter-criteria-status-verified{
					background: $_color_status_verified;
				}
				&.filter-criteria-status-rejected{
					background: $_color_status_rejected;
				}
				&.filter-criteria-status-pending{
					background: $_color_status_pending;
				}
			}

			@media #{$phone} {
				font-size: 12px;
			}

			@media #{$miniPhone} {
				font-size: 10px;
			}
		}

	}
	
	.orgUnit-weekTimesheet-modal-body-height {
		@include height-vh-patch("90vh - 46px - 47px");
	
		//min-height:50vh;
		@media #{$phone} {
			@include height-vh-patch("100vh - 46px - 47px");
			padding: 15px 10px !important;
		}
	}
	
	.modal-footer {
		padding: 0px 15px
	}
}

.timesheet-list, .timesheet-week-period {
	textarea {
		padding-left: 6px;
		padding-right: 6px;
	}

	&:nth-child(odd) {
		background-color: rgba(100, 100, 100, 0.05);
	}

	&:hover {
		background-color: rgba(221, 221, 221, 0.8); 
		// color: inherit;

		textarea {
			color: initial !important;
		}

		.timesheet-item {
			input {
				color: black !important;
			}
		}
	}

	&.selected {
		background-color: #d3d3d3;
		color: inherit;

		textarea {
			color: initial !important;
		}

		.timesheet-item {
			input {
				color: black !important;
			}
		}
	}

}


.required-field {
	&.invalid {
		color: red;

		input,
		textarea {
			border-color: red;
		}
	}
}

.user-timesheet-week-list {
    input, textarea {
        &.ng-invalid {
            border: 2px solid red;
        }
    }
}

.otis-modal.timesheet-modal {
	.otis-modal-content-md {
		$_width: 60vw;
		$_backgroundColor: white;
		@include otis-modal-content($_width, $_backgroundColor) ;

		@media #{$desktop-lg-above}{
			$_width: 50vw;
			overflow:hidden;
			$_backgroundColor: white;
			@include otis-modal-content($_width, $_backgroundColor) ;
		}

		@media #{$desktop-xs} {
			$_width: 80vw;
			overflow:hidden;
			$_backgroundColor: white;
			@include otis-modal-content($_width, $_backgroundColor) ;
		}
		
		@media #{$tablet} {
			$_width: 80vw;
			overflow:hidden;
			$_backgroundColor: white;
			@include otis-modal-content($_width, $_backgroundColor) ;
		}
		@media #{$phone}{
			$_width: 100vw;
			overflow:hidden;
			$_backgroundColor: white;
			@include otis-modal-content($_width, $_backgroundColor) ;
			
			margin-top:0;
			margin-bottom:0;
			margin:0;
			top:0;
			left:0;
			overflow:hidden;
		}

	}
}