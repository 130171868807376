/* pollex body scss 2016.01.30 by smith sun */
:root {
	--vh-offset: 0px;
	--vh: 1vh;
}

body{
	font-family:$context-font;
	-webkit-touch-callout: default;
	height: 100%;
	-webkit-overflow-scrolling: touch;
	-webkit-font-smoothing: antialiased;
	@media #{$phone}{
		overflow-y:scroll;
	}
	*:not[class^="icon-*"]{
		font-family:$context-font;
	}

}


.w50{
    width: 50%;
}

.w26{
    width: 26%;
}
.w16{
    width: 16%;
}
.w70{
    width:70%;
}