/* weekTodo.scss */
.weekTodo-overview--header{
    border: 2px gray solid;
    border-radius: 6px;
    max-width: 500px;
    margin: auto;
    border-radius: 6px 6px 0px 0px;
    border-bottom: 0px;
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 800;
    @media #{$phone}{
       font-size: 12px;
    }
}
.weekTodo-overview--foot{
    border: 2px gray solid;
    border-radius: 6px;
    max-width: 500px;
    margin: auto;
    border-radius: 0px 0px 6px 6px;
    display: flex;
    align-items: center;
    margin-bottom: 15px;
}

.weekTodo-overview-projectHeader{
	background-color: #cccccc;
	font-size: 13px;
	font-weight: bold;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 6px;
    padding-right: 63px;
    margin:0px 30px;
    @media #{$phone}{
        margin:0px -15px;
        padding-right: 30px;
	}
	
	.efforts-sum {
		font-size: 11px;
		color: dimgray;
	}
}

.weekTodo-overview-projectBody{
    background-color: #eeeeee;
    padding: 8px;
    border-top: 1px solid whitesmoke;
    margin:0px 30px;
    &.noBorder{
        border: none;
	}
	
	.weekTodo-overview--processBody {
		border-bottom: none;
		
		&, &:nth-child(odd) {
			background-color: inherit;
		}
		
	}
	
    @media #{$phone}{
        margin:0px -15px;
	}
}

.weekTodo-overview--processBody {
	.dot {
		margin-top: 5px;
	}
}

.weekTodo-overview--select{
    float: right;
    width: 160px;
    margin-right: 30px;
    margin-bottom: -2px;
    @media #{$phone}{
        margin-top: -42px;
        margin-right: 10px; // todo#40548: 行動裝置瀏覽 My Todos This Week ，其專案類別(全部/組織/個人)會和 Title 重疊。 [Jane 2018-09-27 14:35:53]
        width: 120px;
        margin-left: -120px;
	}
}
.weekTodo-overview-timestamp{
    display: flex;
    align-items: center;
    justify-content: space-between;
	background-color: rgba(250, 250, 250, 0.5);
	color: lightslategray;
    border-radius: 6px;
    padding:5px 15px;
	margin: 5px 40px;
	font-size: 80%;
    @media #{$phone}{
        margin:5px;
	}
	
	input {
		font-size: 90% !important;
		width: 100%;
		padding: 2px;
		color: initial;
		text-align: right;
	}
	
	&.effort-item--not-on-me {
		color: darkgray;
	}
	
	&.effort-filled {
		// color: #393939;
		// font-weight: bold;
	}
	
	&.timeframe-inline {
		display: inline-block;
		margin: 0px;
		margin-left: 10px;
		padding: 2px 10px;
		opacity: 0.7;
		color: darkgray;
	}
	
	.timeframe {
		&.my-doing {
			font-weight: bold;
			color: gray;
		}
		
		.weekday {
			width: 50px;
    		display: inline-block;
		}
	}
}
.weekTodo-overview-numCloum{
    text-align: center;
    width: 70px;
    // display: inline-flex;
    align-items: center;
    justify-content: space-around;
    padding:0px 4px;
    @media #{$phone} {
        padding:0px 10px; //fine-tune in phone [Jane 2018-09-20 13:35:06]
    }
    // margin-bottom: 5px;
    &:empty{
        &::after{
            content: '--';
        }
	}
	
}


.weekTodo-overview--processHeader{
    background-color: transparent;
    display: flex;
    justify-content: space-between;
    padding: 6px;
    margin:0px 30px;
    margin-top: 15px;
    font-size: 12px;
	font-weight: 800;
	color: gray;
    @media #{$phone}{
        margin: 0px -15px;
        background-color: #eeeeee;

        .project-name {
        	text-align: left;
        }
    }
}
.weekTodo-navTab{
    @media #{$phone}{
        margin: 0px -15px;
    }
}
.weekTodo-overview--process{
    margin:0px 30px;
    border: solid 1px #d6d6d6;
    border-radius: 8px;
    overflow: hidden;
    @media #{$phone}{
        margin: 0px -15px;
        border-right: 0px;
        border-left: 0px;
        border-radius: 0px;
    }

}
.weekTodo-overview--processBody{
    display: flex;
    justify-content: space-between;
    padding: 4px 8px 2px;
    border-bottom: solid 1px #d6d6d6;
    &:nth-child(odd){
        background-color: #ffffff;
    }
    &:nth-child(even){
        background-color:#f9fbfe;
    }
    &:nth-last-child(1){
        border-bottom: none;
    }
}
.weekTodo-overview--processTitle{
    flex: 1 1;
	text-align: left;
	overflow: hidden;
	text-overflow: ellipsis;
}
.weekTodo-overview--processDate{
    min-width: 60px;
    text-align: center;
}
.weekTodo-overview--processIcon{
    min-width: 30px;
    text-align: center;
}
.weekTodo-modal-body{
    min-height: 300px;
	max-height: 80vh;
	
    @media #{$phone}{
       // max-height: calc( 100% - 40px ); 
        height: 100%; // todo#40445:手機瀏覽My Todos This Week時，沒有產生scroll bar--無法瀏覽超過一屏的資訊。[Jane 2018-09-25 11:39:01]
        padding-bottom: 0px;
		padding-top: 0px; // todo#40662:https://www.otispm.com/otis/index.html#!/pjm/p/725/backlog-list?showTodoDetail=40662 [Jane 2018-09-25 14:01:39]
		margin-top: 0px;
	}
	
	.icon-user-doing, .icon-project {	// 微調圖示大小 [Benx 2018-09-06 22:24:42]
		font-size: 110%;
	}
	
	.icon-project {
		display: none;	// 不顯示專案圖示，因為圖示好像太多，有點亂 [Benx 2018-09-07 00:35:08]
	}
	
	.todo-entry {
		// &.todo-doing {
		// 	color: #0cb8f1;
		// }
		// &.todo-done {
		// 	color: green !important;
		// }
		&.todo-not-assign-me {
			color: dimgray;
		}
	}
	
}

.my-worked-todo-list {
	$_rowHeaderFontSize: 14px;
	
	.weekTodo-project-section {
		&.--notMyProject {
			opacity: 0.6;
			
			.text--left-project {
				margin: 0px 10px;
				line-height: 20px;
				float: right;
				font-size: 10px;
				color: darkred;
			}
			
			.weekTodo-overview-projectHeader {
				.project-name {
					font-style: italic;
				}
			}
		}
		
		.weekTodo-overview-projectHeader {
			.project-name {
				font-size: $_rowHeaderFontSize;
				color: black;
				font-weight: bold;
			}
			
			.project-zone-name {
				font-size: $_rowHeaderFontSize;
				color: gray;
				font-weight: normal;
			}
		}
		
	}	// end of .weekTodo-project-section
	
	.efforts-overtime {
		color: darkred;
		white-space: nowrap;
	}
	
	.efforts-user-input {
		color: mediumblue;
		white-space: nowrap;
	}
	
	.efforts-auto-calc {
		color: dimgray;
		width: 76px;
		white-space: nowrap;
	}
}

.my-scheduled-todo-entry {
	.todo-doneDate {
		font-size: 10px;
    	color: gray;
	}
	
	&.due-on-today .todo-dueDate {
		font-weight: bold;
	}
}

.nav>li>a.weekTodo-tabA-overwrite{
    @media #{$phone}{
        padding: 2px;
    }
}

.todo-done .todo-text {
	text-decoration: line-through;
}

.otis-modal.myWeekTodo {
	
	.modal-header{
		padding-bottom: 0px !important;
		
		.nav-tabs {
			border-bottom: 0px;
		}
		
		.nav-tabs>li.active.weekTodo--active>a{
			background-color: $tab-background-active;
			color: $tab-active;
		}
		
		.nav-tabs>li>a:hover {
			cursor: pointer;
			background-color: $tab-background-hover;
			color: $tab-active;
		}

		@media #{$phone} {
			.nav>li>a {
				padding: 10px 6px;
			}
		}
	}
	
	.myWeekTodo--emptyWorked, .myWeekTodo--emptyScheduled {
		font-size: 1.5em;
		margin-top: 50px;
		text-align: center;
		color: coral;
		opacity: 0.6;
	}
    
}

.myWeekTodo-worked.dot {
	background-color: green;
}

.myWeekTodo-scheduled.dot {
	background-color: orange;
}

.myWeekTodo-title{

	.nav-tabs>li>a {
		color: $tab-color;
	}

	@media #{$phone} {
		text-align: left;	
	}
}

.nav-tabs {
	.active {
		.myWeekTodo-worked, .myWeekTodo-scheduled {
			display: none;
		}
	}
}