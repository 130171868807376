// Override simpleMde style

.panel-header {
	display: flex;
	.editor-toolbar {
		display: inline-flex;
		border-top: 0;
		border-left: 0;
		border-right: 0;
		background: antiquewhite;
		border-top-right-radius: 0;
		border-bottom-left-radius: 4px;
		height: 36px;

		&:before {
			margin-bottom: 0;
		}
		&:after {
			margin: 0;
		}
		&:hover {
			opacity: 0.6;
		}

		a,
		i {
			display: none;
		}
		a {
			height: 36px;
			width: 32px;
			font-size: 15px;
			outline: none;
		}
		a::before {
			line-height: 36px;
		}

		.fa-paperclip {
			font-size: 18px;
		}

		.icon-files {
			font-size: 22px;
		}
		.simplemde-fixed-tool {
			display: inline-block;
		}
	}
	.CodeMirror,
	.CodeMirror-scroll {
		min-height: 0px;
		width: 100%;
		max-height: 36px;
		border-bottom-left-radius: 0;
		border-top-right-radius: 4px;
		padding: 0;
		.CodeMirror-sizer {
			// margin-bottom: 20px !important;
			min-height: 0px !important;
		}
		.CodeMirror-line {
			word-break: break-all;
		}
	}

	&.show {
		flex-direction: column;
		margin-top: -10px;
		.editor-toolbar {
			width: 100%;
			border-top-right-radius: 4px;
			border-bottom-left-radius: 0;
			a,
			i {
				display: inline-block;
			}
		}
		.CodeMirror,
		.CodeMirror-scroll {
			min-height: 110px;
			max-height: 200px;
			border-bottom-left-radius: 4px;
			border-top-right-radius: 0;
			.CodeMirror-sizer {
				// margin-bottom: 20px !important;
				min-height: 100px !important;

				padding-left: 5px;
			}
		}
	}
}

// patch simpleMDE Windows scroll always display bug
.panel-header {
	.CodeMirror,
	.CodeMirror-scroll {
		margin-bottom: 0px;
		overflow-x: hidden !important;
	}

	.CodeMirror .CodeMirror-vscrollbar {
		display: none !important;
	}
}
