/* report scss */
body{
	background:transparent;
}
.highcharts-container{
	border-radius:8px;
	//border:1px solid lightgray;
}

.chart-background{
	background-color:whitesmoke;
	border-radius:6px;
}
.otis-report{
    position: fixed;
    left: 0;
    width: 100vw;
    height: calc(100% - #{$navbarHeight});
    background-color:  $main-background-color;
    .report-title{
    	// color: $viewTitleTextColor;
    	@include h2TextSize();
    }
   .effort-report{
   	 	.chart-zone{
   		 	color: $viewTitleTextColor;
   	 	}
   }
   .effort-report-title{
    	color: $viewTitleTextColor;
   		padding:15px;
    	@include h2TextSize();
    	font-weight: 800;

   }
   .effort-report-subTitle{
    	color: $viewTitleTextColor;
    	@include h2TextSize();
    	font-weight: 600;
    	margin-bottom: 5px;
   }
   .report-manager-div{
   		color: $viewTitleTextColor;
		position:absolute;
		right:6px;
		margin-top:-2em;
		@media #{$phone}{
			position: relative; 
			right: normal;
			right: initial;
			margin: 0;
			margin-left: 6px;
		}
	}

   .dashboard-card,.dashboard-chart{
		margin:6px 0;
		padding:5px;
		@media #{$phone}{
			padding:3px;
		}
		.card-title{
			background: gray;
			padding: 6px 0px;
			color:white;
		}
		.card-content{
			height:3em;
			line-height: 3em;
			color: darken(gray,30%);
			background: lighten(gray, 20%);
		}
	}


	.chart-legend-div{
		background: rgba(255,255,255,0.2);

		.chart-legend-zone{
			display:inline-block;
			padding: 6px;
			text-align: left;
			width: 80%;
			float:right;
			font-size: 0.8em;
			.chart-legend-span{
				display: inline-block;
				width: 50%;
				padding: 0 6px;
				.color-box{
					display:inline-block;
					width: 15px;
					height: 8px;
				}
			}
		}
	}
	.next-previous-btn{
		padding: 6px;
		text-align: center;
		color: $viewTitleTextColor;
		.next-btn,.previous-btn{
			cursor: pointer;
			&:hover{
				color: darken($viewTitleTextColor,25%);
				opacity: 0.8;
			}
			&:active{
				color: darken($activeColor,0%);
				opacity: 1;
			}
		}
	}

    .individual-report{
   		color:$viewTitleTextColor;
   		input{
			padding: 6px !important;
		}
		.members{
			padding-top: 15px;
		}

   		.by-person-report{

   			padding: 10px 0px;

   			$_chartHeight: 200px; // same with html hightchart setting
			$_largeChartHeight: 300px;
   			@media #{$phone}{
	   				padding:10px 0px;
	   			}
   			.title{
	   			@include h2TextSize();
	   			padding: 3px 0;
	   		}
	   		.sub-title{
	   			@include mainTextSize();
	   			padding: 3px;
	   		}
	   		.no-data{
				height: $_chartHeight;
				line-height: $_chartHeight;
				background: rgba(130,130,130,0.65);
				clear:both;
				color: white;
				@media #{$phone}{
					height:80px !important;
					line-height:80px !important;
				}
				&.no-data-lg{
					height: $_largeChartHeight;
					line-height: $_largeChartHeight;
				}
			}

   		}
   		.effort-zone{
   			@media #{$phone}{
   				padding:0 !important;
   			}
   		}
   		.onTime-zone{
   			@media #{$phone}{
   				padding:0 !important;
   			}
   		}
   }
   
   .pm-monthly-progress-report {
		.edit-icon{
			visibility:hidden
		}
		.editable{
			cursor: pointer;
			color:navy;
			&:hover{
				.edit-icon {
					visibility:visible
				}
			}
		}
		// .pmprSprintStatusPopover{
		// 	max-width:300px;
		// 	width:300px;
		// }
		.report-text{
			word-break: break-word;
		}
		
		.otis-td {
			border-bottom: 1px solid;
			padding-bottom: 10px
		}
		
		.project-name {
			font-weight: bold
		}
		
		.project-description {
			font-size: 90%;
			margin-top: 10px;
			padding-right: 20px;
    		color: dimgray;
		}
		
		.pmpr-user-efforts {
			&.ts-has-pending {
				&:before {
					content: '* ';
				}
			}
		}
		
		.badge.pmpr-no-efforts-data {
			opacity: 0.5;
			font-style: italic;
		}
		
		.badge.pmpr-verified-efforts {
			background: lightcoral;
			
			&.ts-all-verfieid {
				background: lightseagreen;
			}
		}
		
		[uib-popover-template-popup].popover {
			max-width: 400px;
			width: 400px;
		}
		
		.otis-td-row  {
			.otis-td-child:nth-child(even), .otis-td-child:nth-child(odd), .otis-td-child {
				padding: 10px 0px;
			}
			
			.otis-td-child:nth-child(even) {
				background-color: rgba(100,100,100,0.08);
			}
		}
		
		.current-pm-data .col-pmpr-pm-name {
			color: blue;
		}
		
		.sprint-not-assigned .col-pmpr-sprint-name {
			color: lightslategray;
		}
		
		.pmpr-sprint {
			margin-bottom: 5px;
		}

		.pmpr-sprint-divider {
			padding-left: 10px;
			color: lightslategray;
		}
		
   }
   
   table{
	   border-color: $dynamicColorGray;
   }
}
.back-to-report-list{
	cursor: pointer;
	font-size: 0.9em;
	position:absolute;
}

.report-header{
	@include h1TextSize();
}
.report-home-page{
	padding: 2em;
	@include height-vh-patch("100vh - #{$navbarHeight} - #{$viewTitleHeight} ");
	overflow-y:auto;
	overflow-x:hidden;
	@include h2TextSize();
	@media #{$phone}{
		padding:0;
		padding-bottom: 150px;
	}
	text-align: center;
	.report-type{
		font-size:1.23em;

	}
	.report-card{
		cursor: pointer;
		display: inline-block;
		@include swimLanWidth();

		height: auto;
		margin: 1em 2em 1em 0;
		position: relative;
		overflow: hidden;
		outline:1px solid lightgray; 
		//box-shadow: 0px 1px 1px $viewTitleTextColor;
		@media #{$phone}{
			margin: 0 0 1em 0;
		}



		&:hover{
			.report-img{
				opacity: 0.8;
			}
			.report-name{
				background:rgba(255,255,255,0.9);
			}
		}
		&:active{
			.report-img{
				opacity: 1;
			}
		}

		.report-name{
			text-align: center;
			padding: 10px;
		    font-size: 1.23em;
			color: darken($main-text, 30%);
			background:rgba(255,255,255,0.9);
		}
		.report-img{
			width: 100%;


			&:hover{
				opacity: 0.8;
			}
			&:active{
				opacity: 1;
			}
		}
		&.progress-report-background{
			background-size: cover !important;
		}
		&.teamEffort-report-background{
			background-size: cover !important;
		}
	}
}

.report-selection{
	display:flex;
	flex-wrap:wrap;
	justify-content:center;
	text-align:left;
	margin-bottom: 10px;
	padding:10px 20px;
}

.report-div {
    $_mainColor:$cool;
    $_secondaryColor: darken($main-text, 5%);
  	padding: 15px;


    @media #{$phone}{
    	padding: 6px 6px 6px 6px;
		padding-bottom:150px; // in some mobile, need extra padding bottom to skip the native bottom bar
    }

    @include mainTextSize();
    overflow-x:auto;
    @include height-vh-patch("100vh - #{$navbarHeight} - #{$viewTitleHeight} ");

 	.report-subTitle{
 		color:$viewTitleTextColor;
 	}

    .highcharts-container{
		margin-right:15px;
		margin-bottom:15px;
    	@media #{$phone}{
    		margin-right:0px !important;
    	}
    }

    .warning-message{
		text-align: center;
		@include mainTextSize();
		color:$viewTitleTextColor;
    }


    label{
    	font-weight: initial !important;
    }

    	.report-steps{
			//flex-grow:1;
			//margin-bottom: 0.5em;
			background: lighten($reportStepColor, 35%);
			padding: 15px 20px;
    		//padding-right:2em;
			@media #{$tablet}{
				margin-right:0; 
				padding: 5px 20px;
			}

    		@media #{$phone}{
    			margin:0 !important; 
				padding: 5px  20px;
    		}
    		position:relative;
			.period-rpt{
				margin-top:0.5em;
				margin-bottom: 6px;
			}

    		&.step3{
    			select{
    				padding:6px;
   					padding-right: 30px;
   					width:100%;
   					border: 1px solid whitesmoke !important;
   					color : $viewTitleTextColor;
   					option{
   						padding: 10px !important;
   						color: $main-text !important;
   					}
    			}
 
				.control-label{
						//width:5em;
						margin-top:0.7em;
    					margin-bottom: 6px;
				}

				.controls{
					display: inline-block;
					flex-grow:1;
					input{
						padding: 0.2em 0.5em;
					}
				}

    		}

    		&.step4{

    			.from-date, .to-date{
    				display: inline-flex;
    				width: 100%;
    				align-items: center;
    				margin-top:0.5em;
    				//margin-bottom: 6px;
    				input{
    					flex-grow:1;
    					width: 100%;
    				}

    				.control-label{
    					width:5em;
    					display:inline-block;
    					padding: 0;
    					padding-right: 0.5em;
    					text-align:left !important;
    				}
    				.controls{
    					display: inline-block;
    					flex-grow:1;
    					input{
    						padding: 0.2em 0.5em;
    					}
    				}
    			}
    		}
		    .report-step-title{
				@include otisInfoTitle();
				font-weight: 800;
				margin-bottom: 10px;
				margin-left: -10px;
				//$_arrowWidth: 30px;
				//color: white;
				color: black;
				//margin-bottom:10px;
				//border-bottom-left-radius: 20px;
				//border-top-left-radius: 20px;
		    	//$_mainColor:$_mainColor;
		    	//$_height: 40px;
				//$_bodyBackgroundColor: $reportStepColor;
				//$_bodyBackgroundColor: whitesmoke;
		    	//$_padding:$_height * 0.3;
				//width: calc( 100% - #{$_arrowWidth} );  
		    	//@include arrowBlock($_height,$_mainColor,$_bodyBackgroundColor,$_padding,$_arrowWidth);
		    	//padding-left:15px; //remove this when enable left triangle;

		    }
    	}
    .report-step-subtitle{
    	color: $_mainColor;
    }

    .report-type-div{
	   	// margin-bottom: 0.5em;
	    //background: lighten($reportStepColor, 35%);
	    //padding: 15px 20px;
	    //min-height:8em;
	    $_color: $main-text;
	    $_borderColor: lighten($_color, 20%);
	    color: $_color;

	    @include mainTextSize();
		@media #{$tablet}{
			width:calc(90%);
		}
		@media #{$phone}{
			min-height:4em;
		}
		.report-name-title{
			margin-bottom:3px;
		}
		.report-name-option{
				color:$_secondaryColor;
				font-size:1em;
				.report-options{
					padding: 2px 1em;
					&.long-label{
						padding-right:0;
						.label{
							display:inline !important;
						}
					}
				}
				.report-checkBox{
					padding: 3px 1em;
				}
				&.round-border{
					border-radius:6px;
					border: 1px solid lighten($_borderColor, 10%);
				}
	   	}
	  
		.org-selector-div{
			display:inline-flex;
			width:100%;
			align-items:center;
			margin-bottom:10px;
			color:  $_color;
			input{
				flex-grow:1
			}
		}
		.dropdown-toggle{
			border-color: $_borderColor !important;
		}
		.otis-input{
			input{
				border-color: $_borderColor !important;
			}
		}

		.next-previous-btn{
			cursor:pointer;
			margin-bottom: 20px;

			.previous-btn,.next-btn{
				color:$viewTitleTextColor;

				&:hover{
					color:darken($viewTitleTextColor, 10%);
				}
				&:active{
					color:darken($viewTitleTextColor, 5%);
				}
				.fixed-width{
					display:inline-block;
					min-width: 4em;
				}
			}

		}

		.from-date{
			color:  $_color;
			display: inline-flex;
	    	width: 100%;
	    	align-items: center;
			input{
				padding: 6px;
				flex-grow:1;
				width:100%;
			}
		}
    }
}



.form-horizontal .control-group {
 	width:100%;
    display:inline-block;
    padding-left: 15px;
}

.footerContent {
    display: none;
}

.rpt-comment hr {
    margin: 0;
    border-top: 1px solid black;
    margin: 5px 0;
    border: 0;
    border-top: 1px solid black;
    border-bottom: none;
}

.report-table-header{
	background: darken($mainColor, 10%);
	//box-shadow: 0px -1px 1px $viewTitleTextColor;
	color: $viewTitleTextColor;
	th{
		text-align:center;
		padding:5px;
		font-size: 1em;
	}
}

.pjt-text-rpt{
	.warning-done-overdue{
	}
	.warning-overdued,.warning-overEffort{
		background: darkred !important;
		color:white !important;
		display:block;
		text-align:center;
		font-weight:800;
		font-style:normal;
	}
	.warning-overEffort{
		background: orange !important;
	}

	.indent-one{
		text-indent:0.5em;
	}

	.indent-two{
		text-indent:1em;
	}
	.report-text{
		word-break:break-word ;
	}
	.rpt-comments{
		max-width:15em;
		@include smartBreakWord();
		.commentBy{
			font-style:italic;
			font-size:0.88em;
		}
	}

	.todo-text{
		@include smartBreakWord();
		text-indent: -2em;
	    margin-left: 3em;
	    display: block;
	}

	.subTodo-text{
		@include smartBreakWord();
		text-indent: -2em;
	    margin-left: 4em;
	    display: block;
	}
	.date-progress{
		font-size: 0.9em;
	}

}

#player_wrapper{
	padding: 0 3em;
	@media #{$tablet}{
		padding: 0;
	}
	@media #{$phone}{
		padding: 0;
	}
	.pjt-text-rpt{
		width:100%;
		overflow-x:auto;
	}
	.notice-message{
		text-align: center;
		&.info{
			color: $viewTitleTextColor;
		}
		&.error{
			color: red;
			text-shadow: 1px 1px white;
		}
	}
}

.milestone-summary-report{
	.title{
		color: $viewTitleTextColor;
	}
	.report-duration{
		color: $viewTitleTextColor;
	}
}

.steps-dropdown{
	$width: 100% !important;
	width: $width;

	.filter-option{
		width: $width !important;
	    overflow: hidden;
	    display: inline-block;
	    text-overflow: ellipsis;
	}
	.dropdown-menu{
		li{
			a{
				.text{
					white-space: pre-wrap !important;
				}
			}

		}
	}
}

.commentReportSubTable{
	font-size: 14px;
	text-align: center;
	width:100% ;
}

.otis-report-tr{
	background-color: #EBF1DE !important;
	.project-owner{
		vertical-align:top;
	}
	.parent-row{
		text-align:left;
		background: gray;
		color:white;
		padding: 6px;
	}

	.report-text{
		text-align: left;
		padding-bottom: 6px;
		line-height: 1.3em;
		word-break:break-word;
		white-space:pre-wrap;
		vertical-align:top;
	}
	.text-indent{
		padding-left:6px;
		&.layer-two{
			padding-left: 12px;
		}
	}
	.comment-target-item{
		text-align: left;
	}
	.comment-by{
		font-size: 0.8em;
		font-style: italic;
		padding:3px ;
		color:gray;
	}

}