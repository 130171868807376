/* project Add modal */

.addProjectModal{
	$_mainColor: $cool;

	@include mainTextSize();
	.modal-header{

		height: auto;
		//border-bottom: 1.5px solid lighten($main-text, 40%);

		.header-text{
			text-align: center;
			@include h1TextSize();
			font-weight: 800;
		}
		.search-input-div{

			padding-right:2em;

			input{
				width:100% !important;
				padding:5px !important;
			}
		}


	}

	.modal-body{
		overflow-scrolling: touch;
		max-height: 60vh;
		min-height:10em;
		.row{
			padding: 3px;
			.project-name{
				text-indent:-1em;
			}

			.text-Input-Cell{
				input{
				    padding: 6px;
				    width: 40%;
				}
				&.project-name-input{
					input{
						width:100%;
					}
				}

			}

			textarea{
				padding: 2px 6px;
				width: 100%;
			}



			.select-template,.select-project-type{
				.dropdown-toggle{
					border-color: darkgray !important;
					color: gray;
   					padding: 6px;
				}
			}

			&.add-member{
				$_member-smallHeight: 36px;

				.member-icon{
					height: $_member-smallHeight;
					width: $_member-smallHeight;
					line-height: $_member-smallHeight;
					.member-name{
						font-size:0.6em;

						height: $_member-smallHeight;
						width: $_member-smallHeight;
					}
				}



				.add-icon{
					display: inline-block;
					width: $_member-smallHeight;
					height: $_member-smallHeight;
					border: 1px solid $main-text;
					text-align: center;
					line-height: $_member-smallHeight;
					@inlcude bigIconSize();

					&:hover{
						background: lighten($cool, 25%);
						color:white;
						border:none;
					}

					&:active{
						background: lighten($cool, 20%);
						color:white;
						border: 1px solid  lighten($cool, 60%);
					}
				}
			}

		}
	}

	.modal-footer{
		height: auto;
		//border-top: 1.5px solid lighten($main-text, 40%);
		clear:both;

		.popBtn{
			background: lighten($cool, 25%);
			border-radius:0;
			box-shadow: none;
			color: white;
			border:0;
   			padding: 3px 12px;

			&:hover{
				background: lighten($cool, 20%);
				color:white;
			}

			&:active{
				background: lighten($cool, 15%);
				color:white;
			}
		}

	}
}

	.project-add-showMore{
		padding-top: 5px;
		padding-left: 15px;
		clear: both;
	}
