/* variables */

$desktop: "(min-width: 1024px)";
$desktop-xs-md: "(min-width: 992px)  and (max-width:1365px)";
$desktop-lg-above: "(min-width: 1600px)";
$desktop-md-above : "(min-width: 1366px)";
$desktop-sm-above : "(min-width: 1200px)";
$desktop-tablet-md: "(min-width: 768px) and (max-width:1679px)";
$desktop-xs: "(min-width: 992px) and (max-width:1219px)";
$desktop-sm: "(min-width: 1200px) and (max-width:1365px)";
$desktop-md: "(min-width: 1366px) and (max-width:1599px)";
$desktop-lg: "(min-width: 1600px) and (max-width:1919px)";
$desktop-xl: "(min-width: 920px)";
$tablet: "(min-width: 768px) and (max-width: 991px)";
$phone: "(min-width:308px) and (max-width:767px)";
$miniPhone :"(min-width:308px) and (max-width:320px)";

$bootstrapXsSize:"(max-width: 575px)";
$bootstrapSmSize:"(min-width: 576px) and (max-width: 767px)";
$bootstrapMdSize:"(min-width: 768px) and (max-width: 991px)";
$bootstrapLgSize:"(min-width: 992px) and (max-width: 1199px)";
$bootstrapXlSize:"(min-width: 1200)";

$_fontUnit: 16px;

$scrollBarHeight : 15px;
$ddListHeaderHeight : 36px;
$ddListBottomHeight : 37px;
$commentEditorHeight : 93px;

$borderRadius: 2px;
$otisBorder: 1px;
$todoFilterMinWidth: 260px;
$noticeFilterHeight: 30px;
$myTodoTitleHeight: 50px;
$myTodoTitleFilterHeight: 35px;
$myTodoBriefDashboardHeight:150px; 
$myTodoBriefDashboardHeightXs:100px;
$myTodoDashboardHeight: 125px; //[Jane 2018-09-04 12:03:53]


$systemSettingSidebarWidth:15rem;
$systemSettingTitleHeight: 5rem;

$scale6 : 2em;
$scale5 : 1.67em;
$scale4 : 1.33em;
$scale3 : 1em;
$scale2 : 0.67em;
$scale1: 0.33em;

@mixin font-group-setting($factor){
	 $fontUnit : $_fontUnit * $factor !global;
	 $h1: 1.6 * $fontUnit * $factor !global;
	 $h-icon-md: 1.5 * $fontUnit * $factor !global;
	 $h-icon: 1.2 * $_fontUnit * $factor !global;
	 $h2: 1.1 * $fontUnit * $factor !global;
	 $h3: 1 * $fontUnit * $factor !global;
	 $h4: 0.9 * $fontUnit * $factor !global;
	 $h5: 0.7 * $fontUnit * $factor !global;
	 $h6: 0.6 * $fontUnit * $factor !global;
	 $h7: 0.5 * $fontUnit * $factor !global;
	 $h8: 0.4 * $fontUnit * $factor !global;
	 $h9: 0.3 * $fontUnit * $factor !global;
	 $h10:0.2 * $fontUnit * $factor !global;
	 $dashboard-list-height: 3 * $fontUnit * $factor !global;
	 $project-list-item-height: $dashboard-list-height * 1.2 *  $factor !global;

	 $sidebarWidth: 2.6 * $fontUnit *  $factor !global;
 	 $sidebarIconHeight: 3.2 * $fontUnit *  $factor  + 5px  !global;

	 $navbarHeight: 3.2 * $fontUnit * $factor  !global;
	 //$generalMemberHeight: $navbarHeight * 0.96 !global;

	//  $navbarHeight: 50px !global;
	 $generalMemberHeight: 38px !global;

	 $navbarHeight-vh:6vh !global;
	 $navbarHeight-min: 3* $fontUnit * $factor  !global;

	 $viewTitleHeight: 3.2 * $fontUnit * $factor   !global;

	 $swimLanHeaderHeight: 3 * $fontUnit * $factor   !global;
	 $swimLanHeaderHeight-vh: 5vh !global;

	 $todoPanelHeight: 100vh - $navbarHeight-vh !global;

	 $_todoCreateTextAreaHeight: 8vh;
	 $swimLanHeight: $todoPanelHeight - $_todoCreateTextAreaHeight - $swimLanHeaderHeight-vh - 10vh !global;
	 $swimLanOutlineHeight:  $todoPanelHeight !global;
	 $swimLanWidth: 24 * $fontUnit * $factor   !global;
	 $sidebarImagePadding: 0.3571 * $fontUnit * $factor  !global;
	 $navbarMemberMarginTop: 0.2vh !global;
	 $navbarMemberMarginTop-min: 0.2142 * $fontUnit * $factor   !global;
	 $discussionDetailHeight : 76vh !global;
	 $discussionTopicTitleWidth: 3 * $fontUnit * $factor  *1.1 !global;


	 $btn-large: 3 * $fontUnit * $factor  !global; //initial setting 50px, change to X*$fontUnit to make system configurable.
	 $btn-middle: 2 * $fontUnit * $factor  !global;
	 $barSize: 0.571 * $fontUnit * $factor  !global;

	 $projectCardRow1:3em !global;
	 $projectCardRow3:4.5em !global;
	 $projectCardRow2:1.2em !global;
	 $projectCardPadding: 0.5em !global;

	 $panel-side-margin: 5px !global;
}

// :::FIXED:::
// DEPRECATION WARNING: As of Dart Sass 2.0.0, !global assignments won't be able to declare new variables.
$fontUnit : null;
$h1: null;
$h-icon-md: null;
$h-icon: null;
$h2: null;
$h3: null;
$h4: null;
$h5: null;
$h6: null;
$h7: null;
$h8: null;
$h9: null;
$h10: null;
$dashboard-list-height: null;
$project-list-item-height: null;

$sidebarWidth: null;
$sidebarIconHeight: null;

$navbarHeight: null;
//$generalMemberHeight: null;

//  $navbarHeight: null;
$generalMemberHeight: null;

$navbarHeight-vh: null;
$navbarHeight-min: null;

$viewTitleHeight: null;

$swimLanHeaderHeight: null;
$swimLanHeaderHeight-vh: null;

$todoPanelHeight: null;

$swimLanHeight: null;
$swimLanOutlineHeight: null;
$swimLanWidth: null;
$sidebarImagePadding: null;
$navbarMemberMarginTop: null;
$navbarMemberMarginTop-min: null;
$discussionDetailHeight : null;
$discussionTopicTitleWidth: null;


$btn-large: null;
$btn-middle: null;
$barSize: null;

$projectCardRow1: null;
$projectCardRow3: null;
$projectCardRow2: null;
$projectCardPadding: null;

$panel-side-margin: null;

@include font-group-setting(0.875);


$bootstrap-primary: #337ab7;