.forgotPassword, .changePassword{
	.control-group{
		margin-bottom:0.5em;
		display:inline-block;
		width:100%;
		label{
			@include h2TextSize();
			margin-bottom: 0 !important;
			&.must-fill{
				text-indent:-0.5em;
			}
		}
		.controls{

			input{
				width:100%;
				border-radius:0%;
				font-weight:200;
			}
		}

	}
	.main-title{
		font-size: $fontUnit * 3.6 ;
		color:darkslategray;
		@media #{$desktop-md-above}{
			font-size: $fontUnit * 3.6 * 1.1 ;
		}

	}
	.main-sub-title{
		font-size: $fontUnit * 1.8  ;
		color:orangered;
		@media #{$desktop-md-above}{
			font-size: $fontUnit * 1.8 * 1.1 ;
		}
		padding-bottom:10px;
		margin:0 -1em;
	}
	.sub-title{
		font-size: $fontUnit * 1.6;
		color:darkslategray;
		@media #{$desktop-md-above}{
			font-size: $fontUnit * 1.6 * 1.1 ;
		}
		color: $main-text;
		padding-bottom: 1em;
		margin-top:-0.8em;
	} 
	.description-text{
		font-size: $fontUnit * 1.2;
		@media #{$desktop-md-above}{
			font-size: $fontUnit * 1.2 * 1.2 ;
		}
		color: black;
		padding-bottom:10px;
		font-weight: 200;
		@media #{$phone} {
			line-height: $fontUnit * 1.6;
		}

	}
}
