/* mixin scss */
$_swimLanWidth: 19 * $fontUnit;
$_swimLanMarginRightWidth: 3px;


@mixin height-vh-patch($_calcExpr) {
	height: calc(#{$_calcExpr}); // For IE 11 not support `var`
	height: calc(#{$_calcExpr} - var(--vh-offset));
}

@mixin max-height-vh-patch($_calcExpr) {
	max-height: calc(#{$_calcExpr}); // For IE 11 not support `var`
	max-height: calc(#{$_calcExpr} - var(--vh-offset));
}

@mixin min-height-vh-patch($_calcExpr) {
	min-height: calc(#{$_calcExpr}); // For IE 11 not support `var`
	min-height: calc(#{$_calcExpr} - var(--vh-offset));
}	


@mixin maxWidthAndCenter($_width){
	max-width: $_width;
	margin:auto;
}

@mixin minHeight($_height){
	min-height: $_height; 
}

@mixin otisPlaceholderColor($_placeHolderColor){
	input{
		&::placeholder {
			color: $_placeHolderColor !important;
			opacity: 1;
		}
		
		&:-ms-input-placeholder { /* IE 10+ */
		  color: $_placeHolderColor !important;
		}
	
	}
}

@mixin otisLabel(){
  background:cornflowerblue;
  margin: 0px 5px 5px 0;
  display: inline-block;
  font-size:0.8em;
  border-radius:3px;
}


@mixin otisMoreLinkColor(){
	color:white;
	&:hover{
		color:gold;
	}
	&:active{
		color:orange;
	}
}

@mixin overdueColorSet(){
	background: lighten($dueTodayColor, 10%);
	color:white;
	&.overdue{
		background:rgba(darken($overdueColor, 10%),0.7);
	}
	&.due-today{
		background: lighten($dueTodayColor, 10%);
	}
	&.due-inOneWeek{
		background: lighten($dueTodayColor, 10%);
	}
	&.due-today-done{
		background: gray;
	}
	&.overdue-done{
		background: gray;
	}
	&.done{
		background:gray;
	}
}

@mixin placeholderColor($color){
	&::-webkit-input-placeholder {
   	   color:   $color;
    }
	&::-moz-placeholder {
	   color:   $color;
	 }
	&:-moz-placeholder {
	   color:   $color;
	 }

	&:-ms-input-placeholder {
	   color:   $color;
	}
}

@mixin inputReadonlyBk($color){
	input:-moz-read-only {
		background-color: $color !important;
		border:none !important;  
	} 

	input[read-only] {
		background-color: $color !important;
		border:none !important;  
	}
	  
	/* Supported in Blink/WebKit/Edge without a prefix */
	input:read-only {
		background-color: $color !important;
		border:none !important;  
	}

	
}

@mixin smartBreakWord(){
	overflow-wrap: break-word !important;/*chrome and firefox*/
	word-wrap: break-word !important;/*for ie*/
	word-break: break-word;/*for if chrome set break-all*/
	-ms-hyphens: auto;
	-moz-hyphens: auto;
	-webkit-hyphens: auto;
	hyphens: auto;
}

@mixin smartCardWidth($cardNumber,$blockPadding,$cardMargin,$cardMinWidth){
 	min-width: $cardMinWidth !important;
	width: calc((100%  -  #{$blockPadding} * 2 - #{$cardMargin} * #{$cardNumber}) /  #{$cardNumber} );
	width: -webkit-calc((100%  -  #{$blockPadding} * 2 - #{$cardMargin} * #{$cardNumber}) / #{$cardNumber} );
}

@mixin fileThumbnail($fileMargin, $fileThumbnailNum){
	$_fileThumbnailSize: calc((100% - (#{$fileMargin} * 2) * #{$fileThumbnailNum}) / #{$fileThumbnailNum});
	margin: $fileMargin;
	width: 	$_fileThumbnailSize;
}

@mixin cardWidth(){
	width: 20em;
	
	@media #{$desktop-sm}{
		$cardNumber:3;
		$blockPadding:6px;
		$cardMargin:14px;
		$cardMinWidth:15em;
		@include smartCardWidth($cardNumber,$blockPadding,$cardMargin,$cardMinWidth); 
	}
	@media #{$desktop-xs}{

		 $cardNumber:3;
		 $blockPadding:6px;
		 $cardMargin:14px;
		 $cardMinWidth:20em;
		 @include smartCardWidth($cardNumber,$blockPadding,$cardMargin,$cardMinWidth); 
	}
	@media #{$tablet}{
		$cardNumber:2;
		$blockPadding:6px;
		$cardMargin:14px;
		$cardMinWidth:15em;
		@include smartCardWidth($cardNumber,$blockPadding,$cardMargin,$cardMinWidth); 
	}

	@media #{$phone}{
		width: calc(100% - 12px)  !important;
		//max-width:400px;
	}

	@media #{$phone} and (orientation:landscape){
		$cardNumber:2;
		$blockPadding:6px;
		$cardMargin:14px;
		$cardMinWidth:15em;
		@include smartCardWidth($cardNumber,$blockPadding,$cardMargin,$cardMinWidth); 
		max-width:400px;
	}

	@media #{$desktop-md-above}{ 
		 $cardNumber:5;
		 $blockPadding:6px;
		 $cardMargin:14px;
		 $cardMinWidth:16em;
		 @include smartCardWidth($cardNumber,$blockPadding,$cardMargin,$cardMinWidth); 
	}
	@media #{$desktop-lg-above}{
		$cardNumber:6;
		$blockPadding:6px;
		$cardMargin:14px;
		$cardMinWidth:16em;
		@include smartCardWidth($cardNumber,$blockPadding,$cardMargin,$cardMinWidth);  
		 max-width: 25em;
	 
	}
}

@mixin _projectOverdue($_overdueColor, $_fontColor){
	background: $_overdueColor !important;
	color: $_overdueColor !important;
	$_fontColor:white;
	.project-name{
		color: $_fontColor !important;
	}
    .milestone-progress{
    	color: $_fontColor !important;
    }
    .project-due-status-tag{
    	background: $_fontColor !important;
    	color : darken($_overdueColor,10%) !important;
    }
    .edit-icon, .watch-project{
    	color: $_fontColor !important;
    }
   &:hover{
   	background: darken($_overdueColor, 10%) !important;
   }
  .project-date-data{
  	font-size: 0.9em;
  	font-weight: 800;
	color: $_fontColor !important;
  }

}

@mixin retinaElementWidth($_elementWidth){
	width: $_elementWidth ;
	@media all and (-webkit-min-device-pixel-ratio: 3),(min-resolution: 414dpi) {
			width: $_elementWidth * 1.3 !important;
	}
	// @media all and (-ms-high-contrast: active), (-ms-high-contrast: none) {
	//     	width: $_elementWidth * 1.2 !important;
	// }
}

@mixin retinaFontSize($_fontSize){
	@media all and (-webkit-min-device-pixel-ratio: 3),(min-resolution: 414dpi) {
			//font-size: $_fontSize * 1;
			// NOTE: smith 希望在此規格下的字體能再大一點(todo#48316:在iPhone retina 上，字型顯得太小了)[Jane 2019-01-04 12:01:38]
			font-size: $_fontSize * 1.1; 
	}
	@media all and (-webkit-min-device-pixel-ratio: 2),(min-resolution: 192dpi) {
			font-size: $_fontSize  * 1.3;
	}
	@media all and (-webkit-min-device-pixel-ratio: 1.5),(min-resolution: 144dpi) {
			font-size: $_fontSize  * 1.1;
	}
	// @media all and (-webkit-min-device-pixel-ratio: 1.25),(min-resolution: 120dpi) {
	// 		font-size: $_fontSize  * 1.1;
	// }
	// @media all and (-ms-high-contrast: active), (-ms-high-contrast: none) {
	//     	font-size: $_fontSize * 1.1;
	// }
}

@mixin retinaFontSizeSlogan($_fontSize){
	@media all and (-webkit-min-device-pixel-ratio: 3),(min-resolution: 414dpi) {
			font-size: $_fontSize * 1.3;
	}
	// @media all and (-ms-high-contrast: active), (-ms-high-contrast: none) {
	//     	font-size: $_fontSize * 1.3;
	// }
}

@mixin retinaRWDLineHeight($_height, $_lineHeight){
	@media all and (-webkit-min-device-pixel-ratio: 3),(min-resolution: 414dpi) {
			height: $sidebarIconHeight * 1.5 !important;
			line-height: $sidebarIconHeight * 1.5 !important;
	}
	// @media all and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    // 		height: $sidebarIconHeight * 1.5  !important;
	// 		line-height: $sidebarIconHeight * 1.5  !important;
	// }
}

@mixin rwdLineHeight($_height, $_lineHeight){
	@media #{$desktop-md-above}{
		height: $sidebarIconHeight * 1.1  !important;
		line-height: $sidebarIconHeight * 1.1  !important;
	}

	@include retinaRWDLineHeight($_height, $_lineHeight);
}

@mixin sloganTitleSize(){
  //$_fontSize: 16px * 2.6;
  $_fontSize: 16px * 2.0;
	font-size: $_fontSize  !important;
	@media #{$desktop-md-above}{
		font-size: $_fontSize * 1.1 !important;
	}
  //temp for low resolution devices not applying font-size * 3 in retinaFontSizeSlogan() function
  @media all and (-webkit-min-device-pixel-ratio: 3),(max-resolution: 414dpi) {
			font-size: $_fontSize * 1.3 !important;
	}
	@include retinaFontSizeSlogan($_fontSize);
}

@mixin sloganSubTitleSize(){
  // $_fontSize: 16px * 1.6;
   $_fontSize: 16px * 1.4;
	font-size: $_fontSize  !important;
	@media #{$desktop-md-above}{
		font-size: $_fontSize * 1.1 !important;
	}
  //temp for low resolution devices not applying font-size * 3 in retinaFontSizeSlogan() function
  @media all and (-webkit-min-device-pixel-ratio: 3),(max-resolution: 414dpi) {
			font-size: $_fontSize * 1.3 !important;
	}
	@include retinaFontSizeSlogan($_fontSize);
}

@mixin sloganContextTitleSize(){
  $_fontSize: 16px * 1.6;
	font-size: $_fontSize  !important;
	@media #{$desktop-md-above}{
		font-size: $_fontSize * 1.1 !important;
	}
	@include retinaFontSizeSlogan($_fontSize);
}

@mixin sloganContextTextSize(){
  $_fontSize: 16px * 1.2;
	font-size: $_fontSize  !important;
	@media #{$desktop-md-above}{
		font-size: $_fontSize * 1.1 !important;
	}
	@include retinaFontSizeSlogan($_fontSize);
}

@mixin menuDropdownSize(){
  	$_fontSize: $h2 * 1.1;
	font-size: $_fontSize  !important;
	@media #{$desktop-md-above}{
		font-size: $_fontSize * 1.1 !important;
	}
	@include retinaFontSize($_fontSize);
}

@mixin labelSize(){
    $_fontSize: $h3;
	font-size: $_fontSize  !important;
	@media #{$desktop-md-above}{
		font-size: $_fontSize * 1.2 !important;
	}
	@media #{$phone}{
		font-size:$phoneLabelSize !important;
	}
	@include retinaFontSize($_fontSize * 1.1 );
}

@mixin h1TextSize(){
	$_fontSize: $h1 ;
	font-size: $_fontSize  !important;
	@media #{$desktop-md-above}{
		font-size: $_fontSize * 1.2 !important;
	}
	 
	@include retinaFontSize($_fontSize);
}

@mixin h2TextSize(){
	$_fontSize: $h2;
	font-size: $_fontSize  !important;
	@media #{$desktop-md-above}{
		font-size: $_fontSize * 1.2 !important;
	}
	 
	@include retinaFontSize($_fontSize * 1.1);
}

@mixin h3TextSize(){
	$_fontSize: $h3;
	font-size: $_fontSize  !important;
	@media #{$desktop-md-above}{
		font-size: $_fontSize * 1.3 !important;
	}
	 
	@include retinaFontSize($_fontSize * 1.1 );
}

@mixin mainTextSize(){
	$_fontSize: $h3;
	font-size: $_fontSize  !important;
	@media #{$desktop-md-above}{
		font-size: $_fontSize * 1.2 !important;
	}
	 
	@include retinaFontSize($_fontSize);
}

@mixin basicFontSize(){
	$_fontSize: 12px;
	font-size: $_fontSize  !important;
	@media #{$desktop-md-above}{
		font-size: $_fontSize * 1.2 !important;
	}
	@media #{$phone}{
		font-size:$phoneMenuDropdownSize !important;
	}
	@include retinaFontSize($_fontSize);
}

@mixin smallTextSize(){
	$_fontSize: $h4 ;
	font-size: $_fontSize  !important;
	@media #{$desktop-md-above}{
		font-size: $_fontSize * 1.2 !important;
	}

	@include retinaFontSize($_fontSize);
}

@mixin headerText(){
	@include h1TextSize();
	font-weight: 600;
}

@mixin headerTexth2(){
	$_fontSize: $h2 * 1.2;
	font-size: $_fontSize  !important;
	@media #{$desktop-md-above}{
		font-size: $_fontSize * 1.1 !important;
	}
	@include retinaFontSize($_fontSize);
	font-weight: 600;
}

@mixin headerTexth3(){
	$_fontSize: $h2 * 1;
	font-size: $_fontSize  !important;
	@media #{$desktop-md-above}{
		font-size: $_fontSize * 1 !important;
	}
	@include retinaFontSize($_fontSize);
	font-weight: 600;
}



@mixin memberTextSize(){
	$_fontSize: $h4 * 0.9 ;
	font-size: $_fontSize  !important;
	@media #{$desktop-md-above}{
		font-size: $_fontSize * 1.2 !important;
	}
	@include retinaFontSize($_fontSize);
	@media #{$phone}{
		font-size: $_fontSize;
	}
}


@mixin tinyTextSize(){
	$_fontSize: $h5  ;
	font-size: $_fontSize  !important;
	@media #{$desktop-md-above}{
		font-size: $_fontSize * 1.2 !important;
	}
	@include retinaFontSize($_fontSize);
}

@mixin bigIconSize(){
	$_fontSize: $h-icon;
	font-size: $_fontSize !important;
	@media #{$desktop-md-above}{
		font-size: $h-icon * 1.1 !important;
	}

	@include retinaFontSize($_fontSize);
}

@mixin mainIconSize(){
	$_fontSize: $h3;
	font-size: $_fontSize  !important;
	@media #{$desktop-md-above}{
		font-size: $_fontSize * 1.2 !important;
	}
	@media #{$phone}{
		font-size:$phoneLabelSize !important;
	}
	@include retinaFontSize($_fontSize);
}

@mixin OtisPagePadding(){
	// padding: $panel-side-margin;
	padding: $panel-side-margin 2px $panel-side-margin $panel-side-margin; // [Benx 2018-08-28 12:13:55]
	@media #{$phone}{
		padding: 5px $panel-side-margin;
	}
}
@mixin leftBottomAngle($_angleWidth, $_bkColor){
	$_borderWidth: $_angleWidth;
	$_borderColor: $_bkColor;

 	&:before{
    	position:absolute; left:-1px; bottom:-1px; content:'';
	    border-bottom: $_borderWidth solid $_borderColor ;
	    border-right: $_borderWidth solid transparent;
    }
   &:after {
	    position:absolute; left:-2px; bottom:-2px; content:'';
	    border-bottom: $_borderWidth solid $_borderColor  ;
	    border-right: $_borderWidth solid transparent;
	}
}

@mixin pageScrollArrow(){
	.scroll-X-btn{
	    font-size: 1em;
	    cursor:pointer;
		color:darken(gray, 30%) !important;
		// 桌機使用捲軸，因此應該不需要這個設計了，暫時隱藏 [Benx 2018-09-02 19:34:57]
		// 但依據 https://shrtm.nu/XoH5 工作，桌機版仍須此按鈕，故將 display 屬性註解起來 [tomas 2021-6-29]
		// display:none;
		@media #{$phone}{
			display:block;	// 手機直接觸控捲動，這個按鈕設計方便使用者一次捲動一個水道 [Benx 2018-09-02 19:34:57]
		}
		
		.arrow-left, .arrow-right {
			position: absolute;
			padding:15px 10px;
			top:25vh;
			visibility: hidden;
			color: white;
			background: $dynamicBackgroundTransparent;
			border-radius: 5px;
			box-shadow: 0 0 5px lighten($viewTitleTextColor, 20%);
			//z-index: 100;
			opacity: 0.5;
			transition: 1.5s;

			&:active {
				opacity: 1;
				transition: all 0.3s;
			}

			@include hover-supported {
				// background: darken($dynamicBackgroundTransparent, 10%);
				opacity: 0.75;
			}
		}

		.arrow-left{
			
			position: absolute;
		    // left: $_swimLanWidth  + $sidebarWidth + $panel-side-margin;
		    left: $_swimLanWidth + $_swimLanMarginRightWidth;
			
			@media #{$desktop-md-above}{
	        //   left: $_swimLanWidth * 1.2  + $sidebarWidth + $panel-side-margin;
	          left: $_swimLanWidth * 1.2 + $_swimLanMarginRightWidth;
	        }
			@media #{$phone}{
				left:0px; //todo#38061 [Jane 2018-08-28 11:12:00]
			}
		}
		
		.arrow-right{
		   	right: -1px;
		}
    }
}

@mixin todoCard($_mainColor,$_hoverPercent,$_bottomPercent,$_todoCardTextColor,$_listHeaderTextColor){
	.dd-list-header{
		background:$_mainColor;
		color: $_listHeaderTextColor ; //replace $_todoCardTextColor;
	}
	.dd-list{
		background:$_mainColor;
		height:auto;
		&.sprint-view-done{
			background: transparent !important;
		}
	}
	.dd3-content{ 
		&:hover{
			background: lighten($_mainColor, $_hoverPercent)  ;
		}
		&.todo-doing{
			background: lighten($doingTodoColor, 5%) !important;
			&:hover{
				background: lighten($doingTodoColor, 20%) !important;
			}
		}

	}
	.otis-tips{
		background:  lighten($_mainColor, 5%) !important;
		overflow-y: hidden !important;
		color: darken($_mainColor, 15%) !important;
		&:before{
			border-width:0 !important;

		}
	}
	.dd-list-bottom{
		background:darken($_mainColor, $_bottomPercent) ;
		color:$_listHeaderTextColor !important;
		@include mainTextSize();
		button{
			@include smallTextSize(); 
		} 
	}
}

@mixin overflowBottomSpace($_height){
	&:after{
		content:"";
		height: $_height;
		display:block;
	}
}

@mixin modalSmartMargin(){
	margin: 0 10% !important;
	@media #{$tablet}{
		margin:0 5% !important;
	}
	@media #{$phone}{
		margin:0 3% !important;
	}
}

@mixin todoCardShadow(){
	box-shadow: 0px 15px 10px -15px #111; 
	border:1px solid lighten(lightgray, 5%);
}

@mixin backlog-list-header(){
		.dd-list-header{
			color: $viewTitleTextColor;
			background:transparent;
			font-weight:600;
			$_lineColor: lighten($viewTitleTextColor,45%);
		
			@media #{$phone}{  
				color: black;
				border-image: normal;
				border-image: initial;
			}
		}
		.dd-list{
			background:transparent ;
		}

		.dd-list-bottom{
			background:transparent ;
		}
}

@mixin scrollTinyBar($barColor,$barSize){

	 	overflow-x:hidden;
		overflow-y:auto;
		&::-webkit-scrollbar{
			width: $barSize;
		}
		@media #{$phone}{
			&::-webkit-scrollbar{
				// width: $barSize * 1.5 !important; // not working 
				width: 2px;  // [Benx 2018-10-05 20:41:03]
			}
		}
		&::-webkit-scrollbar-track {
		    -webkit-box-shadow: inset 0 0 6px rgba($barColor,0.1);
		}
		&::-webkit-scrollbar-track-piece {
		     background:  rgba(0,0,0,0.1);
		}
		// &::-webkit-scrollbar-button {
		//       background:  rgba($barColor,0.7);
		//       border-top: 1px solid white;
		//       border-botoom: 1px solid white;
		// }
		&::-webkit-scrollbar-thumb {
		    -webkit-border-radius: 10px;
		    border-radius: 10px;
		    background:  rgba($barColor,0.4);
		    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5);
		    	&:window-inactive {
					background: rgba($barColor, 0.2);
				}
		}	
		-ms-scrollbar-base-color: lighten($barColor, 20%);
		-ms-scrollbar-track-color: $barColor;
		-ms-scrollbar-shadow-color: lightgray;

}


@mixin xsShowScroll(){
	// ::-webkit-scrollbar {
	// 	-webkit-appearance: none;
	// }
	
	// ::-webkit-scrollbar:vertical {
	// 	width: 12px;
	// }

	-webkit-overflow-scrolling: touch;
}

@mixin arrowBlock($_height,$_mainColor,$_bodyBackgroundColor,$_padding,$_arrowWidth){

	height:$_height;
	line-height:$_height;
	background: $_bodyBackgroundColor; //lighten($_mainColor, 20%);
 	padding-left:$_height + $_padding;

	&:after{
		content:"";
	    position:absolute;
	    border-bottom: ($_height * 0.5) solid transparent;
	    border-left: $_arrowWidth solid $_bodyBackgroundColor;
	    border-top: ($_height * 0.5) solid transparent;
	    height: 0px;
	    width: 0px;
	    right: $_arrowWidth;

   	}
	&:before{
		content:"";
	    position:absolute;
	    height: 0px;
	    width: 0px;
	    margin-left:   - ($_height + $_padding);
	}
}

@mixin 	swimLanWidth(){
	width: $_swimLanWidth ;
	min-width: $_swimLanWidth ;
	max-width: $_swimLanWidth;

	@media #{$desktop-md-above}{
		    width: $_swimLanWidth * 1.2 !important;
			min-width: $_swimLanWidth * 1.2  !important;
			max-width: $_swimLanWidth * 1.2  !important;
	}

	
	 @media #{$phone} {
		width: 85vw !important; 
		min-width: 85vw !important ;
		max-width: 85vw !important;
	 }

}





@mixin	todoLabelMaxWidth(){
	$_margin : 10px + 30px;
	max-width: calc( 50vw - 20px);   //$_swimLanWidth  - $_margin;
	min-width:$_swimLanWidth  - $_margin;

	@media #{$desktop-md-above}{
		  width: 100%; //$_swimLanWidth * 1.2  - $_margin;
		  min-width: $_swimLanWidth * 1.2  - $_margin ;
	}


	 @media #{$phone} {
		  max-width: calc( 100vw - 20px);
		  max-width: -webkit-calc( 100vw - 20px);
	 }
}


@mixin projectBodyWidth(){
	@media #{$desktop} {
		 width: calc(100%);
	}
	@media #{$tablet} {
		 width: calc(100%);
	}
	@media #{$phone} {
		  width: 100vw;
	}
}

@mixin otis-modal-content($_width, $_backgroundColor){
	background: $_backgroundColor;
	width: $_width;
	position: fixed;
	left:50%;
	top: 0px;
	margin-left: - ( $_width * 0.5);
	margin-top: 30px;
	border-radius: 5px;

	&.full-height {
		@include height-vh-patch("100vh");
		margin-top: 0px;
	}

	&.auto-height {
		height: auto;
	}
	
	@media #{$phone} {
		border-radius: 0px;
	}
}

@mixin multiline-ellipsis($_lineHeight, $_lines){
	text-overflow: ellipsis;
	display: flex;
	display: -webkit-box;
    line-height: $_lineHeight;
    -webkit-line-clamp: $_lines;
    -webkit-box-orient: vertical;
    max-height: $_lines * $_lineHeight;
    overflow: hidden;
    @media #{$desktop-md-above}{
  		 //line-height: $_lineHeight * 1.2;
  		 //max-height: $_lines * $_lineHeight * 1.2;
    }
}

@mixin chatLeft($_color ){
	.chat-left{
		background-color: $_color;
		border-radius: 5px;
		box-shadow: 0 0 3px #B2B2B2;
		display: inline-block;
		padding: 5px;
		position: relative;
		//vertical-align: top;
		//float: left;   
		width:100%;
		border-color: #cdecb0;  
		margin: 0px 0px 0px 10px;  
		max-width: calc(100% - 60px);
		@media #{$phone}{ 
			margin: 0px 0px 0px 10px !important;  
			max-width: calc(100% - 70px);
		}
		//z-index:0;
		&::before {
			// background-color: $_color;
			background: linear-gradient(38deg, #bedbf5 1%, rgba(255, 255, 255, 0.1) 50%), #f0f8ff;
			content: "\00a0";
			display: block;
			height: 16px;
			position: absolute;
			top: 15px;
			transform:             rotate( 29deg ) skew( -35deg );
				-moz-transform:    rotate( 29deg ) skew( -35deg );
				-ms-transform:     rotate( 29deg ) skew( -35deg );
				-o-transform:      rotate( 29deg ) skew( -35deg );
				-webkit-transform: rotate( 29deg ) skew( -35deg );
			width:  20px;
			box-shadow: -1px 1px 1px 0 rgba( 178, 178, 178, .4 );
			left: -5px;  
			//z-index:-1;
			}
	}
}

@mixin latestInfo($_padding){
    //work with _latest-info.scss
    $_mainText: $main-text;
    $_minHeight: 30vh;


   	latest-file-modal{
		$_mainColor: beige;
		background:rgba($_mainColor,$dynamicOpacityFactor);
   		padding: $_padding;
   		display:inline-block;
   		min-height: $_minHeight;
		@media #{$phone}{
			width:100%;
		}
		.latestInfo-card{
			background: lighten($_mainColor, 50%);

			@include hover-supported() {
				background: darken($_mainColor, 40%);
				color:black;
			}
			&:active{
				background: darken($_mainColor, 45%);
			}

		}
		.file-preview{
			max-height:200px;
			overflow:hidden;
		}
	}

	latest-comment-modal{
		$_mainColor:powderblue;;
		background:rgba($_mainColor,$dynamicOpacityFactor);
	    padding: $_padding;
	    display:inline-block;
   		min-height: $_minHeight;
		@media #{$phone}{
			width:100%;
		}
		.latestInfo-card{

			max-width: 100%;
			width: calc(100% - 6px);
			margin: 5px 0px 10px 6px;
			background: lighten($_mainColor, 45%);

			@include hover-supported() {
				background: darken($_mainColor, 30%);
				&:before{
					background: darken($_mainColor, 30%);
				}
			}
			&:active{
				background: darken($_mainColor, 35%);
				&:before{
					background: darken($_mainColor, 35%);
				} 
			}
		}
		
		@include chatLeft(white);
		.chat-left{
			//min-width: 100%;
		 
			&:before{
				top: 0px;
				transform: rotate(52deg) skew(-39deg);
				-moz-transform: rotate(52deg) skew(-39deg);
				-ms-transform: rotate(52deg) skew(-39deg);
				-o-transform: rotate(52deg) skew(-39deg);
				-webkit-transform: rotate(52deg) skew(-39deg);
			}
			 
		}

		 
	}
	latest-todo-modal{
		$_mainColor: skyblue;
		background:rgba($_mainColor,$dynamicOpacityFactor);
	    padding: $_padding;
   		min-height: $_minHeight;
	    display:inline-block;
		@media #{$phone}{
			width:100%;
		}
		.latest-todos{
			.recent-bell{
				margin-top: -0.5em;
			}
			.todo-card{
				background:white;
				&.todo-done {
					background:lighten(gray, 30%);
					@include hover-supported() {
						.latestInfo-card{
							
							background:lighten(gray,15%) !important;
						}
					}
			    }
				.latestInfo-card{
					background: inherit !important;
				}

				@include hover-supported() {
					.latestInfo-card{
						background: darken($_mainColor, 30%) !important;
						color:white;	
					}
					.assignee-span{
						color:white;
					}
				}

				&:active{
					.latestInfo-card{
						background: darken($_mainColor, 35%) !important;
					}
				}
			}

		}

	}
}

@mixin unSelectable{
	@media #{phone}{	// REVIEW: shall we use unSelectable !??? [Benx 2019-04-13 11:21:32]
		-webkit-touch-callout: none;
		-webkit-user-select: none;
		-khtml-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
	}
}

@mixin otisInfoCard{
	//background: rgba(100,100,100,0.1);
	background: whitesmoke;
	padding: 6px;
    border: 1px solid lightgray;
	border-radius: 8px; 
	box-shadow: 0 5px 5px -5px #333;
}

@mixin otisToggle(){
	 
	cursor:pointer;
	display:inline-block;
	//padding-right: 10px;
	&:focus{
		// outline:0;
	}
	&:hover{
		color: $toggle-color !important;
		background: $toggle-hover !important;

		.text-placeholder {
			color: $toggle-color !important;
		}
	}
	&:active{
		background: $toggle-active !important;
		color: $toggle-color !important;
	}
 
}

@mixin otisRoundBtn(){
	//border: 1px solid lightgray !important;
   // margin-right: 10px;
    //border-radius: 3px !important;
	//padding: 6px 12px !important; 
    //display: inline-block;
	cursor : pointer;
	//&:hover{
	//	@include otisToggle();

	//}
}

@mixin otisInfoTitle(){
	display:inline-block;
	border-radius: 20px;
	border: 1px solid lightgray;
	line-height: normal; 
	line-height:initial;
	padding: 3px 6px;
	background: rgba(100,100,100,0.1); 
}

@mixin hover-supported {    
    @media not all and (pointer: coarse) {
        &:hover {
            @content;
        }
    }
}


/**
 * Fix for vw, vh, vmin, vmax on iOS 7.
 * http://caniuse.com/#feat=viewport-units
 *
 * This fix works by replacing viewport units with px values on known screen sizes.
 *
 * iPhone 6 and 6 Plus cannot run iOS 7, so are not targeted by this fix.
 * Target devices running iOS 8+ will incidentally execute the media query,
 * but this will still produce the expected result; so this is not a problem.
 *
 * As an example, replace:
 *
 *   height: 50vh;
 *   font-size: 5vmin;
 *
 * with:
 *
 *   @include viewport-unit(height, 50vh);
 *   @include viewport-unit(font-size, 5vmin);
 */
 @mixin viewport-unit($property, $value, $deductPx) {
	
  
	$unit: unit($value);
  
	@if (index((vw, vh, vmin, vmax), $unit) != null) {
	  $devices: (
		(768px, 1024px), // iPad (all versions)
		(320px, 480px),  // iPhone 4
		(320px, 568px),  // iPhone 5, 5C, 5S
		(375px, 667px),  // iPhone 6/7/8
		(414px, 736px),	 // iPhone 6/7/8 Plus
		(375px, 812px),  // iPhone X
		(1024px, 1366px) // iPad Pro
	  );
  
	  @each $device in $devices {
		$device-width: nth($device, 1);
		$device-height: nth($device, 2);
  
		$device-query: "only screen and (-webkit-min-device-pixel-ratio: 1)";
		$device-query: "#{$device-query} and (device-width: #{$device-width})";
		$device-query: "#{$device-query} and (device-height: #{$device-height})";
  
		$percent: $value / ($value * 0 + 1); // see https://github.com/sass/sass/issues/533
  
		$percent-width: ($device-width * $percent / 100 )  - $deductPx !important;
		$percent-height: ($device-height * $percent / 100 ) - $deductPx   !important;
  
		@if ($unit == vmin or $unit == vmax) {
		  @media #{$device-query} {
			#{$property}: if($unit == vmin, $percent-width, $percent-height);
		  }
		}
		@else {
		  @media #{$device-query} and (orientation: portrait) {
			#{$property}: if($unit == vw, $percent-width, $percent-height);
		  }
  
		  @media #{$device-query} and (orientation: landscape) {
			#{$property}: if($unit == vw, $percent-height, $percent-width);
		  }
		}
	  }
	}
  }