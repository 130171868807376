$manual-defaultFontFamily: "Noto Sans CJK","TC","DemiLight";
$manual-defaultFontSize: 16px;
$manual-smallFontSize: 14px;
$manual-defaultFontColor: #474438;
$manual-subFontColor:#848173;
$manual-bold: 900;
$manual-titleFontSize: $manual-defaultFontSize + 2px;
$manual-mainColor: #00a99d;
$manual-mainBlack: #474438;
$manual-mainWhite: #bdccd4;
$manual-specialColor: #fff0b0;
$manual-baseWhite : #f4f3e9;

/*manual CSS*/
.manual-header,.manual-main {
	font-family: $header-font;
}

.manual-header {
	background-color: $manual-mainBlack;
	height: 50px;
	display: flex;
	align-items:center;
	color: $manual-baseWhite;
	position: fixed;
	top: 0px;
	left: 0px;
	width: 100%;
	z-index: 100;
}

.manual-header-setLayout {
	display: flex;
	align-items:center;
}

.manual-phone-sideNav{
	position: fixed;
	top: 49px;
	left: 0px;
	background-color: $manual-mainBlack;
	color:$manual-baseWhite;
	@include height-vh-patch("100vh - 50px");
	padding:20px 30px 60px 30px;
	width: 220px;
	overflow-y: scroll;
	z-index: 100;
	.manual-menu-title {
		color: #ffffff;
		padding: 5px 0px;
	}
	.manual-menu-panel {
		padding: 5px 0px;
		&:hover{
			color: $manual-specialColor;
		}
	}
	a:hover{
			text-decoration: none;
		}
}
	.manual-phon-menu-icon{
		font-size: 26px;
		margin-right: 10px;
		padding: 5px;
		&:active{
			color: darken($manual-defaultFontColor,20%);
		}
	}

	.manual-header-otis {
		height: 45px;

	}
	.manual-header-help {
		margin-left: 20px;
		height: 45px;
	}

.manual-main {
	color: $manual-mainBlack;
	@include height-vh-patch("100vh - 50px");
    overflow-y: scroll;
    background-color: #f4f3e9;

}
.manual-main--mt{
	margin: 50px 0px 0px 0px;
	
    @media #{$phone}{
		margin: 50px -15px 0px -15px;
	}
}



	.manual-sideNav {
		text-align:left;
		width: 200px;
		position: fixed;
		left: 0px;
		padding-left:20px;
		padding-top: 20px;
	}
		.manual-searchBar {
			height: 50px;
			line-height: 50px;
			margin-bottom: 10px;
			input{
				padding-left:20px;
				height: 100%;
				width: 100%;
				font-size: $manual-defaultFontSize;
				border-style: solid;
				border-width: 1px;
			}
		}
	.manual-sideNav-menu {
		@include height-vh-patch("100vh - 145px");
		overflow:auto;
		padding-bottom: 10px;
		a{
					text-decoration: none;
				}
	}
	.manual-content{
		padding-bottom:3px;
		background: #474438;
		color: aliceblue; 
		overflow:auto;
		font-size: $manual-defaultFontSize;
		text-align: center;
		width: 100%;
		height:30;	
		line-height:21px;
		
	}
	.manual-menu-title {
		color: $manual-mainColor;
		margin-bottom: 5px;
		font-size: $manual-defaultFontSize;
		font-weight: $manual-bold;
		padding:3px;
		&.menu-expanded{
			background: rgba(50,50,50,0.62);
			color: white;
		}
		a{
			color: inherit;
			&:hover{
				text-decoration: none;
			}
		}
	}
	.manual-menu-sub {
		margin-left: 10px;
	}
	.manual-menu-panel {
		margin-bottom: 3px;
		margin-left: 5px;
		color: $manual-subFontColor;
		border-left: solid 3px transparent;
		padding-left: 8px;
		&.active{
			color: darken($manual-subFontColor,50%);
			border-left-color: inherit;
			font-weight: 800;
    }
    pre{
      background-color: #d0cebe;
    }
	}
	.manual-artical {
		padding:0 20px;
		margin-left: 200px;
    	width: calc(100% - 200px);
    	@include min-height-vh-patch("100vh - 50px");
		background: rgba(100,100,100,0.1);
    	@media #{$phone}{
			margin-left: 0px;
			width: 100%;
	  	}
	}


		.manual-artical-className {
			color: $manual-mainColor;
			font-weight: $manual-bold;
			font-size: $manual-titleFontSize;
			padding-top:20px;
		}
				.manual-artical-title,.manual-artical-text,.manual-artical-img,.manual-artical-tips{
					padding-bottom: 10px;
				}
				.manual-artical-title {
					padding-top: 20px;
					font-weight: 700;
					font-size: $manual-titleFontSize;
					&:before{
						content: '-'
					}
				}
				.manual-artical-text {// must wrap every inner lis with an ul
					padding:0px 10px;
					line-height: 1.5;
					ul{
						padding-left: 20px;
					}
					li{//first li
						margin-bottom: 10px;
						list-style-type:none;
						counter-increment: firstList;
						&:before{
							content: counter(firstList) ". ";
						}
						li{//secound step li
							counter-increment: secondList;
							&:before{
								content: counter(secondList) ") ";
							}
								li{//third step li
									counter-increment: thirdList;
									&:before{
										content: counter(thirdList, upper-roman) ") ";
									}
									li{//forth step li
										counter-increment: forthList;
										&:before{
											content: counter(forthList, lower-alpha) ") ";
										}
									}
								}
						}
					}
				}
				.manual-artical-img {
					img{
						width: 100%;
						max-width: 700px;
					}
				}
				.manual-artical-tips {
					padding:10px 10px;
					background-color: $manual-specialColor;
					font-size: $manual-smallFontSize;
				}

.manual-section{
	padding-bottom: 10px;
	&:last-of-type{
		@include min-height-vh-patch("100vh - 110px");
	}
}
