

.myNotice-list-box{
	.notice-card{
		    //border: 1px solid white;
		    display: block;
		    background: $notice-card-background;
		    margin: 10px 15px ;
		    padding: 10px 10px 0px 10px;
			border:3px solid transparent;
			border-radius:10px;
			color: $notice-card-color;

			.my-activity{
				display:inline-block;
				width: calc(100% - 60px); // 60px is the read-icon size
				margin-left: 8px;
				cursor:pointer;
				//todo#40748: 
				//個人通知的項目若為檔案上傳且為圖片時，應可以顯示縮圖 （非圖片則顯示相對應檔案類型圖片）[Jane 2019-01-17]
				.activity-item-detail i[class^="icon-"], [class*=" icon-"] {
					font-size: 20px;
					line-height: 1;
				}
			}
		    .member-icon{
		    	cursor: initial;
		    	&:hover{
		    		background-color: darken( $memberIconBkColor, 0%);
	  			    color: $memberColor;
		    	}
		    }

			.my-notice-entry {
				position: relative;
				padding-bottom: 5px;

				.mark-read-btn {
					position: absolute;
					right: -7px;
					top: -10px;
					color: lightgray;
					@include h1TextSize();
				}
			}
			&.read{
				opacity:0.5;
				//background: gray;
			}
			&.unread {
				//background: rgba(50,50,50,0.5);
				&:hover{
					background: $notice-card-background-hover;
				}
				&:active{
					background: $notice-card-background;
				}
			}
			&.unview {
				border-top: 3px solid lightcoral;
			}
			&.read .my-notice-entry .mark-read-btn {
				display: none;
			}

	}

	.notice-filter{
		padding:0px 15px ;
		height: $noticeFilterHeight;
		display:inline-flex;
		background: $notice-filter-background;
		width:100%;
		justify-content: space-between;
		align-items: center;
		color: $notice-filter-color;
		@media #{$phone}{
			background: $notice-filter-mobile-background;
			color: $notice-filter-mobile-color;
		}
	}

	.myNotice-zone{
		@include height-vh-patch("100vh - #{$navbarHeight} - #{$viewTitleHeight} - #{$noticeFilterHeight}");
		background: $notice-main-background;
		color: $notice-main-color;
		overflow-x:hidden;
		@media #{$phone}{
			padding-bottom:80px;
			-webkit-overflow-scrolling: touch;
			@include height-vh-patch("100vh - #{$viewTitleHeight} - #{$noticeFilterHeight}");
		}
	}

	.dropdown-width-md{
		width:300px;
	}

	.project-recent-day{
		display:inline-flex;
		flex-wrap:wrap;
		justify-content: space-between;
		width:100%;
	}

	.otis-info-title {
		margin-right: 15px;
	}

}
// .myNotice-modal-header{
// 	color:white;
// 	background-color: $noticeBackground;
// 	border-bottom: 1px solid #e5e5e5;
// 	&.view-title-height{
// 		overflow: initial;
// 		min-height: $viewTitleHeight;
// 		height: auto;
// 	}
// }

.notice-section-tab {
	@include smallTextSize();
	cursor: pointer;
	height: 25px;
	padding: 0;
	line-height: 25px;
	display: inline-block;
	min-width:7em;
	border-radius: 0px;
	text-align: center;
	color:$viewTitleTextColor;
	background: $notice-tab-background;
	&.left{
		border-left:none;
		border-top-left-radius:6px;
		border-bottom-left-radius:6px;
	}
	&.right{
		border-left:none;
		border-top-right-radius:6px;
		border-bottom-right-radius:6px;
	}
	&:hover{
		background: $notice-tab-hover;
		color:white;
	}

	&.active {
		background: $notice-tab-active;
		color:white;
		cursor: default;
	}
	&:before {
		padding-right: 5px;
	}
}

.notice-footer{
	padding:5px 0px;
	//height: 20px;
	.project-name{
		max-width: calc( 100% - 10em);
	}
}

.my-notice-entry{
	.member-icon{
		overflow:hidden !important;
	}
	.my-notice-summary {
		margin-bottom: 8px;
	}
	.my-activity{
		.activity{
			padding-left:3px;
			display:inline-block;
			width: calc(100% - 10px); // 10px for keep space from read-icon
		}
		.label-activity-Todo{
			color:gold;
		}
		.label-activity-Phase {
			color: yellow;

		}
	}

	&.inactive {
		* {
			cursor: default;
			color: gray !important;
		}

	}


}

.my-notice-popup {
  //background: rgba(darken($dynamicBackground, 0%), 1);
  background: rgba(50,50,50,1);
  color: white;
  .activity{
	  width: calc(100% - 60px) !important; // 10px for keep space from read-icon
  }

  .mark-read-btn {
    display: none !important;
  }
}

.pop-date-time-hr-caption {
	text-align: center;

	.caption-text {
		//background: $noticeBackground;
		display: inline-block;
		padding: 3px;
		color: $viewTitleTextColor;
		@media #{$phone}{
			//background: transparent;
			color: $viewTitleTextColor;
			font-weight:600;
		}
	}

	&.sticky-enabled {
		position: sticky;
		top: 0px;
		z-index: 100;

		.caption-text {
			border: 1px solid;
			color: #fff;
			border-top: none;
			border-bottom-left-radius: 3px;
			border-bottom-right-radius: 3px;
			padding: 3px 10px;
			background: darken($noticeBackground, 20%);

		}
	}

}

.notification-dot{
	width: 7px;
    height: 7px;
    display: inline-block;
    background-color: lightcoral;
    border-radius: 50%;
    position: absolute;
    margin-top: 3px;
}
.myNotice-icon{
	overflow: hidden;
}
.myNotice-icon .notification-dot {
	width: 10px;
	height: 10px;
	margin-top: 5px;
	margin-left: -8px;
}
.notification-count-refresh.badge{
	// animation: noticeSlide 0.7s cubic-bezier(0.64, 0.57, 0.67, 1.53);
	-webkit-animation: notification-badge-grow 0.7s;
  	animation: notification-badge-grow 0.7s;
}

.notification-count-refresh.notification-dot {
	-webkit-animation: notification-dot-grow 0.7s;
  	animation: notification-dot-grow 0.7s;
}
@keyframes noticeSlide{
    0%{
        transform: translateY(0%);
    }
    100%{
        transform: translateY(-100%);

    }
    // 100%{
    //     transform: translateY(0%);
    // }
}
@-webkit-keyframes notification-badge-grow {
	30% {
		-webkit-transform: scale(1.3);
		-ms-transform: scale(1.3);
		transform: scale(1.3);
	}
	60% {
		-webkit-transform: scale(0.8);
		-ms-transform: scale(0.8);
		transform: scale(0.8);
	}
	to {
		-webkit-transform: scale(1);
				transform: scale(1);
	}
}
@keyframes notification-badge-grow {
	30% {
		-webkit-transform: scale(1.3);
		-ms-transform: scale(1.3);
		transform: scale(1.3);
	}
	60% {
		-webkit-transform: scale(0.8);
		-ms-transform: scale(0.8);
		transform: scale(0.8);
	}
	to {
		-webkit-transform: scale(1);
				transform: scale(1);
	}
}

@-webkit-keyframes notification-dot-grow {
	50% {
		-webkit-transform: scale(1.5);
		-ms-transform: scale(1.5);
		transform: scale(1.5);
	}
	80% {
		-webkit-transform: scale(0.8);
		-ms-transform: scale(0.8);
		transform: scale(0.8);
	}
	to {
		-webkit-transform: scale(1);
				transform: scale(1);
	}
}
@keyframes notification-dot-grow {
	50% {
		-webkit-transform: scale(1.5);
		-ms-transform: scale(1.5);
		transform: scale(1.5);
	}
	80% {
		-webkit-transform: scale(0.8);
		-ms-transform: scale(0.8);
		transform: scale(0.8);
	}
	to {
		-webkit-transform: scale(1);
				transform: scale(1);
	}
}