/* report form scss */

.report-div{
	.rpt-pjt-header{
		color: $viewTitleTextColor !important;
		.rpt-project-name{
			@include h2TextSize();
		}
	}
	.rpt-title{
		  color: $viewTitleTextColor !important;
		  @include h1TextSize();
		  text-align:center;
	}
	.rpt-sub-title{
		  @include h2TextSize();
			color: $viewTitleTextColor !important;
	}
	.rpt-checkbox{
		display: inline-flex;
		align-items: center;
	}
	.rpt-filter{
		text-align:right;
		padding-top:15px;
		@media #{$phone}{
			padding-right: 10px;
		}
		.filter-checkbox{
			margin-right:6px;
			display:inline-flex;
			padding: 3px 6px;
			input{
			  
  			    display: inline-flex;
				width: auto;
				margin: 0 0 0 15px;
			}
		}
		.report-duration{
			input{
				width: 7em !important;
			}
		}
		.tag-area{
			@include mainTextSize();
		}
	}


	tr{

		&.row-todo{
			background: white;
		}
		&.row-milestone{
			background: rgba(100,100,100,0.3);
			color: $viewTitleTextColor !important;
			border-bottom-color:$viewTitleTextColor;
			font-size:1.05em;
			font-weight:800;
			.milestone-dueDate{
				font-weight:normal;
				font-size:0.9em;
			}
		}
		&.row-subTodo{
			background: #eefbf3;
		}
		&.item-overdue{
			color:red;
		}
		&.item-done{
			color: gray;
			font-style:italic;
		}
		td{
			@include smartBreakWord();
			padding: 3px;
		}
	}

	.pjt-text-rpt{
		@include basicFontSize();
		thead{
			&.fixed-header{
				position: fixed;
			    display: inherit;
			    border: inherit;
			}
		}
		tr{
			height: 3em;
			min-height:3em;
		}
		&.padding-top{
			padding-top: 50px;
		}
	}


}

.member-todo-list-div{
	//.margin-bottom: 15px;
	.member-todo-list{
		  max-height:30vh;
		  min-height: 10vh;
		  //background:rgba(255,255,255,0.2);
		  padding:1px 15px;
		  $barColor: darken($mainColor, 0%);
		  @include scrollTinyBar($barColor,$barSize);
		  @media #{$phone}{
			padding:0 !important;
		  }

	}
	.member-todos{
		@media #{$phone}{
			padding:0 !important;
		}
	}
	.todo-text{
		color:$viewTitleTextColor;
		cursor: pointer;
		$_lineHeight: 1.2em;
		$_lines:2;
		@include multiline-ellipsis($_lineHeight, $_lines);
	}
	.todo-info{
		color:$dynamicColorGray;
		
	}
	.todo-effort{
		color: $reportEffort;
	}
	.todo-card{
		padding-left: 10px;
		margin-bottom: 10px;
		line-height: 1.2em;
		background: rgba(50,50,50,0.1);
		border-radius: 0px;
		box-shadow: 0px 0px 1px $viewTitleTextColor;
		padding: 5px 10px;
		text-align: left;
		min-height: 3em;
 

	}
	.title{
		color: $dynamicTextColor-dark;
		padding-bottom:5px;
		padding-top:15px;
		border-bottom:1px solid $dynamicColorGray;
	}
	.sub-title{
		line-height:1.5em;
		color: $reportSubTitle ;
		font-weight:800;
		padding:10px 0px;
		text-align: center;
	}
}