/* setting scss */
.user-setting{
	@include h2TextSize();
	.login-name, .display-name{
		
		label{
			padding-top:6px !important;
		
		}
	}
	.nav{
		padding: 10px 15px 0px 15px;
	}
	
	.nav-tabs>li>a {
		color: $profile-nav-tabs-color;
		&:hover {
			background: $profile-nav-tabs-hover;
			color: $profile-nav-tabs-active-color;
		}
	}
	
	.nav-tabs>li.active>a {
		background: $profile-nav-tabs-active;
		color: $profile-nav-tabs-active-color;
	}

	label{
		@media #{$phone}{
			padding:0px !important;
			margin:0px !important;
		}

	}

	.tab-content{
		padding:10px 15px;
		@media #{$phone} {
			// todo#26517:使用iPhone SE Save功能會被下面的瀏覽器功能列遮住，且畫面無法滑動。
			// 和Tomas討論後，決定先採用新增專案在手機視窗大小的設計。但這樣的設計感覺還是有點奇怪，之後應該會再做設計上的調整吧?!
			// 不清楚新增專案設計的max-height的57px是哪來的。 [Jane 2018-08-30 09:17:09]
			@include height-vh-patch("100vh - 57px - 57px - 130px"); 
			overflow: auto;
		}
	}
    

	input{
		padding:10px;
		height:initial;
		line-height:initial;
		font-size: inherit;
	}
	.change-pwd-btn{
		margin:-5px 0px 5px 0px;
		.btn-link{
			cursor:pointer;
		}
	}
	.footer{
		text-align: right;
	    padding: 10px 15px;
	    border-top: 1px lightgray solid;
	}
	#customizeSetting{
		.dropdown{
			button{
				width: 100%;
				padding:10px;
				height:initial;
				line-height:initial;
				background: transparent;
    			border: 1px solid lightgray;
    			text-align:left;
				font-size: inherit;
			}
			.dropdown-arrow{
				position:absolute;
				right:0.5em;
				top: 0.5em;
				
			}
			&.open{
				.dropdown-menu{
					width:100%;
				}
			}
		}
	}

	.modal-header{
		@include h2TextSize();
		font-weight:800;
	}
	.modal-body{
		@include mainTextSize();
	}
    input{
    	&.user-id{
	    	background: transparent;
		    border: none;
		    box-shadow: none;
	    }
	}
 	.google-login-button{
 		width: 100%;
 		background: transparent;
		border: 1px solid lightgray;
 		.google-img{
 			height: 30px;
 		}
 	}  
}

.calender-sync-project-modal{
	@include h2TextSize();
	.modal-body{
		max-height: 70vh;
		.title{
			font-weight:800;
			margin-top:1em;
		}
		.project-list{
			 
			padding-left:1em;
		}
		.project-name{
			display:inline-block;
			width:calc(100% - 40px);
		}
	}
	input {
		padding : 10px !important;
		vertical-align:top;
	}
	
}

.personal-setting {
	min-height: 50vh;
	max-height: 80vh;
	overflow-y: auto;
	
	$_headerMarginBaseLines: 30px;
	$_tabColor: $profile-nav-tabs-active;

	.personal-setting-nav {
		position: fixed;
		padding: 0px;
		width: 130px;

		@media  #{$phone} {
			width: 100%;
			position: relative;
		}
	}

	.nav-tab {
		padding-bottom:  5px;
		padding-left: 5px;
		margin-bottom: $_headerMarginBaseLines;

		@include hover-supported() {
			cursor: pointer;
			color: $_tabColor;
			
		}

		&.active {
			font-weight: bold;
			color: $_tabColor;
			border-bottom: 2px solid $_tabColor; 

			@media #{$phone} {
				border-color:transparent;

				.nav-tab-underline {
					border-bottom: 2px solid #4DA8FB; 
					width: 50%;
					position: fixed;
					top: 85px;
	
					&.right { right: 0;}
	
					&.left { left: 0;} 
				}

			}
		}

		@media #{$phone} {
			width: 50%;
			display: inline-block;
			margin-bottom: 15px;
			padding-left: 0px;
			text-align: center;
		}
	}

	.personal-setting-header {
		margin-bottom: $_headerMarginBaseLines;
		font-weight: bold;
		font-size: 120%;
	}
}


.personal-setting-panel {
	display: none; 
	margin-bottom: 30px;

	&.active { display: block;}

	.dropdown {
		width: 80%;

		button {
			width: 100%;
			background: transparent;
			border: 1px solid #666666;
			border-radius: 2.5px;
			text-align:left;
		}
		.dropdown-arrow{
			position:absolute;
			right: 0.5em;
			top: 0.5em;
		}

		&.open { color: #333;}

		&.open .dropdown-menu{
			width:100%;
		}
	}

}

.personal-setting-item {
	margin-bottom: 14px;
	position: relative;

	.item-name {
		margin: 8px 0px; 
		font-weight: bold;
	}

	.item-current-info {
		margin-right: 5px;
		color: #666666;
	}

	.item-detail {
		font-weight: bold; 
	}
	
	.item-multiple-settings {
		margin: 10px 0px;
	}

	.user-id {
		border: 0;
		text-align: right;

		&:focus {outline: 0}
	}

}

.personal-setting-alter {
	margin: 10px 0px;
	display: none;

	.save-result {
		margin-top: 10px;
	}

	.alter-tip {
		margin: 10px 0px;
		font-weight: bold;
	}

	.alter-value {
		display: block;
		margin: 10px 0px;
		border-radius: 2.5px !important;
		border: 1px solid #666666;
	}

	.btn-save {
		border-radius: 3px;
		margin-top: 10px;
	}

}

.email, .resetPwd {
	input {
		width: 80%;
	}
}
.resetPwd {

	.tip-pwd {
		color: #666666;
		font-size: 11px;
		margin-left: 55px;
	}	

	.tip-resend-email {
		color: #337AB7;
		font-size: 11px;
		cursor: pointer;
	}
}

.separation-line {
	border-bottom: 2px solid #E5E5E5;
	margin: 20px 0px;
}

.prompt-message {
    padding: 10px;
    width: 80%;
    border-radius: 3px;
	
	&.error {
		background: #F2DEDE;
		color: #C9302C;
	}

	&.send {
		background: #FFDE9A;
		color: #666666;
	}
}


.btn-expand{
	position: absolute;
	top: 0;
	right: 0px;
	border: 1px solid #999999;
	border-radius: 4.5px;
	background: #fff; 
	padding: 0px 10px;
	cursor: pointer;

	&:focus { outline: 0;}
}

.btn-select-project {
	border:1px solid #999999; 
	background: #74B4D0;
	border-radius: 4.5px; 
	color: #FFFFFF;

	&:focus { outline: 0;}
}

.guide-on-off {
	.btn-box {
		display:inline-block;
		vertical-align: middle;
		width: 40px;
		height: 15px;
		border-radius: 100px;
		background-color: #C2C2C2;
		box-shadow: 0px 3px 0px rgba(0,0,0,.13) inset;
	}

	.btn-switch {
		display:inline-block;
		width: 20px;
		height: 20px;
		border-radius:50%;
		background-color: #fff;
		margin-left: 0;  /* 動畫從 margin-left為 0 開始 */
		transition: .5s; 
		border: 1px solid #E5E5E5;
		box-shadow: 1px 2px 2px rgba(0,0,0,0.3);
		margin-top: -4px;
	}

	.btn-switch-checkbox {
		position:absolute;
		opacity:0;
	}
	// NOTE: "+" 影響到後方同層級的第一個元素
	.btn-switch-checkbox:checked + .btn-box {
		background-color: #8BB7F9;
	}

	.btn-switch-checkbox:checked + .btn-box .btn-switch {
		margin-left: 21px;
	}
}

@keyframes fade-in {  
    0% {opacity: 0;}   /* 初始状態 */  
    40% {opacity: 0;}  /* 過渡状態 */  
    100% {opacity: 1;} /* 结束状態 */  
}  
@-webkit-keyframes fade-in {  
    0% {opacity: 0;}  
    40% {opacity: 0;}  
    100% {opacity: 1;}  
}  

#accountSetting, #customizeSetting {    
    animation: fade-in;  
    animation-duration: .7s;  
    -webkit-animation: fade-in .5s;  
}  