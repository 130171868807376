/* pollex font scss 2016.01.30 by smith sun */
$header-font: "San Francisco","Helvetica Neue","Ubuntu",".PingFang-SC-Regular","Heiti TC", "WenQuanYi Micro Hei", "Microsoft Jhenghei", Arial, sans-serif, EUDC;
$context-font: "San Francisco","Helvetica Neue","Ubuntu",".PingFang-SC-Regular","Heiti TC","WenQuanYi Micro Hei", "Microsoft Jhenghei", Arial, sans-serif, EUDC;

$phoneMenuDropdownSize : 16px;
$phoneLabelSize : 15px;
$phoneBigIconSize: 20px;


[class^="icon-"],
[class*=" icon-"] {
    font-family: initial !important; 
    background-image: none !important; 
    line-height: 1.2;
    width:auto;
    height:auto;
    margin:0;
    vertical-align:initial;
}

[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: "icomoon" !important; 
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none; 

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}