/* project Activities */

activities-timeline-list{
	display:inline !important;
}
.pjt-activity{
	font-size:0.95em;
	padding-bottom: 70px;
	
	.timeline {
		  width: auto;
		  height: 100%;
		  margin: 20px auto;
		  position: relative;
		  .timeline:before {
			  position: absolute;
			  content: '';
			  height: 100%;
			  width: 4px;
			  background: #d1d4d7;
			  left: 50%;
			  margin-left: -2px;
			}
	}
	.timeline-left {
		  width: auto;
		  height: 100%;
		  margin: 10px auto;
		  position: relative;
		  &:before{
		  	  position: absolute;
			  content: '';
			  height: 100%;
			  width: 4px;
			  background: #d1d4d7;
			  left: 30%;
			  margin-left: -2px;
		  }
	}
	.activities-timeline-list.ng-isolate-scope {
	    display: block !important;
	}
	.timeslot-left{
	    display: inline-block;
	    position: relative;
	    width: 100%;
	    margin: 4px 0px;
	    min-height: 3em;
		// $_lines: 8;// number of lines in activity-detail-span
		// $_lineHeight:1.3em;
		// max-height: ( $_lines + 2 ) * $_lineHeight;
		// overflow:hidden;

		&.alt{
			 .activity {
			 	margin-top: 5px;
			    margin-left: 40%;
			    width: 60%;
			    -webkit-box-sizing: content-box;
			    -moz-box-sizing: content-box;
			    box-sizing: content-box;
			}
			.activity-item > .time {
				margin-top: 5px;
			    top: 1px;
			    left: 10%;
				//right: 72%;
				white-space:normal;
				width: 120px;
			    padding: 5px 20px 5px 0px;
			    font-size:0.88em;
			    line-height: 1.1em;

			    @media #{$phone} {
			    	line-height: 1.5em;
			    	width: 70px;
			    	left: 0px;
			    	margin-top: 0px;
			    }
			}
		}

		.time{
			position:absolute;

		}
		.activity{
			.activity-content{
				padding: 0px;
				margin-top: 5px;
			    display: block;
				line-height: 1.3em;
				white-space:normal;
			    @include smartBreakWord();
				.activity-field-change{
					border: 0px;
				    background: transparent;
				    padding: 1px;
				    display: inline;

				}
				.label {
					padding:0 !important;
				}
			}
		}
		.activity-detail-span{
			//display:inline-block !important;
			margin-top:6px;
			// $_lines: 8;
			// $_lineHeight:1.3em;
			// @include multiline-ellipsis($_lineHeight, $_lines);
			display:block !important;
			// max-height: $_lines * $_lineHeight;
		}

		ol.activity-detail-span {
			margin-left: -20px;
			&.--single-change {
				margin-left: -35px;
				list-style: none;
			}
		}


		.member-icon{
			    position: absolute;
    			border-radius: 0;
    			background-color: $member-background-color;
    		    color: #393939;
			    height: $generalMemberHeight;
			    width:  $generalMemberHeight;
			    top: 0;
			    left: 30%;
			    margin-left: -20px;
			    border:0;
   				// z-index: 2; todo#58403: 工作細節中的關連工作下拉選單，與 activity 歷程記錄重疊時，avatar 帳號圖示會在上面 [Jane 2019-04-22 15:12:02]
		}
	}

	.timeline-left {
	    width: auto;
	    height: 100%;
	    margin: 10px auto;
	    position: relative;
	    .time {
		    position: absolute;

		    @media #{$phone} {
		    	.timeAgo.date {
		    		display: block;
		    	}
		    }

		}
	}
	.label{
		font-size:1em !important;
	}
}


.my-notice-activity{
	$_lineHeight: 1.2em;
	$_lines:2;
	@include multiline-ellipsis($_lineHeight, $_lines);
}


.label-activity-File {
  color: lightskyblue !important; 

}

.label-activity-Comment {
  color: yellowgreen !important; 

}

.label-activity-Todo {
  color:darkorange !important;;
}

.label-activity-SubTodo {
  color:#e17300 !important;;
}

.label-todo-mark-completed {
  border:1px solid green !important;
  border-radius: 0 !important;
  padding:0 3px !important;
}

.label-activity-Project { 
    color: #ffc357  !important;

}

.label-activity-Phase { 
    color: firebrick !important;

}

.label-activity-Event,.label-comming-Event { 
    color: orangered !important;

}

.label-activity-ProjectRemarkNote {
	color: #ffc357 !important;
}



.p1_std {
  width: 100%;
  overflow: auto;
  .project_card{
  	  -webkit-transition: all linear 0.5s;
  	  transition: all linear 0.1s;

  	  border: 2px solid;
	  display: inline-block;
	  border-radius: 10px;
	  margin: 15px 15px 2px 0px;
	  vertical-align: top;
	  width: 201px;
	  height: 130px;
  	}
 }

.project-activities-div{
	//padding-right:2em;
	@media #{$phone}{
		padding: 6px;
	}
	a{
		white-space: pre-wrap;
		line-height:1.1em;
	}
	.sub-title{
		margin-top:0.5em;
		font-weight:bold;
		margin-bottom:1em;
	}
}

.activity-field-change{
	white-space:pre-wrap;
	$_lines: 5;
	$_lineHeight:1em;
	@include multiline-ellipsis($_lineHeight, $_lines);
	max-height: $_lines * $_lineHeight - 0.1em;

	  @media #{$desktop-md-above}{
			 line-height: $_lineHeight * 1.2;
			 max-height: $_lines * $_lineHeight * 1.2 - 0.1em;
	  }
}

.activity-item{
	.pop-goto-item{
		// color: $dynamicLinkColor !important;
		cursor:pointer;
		white-space: pre-wrap;
		$_lineHeight: 1em;
		$_lines: 2;
		@include multiline-ellipsis($_lineHeight, $_lines);
		@include smartBreakWord();

	}
	.activity-item-detail{
		//word-break:break-all;
		margin-top: 3px;
		white-space: pre-wrap;
		$_lineHeight: 1.2em;
		$_lines: 4;
		@include multiline-ellipsis($_lineHeight, $_lines);
		@include smartBreakWord();
	}
}