/* pollex navbar scss 2016.01.30 by smith sun */

$_iconWidth:35px;
.navbar {
	$_mainTextColor : $header-text;

	font-family: $header-font;
    margin: 0;
    padding: 0;
    top:0;
    height:   $navbarHeight ;
    //line-height:  $navbarHeight;
    min-height:  $navbarHeight-min ;

    border: none;
    border-radius: 0px;
    color: $_mainTextColor !important;
    background: $navBarColor;
    position: fixed; // change from absolute to fixed, make my notice scroll up under nav.
    width: 100vw;
    z-index: 300;
    .otis-brand{
		cursor:pointer;
		svg {
			margin-top: 0.75rem;
			margin-left: 1rem;
			path {
				fill: white;
			}
		}
		&:hover {
			svg > path {
				fill: darken(white, 10%);;
			}
		}
    }
    .xs-brand{
    	font-size: 24px !important;
    	margin-right:6px !important;
    	height: $navbarHeight !important;
		line-height: $navbarHeight !important;
		text-align:center;
		padding:0;// to overwrite the button disable properity //
		width: $_iconWidth !important;
		cursor: pointer;
		z-index:300;
		&:hover{
			background-color:lighten($main-background-hover, 20%);
			color: $viewTitleTextColor ;
		}
		&:focus{

			outline:none;

		}
		&:active,&.active{
			border:1px solid lighten($navBarColor, 20%);
			background-color:lighten($main-background-focus, 10%);
			color: $viewTitleTextColor ;
		}
		.logo-icon{
			width: $_iconWidth !important;
		}
    }
	.current-user{
		display: inline-flex;
		.memberItems{
			&.member-icon{
				background-color: initial;
				border:none;
				margin: 0px;
				cursor: initial;
				&:hover{
					background:  initial !important;
					color: inherit;
				}
				&.member-myself{
					background-color: initial;
					border: none ;
					border-radius: 0;
				}
			}
		}
	}
    .sprint-view-title{
    	$_lineHeight: 1.2em;
    	$_lines: 2;

		@include menuDropdownSize();
   		display: inline-block;
		vertical-align:top;

    	$_height: $navbarHeight;
    	$_mainColor: $navBarColor;
    	$_padding: 0px;
    	height:$_height;
    	line-height:$_height;
    	background: linear-gradient(to right, lighten($_mainColor, 15%), $navBarColor);;
     	padding-left:$_height + $_padding;
     	$_bodyBackgroundColor: $_mainColor;

		&:before{
    		content:"";
		    position:absolute;
		    border-bottom: ($_height * 0.5) solid transparent;
		    border-left: ($_height * 0.5) solid $_bodyBackgroundColor;
		    border-top: ($_height * 0.5) solid transparent;
		    height: 0px;
		    width: 0px;
		    margin-left:-($_height + $_padding)
		}

    	.sprint-view-selSprint{
    		display:inline-flex;
    		align-items:center;
    		word-break:break-word;
    		width: 22em;

    		height: $navbarHeight;
    		$_iconWidth: 15px;
			margin-left: - $_iconWidth;

			@media #{$desktop-xs}{
    		    width: 20em;
    		}
			@media #{$tablet}{
    		    width: 15em;
    		}

    		.selSprint-title{
    			padding-right: 5px;
    			width:calc(100%);
    			@include multiline-ellipsis($_lineHeight, $_lines);
    			line-height: 1.2em !important;
    			align-self: center;

	    		@media #{$phone}{
	    			margin-left:0em !important;
	    			width:calc(90%);
	    			$_lines: 1;
	    			@include multiline-ellipsis($_lineHeight, $_lines);
	    		}
    		}
    		.dateStyle{
    			font-size: 0.7em;
    			width: calc(60%);
				@media #{$desktop-xs}{
					width:calc(60%);
				}
				@media #{$tablet}{
					width:calc(100%);
				}
    			line-height: 1em;

    		}
    	}

	}
	
	.dropdown.open  {
		&.project-list {
			background-color: $navBar-selected;
		}
		
		> .dropdown-toggle.menu-setting-icon {
			border:1px solid lighten($navBarColor, 20%);
			background-color:lighten($main-background-focus, 10%);
			color: $viewTitleTextColor ;
		}
		
	}

	
	.menu-button, .icon-settings, .menu-setting-icon, .search-icon, .myTodo-icon, .myNotice-icon,
	.navbar-member-list .icon-member.show-member-btn{
		    height: $navbarHeight !important;
			line-height: $navbarHeight !important;
			font-size: $fontUnit * 1.5 !important;
		 	text-align:center;
		 	padding:0;// to overwrite the button disable properity //
		 	background: transparent;// to overwrite the button disable properity //
		 	border:0;// to overwrite the button disable properity //
			width: $_iconWidth !important;
			cursor: pointer;

			@include hover-supported {
				background-color:lighten($main-background-hover, 20%);
				color: $viewTitleTextColor ;
			}
			
			&:focus{
				outline:none;
			}
			
			&:active, &.active {
				border:1px solid lighten($navBarColor, 20%);
				color: $viewTitleTextColor ;
			}
			
			&:active {
				background-color:lighten($main-background-focus, 10%);
			}
			
			&.active {
				background-color: $main-background-focus;
			}
			
			&.disabled{ // to overwrite the button disable properity //
				cursor: initial !important;
				$_disabledColor: gray;
				padding:0 !important;
				background: transprent !important;
				background-color:transparent !important;
				color:$_disabledColor !important;
				&:hover{
					background-color:initial;
					color:$_disabledColor;
				}
				&:focus{
					background-color:initial;
					color:$_disabledColor;
				}
				&:active,&.active{
					border:none;
					background-color:initial;
					color:$_disabledColor;
				}
			}
	}
	.myNotice-icon{
		.badge{
			// font-size: x-small;
			position: absolute;
			margin-left: -10px;
			border: 1px solid lightcoral;
			padding: 1px 0px;
			border-radius: 50%;
			height: 1.5em;
			width:1.5em;
			background: lightcoral;
			margin-top: 2px;
		}
	}
	.navbar-brand{
			cursor: pointer;
			height: $navbarHeight;

			.brand-size{
				font-size: $fontUnit * 1.6;
				height: $navbarHeight;
		     	line-height: $navbarHeight ;

			}
			padding: 0 6px;

	}
	.navbar-member-list{
		//margin-right: 6px !important; // remark by smith @2018/10/15
	}

    .navbar-nav {
    	 margin:0;

    	 li{
		     a{

			    position: relative;
	   			display: inline-block;
	   			&:hover{
	   				background-color: transparent;
	   				color:inherit;
	   			}

			 	.open a:focus{
					   	background: $main-background-focus ;
				}
			}
		   .dropdown-toggle{
				&:active{
					background: $main-background-focus ;
				}
			}

		   .dropdown-menu{
		   	  background:white;
		   	  border-radius:0px;
		   	  padding:0;
		   	  margin-left:0.5vw;
		   	  //width:25em; // remove by smith @2017/02/14 , use default
		   	  border: 1px solid lighten(gray, 30%);
		   	  .open{
				  .dropdown-toggle:active{
				 	 background: $main-background-focus;
				  }
			   }
		   }
		}
	}

	.language-setting-div{
		font-size:1em;
	}
}

.nav{
	$_mainColor: $header-text;
	.dropdown{
		&.no-border{
			li{
				border:none;
			}
		}
		li{
			height: normal;
			line-height: normal; 
			height:initial;
		 	line-height:initial;
		 	border-bottom: 1px solid lighten( gray, 30%);
			text-align: left;

			@include menuDropdownSize();
		 	&.dropdown-menu-header{
  	 	 		background: $main-background-hover ;
			 	color: white;
			 	font-size: 1em;
			 	text-align: center;
			 	padding: 0.5em 1em;
				cursor: default;

				@include hover-supported() {
					color: white;
					background: $main-background-hover ;
				}

				&:focus,
				&:active{
					color: rgba($header-text, 0.5);
					background: $main-background-hover ;
				}
			}
			
			@include hover-supported() {
				background:lighten($main-background-hover, 20%);
				color: $viewTitleTextColor;
				a {
					color: $viewTitleTextColor;
				}
			}
			
			&:focus,&:active{
				background:lighten($main-background-hover, 20%);
				color: $viewTitleTextColor;
				a{
					color: $viewTitleTextColor;
				}
		   }
		   
		   &.disabled{
				&:hover,&:focus,&:active{
					background: inherit !important;
					color: inherit;
					a{
						color: inherit;

					}
				}
			}
		   
		   a{
				height:initial;
				white-space:normal;
				width:100%;
				padding: 0.5em 1em  0.5em 1.5em !important;
				&:hover,&:focus,&:active{
					color: $viewTitleTextColor;
				}
			}
			
			
			
			
		 }
	}
	&.project-dropdown{
		display:inline-block;
		@media #{$phone}{
			 width: calc(100vw - 40px * 5 - 10px );
		}
		@media #{$miniPhone}{
			width: calc(100vw - 40px * 5 - 10px );
		}
		.project-dropdown-div{
			display:inline-block;
			vertical-align: text-bottom;
			width:100%;
			ul{
				list-style-type : none;
				-webkit-padding-start: 0px;
				position: absolute;
				background: white;
			}

			.project-title-dropdown{
				li:last-child{
					border-bottom:none !important;
				}
				li.project-list-header {
					text-align: center;
					a {
						color: $modal-header-color !important;
						text-align: center;
						padding: 0.5em 0em !important;
						&:hover,&:focus,&:active{
							color: initial;
						}
					}
					
				}
				
				li.searchbox, li.project-list-header {
					&:hover,&:focus,&:active{
						background: initial !important;
					}
				}
				
				li.searchbox {
					input.ng-not-empty {
						background:lighten($main-background-hover, 20%) !important;
						color: $viewTitleTextColor;
					}
				}
			
				li.project-list-item{

					&:hover,&:active{
						.project-name{
							color: $viewTitleTextColor !important;
						}
					}
					.project-name {
						color: $modal-header-color;
						// width: calc(100% - 40px);  // We dont allow edit Project from dropdown list. Fixed by Benx [Benx 2019-04-13 09:16:19]
						display:inline-block;
					}
					.edit-icon{
						display:inline-block;
					}
					
					&.selected {
						background-color: lighten($main-background-hover, 30%);
					}
				}
			}

			 .dropdown-toggle{
			 	cursor:pointer;
			 }

			 #project-name-dropdown {
				$_projctIconWidth:20px;
				padding-right: 1.5em ;
				@include menuDropdownSize();
				height: $navbarHeight * 1;
				line-height: initial;
				display:inline-flex;
				align-items:center;
				position: relative;
				text-align: left;
				color:$_mainColor;
				margin-left:0.5vw;
				min-width: 20em;
				max-width: 30em;
				overflow: hidden;

				@media #{$phone}{
					min-width: 200px;
					max-width: 200px;
					width:200px;
				}

				.icon-project{
					width: $_projctIconWidth;
					align-items:center;
				}
				.project-sel-name{

					margin-right: 8px;
					margin-left: 3px;
					$_lines: 1;
					$_lineHeight:1.3em;
					$_settingIconWidth: 35px;
					$_logoWidth2: 41px;
					$_carretWidth: 20px;
					@include multiline-ellipsis($_lineHeight, $_lines);
					line-height: $_lineHeight !important;
					max-height: $_lines * $_lineHeight !important;
					width: calc(100% - #{$_projctIconWidth});
					word-break:break-all; // show max length word break-all words @2018/09/27 by smith sun
					// @media #{$phone}{
					// 	$_lines: 1;
					// 	@include multiline-ellipsis($_lineHeight, $_lines);
					// 	//width: calc(100vw - #{$_logoWidth2} - #{$_settingIconWidth} * 3 - #{$_carretWidth} - 5px);
					// 	//width:calc(100%);
					// 	width: 100%;   // give mobile full space @2018/09/27 by smith sun 
					 
					// }
					 



			}

				.dropdown-arrow{
					position:absolute;
					top:0;
					right:6px;
					line-height:$navbarHeight;
					height: $navbarHeight;
				}
			 }
			 .project-title-dropdown{
		   	  	 $_mainColor: $cool;
		   	  	 top:initial;
		   	  	 width: calc(100%);
		   	  	 max-height: 78vh;
				 overflow-y: auto;
				 padding:0;
				 overflow-x: hidden;
				 margin-top: 1px;
				 @media #{$phone}{
						width: 100vw !important;
						max-width:100vw !important;
						max-height:initial !important;
						height: calc(100% - #{$navbarHeight}) !important;
						border-radius: 0 !important;
				 		padding:0 0 100px 0 ;
				}
			 }
		 }

	}

	.project-setting-dropdown{
   	  	 width: auto;
   	  	 min-width: 300px;
		//  box-shadow: 0px 1px 3px gray !important;
		//  border: 1px solid lightgray;
		 background:white;
   	  	 li{
   	  	 	 border-bottom:1px solid lighten(gray, 30%) !important;
			 &:last-child{
				 border-bottom:none !important;
			 }
   	  	 	 a{
   	  	 	 	text-align:left !important;
   	  	 	 	width:100%;
                @include menuDropdownSize();

   	  	 	 	 > i {
		   	  	 	color: $dropDrown-color !important;
					margin-right: 8px;
		   	  	 }
   	  	 	 }

   	  	 	 &.system-setting{
   	  	 	 	width:100%;
   	  	 	 }
   	  	 	 &:hover{
   	  	 	 	background: lighten($main-background-hover, 20%);
   	  	 	 	a{
   	  	 	 		color: $viewTitleTextColor !important;
   	  	 	 		i{
	   	  	 	 		color: $viewTitleTextColor !important;
	   	  	 	 	}
   	  	 	 	}
   	  	 	 }
		  }
		  
	  .dropdown-menu-message {
		font-size: 1em !important;
		padding: 8px;
		text-align: center;

		&.error {
		  background: red !important;
		  color: white !important;
		}
	  }
	}

	/*.xs-menu{
		position:absolute !important
		li{
			a{
				&:hover{
					width: 100%;
				}
			}
		}
	} */
	li{
		 a{
			&:hover{
				background-color:lighten($main-background-focus, 20%);
				color:$header-text;

				i{
					color:inherit;
				}
			}
			&:focus{
				background-color:$main-background-focus;
			}

		}
	}

	&.navbar-nav{

		 &.search-div{

				.search-scope{
					margin-right:0.5em;
					select{
						padding-right:30px;
						background-color: white !important;

						padding: 0 3em 0 1em;
						font-size: 1em;
						box-shadow:inset 0 0 0 0.8em $navBarColor;
						option{
							padding:0.5em;
						}
					}
				}
				.file-search-box{
					margin-right:0.5em;
					margin-top:0.1em;
				}
			}

	}
}

.menu-setting{

	&.open{
		.menu-setting-icon{
			background-color:lighten($main-background-focus, 10%) !important;

			i{
				color: $viewTitleTextColor !important;
			}

		}
	}
}

.navbar-nav {
	display:inline-block;
	.dropdown-menu{

		&.xs-menu{
			$_mainColor:$warm;
			width: 100vw;
			background:rgba(0,0,0,0.75);
			margin-top:1px !important;
			@include height-vh-patch("100vh - #{$navbarHeight} ");
			li {
				padding: 8px 4px;
				background: white;
			}

		  }

	 }
}

@media #{$phone}{
	$_projectNameShortWidth : 95px;
	$_xsBrandWidth: 40px;
	.navbar-actions {
		display: block;
	}
	.navbar{
		.navbar-nav{
		
			li{
				ul{
					width:15em;
					.dropdown-menu{
						li{
							min-width:initial;
							a{
								width:18em;
							}
						}
					}
				}
			}

		}
	}

	.project-name{
		overflow:hidden;
	}

	#project-name-dropdown {
	    padding-right: 0px !important;
	    margin:0 !important;
		width: calc(100%) !important;
		min-width:initial !important;

		&.project-name-short-width{
			width: $_projectNameShortWidth !important;
		}
	   .dropdown-arrow{
	   		right:0px !important;
	   	 
			position:relative !important;
	   		 
	   }

	}

	.sprint-view-selSprint{
		$_arrowWidth : 8px;
		$_logoIconWidth : 60px;
 		width: calc(100vw - #{$_projectNameShortWidth} - #{$_xsBrandWidth} - #{$_iconWidth} * 3 ) !important;

	}
	 .dropdown-menu{

 	 	 &.project-title-dropdown{
	   	  	 width:100vw !important;
	   	  	 position:fixed !important;
	   	  	 top: $navbarHeight;
	   	  	 left:0;
	   	  	 max-height: 78vh !important;
			 overflow-y: auto !important;
			 li{
			 		min-width:initial !important;
			 }
		  }

		  &.project-setting-dropdown{
	   	  	 width:100vw !important;
	   	  	 //margin-right:0.5vw;
	   	  	 min-width:initial;
	   	  	 position:absolute !important;
	   	  	 background-color:white !important;
	   	  	 max-height: 75vh;
	   	  	 li{
	   	  	 	 a{
	   	  	 	 	padding:0 15px;

	   	  	 	 	 i{
			   	  	 	font-size:20px;
			   	  	 	color:$warm !important;
			   	  	 }
	   	  	 	 }
	   	  	 }
		  }
	   }
	   
  	 #otisSearchModal{
  	 	.btn-small{
  	 		padding: 4px  4px !important;
  	 		margin-top:0 !important;
  	 	}
  	 }
}

.testing-feedback{
	$_mainColor: darkorange;
	cursor: pointer;
	width: 7em;
	text-align: center;
	background: $_mainColor;
	position: fixed;
	left: -1.6em;
	transform: rotate(40deg);
	font-size: 0.8em;
	font-weight: 400;
	bottom: 0.8em;
	line-height: 1.8em;
	z-index:100;
	color:white;
	&:hover{
		background:  gold;
		color:black;
	}
	&:active{
		background: darkorange;
		color:white;
	}
}

.feedback-modal{
	//padding:0.5em;
	.feedback-textarea{
		textarea{
			width:100%;
			resize:none;
		}
	}
}

.feedback-input {
	img{
		max-height:100%;
		max-width:100%;
		margin-bottom:15px;
	}
}

.dropdown-toggle{
	.icon-startsprint,.dateStyle{
		margin-right: 6px;
	}
}

.status-ws-disconnect .menu-setting-icon {
  color: red;
}

.dropdown-menu-color-position{
	position: absolute !important;
	background-color: white !important;
}

.calendar-expanded{
	position: fixed;
	right: 0px;
	width: calc( 100vw - #{$sidebarWidth});
	@media #{$phone}{
		width: 100vw;
	}
}
