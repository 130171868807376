.week-hover {
  background-color: darkgray;
}

pop-this-weekday-selector {
	$_weekday_week_color: darken(#20b2aa, 5%);
	
	.this-weekday-selector {
		ul > li {
			display: inline-block;
			margin-left: 6px;
			margin-right: 6px;
			cursor: pointer;
			
			.bullet {
				height: 12px;
				width: 12px;
				background-color: black;
				display: inline-block;
				
				&.invisible, &.hidden {
					background-color: transparent;
					opacity: 0;
				}
			}
			
			&.weekday--0 {
				color: $_weekday_week_color;
				
				.bullet {
					background-color: $_weekday_week_color;
				}
			}
			
			.weekday-text {
				display: none;
			}
			
			&.active {
				font-size: 24px;
				margin-left: 13px;
				margin-right: 10px;
				cursor: default;
				
				.bullet {
					width: 20px;
					height: 20px;
				}
				
				.weekday-text {
					display: inline;
				}
			}
		}
		
		.tooltip.weekday--0  .tooltip-inner{
			background: $_weekday_week_color;
		}
		
		.tooltip.top.weekday--0 > .tooltip-arrow { border-top-color: $_weekday_week_color; }
		
	}
	
}

